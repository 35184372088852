<script lang="ts" setup>
import { computed, onMounted, onUnmounted, ref, Ref, watch } from 'vue';
import { toRaw } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import api from '@/api';
import { PageName } from '@/api/tracking';
import {
  FIRST_PUBLISHED_AT,
  pestNames,
  pests,
  searchPresetActivity,
} from '@/constants';
import {
  DgrIcon,
  DgrLoading,
  DgrSelectbox,
  DgrToggleButton,
} from '@stockmarkteam/donguri-ui';
import axios, { AxiosError } from 'axios';
import dayjs from 'dayjs';
import Qs from 'qs';
import ActivitiesFilter from '@/components/activities/activities-filter.vue';
import AdpDocumentCard from '@/components/common/adp-document-card.vue';
import AdpDocumentInfoDefault from '@/components/common/adp-document/adp-document-info-default.vue';
import AdpDocumentInfo from '@/components/common/adp-document/adp-document-info.vue';
import SearchBar from '@/components/common/molecules/search-bar.vue';
import { useSnackbar } from '@/components/common/snackbar/use-snackbar';
import GroupActionButtons from '@/components/group/group-action-buttons.vue';
import GroupExportActivity from '@/components/group/group-export-activity.vue';
import Content from '@/components/layouts/content.vue';
import Header from '@/components/layouts/header.vue';
import {
  AdpDocument,
  Article,
  ArticleUpdateOption,
  Comment,
  DocType,
  ExportFormatType,
  ExportGroupContentType,
  FilterCheckBoxItem,
  FilterSelectBoxItem,
  Lang,
  MemoFilterValue,
  SearchDate,
  Tag,
  TagSelectItem,
} from '@/types/index';
import { Pagination } from '@/utils';
import { useOutsideClick } from '@/utils/composables/useOutsideClick';
import { getToday } from '@/utils/dates';
import {
  ActionDataResponse,
  downloadActionData,
  handleGroupActivityDownloadError,
} from '@/utils/downloadActionData';
import { getTabs } from '@/utils/group';
import { buildNewsCategoriesByPest } from '@/utils/news_category';
import { ContentPosition, syncContentScrollWithViewport } from '@/utils/scroll';
import { selectArticle } from '@/utils/selectArticle';
import {
  STATES,
  useGroupActivityCounts,
  useGroupActivityNewsCategories,
  useGroups,
  useTeamUsers,
  useUserInfo,
} from '@/utils/swr';
import { useEmitter, useStore } from '@/utils/vue';
import ActivitiesSectionedCheckboxFilter from '../activities/activities-sectioned-checkbox-filter.vue';
import DropdownDateRangeCalendar from '../common/dropdown-date-range-calendar.vue';
import { featureFlags } from '@/featureFlags';

type CommentEntity = {
  type: 'comment';
  data: Comment;
};
type GroupMarkEntity = {
  type: 'mark';
  data: AdpDocument;
};
type GroupActivity = CommentEntity | GroupMarkEntity;
type SortFilterValue = 'updatedAt' | 'latestedAt';
type IsShowCommentFilterValue = 'showComment' | 'hideComment';
type FilterValue = SortFilterValue | IsShowCommentFilterValue;
type FilterItem<T extends FilterValue> = {
  value: T;
  label: string;
};

const SORT_FILTER_ITEMS: FilterItem<SortFilterValue>[] = [
  {
    value: 'updatedAt',
    label: '更新順',
  },
  {
    value: 'latestedAt',
    label: '最新順',
  },
];

const IS_SHOW_COMMENT_FILTER_ITEMS: FilterItem<IsShowCommentFilterValue>[] = [
  {
    value: 'showComment',
    label: 'コメントを表示',
  },
  {
    value: 'hideComment',
    label: 'コメントを非表示',
  },
];

const CONTENT_HEADER_HEIGHT = 260;
const DEFAULT_PAGE_LIMIT = 50;
const PAGE_LIMIT_FOR_WITH_OPENSEARCH_FILTER_CALL = 200;
const PAGE_NAME: PageName = 'group_activities';
const VISIBLE_ARTICLE_PAGE_LIMIT = 10;

withDefaults(
  defineProps<{
    isMenuVisible: boolean;
  }>(),
  {
    isMenuVisible: true,
  },
);

const route = useRoute();
const router = useRouter();
const emitter = useEmitter();
const store = useStore();
const { createSnackbar } = useSnackbar();

const isOpenExportModal = ref(false);
const { data: myInfo } = useUserInfo();
const { data: groups, state } = useGroups();
const rightColumn: Ref<HTMLElement | undefined> = ref<
  HTMLElement | undefined
>();
const rightContent: Ref<HTMLElement | undefined> = ref<
  HTMLElement | undefined
>();
const isLoading = ref(true);
const showingIndex = ref(VISIBLE_ARTICLE_PAGE_LIMIT);
const selectedArticles = ref<AdpDocument[]>([]);
const groupActivities = ref<GroupActivity[]>([]);
const visibleGroupActivites = computed(() => {
  return groupActivities.value.slice(0, showingIndex.value);
});
const groupMarkCount = ref<number>(0);
const activityCommentCount = ref<number>(0);
const groupMarkTotalCount = computed(() => {
  return activityArticleTotalCounts.value?.counts.group_marked_article_count;
});
const activityCommentTotalCount = computed(() => {
  return (
    (activityArticleTotalCounts.value?.counts.group_comment_article_count ??
      0) + (activityArticleTotalCounts.value?.counts.only_comment_count ?? 0)
  );
});

let pagination: Pagination | undefined = undefined;

// 検索窓
const searchQuery = ref<string>('');
const searchQueryForCountApi = ref<string>(searchQuery.value);

// 期間指定フィルタ
const INIT_END_DATE_VALUE = getToday(); // 関数を使用して値を決めているが、このスコープに限っては値を固定させたいため、明示的に大文字スネークケースで定義している
const startDate = ref<Date | undefined>();
const formattedStartDate = computed<string | undefined>(() => {
  return startDate.value
    ? dayjs(startDate.value).format('YYYY-MM-DD')
    : undefined;
});
const endDate = ref<Date | undefined>();
const formattedEndDate = computed<string | undefined>(() => {
  return endDate.value ? dayjs(endDate.value).format('YYYY-MM-DD') : undefined;
});
const dateRangeKeyForTracking = ref<string | null>(searchPresetActivity[0].key);

// 絞り込みフィルター
const selectedTags = ref<string[]>([]);
const memoFilterItems = ref<FilterSelectBoxItem<MemoFilterValue>[]>([
  {
    value: 'all',
    label: 'すべての記事',
    isSelected: false,
  },
  {
    value: 'existsMemo',
    label: 'マイメモした記事',
    isSelected: false,
  },
  {
    value: 'noMemos',
    label: 'マイメモしていない記事',
    isSelected: false,
  },
]);
const articleLangs = ref<FilterCheckBoxItem<Lang>[]>([
  { label: '国内', value: 'ja', isChecked: false },
  { label: '海外', value: 'en', isChecked: false },
]);
const docTypes = ref<FilterCheckBoxItem<DocType>[]>([
  { label: 'ニュース', value: 'article', isChecked: false },
  { label: '論文', value: 'research_paper', isChecked: false },
  { label: '特許', value: 'patent', isChecked: false },
]);

const selectedMemoExistsTypeForApiParam = computed(() => {
  const selectedMemoType = memoFilterItems.value.find(item => item.isSelected);
  if (selectedMemoType?.value === undefined || selectedMemoType.value === 'all')
    return undefined;
  return selectedMemoType.value === 'existsMemo';
});

const memoFilterLabel = computed<string>(() => {
  const selectedItem = memoFilterItems.value.find(item => item.isSelected);
  return selectedItem ? selectedItem.label : memoFilterItems.value[0].label;
});

const formattedSelectedLangForApi = computed<Lang[]>(() => {
  return articleLangs.value
    .filter(data => data.isChecked)
    .map(data => data.value);
});

const selectedLangsForTracking = computed<Lang | 'all'>(() => {
  const checkedLangs: Array<Lang> = articleLangs.value
    .filter(data => data.isChecked)
    .map(data => data.value);

  if (
    checkedLangs.length === 0 ||
    checkedLangs.length === articleLangs.value.length
  )
    return 'all';
  return checkedLangs[0]; // 中国語('zh')も対応が必要になった場合はここの修正が必要そう
});

const formattedSelectedDocTypesForApi = computed<DocType[]>(() => {
  return docTypes.value.filter(data => data.isChecked).map(data => data.value);
});

const selectedDocTypesForTracking = computed<DocType[]>(() => {
  const checkedLangs = docTypes.value
    .filter(data => data.isChecked)
    .map(data => data.value);

  // 何も選択していない場合はUI上はチェックがついていないが、
  // 計測ログにおいてはデータモデル上全て選択しているのと同じ状態なので、すべての値を返す
  // https://stockmarkteam.slack.com/archives/C05FHRWA342/p1711088626566189?thread_ts=1711074814.753969&cid=C05FHRWA342
  return checkedLangs.length > 0
    ? checkedLangs
    : docTypes.value.map(data => data.value);
});

const disableAddGroupMark = computed(() => {
  return groupMarkCount.value >= (groupMarkTotalCount.value ?? 0);
});

const disableAddComment = computed(() => {
  return activityCommentCount.value >= (activityCommentTotalCount.value ?? 0);
});

const totalActivityCount = computed(() => {
  if (isLoadingTotalActivityCounts.value || isLoading.value) return '-';
  return (
    (groupMarkTotalCount.value ?? 0) + (activityCommentTotalCount.value ?? 0)
  );
});

const isLoadingTotalActivityCounts = computed(
  () =>
    activityArticleTotalCountsFetchState.value === STATES.PENDING ||
    activityArticleTotalCountsFetchState.value === STATES.VALIDATING,
);

const exportPanelRef = ref<Element | undefined>();
const openPanelBtnRef = ref<Element | undefined>();
const groupId = computed(() => {
  if (
    route.params.groupId instanceof Array &&
    route.params.groupId[0] !== undefined
  ) {
    return Number(route.params.groupId[0]);
  }
  if (route.params.groupId !== undefined) {
    return Number(route.params.groupId);
  }

  return undefined;
});

const selectedPestNewsCategories = ref<
  InstanceType<
    typeof ActivitiesSectionedCheckboxFilter
  >['$props']['initialFormValue']
>([]);

const {
  data: activityArticleTotalCounts,
  state: activityArticleTotalCountsFetchState,
} = useGroupActivityCounts(
  groupId,
  selectedTags,
  selectedMemoExistsTypeForApiParam,
  startDate,
  endDate,
  formattedSelectedLangForApi,
  formattedSelectedDocTypesForApi,
  searchQueryForCountApi,
  selectedPestNewsCategories,
);
const tagsData: Ref<Tag[] | undefined> = ref(undefined);
const isLoadingPagenate = ref(false);
const tags = computed<TagSelectItem[]>(
  () => tagsData.value?.map(t => ({ ...t, id: t.name })) ?? [],
);
const selectedIsShowCommentFilterItem = ref<IsShowCommentFilterValue>(
  (localStorage.getItem(
    'selectedIsShowCommentFilterItem',
  ) as IsShowCommentFilterValue | null) ?? 'showComment',
);
const isShowArticleCardComment = computed({
  get: () => selectedIsShowCommentFilterItem.value === 'showComment',
  set: value => toggleShowComments(value),
});
const commentToggleLabel = computed<string>(() => {
  const selectedItem = IS_SHOW_COMMENT_FILTER_ITEMS.find(
    item => item.value === selectedIsShowCommentFilterItem.value,
  );
  return selectedItem
    ? selectedItem.label
    : IS_SHOW_COMMENT_FILTER_ITEMS[0].label;
});

const sortType = ref<SortFilterValue>(
  (localStorage.getItem('selectedSortFilterItem') as SortFilterValue | null) ??
    'updatedAt',
);
const sortFilterLabel = computed(() => {
  const selectedItem = SORT_FILTER_ITEMS.find(
    item => item.value === sortType.value,
  );
  return selectedItem ? selectedItem.label : SORT_FILTER_ITEMS[0].label;
});

const isExportLoading = computed(
  () => store.state.downloadActionData.isDownloadingMyActivity,
);
const exportErrorText = ref('');

// 大量のデータを描画するとブラウザが重くなるので、openSearchでフィルタがかかる項目が初期値の場合はデフォルトのページ数を設定する
const pageLimit = computed(() => {
  if (
    (!searchQuery.value || searchQuery.value === '') &&
    dayjs(FIRST_PUBLISHED_AT).isSame(dayjs(startDate.value), 'day') &&
    dayjs(INIT_END_DATE_VALUE).isSame(dayjs(endDate.value), 'day') &&
    selectedLangsForTracking.value === 'all'
  ) {
    return DEFAULT_PAGE_LIMIT;
  } else {
    return PAGE_LIMIT_FOR_WITH_OPENSEARCH_FILTER_CALL;
  }
});

let commentsPageRef = 1;
let groupMarksPageRef = 1;
let prevWindowScrollY = 0;
let resizeObserver: ResizeObserver | undefined = undefined;
let contentPosition: ContentPosition = 'top';

let abortController = new AbortController();

const group = computed(() =>
  groups.value?.groups.find(g => g.id === groupId.value),
);

const closeExportModal = () => {
  exportErrorText.value = '';
  isOpenExportModal.value = false;
};

const isClickedOutside = useOutsideClick([exportPanelRef, openPanelBtnRef]);

watch(isClickedOutside, newValue => {
  if (newValue) {
    exportErrorText.value = '';
    closeExportModal();
  }
});

onMounted(async () => {
  if (!validateGroup()) return;
  sendGroupView();
  window.addEventListener('scroll', handleScroll);
  window.addEventListener('resize', handleResize);
  emitter.on('comment-deleted', handleDelete);
  trackFilterActions();
  tagsData.value = await api.fetchGroupTags(groupId.value, true);
  handleScroll();
  groupActivities.value = await fetchGroupActivities();
  isLoading.value = false;
  if (rightContent.value) {
    resizeObserver = new ResizeObserver(() => handleResize());
    resizeObserver.observe(rightContent.value);
  }
  pagination = new Pagination(async (page, _limit) => {
    if (showingIndex.value < groupActivities.value.length) {
      showingIndex.value = page * VISIBLE_ARTICLE_PAGE_LIMIT;
    } else if (
      groupActivities.value.length <
        (groupMarkTotalCount.value ?? 0) + activityCommentTotalCount.value &&
      !isLoadingPagenate.value
    ) {
      await addActivity();
      showingIndex.value = page * VISIBLE_ARTICLE_PAGE_LIMIT;
    }
  }, 10);
});

const trackFilterActions = () => {
  const period = {
    from: formattedStartDate.value,
    to: formattedEndDate.value,
  };

  api.trackEvent(
    'group_activities_view',
    {
      pageName: 'group_activities',
      pageUrl: route.fullPath,
      pageGroupId: groupId.value,
    },
    undefined,
    undefined,
    {
      request: {
        tags: selectedTags.value,
        // NOTE: メモがあるかないかのフィルター選択値を計測ログでは'article_type' として使用している （経緯は不明）
        article_type: memoFilterLabel.value,
        sort: sortFilterLabel.value,
        show_comment: commentToggleLabel.value,
        lang: selectedLangsForTracking.value,
        query: searchQuery.value,
        data_sources: selectedDocTypesForTracking.value,
        period: dateRangeKeyForTracking.value,
        period_detail: dateRangeKeyForTracking.value ? null : period,
        ...(featureFlags.ANDEV_4635_ADD_CATEGORY_INFORMATION
          ? { categories: pestNewsCategoryFilterTrackingData.value }
          : {}),
      },
    },
  );
};

onUnmounted(() => {
  window.removeEventListener('scroll', handleScroll);
  window.removeEventListener('resize', handleResize);
  emitter.off('comment-deleted', handleDelete);
  resizeObserver?.disconnect();
  pagination?.removeEvent();
});

const { data: users } = useTeamUsers();

const selectedArticleGroupMarkUser = computed(() => {
  const selectedGroupMark = selectedArticles.value[0].group_marks.find(
    gm => gm.group_id === group.value?.id,
  );

  return users.value?.users.find(u => {
    return u.id === selectedGroupMark?.creator_id;
  });
});

const sendGroupView = async () => {
  if (group.value?.is_member) {
    await api.sendGroupView(group.value.id);
  }
  emitter.emit('clear-group-unread-count', group.value?.id);
};

const hasGroupMark: (article: AdpDocument) => boolean = (
  article: AdpDocument,
) => {
  return (
    article.group_marks.find(gm => gm.group_id === group.value?.id) !==
    undefined
  );
};

const validateGroup = () => {
  // グループから他のページに移動したらバリデーションが必要ない
  if (groupId.value === undefined) return false;

  if (state.value === STATES.SUCCESS && group.value === undefined) {
    createSnackbar({
      message: '指定されたグループは存在しません',
      type: 'error',
    });
    router.replace({ name: 'anewsHome' });
    return false;
  }
  return true;
};

/**
 * URLのgroupIdが変わった時は、保持していたステートをリセットし、情報を再取得する
 * (サイドメニューのグループを変えてもonMountedは動かないため考慮が必要)
 */
watch(
  () => groupId.value,
  async () => {
    if (!validateGroup()) return;
    abortController.abort();
    abortController = new AbortController();

    startDate.value = undefined;
    endDate.value = undefined;
    searchQuery.value = '';
    searchQueryForCountApi.value = '';
    selectedPestNewsCategories.value = [];
    trackFilterActions();
    await clickReset();

    tagsData.value = await api.fetchGroupTags(
      groupId.value,
      true,
      abortController,
    );
    selectedTags.value = [];
  },
);

watch(
  () => group.value,
  async () => {
    sendGroupView();
  },
);

watch(sortType, () => {
  if (sortType.value === 'updatedAt') {
    groupActivities.value = sortGroupActivitiesByUpdatedAt(
      groupActivities.value,
    );
  } else {
    groupActivities.value = sortGroupActivitiesByLatestedAt(
      groupActivities.value,
    );
  }
  trackFilterActions();
  localStorage.setItem('selectedSortFilterItem', sortType.value);
});

/* --- emitで実行される関数 start --- */
const toggleShowComments = (value: boolean) => {
  selectedIsShowCommentFilterItem.value = value ? 'showComment' : 'hideComment';
  localStorage.setItem(
    'selectedIsShowCommentFilterItem',
    selectedIsShowCommentFilterItem.value,
  );
  trackFilterActions();
};

const removeGroupMark = async (article: AdpDocument) => {
  try {
    const index = article.group_marks.findIndex(
      gm => gm.group_id === group.value?.id,
    );
    if (index === -1) {
      throw new Error('targetGroupMark is not found');
    }
    await api.deleteGroupMark(article.group_marks[index].id);
    article.group_marks.splice(index, 1);
    createSnackbar({
      message: 'グループマークを外しました',
      type: 'success',
    });
  } catch {
    createSnackbar({
      message: 'グループマークを外せませんでした',
      type: 'error',
    });
  }
};
const applyDate = async (option: {
  presetKey: string;
  value: { from: SearchDate; to: SearchDate };
}) => {
  const isCustomRange = option.presetKey === 'custom';
  dateRangeKeyForTracking.value = isCustomRange ? null : option.presetKey;
  startDate.value = option.value.from;
  endDate.value = option.value.to;
  init();
  isLoading.value = true;
  trackFilterActions();
  groupActivities.value = await fetchGroupActivities();
  isLoading.value = false;
};

const updateArticleInfo = async (
  article: AdpDocument,
  updateOptions: ArticleUpdateOption,
) => {
  const groupIdNum = groupId.value;
  if (!groupIdNum) return;
  try {
    if (updateOptions.shouldUpdateMemo) {
      if (article.memo) {
        await api.updateGroupArticleMemo(
          groupIdNum,
          article.id,
          article.doc_type,
          article.memo,
        );
      } else {
        await api
          .deleteGroupArticleMemo(groupIdNum, article.id, article.doc_type)
          .catch((err: AxiosError) => {
            if (err.response?.status !== 404) {
              throw err;
            }
          });
      }
    }

    if (updateOptions.shouldUpdateTags) {
      await api.updateGroupArticleTags(
        groupIdNum,
        article.id,
        article.doc_type,
        article.tags || [],
        article.lang,
      );
    }

    selectedArticles.value = selectedArticles.value.map(selectedArticle =>
      selectedArticle.id === article.id ? article : selectedArticle,
    );

    groupActivities.value = groupActivities.value.map(groupActivity => {
      if (groupActivity.type === 'mark') {
        return groupActivity.data.id === article.id
          ? {
              type: groupActivity.type,
              data: article,
            }
          : groupActivity;
      } else {
        return groupActivity.data.article?.id === article.id
          ? {
              type: groupActivity.type,
              data: {
                ...groupActivity.data,
                article: article,
              },
            }
          : groupActivity;
      }
    });

    await api.trackEvent(
      'set_article_option',
      {
        pageName: 'group',
        pageUrl: route.fullPath,
        feature: 'group_activities',
      },
      article.id,
      groupIdNum,
      {
        tags: article.tags,
        memo: article.memo,
      },
    );
    createSnackbar({
      message: '記事情報を更新しました',
      type: 'success',
    });
  } catch {
    createSnackbar({
      message: '記事情報を更新できませんでした',
      type: 'error',
    });
  }
};

const handleChangeQuery = (query: string) => {
  searchQuery.value = query;
};

const handleSearchQuery = async () => {
  init();
  isLoading.value = true;
  trackFilterActions();
  groupActivities.value = await fetchGroupActivities();
  isLoading.value = false;
};

const clickReset = async () => {
  isLoading.value = true;

  selectedTags.value = [];
  memoFilterItems.value.forEach(item => {
    item.isSelected = false;
  });
  articleLangs.value.forEach(item => {
    item.isChecked = false;
  });
  docTypes.value.forEach(item => {
    item.isChecked = false;
  });

  init();
  trackFilterActions();
  groupActivities.value = await fetchGroupActivities();
  isLoading.value = false;
};

const clickApply = async (values: {
  tmpSelectedTags: string[];
  tmpMemoFilterItems: FilterSelectBoxItem<MemoFilterValue>[];
  tmpArticleLangs: FilterCheckBoxItem<Lang>[];
  tmpDocTypes: FilterCheckBoxItem<DocType>[];
}) => {
  isLoading.value = true;
  selectedTags.value = structuredClone(toRaw(values.tmpSelectedTags));
  memoFilterItems.value = structuredClone(toRaw(values.tmpMemoFilterItems));
  articleLangs.value = structuredClone(toRaw(values.tmpArticleLangs));
  docTypes.value = structuredClone(toRaw(values.tmpDocTypes));

  init();
  trackFilterActions();
  groupActivities.value = await fetchGroupActivities();
  isLoading.value = false;
};
/* --- emitで実行される関数 end --- */

const sortGroupActivitiesByUpdatedAt = (groupActivities: GroupActivity[]) => {
  return groupActivities.sort(
    (x, y) =>
      getCreatedAtBySortUpdatedAt(y).getTime() -
      getCreatedAtBySortUpdatedAt(x).getTime(),
  );
};

const sortGroupActivitiesByLatestedAt = (groupActivities: GroupActivity[]) => {
  return groupActivities.sort(
    (x, y) =>
      getCreatedAtBySortLatestedAt(y).getTime() -
      getCreatedAtBySortLatestedAt(x).getTime(),
  );
};

const handleDelete = (id: Comment['id']) => {
  groupActivities.value = groupActivities.value.filter(
    activity => activity.data.id !== id,
  );
};

const toggleSelected = (e: MouseEvent, item: AdpDocument) => {
  selectedArticles.value = selectArticle(e, item, selectedArticles.value);
};

const init = () => {
  groupActivities.value = [];
  selectedArticles.value = [];
  groupMarksPageRef = 1;
  commentsPageRef = 1;
  groupMarkCount.value = 0;
  activityCommentCount.value = 0;
};

const fetchComment: () => Promise<Comment[]> = async () => {
  const { comments: groupComments } = await api.fetchGroupActivityComments(
    groupId.value,
    commentsPageRef,
    pageLimit.value,
    selectedTags.value,
    abortController,
    selectedMemoExistsTypeForApiParam.value,
    startDate.value,
    endDate.value,
    formattedSelectedLangForApi.value,
    formattedSelectedDocTypesForApi.value,
    searchQuery.value,
    selectedPestNewsCategories.value,
  );
  activityCommentCount.value += groupComments.length;
  commentsPageRef = commentsPageRef + 1;
  return groupComments;
};

const fetchGroupMarks: () => Promise<Article[]> = async () => {
  const { articles: groupMarks } = await api.fetchGroupMarks(
    groupId.value,
    groupMarksPageRef,
    pageLimit.value,
    selectedTags.value,
    abortController,
    selectedMemoExistsTypeForApiParam.value,
    startDate.value,
    endDate.value,
    formattedSelectedLangForApi.value,
    formattedSelectedDocTypesForApi.value,
    searchQuery.value,
    selectedPestNewsCategories.value,
  );
  groupMarkCount.value += groupMarks.length;
  groupMarksPageRef = groupMarksPageRef + 1;
  return groupMarks;
};

const fetchGroupActivities = async () => {
  // NOTE: アクティビティ総件数を(swrvで)取得するAPIが検索窓の値が変わる度に呼び出されないように、検索実行時のみ値を更新する
  // また、検索窓にテキスト入力まではしたが、Enterキーで絞り込み実行せずに他の絞り込み項目で絞り込み実行した場合を考慮して、ここで値を更新する
  searchQueryForCountApi.value = searchQuery.value;
  const [groupComments, groupMarks] = await Promise.all([
    fetchComment(),
    fetchGroupMarks(),
  ]);

  const commentsResponse: CommentEntity[] = groupComments.map(
    (comment: Comment) => {
      return {
        type: 'comment',
        data: comment,
      };
    },
  );
  const groupMarkResponse: GroupMarkEntity[] = groupMarks.map(
    (article: Article) => {
      return {
        type: 'mark',
        data: article,
      };
    },
  );

  const result =
    sortType.value === 'updatedAt'
      ? sortGroupActivitiesByUpdatedAt([
          ...commentsResponse,
          ...groupMarkResponse,
        ])
      : sortGroupActivitiesByLatestedAt([
          ...commentsResponse,
          ...groupMarkResponse,
        ]);
  return result;
};

const handleResize = (event?: Event) => handleScroll(event, true);
const handleScroll = (_?: Event, reset = false): void => {
  if (!rightColumn.value || !rightContent.value) return;
  const data = syncContentScrollWithViewport(
    rightContent.value,
    rightColumn.value,
    prevWindowScrollY,
    contentPosition,
    CONTENT_HEADER_HEIGHT,
    0,
    reset,
  );
  if (data) {
    prevWindowScrollY = data.windowScrollY;
    contentPosition = data.contentPosition;
  }
};

const getCreatedAtBySortLatestedAt: (groupActivity: GroupActivity) => Date = (
  groupActivity: GroupActivity,
) => {
  if (groupActivity.type === 'comment') {
    const comment = groupActivity.data;
    return new Date(comment.created_at);
  } else {
    const groupMark = groupActivity.data;
    return new Date(
      groupMark.group_marks[groupMark.group_marks.length - 1].created_at,
    );
  }
};

const getCreatedAtBySortUpdatedAt: (groupActivity: GroupActivity) => Date = (
  groupActivity: GroupActivity,
) => {
  // コメントの場合,以下を比較して最大値を抽出
  // 「1:コメントの生成日時」, 「2:子コメントの生成日時」
  if (groupActivity.type === 'comment') {
    const comment = groupActivity.data;
    const childCommentsDate = comment.children.map(comment => {
      return new Date(comment.created_at).getTime();
    });
    const dates = [
      ...childCommentsDate,
      new Date(comment.created_at).getTime(),
    ];
    return new Date(Math.max(...dates));
  } else {
    // groupMarkの場合,以下を比較して最大値を抽出
    // 「1:コメントの生成日時」、「2:子コメントの生成日時」,「３:グループマークの生成日時」
    const groupMark = groupActivity.data;
    const commentCreatedAtList = groupMark.comments
      .map(comment => {
        const list = comment.children.map(childComment => {
          return new Date(childComment.created_at).getTime();
        });
        const result = [new Date(comment.created_at).getTime(), ...list];
        return result;
      })
      .flat();

    const groupMarksCreatedAtList = groupMark.group_marks.map(groupMark => {
      return new Date(groupMark.created_at).getTime();
    });
    const dates = [...commentCreatedAtList, ...groupMarksCreatedAtList];
    return new Date(Math.max(...dates));
  }
};

const isSelected = (item: AdpDocument) => {
  return selectedArticles.value.map(i => i.id).includes(item.id);
};

const enableHover = (article: AdpDocument): boolean | undefined => {
  return !isSelected(article);
};

const setupModal = () => {
  store.commit('modal/setGroup', group.value);
};

const openCommentCreateModalWithoutArticleInfo = () => {
  setupModal();
  store.commit('modal/showModal', 'commentCreateWithoutArticle');
};

store.subscribe(mutation => {
  if (mutation.type === 'modal/setWithoutArticleComment') {
    const newComment: Comment = mutation.payload;
    groupActivities.value.unshift({
      type: 'comment',
      data: newComment,
    });
  }
});

const exportSubmit = async (option: {
  selectedTypes: ExportGroupContentType[];
  exportType: ExportFormatType;
}) => {
  try {
    store.commit('downloadActionData/setIsDownloadingMyActivity', true);

    const startDate = formattedStartDate.value;
    const endDate = formattedEndDate.value;
    const period = {
      start: startDate,
      end: endDate,
    };

    const exportUrl = `/groups/${groupId.value}/action_data?${Qs.stringify(
      {
        tags: selectedTags.value,
        exists_memo: selectedMemoExistsTypeForApiParam.value,
        lang:
          selectedLangsForTracking.value === 'all'
            ? undefined
            : selectedLangsForTracking.value,
        doc_types: formattedSelectedDocTypesForApi.value,
        from: period.start,
        to: period.end,
        query: searchQuery.value !== '' ? searchQuery.value : undefined,
        news_categories: selectedPestNewsCategories.value,
        types: option.selectedTypes,
        tracking_data: {
          page: {
            url: route.fullPath,
            name: 'group_activity_download',
          },
          format: option.exportType,
          period,
          request: {
            sort: sortType.value,
            tags: selectedTags.value,
            // NOTE: メモがあるかないかのフィルター選択値を計測ログでは'article_type' として使用している （経緯は不明）
            article_type: memoFilterLabel.value,
            show_comment: commentToggleLabel.value,
            lang: selectedLangsForTracking.value,
            query: searchQuery.value,
            data_sources: selectedDocTypesForTracking.value,
            period,
          },
          feature: 'group_activity_download',
        },
      },
      { arrayFormat: 'brackets' },
    )}`;
    const { data } = await axios.get<ActionDataResponse>(exportUrl);

    const fileNames = ['action_data'];
    if (startDate) {
      fileNames.push(startDate);
    }
    if (endDate) {
      fileNames.push(endDate);
    }
    const extension = option.exportType === 'excel' ? 'xlsx' : 'csv';

    const filename = `${fileNames.join('_')}.${extension}`;
    await downloadActionData(filename, data, option.exportType);
  } catch (err) {
    exportErrorText.value = handleGroupActivityDownloadError(err);
    return;
  } finally {
    store.commit('downloadActionData/setIsDownloadingMyActivity', false);
  }

  isOpenExportModal.value = false;
};

const addActivity = async () => {
  isLoadingPagenate.value = true;
  let response = [];
  if (
    selectedTags.value.length === 0 &&
    selectedMemoExistsTypeForApiParam.value === undefined
  ) {
    response = await fetchGroupActivities();
  } else {
    const groupMarks = await fetchGroupMarks();
    const groupMarkResponse: GroupMarkEntity[] = groupMarks.map(
      (groupMark: Article) => {
        return {
          type: 'mark',
          data: groupMark,
        };
      },
    );
    response = sortGroupActivitiesByLatestedAt(groupMarkResponse);
  }
  groupActivities.value = [...groupActivities.value, ...response];
  isLoadingPagenate.value = false;
};

/**
 * PESTニュースカテゴリー情報フィルター
 */

const { data: fetchedActivityNewsCategories } =
  useGroupActivityNewsCategories(groupId);

const activityNewsCategoriesByName = computed<Record<string, number>>(() => {
  if (fetchedActivityNewsCategories.value === undefined) return {};

  return fetchedActivityNewsCategories.value.activity_news_categories.reduce(
    (acc, cur) => {
      return {
        ...acc,
        [cur.name]: cur.count,
      };
    },
    {},
  );
});

const pestNewsCategoryFilterSections = computed(() => {
  const newsCategoriesByPest = buildNewsCategoriesByPest();

  return pests.map(pest => {
    return {
      sectionName: pestNames[pest],
      options: newsCategoriesByPest[pest]
        .flatMap(category => {
          const count = activityNewsCategoriesByName.value[category] ?? 0;

          if (count === 0) return [];

          return {
            label: `${category}(${count})`,
            value: category,
          };
        })
        .sort((a, b) => {
          return activityNewsCategoriesByName.value[a.value]! >
            activityNewsCategoriesByName.value[b.value]!
            ? -1
            : 1;
        }),
    };
  });
});

const pestNewsCategoryFilterTrackingData = computed(() =>
  pestNewsCategoryFilterSections.value.flatMap(section => {
    const selectedOptions = section.options.filter(option =>
      selectedPestNewsCategories.value.includes(option.value),
    );

    return selectedOptions.length > 0
      ? [
          {
            name: section.sectionName,
            items: selectedOptions.map(option => ({ name: option.value })),
          },
        ]
      : [];
  }),
);

const clickApplyPestNewsCategoryFilter = async (
  tmpPestNewsCategories: string[],
) => {
  isLoading.value = true;
  selectedPestNewsCategories.value = structuredClone(
    toRaw(tmpPestNewsCategories),
  );

  init();
  trackFilterActions();
  groupActivities.value = await fetchGroupActivities();
  isLoading.value = false;
};
</script>

<template>
  <div class="group-activity" v-if="group">
    <Header
      v-if="myInfo"
      :title="group.name"
      :detail="`メンバー${group.member_count}人`"
      :tabs="getTabs(group, myInfo)"
      content-width="912px"
      :is-menu-visible="isMenuVisible"
    >
      <template #button>
        <GroupActionButtons
          v-if="group.group_type !== 'all_user_group'"
          :group="group"
        ></GroupActionButtons>
      </template>
    </Header>
    <Content>
      <div class="group-content">
        <div class="action-bar">
          <div class="group-activity-header">
            <div class="title">
              <span class="c-title c-title--m">アクティビティ</span>
              <span class="c-text c-text--s">・{{ totalActivityCount }}件</span>
            </div>
            <button
              class="c-btn c-btn--small group-activity-add-comment-btn"
              @click="openCommentCreateModalWithoutArticleInfo"
            >
              グループにコメントする
            </button>
          </div>
          <div>
            <div class="spacing-24"></div>
            <div class="group-activity-search-input">
              <!-- グループごとにインスタンスが再生成させるように:keyでgroupIdを設定 -->
              <SearchBar
                :key="groupId"
                :placeholder="'記事を検索'"
                @on-change-query="handleChangeQuery"
                @on-search-query="handleSearchQuery"
              ></SearchBar>
            </div>
          </div>
          <div class="spacing-16"></div>
          <div class="group-activity-btns-header">
            <div class="group-activity-selects">
              <div class="tag-selectbox">
                <DgrSelectbox
                  :options="SORT_FILTER_ITEMS"
                  v-model="sortType"
                  size="small"
                ></DgrSelectbox>
              </div>
              <div class="spacing-16"></div>
              <ActivitiesFilter
                :is-group-page="true"
                :article-tag-list="tags"
                :selected-tags="selectedTags"
                :memo-filter-items="memoFilterItems"
                :article-langs="articleLangs"
                :doc-types="docTypes"
                @click-reset="clickReset"
                @click-apply="clickApply"
              />
              <div class="spacing-16"></div>
              <DropdownDateRangeCalendar
                :range="{
                  from: startDate,
                  to: endDate,
                }"
                :preset="searchPresetActivity"
                :min="FIRST_PUBLISHED_AT"
                :max="getToday()"
                :is-open="true"
                :disabled="false"
                :font-size="'small'"
                :icon-size="'small'"
                @apply-date="applyDate"
              ></DropdownDateRangeCalendar>
              <template v-if="featureFlags.ANDEV_4635_ADD_CATEGORY_INFORMATION">
                <div class="spacing-16"></div>
                <ActivitiesSectionedCheckboxFilter
                  :initial-form-value="selectedPestNewsCategories"
                  :sections="pestNewsCategoryFilterSections"
                  @submit="clickApplyPestNewsCategoryFilter"
                />
              </template>
              <div class="spacing-16"></div>
              <DgrToggleButton
                class="c-text c-text--s show-comment-toggle"
                v-model="isShowArticleCardComment"
              >
                コメントを表示
              </DgrToggleButton>
            </div>
            <div class="dw-panel-container">
              <button
                class="dw-open-panel-btn dashboard-button"
                @click="() => (isOpenExportModal = !isOpenExportModal)"
                ref="openPanelBtnRef"
              >
                <DgrIcon size="small" name="download" class="c-btn__icon" />
                <div class="c-btn__text">ダウンロード</div>
                <DgrIcon
                  size="small"
                  name="angle-down"
                  class="c-selectBox__arrow"
                />
              </button>
              <div ref="exportPanelRef" class="export-panel">
                <GroupExportActivity
                  v-if="isOpenExportModal"
                  :is-loading="isExportLoading"
                  :error-text="exportErrorText"
                  @on-submit="exportSubmit"
                  @on-close="closeExportModal"
                />
              </div>
            </div>
          </div>
          <div class="group-activity-content">
            <div class="group-activity-card-list-area">
              <div v-if="isLoading || isLoadingTotalActivityCounts">
                <DgrLoading class="loading-spinner" />
              </div>
              <div v-else-if="totalActivityCount === 0">
                <div class="no-activity">
                  <div class="content">
                    <div class="c-text c-text--m">
                      該当するアクティビティはありませんでした。
                    </div>
                  </div>
                  <img src="@/assets/state-prepare-feed-small.png" />
                </div>
              </div>
              <div
                v-for="activity in visibleGroupActivites"
                :key="`${activity.type}_${activity.data.id}`"
              >
                <template
                  v-if="activity.type === 'comment' && !activity.data.article"
                >
                  <div
                    class="group-activity-card"
                    v-if="isShowArticleCardComment"
                  >
                    <AdpDocumentCard
                      width="100%"
                      :theme-id="activity.data.theme_id"
                      :comment="activity.data"
                      :page-name="PAGE_NAME"
                      :feature="'group_activities'"
                      :show-tag-list="true"
                      :priority-display-tags="selectedTags"
                    ></AdpDocumentCard>
                  </div>
                </template>
                <template
                  v-else-if="
                    activity.type === 'comment' && activity.data.article
                  "
                >
                  <div class="group-activity-card">
                    <AdpDocumentCard
                      width="100%"
                      :adp-document="activity.data.article"
                      :theme-id="activity.data.theme_id"
                      :group="group"
                      :show-comment="isShowArticleCardComment"
                      :page-name="PAGE_NAME"
                      :feature="'group_activities'"
                      @click="toggleSelected($event, activity.data.article)"
                      :enable-hover="enableHover(activity.data.article)"
                      :is-selected="isSelected(activity.data.article)"
                      @click-remove-group-mark="
                        removeGroupMark(activity.data.article)
                      "
                      :is-use-remove-group-mark="
                        hasGroupMark(activity.data.article)
                      "
                      :show-tag-list="true"
                      :priority-display-tags="selectedTags"
                    ></AdpDocumentCard>
                  </div>
                </template>

                <template v-else-if="activity.type === 'mark'">
                  <div class="group-activity-card">
                    <AdpDocumentCard
                      width="100%"
                      :adp-document="activity.data"
                      :theme-id="activity.data.theme_id"
                      :group="group"
                      :feature="'group_activities'"
                      :show-comment="isShowArticleCardComment"
                      :page-name="PAGE_NAME"
                      :is-selected="isSelected(activity.data)"
                      :enable-hover="enableHover(activity.data)"
                      @click="toggleSelected($event, activity.data)"
                      @click-remove-group-mark="removeGroupMark(activity.data)"
                      :is-use-remove-group-mark="hasGroupMark(activity.data)"
                      :show-tag-list="true"
                      :priority-display-tags="selectedTags"
                    ></AdpDocumentCard>
                  </div>
                </template>
              </div>
              <div class="group-activity-add-item-btn-container">
                <button
                  class="c-btn c-btn--small c-btn--AnewsPrimary c-text c-text--"
                  @click="addActivity"
                  v-if="
                    !(disableAddComment && disableAddGroupMark) &&
                    !isLoading &&
                    !isLoadingTotalActivityCounts
                  "
                  :disabled="isLoadingPagenate"
                >
                  もっと見る
                </button>
              </div>
            </div>
            <div class="side-content" ref="rightColumn">
              <div class="side-content-body" ref="rightContent">
                <AdpDocumentInfo
                  class="article-info"
                  v-if="selectedArticles.length === 1"
                  :editable="true"
                  :adp-document="selectedArticles[0]"
                  :creator="selectedArticleGroupMarkUser"
                  :tag-list="tags"
                  @article-info-updated="updateArticleInfo"
                  :is-group-view="true"
                  :group-id="groupId"
                  :page-name="PAGE_NAME"
                  :has-document-export="true"
                ></AdpDocumentInfo>
                <AdpDocumentInfoDefault
                  :is-group-view="true"
                  :adp-document="selectedArticles"
                  :page-name="PAGE_NAME"
                  :group-id="groupId"
                  :has-document-export="true"
                  v-else
                ></AdpDocumentInfoDefault>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Content>
  </div>
</template>

<style lang="scss" scoped>
.loading-spinner {
  width: 100%;
  margin: 0 auto;
}
.group-activity {
  margin: -24px 0 0 0;

  .group-activity-btns-header {
    margin-bottom: 16px;
    display: flex;
    justify-content: space-between;
    width: clamp(400px, calc(100vw - 400px), 912px);
  }

  .no-activity {
    display: flex;
    padding: 8px 32px;
    background-color: #fff;
    border: 1px solid $color-gray400;
    border-radius: 4px;
    justify-content: space-between;

    .content {
      display: flex;
      flex-direction: column;
      justify-content: center;
    }

    img {
      width: 100px;
      height: 100px;
    }
  }

  .group-activity-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: clamp(400px, calc(100vw - 400px), 912px);
    .title {
      .c-text--s {
        color: $color-gray600;
        font-weight: bold;
      }
    }

    .group-activity-add-comment-btn {
      width: auto;
      padding-left: 8px;
      padding-right: 8px;
      background-color: white;
      border: 1px solid $color-green600;
      color: $color-green600;
    }
  }

  .group-activity-selects {
    display: flex;

    .search-settings {
      position: relative;
      .c-selectBox {
        .c-selectBox__placeholder.c-text--s {
          line-height: 14px;
        }
      }
      .filter-line {
        display: grid;
        grid-template-columns: 1fr 1fr;
        align-items: center;
      }
      .right-aligned {
        right: 0px;
      }
      .option-select {
        position: absolute;
        z-index: var(--z-dropdown);
        width: 456px;
        padding: 16px;
        box-shadow: 0 1px 5px rgba(74, 74, 74, 0.25);
        // dongri-uiのコンポーネントのスタイルを上書き
        .selectBox-container :deep(.c-selectBox) {
          width: fit-content;
          padding: 0px 8px;
          height: 32px;
        }
        .tag-box :deep(.selector-popup) {
          display: none;
        }
        .checkbox-container {
          display: flex;
          align-items: center;
          gap: 16px;
          .checkbox-item {
            display: flex;
            align-items: center;
          }
        }
        .option-buttons {
          display: flex;
          justify-content: space-between;
          padding-top: 16px;
          border-top: 1px solid $color-border;
          .right-buttons {
            display: flex;
          }

          .dashboard-button--borderless {
            color: $color-green600;
          }
        }
      }
    }
  }

  .group-activity-search-input,
  .group-activity-card-list-area {
    width: 616px;
    .group-activity-card {
      margin-bottom: 16px;
    }
  }
  .side-content {
    position: relative;
    width: 288px;
    margin-left: 16px;
    box-sizing: border-box;
    padding-bottom: 80px;

    .side-content-body {
      bottom: auto;
      width: 288px;
      padding-bottom: 80px;

      .article-info {
        margin-bottom: 20px;
      }
    }
  }
  .export-button {
    width: 200px;
  }

  .group-activity-content {
    display: flex;
  }
  .group-activity-add-item-btn-container {
    width: 100%;
    display: flex;
    justify-content: center;
  }

  .selected-tags {
    width: 920px;
  }
}
.dw-panel-container {
  position: relative;
  .dw-open-panel-btn {
    display: flex;
    align-items: center;

    .icon-box {
      margin-right: 8px;
    }

    .c-selectBox__arrow {
      margin-right: 0;
    }
  }
  .export-panel {
    position: absolute;
    width: 600px;
    z-index: 100;
    right: 0;
  }
}
.show-comment-toggle {
  align-items: center;
}
</style>
