import { UserDocumentEntity } from '@/types';
import { formatDate } from '@/utils/formatters';

export const getPageUrl = (page: number, url: string) => {
  if (url.includes('box.com')) return `${url}#p=${page}`;
  else if (url.includes('sharepoint.com')) return `${url}#page=${page}`;
  return url;
};

export const getIsPageNumberAvailable = (doc: UserDocumentEntity) => {
  return doc.file_type !== 'Word';
};

export const getFileTypeIcon = (doc: UserDocumentEntity) => {
  switch (doc.file_type) {
    case 'PDF':
      return 'file-pdf-fill-color';
    case 'Word':
      return 'file-word-fill-color';
    case 'PowerPoint':
      return 'file-powerpoint-fill-color';
    default:
      return 'file-v';
  }
};

export const getFolderName = (doc: UserDocumentEntity) => {
  const path = doc.path;
  return path.split('/').slice(-1)[0];
};

export const getDocumentUrl = (doc: UserDocumentEntity) => {
  if (!getIsPageNumberAvailable(doc)) return doc.url;
  const pageNumber = doc.page_number;
  return getPageUrl(pageNumber ?? 1, doc.url);
};

export const getModifiedAt = (doc: UserDocumentEntity) => {
  return doc.modified_at ? formatDate(doc.modified_at) : undefined;
};
