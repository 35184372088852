<script setup lang="ts">
import { computed, ref } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import api from '@/api';
import { SHAREPOINT_ERROR_MESSAGES } from '@/constants';
import { isAxiosError } from 'axios';
import { useSnackbar } from '@/components/common/snackbar/use-snackbar';
import AdminContent from '@/components/layouts/admin-content.vue';
import Header from '@/components/layouts/header.vue';
import { featureFlags } from '@/featureFlags';

const { createSnackbar } = useSnackbar();

const tenantId = ref('');
const clientId = ref('');
const clientSecret = ref('');
const siteUrl = ref('');
const router = useRouter();
const route = useRoute();
const storageStatus = route.query.storageStatus;
const isSending = ref(false);

const createSharepointCredential = async () => {
  isSending.value = true;
  try {
    const credentialMethod =
      storageStatus === 'CREDENTIAL_INVALID'
        ? api.putSharepointCredential
        : api.postSharepointCredential;

    await credentialMethod(
      clientSecret.value,
      clientId.value,
      tenantId.value,
      siteUrl.value,
    );
    router.push({ name: 'userDocumentsAdmin' });
  } catch (error) {
    let errorMessage = '認証情報の登録に失敗しました';
    if (isAxiosError(error)) {
      const serverError: string = error.response?.data.error;
      if (serverError in SHAREPOINT_ERROR_MESSAGES) {
        errorMessage = SHAREPOINT_ERROR_MESSAGES[serverError];
      }
    }
    createSnackbar({
      message: errorMessage,
      type: 'error',
    });
    // eslint-disable-next-line no-console
    console.error(error);
  }
  isSending.value = false;
};

const isDisabledSubmitButton = computed<boolean>(
  () =>
    tenantId.value.trim() === '' ||
    clientId.value.trim() === '' ||
    clientSecret.value.trim() === '' ||
    isSending.value,
);

const ANDEV_4901_SHAREPOINT_SITE = featureFlags.ANDEV_4901_SHAREPOINT_SITE;
</script>

<template>
  <div class="user-documents-admin">
    <Header title="社内情報管理" header-width="100%"></Header>
    <AdminContent>
      <div class="title">
        <span class="c-title c-title--xm">SharePoint認証項目</span>
      </div>
      <div class="spacing-16"></div>
      <div class="sharepoint-credential-form-content">
        <div>
          <label class="c-title c-title--m" for="client-id-form">
            クライアントID
          </label>
          <div class="spacing-08"></div>
          <input
            v-model="clientId"
            id="client-id-form"
            class="sharepoint-input"
            type="text"
            maxlength="36"
          />
        </div>
        <div class="spacing-24"></div>
        <div class="spacing-02"></div>
        <div>
          <label class="c-title c-title--m" for="tenant-id-form">
            テナントID
          </label>
          <div class="spacing-08"></div>
          <input
            id="tenant-id-form"
            v-model="tenantId"
            class="sharepoint-input"
            type="text"
            maxlength="36"
          />
        </div>
        <div class="spacing-24"></div>
        <div class="spacing-02"></div>
        <div>
          <label class="c-title c-title--m" for="client-secret-form">
            クライアントシークレット
          </label>
          <div class="spacing-08"></div>
          <input
            id="client-secret-form"
            v-model="clientSecret"
            class="sharepoint-input"
            type="text"
            maxlength="250"
          />
        </div>
        <div class="spacing-24"></div>
        <div class="spacing-02"></div>
        <template v-if="ANDEV_4901_SHAREPOINT_SITE">
          <div>
            <label class="c-title c-title--m" for="site-url-form">
              サイトのURL（任意）
            </label>
            <div class="spacing-08"></div>
            <input
              id="site-url-form"
              v-model="siteUrl"
              class="sharepoint-input"
              type="text"
            />
          </div>
          <div class="spacing-24"></div>
          <div class="spacing-02"></div>
        </template>
        <div class="buttons">
          <router-link
            :to="{ name: 'userDocumentsAdmin' }"
            class="c-btn c-btn--auto c-btnOutline"
          >
            キャンセル
          </router-link>
          <button
            :disabled="isDisabledSubmitButton"
            class="c-btn c-btn--auto c-btn--AnewsPrimary"
            :class="{ disabled: isDisabledSubmitButton }"
            @click="createSharepointCredential"
          >
            認証
          </button>
        </div>
      </div>
    </AdminContent>
  </div>
</template>

<style lang="scss" scoped>
.user-documents-admin {
  width: 100%;
  margin: -24px 0 0 0;
  padding: 0 !important;
  .sharepoint-credential-form-content {
    padding: 25px 24px;
    border-radius: 4px;
    background-color: #fff;
    border: 1px solid $color-border;
    .sharepoint-input {
      max-width: 500px;
      width: 100%;
      box-sizing: border-box;
      height: 40px;
    }
    .buttons {
      display: flex;
      justify-content: flex-start;
      gap: 10px;
    }
  }
}
</style>
