<script setup lang="ts">
import { computed, nextTick, onMounted, ref, watch } from 'vue';
import { useRoute } from 'vue-router';
import api from '@/api';
import { ContentsContext, SearchData } from '@/api/tracking';
import { searchSummaryCache } from '@/apiCache';
import { DgrIcon } from '@stockmarkteam/donguri-ui';
import { DgrLoading } from '@stockmarkteam/donguri-ui';
import { marked } from 'marked';
import sanitizeHtml from 'sanitize-html';
import {
  AdpDocument,
  CitationSource,
  isReport,
  isUserDocument,
  Part,
  SummarySource,
  UserDocument,
} from '@/types';
import { sanitize } from '@/utils/sanitize';
import Feedback from './feedback.vue';
import SummaryRenderContent from './summary-render-content.vue';
import { featureFlags } from '@/featureFlags';

interface Props {
  question?: string;
  sourceDocuments?: (AdpDocument | UserDocument)[];
  searchData?: SearchData;
  contentsContext?: ContentsContext;
}

const props = withDefaults(defineProps<Props>(), {
  question: '',
  sourceDocuments: () => [],
});

const emit = defineEmits<{
  'citation-sources-updated': [
    citationSources: CitationSource[],
    isAnimationEnabled: boolean,
  ];
}>();

const SPACING_CLASS_08 = 'spacing-08';
const DELAY_PER_CHAR = 0.005;
const NON_SPACING_NODE_NAMES: ReadonlyArray<Node['nodeName']> = [
  'UL',
  'OL',
  'TABLE',
]; // `\n`だけの文字列の場合に、spacingクラスを付与したdivを追加しないnodeName

const route = useRoute();

/**
 * marked.jsのRendererをカスタマイズ
 *
 * - 行単位で返ってきたデータを毎回全量でparseするため、結果が変わらないようにするためのカスタムrenderer
 * - li要素が複数ある場合に、1個目のli要素内が最初は`<p>`タグで囲まれず、2個目のli要素が追加されたタイミングで、
 *  `<p>`タグで囲まれてしまう問題を解消する
 *
 * 詳細ログ: https://anews.atlassian.net/browse/ANDEV-4736?focusedCommentId=40605
 */
const renderer = new marked.Renderer();
renderer.listitem = item => {
  let text = item.text.trim();
  return `<li>${marked(text)}</li>\n`;
};
marked.setOptions({
  renderer,
});

/**
 * サニタイズの設定
 */
const MarkdownToHtmlOptions = {
  allowedTags: sanitizeHtml.defaults.allowedTags, // 'img'タグは含まれていない
  allowedAttributes: {
    ...sanitizeHtml.defaults.allowedAttributes,
    a: ['href', 'target', 'style', 'class'],
  },
};

let originMarkdown = ''; // 1行単位のレスポンスデータ (マークダウン形式の文字列) が追加されていく元データ
const citationSources = ref<CitationSource[]>([]);
const contentParts = ref<Part[]>([]); // コンテンツの各部分（テキストとコンポーネントのリスト）
const enableSearchSummaryLog = computed<boolean>(() =>
  props.sourceDocuments.every(doc => !isUserDocument(doc)),
);
const enableImageCaption = computed<boolean>(() =>
  props.sourceDocuments.some(doc => isUserDocument(doc)),
);
const loading = computed(
  () => contentParts.value.length === 0 || error.value !== undefined,
);
const dataFetchComplete = ref<boolean>();
const requestId = ref<string>();
const error = ref<string>();
const useCache = ref<boolean>();
const enableAnimation = computed(() => !useCache.value);

onMounted(async () => {
  await fetchSummaryStream();
});

watch(requestId, () => {
  if (requestId.value && enableSearchSummaryLog.value) {
    const additionalData = {
      summary_id: requestId.value,
      contents_context: props.contentsContext,
    };
    api.trackEvent(
      'search_summary_view',
      {
        pageName: 'search',
        pageUrl: route.fullPath,
        feature: 'search_summary',
      },
      undefined,
      undefined,
      additionalData,
    );
  }
});

const makeCacheKey = (question: string, sources: SummarySource[]): string => {
  // 本来であればキャッシュキーは MD5 などのハッシュ関数を使って固定長にしたいところだが、
  // JavaScript には標準のハッシュ関数の実装がないためひとまずは JSON.stringify した値を繋げている。
  // その制限の元で、以下の工夫を行なっている:
  // - キャッシュヒットミスを防ぐためキーの順序は固定
  // - 長くなるので sentence は除外
  const arr = sources.map(s => JSON.stringify(s, ['id', 'doc_type', 'lang']));
  return `${question}.${arr.join('.')}`;
};

const fetchSummaryStream = async () => {
  contentParts.value = [];
  citationSources.value = [];
  dataFetchComplete.value = false;
  requestId.value = undefined;
  error.value = undefined;
  useCache.value = undefined;
  const sources = props.sourceDocuments.map(a => ({
    id: a.id,
    doc_type: a.doc_type,
    lang: a.lang,
    image_url: isUserDocument(a) ? a.image_url : undefined,
    filename: isUserDocument(a) ? a.filename : undefined,
    sentence: isReport(a) || isUserDocument(a) ? a.chunk_text : undefined,
  }));
  try {
    const key = makeCacheKey(props.question, sources);
    const cache = searchSummaryCache.getCache(key);
    if (cache) {
      useCache.value = true;
      contentParts.value = cache.contents;
      requestId.value = cache.requestId;
      cache.citationSources.forEach(citation => {
        citationSources.value.push(citation);
      });
    } else {
      useCache.value = false;
      let isFirstChunk = true;

      // Server-Sent Events でバックエンドから1行単位でデータが送られてくる
      for await (let chunk of api.summaryStream(
        props.question,
        sources,
        enableSearchSummaryLog.value,
        enableImageCaption.value &&
          featureFlags.ANDEV_4747_IMAGE_CAPTION_SUMMARY,
      )) {
        if (isFirstChunk) requestId.value = chunk.request_id;
        originMarkdown += chunk.content;
        const markdownToHtml = marked.parse(originMarkdown) as string; // ネストした箇条書きなどを考慮するため、追加された行だけではなく全量を parse する
        const sanitizedHtml = sanitize(markdownToHtml, MarkdownToHtmlOptions);
        const parser = new DOMParser();
        const doc = parser.parseFromString(sanitizedHtml, 'text/html');
        const { processedParts } = processNode(doc.body);
        contentParts.value = processedParts;

        isFirstChunk = false;
      }
      const cacheData = {
        contents: contentParts.value,
        citationSources: citationSources.value,
        requestId: requestId.value,
      };
      searchSummaryCache.setCache(key, cacheData);
      emit('citation-sources-updated', citationSources.value, false);
    }
  } catch {
    error.value = '申し訳ありませんが、この質問には回答できません。';
  }
  await nextTick();
  dataFetchComplete.value = true;
};

/**
 * - 元のDOM構造を引き継ぎつつ、`[^${num}]`の正規表現にマッチする記事引用インデックス番号部分をコンポーネントに置き換えるためのparser
 * - 実際の描画は`summary-render-content.vue`で行っている
 *
 * @param node パース対象のNode
 * @param accumulatedDelay 累積のアニメーション遅延時間
 * @param parentDomOrder 親要素のDOM順序
 */
const processNode = (
  node: Node,
  accumulatedDelay = 0,
  parentDomOrder = 1,
): { processedParts: Part[]; updatedDelay: number } => {
  const parts: Part[] = [];
  let currentDelay = accumulatedDelay;

  node.childNodes.forEach((child: Node) => {
    if (child.nodeType === Node.TEXT_NODE) {
      const citationRegex = /\[\^(\d+)\]/g;
      let lastIndex = 0;
      let match;
      const text = child.nodeValue || '';

      // 改行文字列だけの場合は、donguri-uiの`spacingクラス`を適用したdivタグを追加する
      // ただし、 「スペースを追加したくない要素内の場合」 と 「直前が見出し要素の場合」 は過度な隙間やスタイル崩れが発生するので追加しない
      if (text === '\n') {
        if (isInsideNonSpacingElement(child) || isPreviousHeading(parts))
          return;
        currentDelay += DELAY_PER_CHAR;
        parts.push({
          type: 'element',
          tagName: 'div',
          attributes: { class: SPACING_CLASS_08 },
          children: [],
          delay: currentDelay,
          parentDomOrder: parentDomOrder,
        });
        return;
      }

      while ((match = citationRegex.exec(text)) !== null) {
        const citationNumber = parseInt(match[1]);
        const sourceDocument = props.sourceDocuments[citationNumber - 1]; // バックエンドではGPTに引用した記事のインデックスを1始まりで渡しているため、マイナス1する
        if (sourceDocument) {
          if (lastIndex < match.index) {
            const partialText = text.slice(lastIndex, match.index);
            const textParts: Part[] = partialText
              .split('')
              .map((char, index) => ({
                type: 'text',
                content: char,
                delay: currentDelay + index * DELAY_PER_CHAR,
                parentDomOrder: parentDomOrder,
              }));
            parts.push({
              type: 'element',
              tagName: 'span',
              attributes: {},
              children: textParts,
              delay: currentDelay,
              parentDomOrder: parentDomOrder,
            });
            currentDelay += partialText.length * DELAY_PER_CHAR;
          }
          currentDelay += DELAY_PER_CHAR;
          parts.push({
            type: 'citationNumberBadge',
            number: citationNumber,
            sourceDocument,
            delay: currentDelay,
            parentDomOrder: parentDomOrder,
          });
          lastIndex = citationRegex.lastIndex;

          // `search-results.vue`で該当するAdpDocumentCardにバッジを表示するためのデータを作成
          citationSources.value.push({
            index: citationNumber - 1,
            sourceDocument,
            citationNumber: citationNumber,
            animationDelay: currentDelay,
          });
        }
      }

      if (lastIndex < text.length) {
        const remainingText = text.slice(lastIndex);
        const textParts: Part[] = remainingText
          .split('')
          .map((char, index) => ({
            type: 'text',
            content: char,
            delay: currentDelay + index * DELAY_PER_CHAR,
            parentDomOrder: parentDomOrder,
          }));
        parts.push({
          type: 'element',
          tagName: 'span',
          attributes: {},
          children: textParts,
          delay: currentDelay,
          parentDomOrder: parentDomOrder,
        });
        currentDelay += remainingText.length * DELAY_PER_CHAR;
      }
    } else if (child.nodeType === Node.ELEMENT_NODE) {
      const parentDomDelay = currentDelay + DELAY_PER_CHAR;
      const element = child as Element;
      const { processedParts, updatedDelay } = processNode(
        element,
        parentDomDelay,
        parentDomOrder,
      );
      currentDelay = updatedDelay;

      parts.push({
        type: 'element',
        tagName: element.tagName.toLowerCase(),
        attributes: Array.from(element.attributes).reduce<{
          [key: string]: string;
        }>((attrs, attr) => {
          attrs[attr.name] = attr.value;
          return attrs;
        }, {}),
        children: processedParts,
        delay: parentDomDelay,
        parentDomOrder: parentDomOrder,
      });
    }
    parentDomOrder++;
  });
  return { processedParts: parts, updatedDelay: currentDelay };
};

/**
 * spacingクラスを付与したdivを追加したくない要素内か判定する
 */
const isInsideNonSpacingElement = (node: Node): boolean => {
  let currentNode: Node | null = node; // 引数として渡された node を上書きしないように新しい変数を用意する
  while (currentNode) {
    if (NON_SPACING_NODE_NAMES.includes(currentNode.nodeName)) return true;
    currentNode = currentNode.parentNode as Node;
  }
  return false;
};

/** 直前がh1やh2などの見出し要素か判定する */
const isPreviousHeading = (parts: Part[]): boolean => {
  if (parts.length === 0) {
    return false;
  }
  const lastPart = parts[parts.length - 1];
  return (
    lastPart.type === 'element' &&
    ['h1', 'h2', 'h3', 'h4', 'h5', 'h6'].includes(lastPart.tagName)
  );
};
</script>

<template>
  <div class="summary-container">
    <div class="summary-header">
      <div class="summary-title c-title c-title--m">
        <DgrIcon name="sparkles-fill" />
        検索結果の要約
      </div>
    </div>
    <div class="loading" v-if="loading">
      <span class="c-text c-text--m">回答を生成しています...</span>
      <DgrLoading />
    </div>
    <div v-else-if="error" class="c-text c-text--m">
      {{ error }}
    </div>
    <div class="spacing-08"></div>
    <div class="summary-contents typewriter">
      <SummaryRenderContent
        :content-parts="contentParts"
        :enable-animation="enableAnimation"
        :contents-context="props.contentsContext"
      />
    </div>
    <div class="spacing-12"></div>
    <div class="summary-feedback">
      <Feedback
        v-if="
          enableSearchSummaryLog &&
          dataFetchComplete &&
          !loading &&
          requestId !== undefined
        "
        :request-id="requestId"
      />
    </div>
  </div>
</template>

<style lang="scss" scoped>
.summary-container {
  padding: 16px 0px;
  border-bottom: 1px solid $color-gray400;
}

.summary-title {
  display: flex;
  color: $color-green600;
  white-space: nowrap;
}
</style>
