export const setupTechTouch = (): void => {
  const techTouchId = import.meta.env.VITE_TECH_TOUCH_ID;
  // dev・local環境のみで動くように設定
  if (import.meta.env.VITE_ENV === 'development') {
    const techTouchCode = document.createElement('script');
    techTouchCode.setAttribute('type', 'text/javascript');
    techTouchCode.textContent = `
      !function () {
        window.TechtouchObject = {
          startTechtouch: function() {
            // iframe内で動作させる場合は以下の1行を削除もしくはコメントアウトしてください
            try { if (window.top !== window.self) return; } catch(e) { return; }
            if ('TechtouchObject' in window && document.querySelector('script#techtouch-snippet')) return;
            if ('TechtouchAddon' in window) return;
            window.TechtouchObject = {
              organizationUuid: "${techTouchId}"
            };
            var e = document.createElement("script"); e.async = 1, e.src = "https://apps.techtouch.jp/script/${techTouchId}/main.js?projectUuid=proj-666151c3-c2d0-4ad7-ad41-4bb2d71a155a"; e.id = "techtouch-snippet";
            var t = document.getElementsByTagName("script")[0];
            t.parentNode.insertBefore(e, t);
          }
        };

        window.TechtouchObject.startTechtouch();
      }()
    `;
    document.head.appendChild(techTouchCode);
  }
};
