import { createStore } from 'vuex';
import teamInfo, {
  initialTeamInfoState,
  TeamInfoState,
} from '@/stores/common-modules/teamInfo';
import teamUsers, {
  initialTeamUsersState,
  TeamUsersState,
} from '@/stores/common-modules/teamUsers';
import userActions, {
  initialUserActionsState,
  UserActionsState,
} from '@/stores/common-modules/userActions';
import confirmationModal, {
  ConfirmationModalState,
  initialConfirmationModalState,
} from '@/stores/pc-modules/confirmationModal';
import downloadActionData, {
  DownloadActionDataState,
  initialDownloadActionDataState,
} from '@/stores/pc-modules/downloadActionData';
import feedType, {
  FeedTypeState,
  initialFeedTypeState,
} from '@/stores/pc-modules/feedType';
import groups, {
  GroupsState,
  initialGroupsState,
} from '@/stores/pc-modules/groups';
import modal, {
  initialModalState,
  ModalState,
} from '@/stores/pc-modules/modal';
import route, {
  initialRouteState,
  RouteState,
} from '@/stores/pc-modules/route';
import searchResults, {
  initialSearchResultsState,
  SearchResultsState,
} from '@/stores/pc-modules/searchResults';
import tagListModalInfo, {
  TagListModalInfo,
  tagListModalInfoInitState,
} from '@/stores/pc-modules/tagListModal';
import themeList, {
  initialThemeListState,
  ThemeListState,
} from '@/stores/pc-modules/themeList';
import userEditModal, {
  initialUserEditModalState,
  UserEditModalState,
} from '@/stores/pc-modules/userEditModal';
import userInfo, {
  initialUserInfoState,
  UserInfoState,
} from '@/stores/pc-modules/userInfo';
import header, { HeaderState, initialHeaderState } from './pc-modules/header';
import mailAddress, {
  initialMailAddressState,
  MailAddressState,
} from './pc-modules/mailAddress';
import notification, {
  initialNotificationState,
  NotificationState,
} from './pc-modules/notification';
import notificationPopup, {
  initialNotificationPopupState,
  NotificationPopupState,
} from './pc-modules/notificationPopup';
import webPush, {
  initialWebPushSubScriptionState,
  WebPushSubscriptionState,
} from './pc-modules/webPush';

export type RootState = {
  themeList: ThemeListState;
  userActions: UserActionsState;
  userInfo: UserInfoState;
  header: HeaderState;
  confirmationModal: ConfirmationModalState;
  userEditModal: UserEditModalState;
  teamUsers: TeamUsersState;
  teamInfo: TeamInfoState;
  searchResults: SearchResultsState;
  modal: ModalState;
  groups: GroupsState;
  feedType: FeedTypeState;
  tagListModalInfo: TagListModalInfo;
  route: RouteState;
  downloadActionData: DownloadActionDataState;
  mailAddress: MailAddressState;
  notificationPopup: NotificationPopupState;
  notification: NotificationState;
  webPush: WebPushSubscriptionState;
};

const initialState: RootState = {
  themeList: Object.assign({}, initialThemeListState),
  userActions: Object.assign({}, initialUserActionsState),
  userInfo: Object.assign({}, initialUserInfoState),
  header: Object.assign({}, initialHeaderState),
  confirmationModal: Object.assign({}, initialConfirmationModalState),
  userEditModal: Object.assign({}, initialUserEditModalState),
  teamUsers: Object.assign({}, initialTeamUsersState),
  teamInfo: Object.assign({}, initialTeamInfoState),
  searchResults: Object.assign({}, initialSearchResultsState),
  modal: Object.assign({}, initialModalState),
  tagListModalInfo: Object.assign({}, tagListModalInfoInitState),
  groups: Object.assign({}, initialGroupsState),
  feedType: Object.assign({}, initialFeedTypeState),
  route: Object.assign({}, initialRouteState),
  downloadActionData: Object.assign({}, initialDownloadActionDataState),
  mailAddress: Object.assign({}, initialMailAddressState),
  notificationPopup: Object.assign({}, initialNotificationPopupState),
  notification: Object.assign({}, initialNotificationState),
  webPush: Object.assign({}, initialWebPushSubScriptionState),
};

export default createStore({
  modules: {
    themeList,
    userActions,
    userInfo,
    header,
    confirmationModal,
    userEditModal,
    teamUsers,
    teamInfo,
    searchResults,
    modal,
    groups,
    feedType,
    route,
    downloadActionData,
    tagListModalInfo,
    mailAddress,
    notificationPopup,
    notification,
    webPush,
  },
  mutations: {
    resetState(moduleList: RootState) {
      Object.keys(moduleList).forEach(key => {
        Object.assign(
          moduleList[key as keyof RootState],
          initialState[key as keyof RootState],
        );
      });
    },
  },
});
