import { Group, UserInfo } from '@/types';
import { featureFlags } from '@/featureFlags';

export const hasEditPermission = (
  group: Group,
  userInfo: UserInfo | undefined,
) => {
  if (!userInfo) return false;
  return (
    userInfo.role === 'admin' ||
    (userInfo.role === 'group_admin' && group.is_member)
  );
};

export const hasMemberPermission = (
  group: Group,
  userInfo: UserInfo | undefined,
) => {
  if (!userInfo) return false;
  return userInfo?.role === 'admin' || group.is_member;
};

export const isGroupAdmin = (role: string) => {
  return ['admin', 'group_admin'].includes(role);
};

export const getTabs = (
  group: Group,
  userInfo: UserInfo,
  commentId: number | undefined = undefined,
): {
  name: string;
  linkTo: { name: string; params: Record<string, unknown> };
}[] => {
  if (!group.id) {
    return [];
  }

  const tabs = commentId
    ? [
        {
          name: 'アクティビティ',
          linkTo: { name: 'groupActivity', params: { groupId: group.id } },
        },
        {
          name: 'コメント',
          linkTo: { name: 'groupComment', params: { commentId } },
        },
        {
          name: 'グループマーク',
          linkTo: { name: 'groupMarks', params: { groupId: group.id } },
        },
      ]
    : [
        {
          name: 'アクティビティ',
          linkTo: { name: 'groupActivity', params: { groupId: group.id } },
        },
        {
          name: 'コメント',
          linkTo: { name: 'groupDetail', params: { groupId: group.id } },
        },
        {
          name: 'グループマーク',
          linkTo: { name: 'groupMarks', params: { groupId: group.id } },
        },
      ];
  if (
    group.group_type === 'specific_user_group' &&
    hasMemberPermission(group, userInfo)
  ) {
    tabs.push({
      name: 'メンバー',
      linkTo: { name: 'groupMembers', params: { groupId: group.id } },
    });
  }

  if (featureFlags.ANDEV_4571_ADD_GROUP_TAG_LIST) {
    tabs.push({
      name: 'グループタグ',
      linkTo: { name: 'groupTagList', params: { groupId: group.id } },
    });
  }

  return tabs;
};
