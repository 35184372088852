<script lang="ts" setup>
import { computed, ref, watch } from 'vue';
import { useRoute } from 'vue-router';
import api from '@/api/index';
import { DgrSelectbox, SelectboxItem } from '@stockmarkteam/donguri-ui';
import SettingToggleCard from '@/components/common/atoms/setting-toggle-card.vue';
import { useSnackbar } from '@/components/common/snackbar/use-snackbar';
import Content from '@/components/layouts/content.vue';
import Header from '@/components/layouts/header.vue';
import { UserSettingsEmail } from '@/types';
import { useTeamInfo, useUserSettingsEmail } from '@/utils/swr';

type contentTypesType = UserSettingsEmail['mail_content_type'];

const contentTypes: SelectboxItem<contentTypesType>[] = [
  {
    label: 'HTML',
    value: 'html',
  },
  {
    label: 'テキスト',
    value: 'text',
  },
];

const route = useRoute();
const { createSnackbar } = useSnackbar();

const { data: userSettings, mutate } = useUserSettingsEmail();
const { data: teamInfo } = useTeamInfo();

const enabledThemeFavoriteMail = ref(false);
const enabledTeamActivityMail = ref(false);
const enabledOverlookedMail = ref(false);
const enabledNewResearchPapersMail = ref(false);
const mailContentType = ref<contentTypesType>('html');

watch(
  userSettings,
  settings => {
    if (!settings) return;
    enabledThemeFavoriteMail.value = settings.enabled_theme_favorite_mail;
    enabledTeamActivityMail.value = settings.enabled_team_activity_mail;
    enabledOverlookedMail.value = settings.enabled_overlooked_mail;
    enabledNewResearchPapersMail.value =
      settings.enabled_new_research_papers_mail;
    mailContentType.value = settings.mail_content_type;
  },
  { immediate: true },
);

const isTechnicalLiteratureEnabled = computed(
  () => teamInfo.value?.enable_technical_literature,
);

const submit = async () => {
  if (!userSettings.value) return;

  try {
    await api.updateUserSettingsEmail(
      {
        ...userSettings.value,
        enabled_theme_favorite_mail: enabledThemeFavoriteMail.value,
        enabled_team_activity_mail: enabledTeamActivityMail.value,
        enabled_overlooked_mail: enabledOverlookedMail.value,
        enabled_new_research_papers_mail: enabledNewResearchPapersMail.value,
        mail_content_type: mailContentType.value,
      },
      {
        pageName: 'email',
        pageUrl: route.fullPath,
      },
    );
    mutate();
    createSnackbar({
      message: '設定を更新しました',
      type: 'success',
    });
  } catch (e) {
    createSnackbar({
      message: '設定を更新できませんでした',
      type: 'error',
    });
    throw e;
  }
};
</script>
<template>
  <div class="user-settings" v-if="userSettings">
    <Header title="メール配信"></Header>
    <Content>
      <div class="settings-card">
        <div class="c-formBlock">
          <div class="c-formBlock__label">
            Anewsダイジェスト（定期配信メール）
          </div>
          <div class="content-type">
            <div class="c-text c-text--m">配信形式</div>
            <div class="content-type-description c-text c-text--s">
              Anewsダイジェストを、HTMLもしくはテキスト形式に変更することができます。
            </div>
            <DgrSelectbox
              class="content-type-select"
              :options="contentTypes"
              v-model="mailContentType"
              size="small"
            ></DgrSelectbox>
          </div>
          <div class="setting-toggles">
            <SettingToggleCard
              title="新着ニュース"
              v-model="enabledThemeFavoriteMail"
            >
              お気に入りしているテーマの最新ニュースを配信します。
            </SettingToggleCard>
            <SettingToggleCard
              title="先週のハイライト"
              v-model="enabledTeamActivityMail"
            >
              コメントやマーク、グループマークされたニュースをランキング形式にして配信します。
            </SettingToggleCard>
            <SettingToggleCard
              title="本日の読み逃しニュース"
              v-model="enabledOverlookedMail"
            >
              あなたがまだ読んでいないニュースの中から興味に合うニュースを配信します。
            </SettingToggleCard>
            <SettingToggleCard
              v-if="isTechnicalLiteratureEnabled"
              title="新着論文・特許"
              v-model="enabledNewResearchPapersMail"
            >
              お気に入りしているテーマの最新論文・特許を配信します。
            </SettingToggleCard>
          </div>
        </div>
        <div class="spacing-12"></div>
        <button class="c-btn c-btn--small c-btn--AnewsPrimary" @click="submit">
          保存
        </button>
      </div>
    </Content>
  </div>
</template>
<style lang="scss" scoped>
.user-settings {
  width: 100%;
  margin: -24px 0 0 0;

  .settings-card {
    box-sizing: border-box;
    width: 440px;
    padding: 16px;
    background: #fff;
    border: 1px solid $color-border;
    border-radius: $border-radius;

    .c-formBlock {
      margin-bottom: 12px;
    }

    .c-formBlock__label {
      margin-bottom: 16px;
    }

    .scope-select {
      width: 200px;
    }

    .c-btn {
      width: 60px;
      margin: 0;
    }

    .content-type {
      margin: 0 0 12px 16px;
      display: flex;
      flex-direction: column;
      gap: 4px;

      .content-type-select {
        width: 90px;
        height: 32px;
      }
      .content-type-description {
        color: $color-gray600;
      }
    }

    .setting-toggles {
      display: flex;
      flex-direction: column;
      gap: 24px;
      margin-bottom: 12px;
    }
  }
}
</style>
