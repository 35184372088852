<script setup lang="ts">
import { computed } from 'vue';
import { MAX_TAG_NAME_LENGTH } from '@/constants';
import TextCounter from '@/components/common/molecules/text-counter.vue';

const props = defineProps<{
  errorMessage?: string;
  modelValue: string;
}>();

const emit = defineEmits<{
  keypressEnter: [{ value: string }];
  'update:modelValue': [value: string];
}>();

const inputValue = computed({
  get: () => props.modelValue,
  set: target => emit('update:modelValue', target),
});

const addItem = () => {
  emit('keypressEnter', {
    value: inputValue.value,
  });
};

const isValidate = computed(() => {
  return props.errorMessage || props.modelValue.length > MAX_TAG_NAME_LENGTH;
});
</script>

<template>
  <div>
    <div class="c-formBlock__text c-formBlock__text--error" v-if="errorMessage">
      {{ errorMessage }}
    </div>
    <input
      class="m-input-tag__input c-textInput"
      :class="{ 'c-formInput--error': isValidate }"
      type="text"
      v-model.trim="inputValue"
      @keypress.enter="addItem"
    />
    <TextCounter
      :max-length="MAX_TAG_NAME_LENGTH"
      :text="inputValue"
      :max-length-error-message="`${MAX_TAG_NAME_LENGTH}文字以内のグループタグが作成できます`"
    />
  </div>
</template>

<style scoped lang="scss">
input:focus {
  outline: none;
}
</style>
