<script setup lang="ts">
import { computed, onMounted, ref, watch } from 'vue';
import { useRoute } from 'vue-router';
import api from '@/api';
import { ContentsContext, SearchData } from '@/api/tracking';
import { legacySearchSummaryCache } from '@/apiCache';
import SummaryDisplay from '@/components/search/summary/summary-display.vue';
import {
  AdpDocument,
  CitationSource,
  isAdpDocument,
  isDocument,
  isReport,
  isUserDocument,
  SummaryCitation,
  SummaryItem,
  SummaryMessage,
  SummarySource,
  UserDocument,
} from '@/types';
import { gaTracker } from '@/utils/ga';
import { useGTag } from '@/utils/vue';
import { useEmitter } from '@/utils/vue';

interface Props {
  question?: string;
  sourceDocuments?: (AdpDocument | UserDocument)[];
  searchData?: SearchData;
  contentsContext?: ContentsContext;
}

const props = withDefaults(defineProps<Props>(), {
  question: '',
  sourceDocuments: () => [],
});

const emit = defineEmits<{
  'citation-sources-updated': [
    citationSources: CitationSource[],
    isAnimationEnabled: boolean,
  ];
}>();

const route = useRoute();
const gtag = useGTag();
const emitter = useEmitter();

const enableSearchSummaryLog = computed<boolean>(() =>
  props.sourceDocuments.every(doc => !isUserDocument(doc)),
);
const isAnimationEnabled = ref(false);
const animationDelayPerChar = computed(() =>
  isAnimationEnabled.value ? 0.01 : 0,
);
const loading = ref(false);
const responses = ref<
  (SummaryMessage | { type: 'citations'; citations: SummaryCitation[] })[]
>([]);
const citationSources = ref<CitationSource[]>([]);
const requestId = ref<string | undefined>(undefined);
const error = ref<string | undefined>(undefined);

const citationCharCount = ref(0);
const updateCitationSources = (citation: SummaryCitation) => {
  let hasUpdatedSources = false;
  citationCharCount.value += citation.text.length;
  citation.sources?.forEach(source => {
    const sourceDocuments = props.sourceDocuments[source.index];
    if (!citationSources.value.find(({ index }) => index === source.index)) {
      citationSources.value.push({
        index: source.index,
        sourceDocument: sourceDocuments,
        citationNumber: citationSources.value.length + 1,
        animationDelay: citationCharCount.value * animationDelayPerChar.value,
      });
      hasUpdatedSources = true;
    }
    citationCharCount.value++;
  });
  if (hasUpdatedSources) {
    emit(
      'citation-sources-updated', // eslint-disable-line vue/require-explicit-emits
      citationSources.value,
      isAnimationEnabled.value,
    );
  }
};

const processSummaryLine = (line: SummaryItem) => {
  if (line.type === 'message') {
    citationCharCount.value += line.text.length;
    responses.value.push(line);
  } else if (line.type === 'citation') {
    const lastResponse = responses.value[responses.value.length - 1];
    if (lastResponse && lastResponse.type === 'citations') {
      lastResponse.citations.push(line);
    } else {
      responses.value.push({ type: 'citations', citations: [line] });
    }
    updateCitationSources(line);
  } else if (line.type === 'meta') {
    requestId.value = line.request_id;
  } else if (line.type === 'error') {
    error.value = line.message || JSON.stringify(line.error_detail);
  }
};

const makeCacheKey = (question: string, sources: SummarySource[]): string => {
  // 本来であればキャッシュキーは MD5 などのハッシュ関数を使って固定長にしたいところだが、
  // JavaScript には標準のハッシュ関数の実装がないためひとまずは JSON.stringify した値を繋げている。
  // その制限の元で、以下の工夫を行なっている:
  // - キャッシュヒットミスを防ぐためキーの順序は固定
  // - 長くなるので sentence は除外
  const arr = sources.map(s => JSON.stringify(s, ['id', 'doc_type', 'lang']));
  return `${question}.${arr.join('.')}`;
};

const getSummary = async () => {
  responses.value = [];
  citationSources.value = [];
  requestId.value = undefined;
  error.value = undefined;

  loading.value = true;
  const sources = props.sourceDocuments.map(a => ({
    id: a.id,
    doc_type: a.doc_type,
    lang: a.lang,
    sentence: isReport(a) || isUserDocument(a) ? a.chunk_text : undefined,
  }));
  try {
    const key = makeCacheKey(props.question, sources);
    const cache = legacySearchSummaryCache.getCache(key);
    if (cache) {
      responses.value = cache.responses;
      requestId.value = cache.requestId;
      cache.responses
        .flatMap(r => (r.type === 'citations' ? r.citations : []))
        .forEach(citation => {
          updateCitationSources(citation);
        });
    } else {
      isAnimationEnabled.value = true;
      for await (let line of api.legacySummaryStream(
        props.question,
        sources,
        enableSearchSummaryLog.value,
      )) {
        processSummaryLine(line);
      }
      if (error.value) {
        loading.value = false;
        return;
      }
      const cacheData = {
        responses: responses.value,
        citationSources: citationSources.value,
        requestId: requestId.value,
      };
      legacySearchSummaryCache.setCache(key, cacheData);
    }
  } catch {
    error.value = '申し訳ありませんが、この質問には回答できません。';
  }
  loading.value = false;
};

onMounted(() => {
  getSummary();
});
watch(
  () => props.question,
  () => {
    getSummary();
  },
);

watch(requestId, () => {
  if (requestId.value && enableSearchSummaryLog.value) {
    const additionalData = {
      summary_id: requestId.value,
      contents_context: props.contentsContext,
    };
    api.trackEvent(
      'search_summary_view',
      {
        pageName: 'search',
        pageUrl: route.fullPath,
        feature: 'search_summary',
      },
      undefined,
      undefined,
      additionalData,
    );
  }
});

const citationClicked = ({
  targetDocument,
}: {
  targetDocument: AdpDocument | UserDocument;
  citationNumber: number;
}) => {
  if (!targetDocument) return;
  if (!isDocument(targetDocument)) return;

  emitter.emit('article-updated', { ...targetDocument, is_read: true });
  gaTracker.trackArticleView(gtag);
  if (isAdpDocument(targetDocument)) {
    api.sendView({
      adpDocument: targetDocument as AdpDocument,
      trackingBaseData: {
        pageName: 'search',
        pageUrl: route.fullPath,
        feature: 'search_summary',
      },
      contentsContext: props.contentsContext,
    });
  }
};
</script>

<template>
  <SummaryDisplay
    v-if="question.length > 0 && sourceDocuments.length > 0"
    class="search-summary"
    :question="question"
    :loading="loading"
    :responses="responses"
    :citation-sources="citationSources"
    :error="error"
    :request-id="requestId"
    :animation-delay-per-char="animationDelayPerChar"
    :enable-search-summary-log="enableSearchSummaryLog"
    @citation-link-clicked="citationClicked"
  />
</template>

<style lang="scss" scoped>
.search-summary {
  width: auto;
}
</style>
