<script lang="ts">
import { computed, defineComponent, PropType } from 'vue';
import { useRoute } from 'vue-router';
import api from '@/api';
import {
  ContentsContext,
  Feature,
  FeedType,
  IndustryData,
  PageName,
  SearchData,
  SiteType,
} from '@/api/tracking';
import { AdpDocument, AdpDocumentBasicInfo, isDocument } from '@/types';
import { formatDate } from '@/utils/formatters';
import { gaTracker } from '@/utils/ga';
import { setRedirectUrl, unsetRedirectUrl } from '@/utils/rightClickOpenTab';
import { userSession } from '@/utils/userSession';
import { useGTag, useStore } from '@/utils/vue';

export default defineComponent({
  props: {
    adpDocument: {
      type: Object as PropType<AdpDocument | AdpDocumentBasicInfo>,
      required: true,
    },
    themeId: {
      type: Number,
    },
    groupId: {
      type: Number,
    },
    rankInWholeFeed: { type: Number },
    pageName: { type: String as PropType<PageName>, required: true },
    pageNumber: { type: Number, default: 1 },
    feature: { type: String as PropType<Feature> },
    feedType: { type: String as PropType<FeedType> },
    searchData: { type: Object as PropType<SearchData> },
    industryData: { type: Object as PropType<IndustryData> },
    trendCompanyText: { type: String, default: '' },
    usePdfUrl: { type: Boolean, default: true },
    contentsContext: { type: Object as PropType<ContentsContext> },
    execution: { type: String },
  },
  emits: ['article-clicked'],
  setup(props, context) {
    const route = useRoute();
    const store = useStore();
    const gtag = useGTag();

    const hasPdfUrl = computed(() => {
      switch (props.adpDocument.doc_type) {
        case 'article':
        case 'patent':
          return false;
        case 'research_paper':
          return (
            props.adpDocument.pdf_url !== undefined &&
            props.adpDocument.pdf_url !== null
          );
        case 'report':
          return props.adpDocument.url.includes('.pdf');
        default:
          return true;
      }
    });

    const href = computed(() => {
      switch (props.adpDocument.doc_type) {
        case 'article':
        case 'patent':
        case 'research_paper':
          return hasPdfUrl.value && props.usePdfUrl && props.adpDocument.pdf_url
            ? props.adpDocument.pdf_url
            : props.adpDocument.url;
        case 'report':
          return `${props.adpDocument.url}#page=${props.pageNumber}`;
        default:
          return '';
      }
    });

    const isAllianceMedia = computed(() => {
      if (!props.adpDocument) return false;
      return (
        'is_alliance_media' in props.adpDocument &&
        props.adpDocument.is_alliance_media
      );
    });

    const teamInfo = computed(() => store.state.teamInfo.teamInfo);
    const enableAllianceMedia = computed(
      () => teamInfo.value?.enable_alliance_media ?? false,
    );

    const sendViewParams = computed(() => ({
      adpDocument: props.adpDocument as AdpDocument,
      themeId: props.themeId,
      groupId: props.groupId,
      rankInWholeFeed: props.rankInWholeFeed,
      trackingBaseData: {
        pageName: props.pageName,
        pageUrl: route.fullPath,
        feature: props.feature,
        feedType: props.feedType,
      },
      searchData: props.searchData,
      industryData: props.industryData,
      siteType: hasPdfUrl.value
        ? ((props.usePdfUrl ? 'pdf_direct' : 'provider') as SiteType)
        : undefined,
      description: props.trendCompanyText,
      contentsContext: props.contentsContext,
      enableAllianceMedia: enableAllianceMedia.value,
      execution: props.execution,
    }));

    const tapArticle = async () => {
      if (!props.adpDocument) return;
      if (!isDocument(props.adpDocument)) return;

      // 有料記事の場合はIsOpenedFromAdpDocumentCardLinkのlocal storageをtrueにして遷移する
      // 記事詳細画面ではIsOpenedFromAdpDocumentCardLink がtrueの場合は api.sendView を実行しない
      if (isAllianceMedia.value)
        userSession.setIsOpenedFromAdpDocumentCardLink(true);

      gaTracker.trackArticleView(gtag);
      await api.sendView(sendViewParams.value);

      context.emit('article-clicked');
    };

    const setRightClickRedirectUrl = (event: Event) => {
      if (!isDocument(props.adpDocument)) return;
      setRedirectUrl(event, sendViewParams.value);
    };

    return {
      href,
      formatDate,
      tapArticle,
      setRightClickRedirectUrl,
      unsetRedirectUrl,
    };
  },
});
</script>

<template>
  <a
    :href="href"
    target="_blank"
    rel="noopener noreferrer"
    @click.stop="tapArticle()"
    @click.middle="tapArticle()"
    @contextmenu="setRightClickRedirectUrl"
    @mouseleave="unsetRedirectUrl"
  >
    <slot></slot>
  </a>
</template>

<style scoped lang="scss"></style>
