<script lang="ts">
import { computed, defineComponent, inject, PropType, toRef } from 'vue';
import { useRoute } from 'vue-router';
import {
  COMMENT_SHARE,
  GROUP_MARK_SHARE,
  MAX_TOOLTIP_LIST_LENGTH,
} from '@/constants';
import { DgrIcon } from '@stockmarkteam/donguri-ui';
import ActionMenu from '@/components/common/adp-document/action-menu.vue';
import MarkButton from '@/components/common/adp-document/mark-button.vue';
import { useSnackbar } from '@/components/common/snackbar/use-snackbar';
import { AdpDocument, Comment } from '@/types';
import { visibleAdpCardKey } from '@/utils/injectionKeys';

export default defineComponent({
  components: {
    ActionMenu,
    MarkButton,
    DgrIcon,
  },
  props: {
    adpDocument: {
      type: Object as PropType<AdpDocument>,
      required: true,
    },
    comments: { type: Array as PropType<Comment[]>, default: () => [] },
    hasActionMenu: { type: Boolean },
    isUndisplayButtonShown: { type: Boolean, required: true },
    isSiteExclusionButtonShown: { type: Boolean, required: true },
    isRemoveGroupMarkButtonShown: { type: Boolean },
    isMarkingArticle: { type: Boolean, required: true },
    isNotInterested: { type: Boolean },
    articleExclusionId: {
      type: Number as PropType<number | null>,
      default: null,
    },
    disabled: { type: Boolean, default: false },
    isNarrow: { type: Boolean, default: false },
  },
  emits: {
    'click-undisplay': () => true,
    'click-site-exclusion': () => true,
    'click-not-interested': () => true,
    'click-remove-not-interested': () => true,
    'click-mark-icon': () => true,
    'click-open-mark-modal': () => true,
    'click-comment-icon': () => true,
    'click-remove-group-mark': () => true,
    'click-group-mark-icon': () => true,
  },
  setup(props, context) {
    const { createSnackbar } = useSnackbar();

    const groupMarks = toRef(() => props.adpDocument.group_marks ?? []);

    const onClickCopyButton = (isSuccess: boolean) => {
      if (isSuccess) {
        createSnackbar({
          message: 'リンクをコピーしました',
          type: 'success',
        });
      } else {
        createSnackbar({
          message: 'リンクをコピーできませんでした',
          type: 'error',
        });
      }
    };

    const undisplayArticle = () => {
      context.emit('click-undisplay');
    };

    const siteExclusionArticle = () => {
      context.emit('click-site-exclusion');
    };

    const notInterestedArticle = () => {
      context.emit('click-not-interested');
    };

    const removeNotInterestedArticle = () => {
      context.emit('click-remove-not-interested');
    };

    const clickMarkIcon = () => {
      if (props.isMarkingArticle) return;
      context.emit('click-mark-icon');
    };

    const clickOpenMarkModal = () => {
      if (props.isMarkingArticle) return;
      context.emit('click-open-mark-modal');
    };

    const clickCommentIcon = () => {
      if (props.disabled) return;
      context.emit('click-comment-icon');
    };

    const onClickRemoveGroupMark = () => {
      context.emit('click-remove-group-mark');
    };

    const groupMarkCount = computed(() => groupMarks.value.length);

    const commentCount = computed(() => {
      return props.comments.length;
    });

    const clickGroupMarkIcon = () => {
      if (props.disabled) return;
      context.emit('click-group-mark-icon');
    };

    // FIXME: 共通コンポーネントに独自の処理を入れない
    const route = useRoute();
    const visibleAdpCard =
      route.fullPath === '/follow_lists' ? inject(visibleAdpCardKey) : true;

    const distinctCommentsByUserId = computed(() => {
      const userIds = new Set<number>();
      return props.comments.filter(comment => {
        if (userIds.has(comment.user_id)) {
          return false;
        }
        userIds.add(comment.user_id);
        return true;
      });
    });

    return {
      clickMarkIcon,
      clickOpenMarkModal,
      clickGroupMarkIcon,
      groupMarkCount,
      clickCommentIcon,
      commentCount,
      onClickCopyButton,
      undisplayArticle,
      siteExclusionArticle,
      notInterestedArticle,
      removeNotInterestedArticle,
      onClickRemoveGroupMark,
      visibleAdpCard,
      groupMarks,
      GROUP_MARK_SHARE,
      MAX_TOOLTIP_LIST_LENGTH,
      COMMENT_SHARE,
      distinctCommentsByUserId,
    };
  },
});
</script>

<template>
  <div class="m-article-card-user-action">
    <div class="m-mark-and-comment">
      <span v-if="visibleAdpCard">
        <MarkButton
          :adp-document="adpDocument"
          :disabled="disabled"
          @click-mark-button="clickMarkIcon"
          @click-open-mark-modal-button="clickOpenMarkModal"
        ></MarkButton>
      </span>

      <VTooltip
        theme="menu"
        placement="top"
        @click="clickGroupMarkIcon"
        :disabled="!visibleAdpCard"
      >
        <div class="m-article-card-mark">
          <div class="m-mark-info-wrap" v-if="groupMarkCount > 0">
            <div
              class="m-mark-info m-action-button"
              :class="{ 'non-disabled': !disabled, disabled: disabled }"
            >
              <DgrIcon
                size="small"
                name="group-mark"
                class="icon m-icon__outer"
                :class="{ disabled: disabled }"
              />
              <div class="m-article-card-mark-count c-title c-title--s">
                {{ groupMarkCount }}
              </div>
            </div>
          </div>
          <div
            class="m-no-mark m-action-button c-title c-title--s"
            :class="{ 'non-disabled': !disabled, disabled: disabled }"
            v-else
          >
            <div class="m-no-mark-icon-wrap">
              <DgrIcon
                size="small"
                name="group-mark"
                class="m-icon__outer"
                :class="{ disabled: disabled }"
              />
            </div>
          </div>
        </div>
        <template #popper>
          <template v-if="groupMarkCount === 0">
            <span>{{ GROUP_MARK_SHARE }}</span>
          </template>
          <template v-if="groupMarkCount > 0">
            <router-link
              v-for="groupMark in groupMarks.slice(0, MAX_TOOLTIP_LIST_LENGTH)"
              :key="groupMark.id"
              :to="{
                name: 'groupMarks',
                params: { groupId: groupMark.group_id },
              }"
            >
              ・{{ groupMark.group_name }}
            </router-link>
          </template>
          <template v-if="groupMarkCount > MAX_TOOLTIP_LIST_LENGTH">
            <span>他{{ groupMarkCount - MAX_TOOLTIP_LIST_LENGTH }}件</span>
          </template>
        </template>
      </VTooltip>

      <VTooltip
        theme="menu"
        placement="top"
        @click="clickCommentIcon"
        :disabled="!visibleAdpCard"
      >
        <div class="m-article-card-comment-wrap">
          <div class="m-article-card-comment">
            <div
              class="m-has-comment m-action-button"
              :class="{ 'non-disabled': !disabled, disabled: disabled }"
              v-if="commentCount > 0"
            >
              <DgrIcon
                size="small"
                name="comment"
                class="o-icon m-icon__outer"
                :class="{ disabled: disabled }"
              />
              <div
                class="m-article-card-media-comment-count c-title c-title--s"
              >
                {{ commentCount }}
              </div>
            </div>
            <div
              class="m-has-not-comment m-action-button"
              :class="{ 'non-disabled': !disabled, disabled: disabled }"
              v-else
            >
              <DgrIcon
                size="small"
                name="comment"
                class="o-icon m-icon__outer"
                :class="{ disabled: disabled }"
              />
              <div class="m-comment-text c-title c-title--s"></div>
            </div>
          </div>
        </div>

        <template #popper>
          <template v-if="commentCount === 0">
            <span>{{ COMMENT_SHARE }}</span>
          </template>
          <template v-if="commentCount > 0">
            <div
              v-for="comment in distinctCommentsByUserId.slice(
                0,
                MAX_TOOLTIP_LIST_LENGTH,
              )"
              :key="comment.id"
            >
              <router-link
                :to="{
                  name: 'groupComment',
                  params: { commentId: comment.id },
                }"
              >
                ・{{ comment.user_name }}
              </router-link>
            </div>
          </template>
          <template v-if="commentCount > MAX_TOOLTIP_LIST_LENGTH">
            <span>他{{ commentCount - MAX_TOOLTIP_LIST_LENGTH }}件</span>
          </template>
        </template>
      </VTooltip>
    </div>
    <div class="m-article-card-action-icon-list-container">
      <div
        class="m-article-card-action-icon-list"
        :class="{ 'is-narrow': isNarrow }"
        v-if="hasActionMenu && visibleAdpCard"
      >
        <ActionMenu
          :adp-document="adpDocument"
          :is-undisplay-button-shown="isUndisplayButtonShown"
          :is-site-exclusion-button-shown="isSiteExclusionButtonShown"
          :is-not-interested="isNotInterested"
          :article-exclusion-id="articleExclusionId"
          :is-remove-group-mark-button-shown="isRemoveGroupMarkButtonShown"
          :disabled="disabled"
          @click-copy="onClickCopyButton"
          @click-undisplay="undisplayArticle"
          @click-site-exclusion="siteExclusionArticle"
          @click-not-interested="notInterestedArticle"
          @click-remove-not-interested="removeNotInterestedArticle"
          @click-remove-group-mark="onClickRemoveGroupMark"
        ></ActionMenu>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
div.m-article-card-user-action {
  display: flex;
  flex-direction: row;
  padding: 2px 0;
  width: 100%;

  div.m-mark-and-comment {
    display: flex;
    flex-direction: row;
    align-items: center;

    div.m-article-card-mark {
      .m-mark-info {
        display: flex;
        flex-direction: row;
        align-items: center;
        min-width: 32px;
      }
    }

    div.m-article-card-mark {
      color: #1da482;
    }

    div.m-article-card-comment {
      color: #717171;
    }
  }

  div.m-article-card-action-icon-list-container {
    width: 100%;
  }

  div.m-article-card-action-icon-list {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-right: -6px;
    &.is-narrow {
      justify-content: flex-end;
    }
  }

  .m-article-card-mark-count-active {
    color: #1da482;
    font-weight: bold;
    font-size: 12px;
    line-height: 12px;
  }
  .m-article-card-mark-count {
    color: #717171;
    font-weight: bold;
    font-size: 12px;
    line-height: 12px;
  }
  .m-article-card-mark-user-name-active {
    color: #1da482;
    font-size: 12px;
    line-height: 16px;
  }
  .m-article-card-mark-user-name {
    color: #717171;
    font-size: 12px;
    line-height: 16px;
  }
  .m-no-mark {
    color: #717171;
    font-weight: bold;
    font-size: 12px;
    line-height: 12px;
    display: flex;
    flex-direction: row;
    align-items: center;
    min-width: 32px;
  }
  .m-has-comment {
    display: flex;
    flex-direction: row;
    align-items: center;
    min-width: 32px;
  }
  .m-article-card-media-comment-count {
    color: #717171;
    font-weight: bold;
    font-size: 12px;
    line-height: 12px;
  }
  .m-article-card-media-comment-user-name {
    font-size: 12px;
    line-height: 16px;
    color: #717171;
  }
  .m-has-not-comment {
    display: flex;
    flex-direction: row;
    align-items: center;
    min-width: 32px;
    .m-comment-text {
      color: #717171;
      font-weight: bold;
      font-size: 12px;
      line-height: 12px;
    }
  }
  .m-action-button {
    padding: 8px 10px;
    &.disabled {
      cursor: default;
    }
    &.non-disabled {
      cursor: pointer;
      &:hover {
        background: #f2f2f2;
        border-radius: 4px;
      }
    }
  }

  .disabled {
    fill: #b3b3b3;
  }
}
.animation {
  animation: zoomInOutSingleFeatured 0.2s forwards;
}
@keyframes zoomInOutSingleFeatured {
  0% {
    transform: scale(0.8, 0.8);
  }
  50% {
    transform: scale(1, 1);
  }
  100% {
    transform: scale(0.6667, 0.6667);
  }
}

div.m-midpoint {
  font-size: 12px;
}

.m-icon {
  cursor: pointer;
}

.m-copy-button {
  padding: 16px 14px;
  border-radius: 4px;
  margin: -16px -10px;
  &:hover {
    background-color: #f2f2f2;
  }
}
.m-icon__outer {
  margin-right: 4px;
}
</style>
