/**
 * @param enableSearchSummaryLog 社内情報はこのparamにfalseを渡して要約生成のログを登録しないようにする
 * 計測設計資料: https://www.notion.so/stockmark/WIP-v1-1654c21960844033ab54e20f2b0e0ea5?pvs=4#566df8168ba44f7da14717ec84c463e8
 */
import axios from 'axios';
import { SummaryChunk, SummaryItem, SummarySource } from '@/types';

export async function* legacySummaryStream(
  question: string,
  sources: SummarySource[],
  enableSearchSummaryLog: boolean,
): AsyncGenerator<SummaryItem> {
  if (globalThis.fetch === undefined) return; // テスト実行でfetchはundefinedなのでreturn
  const stream = await fetch('api/v1/summary/stream', {
    headers: {
      'Content-Type': 'application/json',
    },
    method: 'POST',
    body: JSON.stringify({
      question: question,
      sources: sources,
      enable_search_summary_log: enableSearchSummaryLog,
    }),
  });

  const reader = stream.body?.pipeThrough(new TextDecoderStream()).getReader();

  if (stream.status !== 200 || !reader) {
    throw new Error('Request failed');
  }

  let lastLine = '';
  while (true) {
    const { done, value } = await reader.read();
    if (done) {
      if (lastLine) {
        yield JSON.parse(lastLine);
      }
      break;
    }

    lastLine += value;
    const lines = lastLine.split('\n');
    for (let i = 0; i < lines.length - 1; i++) {
      yield JSON.parse(lines[i]);
    }
    lastLine = lines[lines.length - 1];
  }

  reader.releaseLock();
}

export async function postSummaryFeedback(
  requestId: string,
  evaluation: 'positive' | 'negative',
  reasons?: string[],
  comment?: string,
): Promise<void> {
  await axios.post<void>(`/summary/feedback`, {
    request_id: requestId,
    evaluation,
    reasons,
    comment,
  });
}

export async function deleteSummaryFeedback(requestId: string): Promise<void> {
  await axios.delete<void>(`/summary/feedback/${requestId}`);
}

/**
 * @param enableSearchSummaryLog 社内情報はこのparamにfalseを渡して要約生成のログを登録しないようにする
 * 計測設計資料: https://www.notion.so/stockmark/WIP-v1-1654c21960844033ab54e20f2b0e0ea5?pvs=4#566df8168ba44f7da14717ec84c463e8
 */
export async function* summaryStream(
  question: string,
  sources: SummarySource[],
  enableSearchSummaryLog: boolean,
  enableImageCaption: boolean,
): AsyncGenerator<SummaryChunk> {
  if (globalThis.fetch === undefined) return; // テスト実行でfetchはundefinedなのでreturn
  const response = await fetch('api/v1/summary_v2/stream', {
    headers: {
      'Content-Type': 'application/json',
      Accept: 'text/event-stream',
    },
    method: 'POST',
    body: JSON.stringify({
      question: question,
      sources: sources,
      enable_search_summary_log: enableSearchSummaryLog,
      enable_image_caption: enableImageCaption,
    }),
  });

  const reader = response.body
    ?.pipeThrough(new TextDecoderStream())
    .getReader();

  if (response.status !== 200 || !reader) {
    throw new Error('Request failed');
  }

  while (true) {
    const { done, value } = await reader.read();
    if (done) break;
    if (!value) continue;

    const jsons = value
      .trim()
      .split('data: ') // Server-Sent Eventsでは`data: `で始まる箇所がデータ行
      .map(line => line.trim())
      .filter(s => s); // 余計な空行を取り除く;
    for (const json of jsons) {
      try {
        if (json === '[DONE]') return;
        const chunk = JSON.parse(json);
        if (chunk.type === 'token') {
          yield chunk;
        }
      } catch (error) {
        // eslint-disable-next-line no-console
        console.error('ストリーミングデータの処理に失敗:', json, error);
      }
    }
  }
  reader.releaseLock();
}
