<script setup lang="ts">
import { computed } from 'vue';
import AdpDocumentBasicInfo from '@/components/common/adp-document/basic-info.vue';
import { ThemePreview } from '@/types';
import { isArticle } from '@/types';
import { getPublisher } from '@/utils/adpDocument';
import { getSiteCategoryLabel } from '@/utils/siteCategories';
import { userSession } from '@/utils/userSession';

interface Props {
  adpDocument: ThemePreview;
  displayTranslatedDocumentTitle?: boolean;
}

const props = withDefaults(defineProps<Props>(), {
  displayTranslatedDocumentTitle: false,
});

const category =
  // ストックマーク環境限定でサイト種別を表示
  userSession.getTeamId() === 1 && isArticle(props.adpDocument)
    ? getSiteCategoryLabel(props.adpDocument)
    : undefined;

const documentPublisher = computed(() => getPublisher(props.adpDocument));

const adpDocumentCopy = props.displayTranslatedDocumentTitle
  ? {
      ...props.adpDocument,
      title: props.adpDocument.translated_title ?? props.adpDocument.title,
    }
  : props.adpDocument;
</script>

<template>
  <a
    class="theme-preview-adp-document__content"
    :href="adpDocumentCopy.url"
    target="_blank"
    rel="noopener noreferrer"
  >
    <div class="adp-document-info">
      <AdpDocumentBasicInfo
        :adp-document="adpDocumentCopy"
        size="xm"
        page-name="theme"
      >
        <template #documentMediaName>
          <div class="document-media-name c-text c-text--s">
            <span class="text-content">
              {{ documentPublisher }}
            </span>
            <div v-if="category" class="media-type-label c-title c-title--xs">
              {{ category }}
            </div>
          </div>
        </template>
      </AdpDocumentBasicInfo>
    </div>
    <div class="hit-keyword">
      <div
        class="hit-keyword__tag c-outlineTag c-outlineTag--small"
        v-for="keyword in [
          ...adpDocumentCopy.hit_keywords,
          ...(adpDocumentCopy.synonym_keywords || []),
        ]"
        :key="keyword"
      >
        {{ keyword }}
      </div>
    </div></a
  >
</template>

<style lang="scss" scoped>
.theme-preview-adp-document__content {
  width: 100%;
  .adp-document-info {
    margin-left: 12px;
    margin-right: 12px;
  }
  .hit-keyword {
    display: flex;
    align-items: center;
    flex-direction: row;
    flex-wrap: wrap;
    margin: 8px 0 4px 10px;
  }
  .hit-keyword__tag {
    display: inline-block;
    max-width: 100%;
    box-sizing: border-box;
    margin: 0 4px 4px 0;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }
  .document-media-name {
    display: flex;
    max-width: 100%;
    gap: 4px;
    flex-wrap: wrap;
    font-weight: 700;
    .text-content {
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
    }
    .media-type-label {
      padding: 1px 8px;
      color: $color-gray800;
      background-color: $color-gray200;
      white-space: nowrap;
      border-radius: 2px;
    }
  }
}
</style>
