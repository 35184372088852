import { Article } from '@/types';

export const ALLIANCE_MEDIA_VALUE = 'Alliance Media';
export type SearchSiteCategory =
  | 'Media'
  | 'Company'
  | 'Government'
  | 'Local Gov'
  | 'Institution'
  | 'Association'
  | 'Blog'
  | typeof ALLIANCE_MEDIA_VALUE
  | 'Others';

export type SiteCategory = Exclude<
  SearchSiteCategory,
  typeof ALLIANCE_MEDIA_VALUE
>;

export const isSiteCategory = (arg: string): arg is SiteCategory => {
  return arg in SITE_CATEGORY_LABEL_MAP;
};

export type SiteCategoryItemsLabels =
  | 'メディア'
  | '企業'
  | '政府・中央省庁'
  | '地方公共団体'
  | '研究機関'
  | '協会'
  | 'ブログ'
  | 'Anewsで読める有料メディア'
  | 'その他';

export type SiteCategoryItems = {
  label: SiteCategoryItemsLabels;
  value: SearchSiteCategory;
};

export const SITE_CATEGORY_LABEL_MAP: {
  [key in SearchSiteCategory]: SiteCategoryItemsLabels;
} = {
  Media: 'メディア',
  Company: '企業',
  Government: '政府・中央省庁',
  'Local Gov': '地方公共団体',
  Institution: '研究機関',
  Association: '協会',
  Blog: 'ブログ',
  'Alliance Media': 'Anewsで読める有料メディア',
  Others: 'その他',
};

export const SITE_CATEGORY_ITEMS: SiteCategoryItems[] = [
  { label: 'メディア', value: 'Media' },
  { label: '企業', value: 'Company' },
  { label: '政府・中央省庁', value: 'Government' },
  { label: '地方公共団体', value: 'Local Gov' },
  { label: '研究機関', value: 'Institution' },
  { label: '協会', value: 'Association' },
  { label: 'ブログ', value: 'Blog' },
  { label: 'Anewsで読める有料メディア', value: ALLIANCE_MEDIA_VALUE },
  { label: 'その他', value: 'Others' },
];

export const getAllSiteCategories = () => {
  return SITE_CATEGORY_ITEMS.map(i => i.value);
};

export const getSiteCategoriesValueForSearch = (
  categories: SearchSiteCategory[],
) => {
  return categories.filter(
    (item: SearchSiteCategory): item is SiteCategory =>
      item !== ALLIANCE_MEDIA_VALUE,
  );
};

export const getIncludeAllianceMediaValueForSearch = (
  categories: SearchSiteCategory[],
) => {
  return categories.includes(ALLIANCE_MEDIA_VALUE);
};

export const getExcludedSiteCategories = (
  selectedSiteCategories: SiteCategory[],
) =>
  (
    getSiteCategoriesValueForSearch(
      getAllSiteCategories(),
    ) as unknown as SiteCategory[]
  ).filter(item => !selectedSiteCategories.includes(item));

export const getIncludedSiteCategories = (
  excludedSiteCategories: SiteCategory[],
) =>
  (
    getSiteCategoriesValueForSearch(
      getAllSiteCategories(),
    ) as unknown as SiteCategory[]
  ).filter(item => !excludedSiteCategories.includes(item));

export const getSiteCategoryLabel = (adpDocument: Article) => {
  if (adpDocument.is_alliance_media) return 'Anewsで読める有料メディア';
  if (isSiteCategory(adpDocument.category)) {
    return SITE_CATEGORY_LABEL_MAP[adpDocument.category];
  }
  // サイト種別が欠損してる場合は'その他'とする
  return 'その他';
};
