import { createTrackingData, TrackingBaseData } from '@/api/tracking';
import axios from 'axios';
import { Dayjs } from 'dayjs';
import Qs from 'qs';
import { Article, FeedType, Theme, ThemeList } from '@/types';
import { SiteCategory } from '@/utils/siteCategories';
import { userSession } from '@/utils/userSession';

export async function fetchTheme(themeId: number): Promise<Theme> {
  const { data } = await axios.get<{ themes: Theme }>(`/themes/${themeId}`);
  return data.themes;
}

export async function getThemeKeywordFeeds(
  themeId: number,
  feedType: FeedType,
  date: Dayjs,
  page = 1,
  limit = 10,
): Promise<{ articles: Article[] }> {
  const params = {
    feed_type: feedType,
    date: date.format('YYYY-MM-DD'),
    page: page,
    limit: limit,
  };
  const { data } = await axios.get<{ keyword_feeds: Article[] }>(
    `/themes/${themeId}/keyword_feeds?${Qs.stringify(params)}`,
  );
  return { articles: data.keyword_feeds };
}

export async function getThemeRelatedFeeds(
  themeId: number,
  feedType: FeedType,
  date: Dayjs,
  page = 1,
  limit = 10,
): Promise<{ articles: Article[] }> {
  const params = {
    feed_type: feedType,
    date: date.format('YYYY-MM-DD'),
    page: page,
    limit: limit,
  };
  const { data } = await axios.get<{ related_feeds: Article[] }>(
    `/themes/${themeId}/related_feeds?${Qs.stringify(params)}`,
  );
  return { articles: data.related_feeds };
}

export async function createTheme(
  themeName: string,
  description: string,
  keywords: string[],
  topics: string[],
  perspectives: string[],
  isForeignMediaEnabled: boolean,
  isResearchPaperEnabled: boolean,
  isPatentEnabled: boolean,
  isChinese: boolean,
  isEntertainmentSitesFiltered: boolean,
  isInitialTheme: boolean,
  site_exclusion_ids: number[],
  accessScope: 'personal' | 'public' | 'group',
  group_id: number | undefined,
  user_id: number | undefined = undefined,
  skipEmail: boolean,
  excludedSiteCategories: SiteCategory[] = [],
  excludeAllianceMedia: boolean = false,
  by_onboarding?: boolean,
): Promise<ThemeList> {
  const myUserId = userSession.getUserId();

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const themeData: any = {
    name: themeName,
    description: description,
    keywords: keywords,
    topics: topics,
    perspectives: perspectives,
    is_foreign_media_enabled: isForeignMediaEnabled,
    is_research_paper_enabled: isResearchPaperEnabled,
    is_patent_enabled: isPatentEnabled,
    is_chinese: isChinese,
    is_entertainment_sites_filtered: isEntertainmentSitesFiltered,
    is_initial_theme: isInitialTheme,
    site_exclusion_ids: site_exclusion_ids,
    access_scope: accessScope,
    skip_email: skipEmail,
    by_onboarding: by_onboarding,
    excluded_site_categories: excludedSiteCategories,
    exclude_alliance_media: excludeAllianceMedia,
  };
  if (accessScope === 'personal') {
    themeData.user_id = user_id ?? myUserId;
  }
  if (accessScope === 'group') {
    themeData.group_id = group_id;
  }
  const { data } = await axios.post<ThemeList>('/themes', themeData);
  return data;
}

export async function updateTheme(
  themeInfo: Pick<
    Theme,
    | 'id'
    | 'name'
    | 'description'
    | 'keywords'
    | 'is_foreign_media_enabled'
    | 'is_research_paper_enabled'
    | 'is_chinese'
    | 'is_entertainment_sites_filtered'
    | 'is_initial_theme'
    | 'access_scope'
    | 'group_id'
    | 'user_id'
    | 'site_exclusion_ids'
    | 'excluded_site_categories'
    | 'exclude_alliance_media'
  >,
  options?: { skipEmail: boolean },
): Promise<void> {
  const myUserId = userSession.getUserId();
  const { id, ...themeData } = themeInfo;
  if (themeData.access_scope === 'personal') {
    themeData.user_id = themeInfo.user_id ?? myUserId;
  }
  await axios.put<void>(`/themes/${id}`, {
    ...themeData,
    skip_email: options?.skipEmail ?? false,
  });
}

export async function deleteTheme(themeId: number): Promise<void> {
  await axios.delete<void>(`/themes/${themeId}`);
}

export async function createThemeFavorites(
  themeId: number,
  userIds: number[],
  trackingBaseData: TrackingBaseData,
): Promise<void> {
  await axios.post<void>(`/themes/${themeId}/favorites`, {
    user_ids: userIds,
    tracking_data: createTrackingData({
      ...trackingBaseData,
      themeId: themeId,
    }),
  });
}

export async function deleteThemeInvitation(
  themeId: number,
  userIds: number[],
  trackingBaseData: TrackingBaseData,
): Promise<void> {
  await axios.delete<void>(`/themes/${themeId}/invitations`, {
    data: {
      user_ids: userIds,
      tracking_data: createTrackingData({
        ...trackingBaseData,
        themeId: themeId,
      }),
    },
  });
}

export async function deleteThemeFavorites(
  themeId: number,
  userIds: number[],
  trackingBaseData: TrackingBaseData,
): Promise<void> {
  await axios.delete<void>(`/themes/${themeId}/favorites`, {
    data: {
      user_ids: userIds,
      tracking_data: createTrackingData({
        ...trackingBaseData,
        themeId: themeId,
      }),
    },
  });
}

export async function createThemeInvitation(
  themeId: number,
  userIds: number[],
  trackingBaseData: TrackingBaseData,
): Promise<void> {
  await axios.post<void>(`/themes/${themeId}/invitations`, {
    user_ids: userIds,
    tracking_data: createTrackingData({
      ...trackingBaseData,
      themeId: themeId,
    }),
  });
}

export async function excludeSiteAndArticle(
  article: Article,
  trackingBaseData: TrackingBaseData,
  rankInWholeFeed?: number,
): Promise<void> {
  await axios.put<void>(
    `/themes/${article.theme_id}/exclude_site_and_article`,
    {
      url: article.url,
      article_id: article.id,
      tracking_data: createTrackingData({
        adpDocument: article,
        themeId: article.theme_id,
        rankInWholeFeed,
        ...trackingBaseData,
      }),
    },
  );
}

export async function getThemeMemberCounts() {
  const response = await axios.get<{
    theme_member_counts: Record<string, number>;
  }>('/themes/member_counts');
  return response.data.theme_member_counts;
}
