<script setup lang="ts">
import { computed, ref, watch } from 'vue';
import { DgrSelectbox } from '@stockmarkteam/donguri-ui';
import { Group } from '@/types';
import { sortActiveGroups } from '@/utils/sorters';
import { useGroups } from '@/utils/swr';

const props = withDefaults(
  defineProps<{
    optionsMaxHeight?: string;
    initialGroupId?: number;
    isError?: boolean;
  }>(),
  {
    isError: false,
  },
);

const emit = defineEmits<{
  selected: [group: Group];
}>();

const { data: groups } = useGroups();
const groupList = ref<Group[]>([]);
const selectedGroup = ref<Group | undefined>(undefined);

const groupOptions = computed(() =>
  groupList.value.map(g => {
    return {
      label: g.name,
      value: g.id,
    };
  }),
);

const selectedGroupId = computed({
  get: () => {
    return selectedGroup.value?.id;
  },
  set: value => {
    selectedGroup.value = groupList.value.find(g => g.id === value);
    if (selectedGroup.value !== undefined) {
      emit('selected', selectedGroup.value);
    }
  },
});

watch(
  groups,
  () => {
    if (!groups.value) {
      return;
    }
    const sortedMemberGroups = sortActiveGroups(
      groups.value.groups.filter(g => g.is_member),
      'share_count',
      'desc',
    );
    groupList.value = sortedMemberGroups;

    const initialGroup =
      groupList.value.find(g => g.id === props.initialGroupId) ??
      groupList.value[0]; // 全体・つぶやき
    selectedGroupId.value = initialGroup.id;
  },
  { immediate: true },
);
</script>

<template>
  <div class="group-select c-text c-text--m" v-if="selectedGroup">
    <DgrSelectbox
      :class="{ error: isError }"
      :options="groupOptions"
      v-model="selectedGroupId"
      :options-max-height="optionsMaxHeight"
      size="small"
    ></DgrSelectbox>
  </div>
</template>
<style lang="scss" scoped>
.group-select {
  display: flex;
  align-items: center;
  .text {
    margin-left: 8px;
  }
}
.error {
  border: 1px solid $color-orange1000;
  border-radius: 4px;
}
</style>
