<script setup lang="ts">
import {
  computed,
  nextTick,
  onMounted,
  onUnmounted,
  onUpdated,
  ref,
  shallowRef,
  toRefs,
} from 'vue';
import { useRoute } from 'vue-router';
import api from '@/api';
import { Feature, PageName } from '@/api/tracking';
import {
  MAX_NAME_LENGTH_FOR_COMMENT,
  MAX_REACTION_TYPE_COUNT,
} from '@/constants';
import { DgrIcon, DgrPopover } from '@stockmarkteam/donguri-ui';
import Avatar from '@/components/common/atoms/avatar.vue';
import CommentMenu from '@/components/common/comment/comment-menu.vue';
import CommentReactionList from '@/components/common/comment/comment-reaction-list.vue';
import CommentBox from '@/components/common/molecules/comment-box.vue';
import MentionAutocompleteTextarea from '@/components/common/molecules/mention-autocomplete-textarea.vue';
import RelativeTime from '@/components/common/molecules/relative-time.vue';
import { useSnackbar } from '@/components/common/snackbar/use-snackbar';
import { AdpDocument, Comment, TeamUser } from '@/types';
import { getArticlePageUrl, isMobileUser as utilsIsMobileUser } from '@/utils';
import { formatUserName as utilsFormatUserName } from '@/utils/formatters';
import { gaTracker } from '@/utils/ga';
import * as Parser from '@/utils/parsers';
import { sanitize } from '@/utils/sanitize';
import { useEmitter, useGTag, useStore } from '@/utils/vue';

interface Props {
  comment: Comment;
  commentFontSize?: string;
  commentLineHeight?: string;
  omitting?: boolean;
  article?: AdpDocument;
  showAvatar?: boolean;
  avatarSize?: string;
  showReplyComment?: boolean;
  showGroup?: boolean;
  showReplyButton?: boolean;
  groupCommentsCount?: number;
  isDisplayOnly?: boolean;
  isChild?: boolean;
  isSummaryGroup?: boolean;
  pageName?: PageName;
  feature?: Feature;
  foldReplies?: boolean;
  showJoinGroupButton?: boolean;
  showReaction?: boolean;
  showCommentMenu?: boolean;
  isNarrow?: boolean;
}

const props = withDefaults(defineProps<Props>(), {
  commentFontSize: '14px',
  commentLineHeight: '22px',
  omitting: false,
  avatarSize: 'm',
  showReplyButton: true,
  groupCommentsCount: undefined,
  isDisplayOnly: false,
  isChild: false,
  isSummaryGroup: false,
  foldReplies: true,
  showJoinGroupButton: false,
  showReaction: true,
  showCommentMenu: true,
  isNarrow: false,
});

const emit = defineEmits<{
  'click-reply': [userName: string];
  reply: [comment: Comment];
}>();

const store = useStore();
const route = useRoute();
const emitter = useEmitter();
const { createSnackbar } = useSnackbar();
const gTag = useGTag();

// NOTE: 計測ログなど実行時にroute.fullPathを使用すると、
// route.fullPathで取得する前に画面遷移など行われて、
// 想定と異なるパスが取得される可能性があるため定数で保持する
const PAGE_URL = route.fullPath;

const { comment, isChild, foldReplies, pageName, feature } = toRefs(props);

const userInfo = computed(() => store.state.userInfo.userInfo);
const groups = computed(() => store.state.groups.groups);
const activeTeamUsers = computed<TeamUser[]>(
  () => store.getters['teamUsers/activeTeamUsers'],
);

const isSticky = computed(() => store.state.header?.isSticky);
const emojiZIndex = computed(() => {
  // デフォルトは --z-dropdown の3だが、headerがstickyでない状態の時には
  // --z-content-header + 1 の13に設定
  return isSticky.value ? 3 : 13;
});

const isOpenReplyTextarea = ref(false);
const expandedReplies = ref(false);
const defaultComment = ref<string | undefined>(undefined);
const replyFocused = ref(false);

const articleCommentRef = ref<HTMLElement>();
const replyRef = ref<InstanceType<typeof MentionAutocompleteTextarea>>();

onMounted(() => {
  setGATracking();
  stopClickPropagationInComment();
  setReplyChangeHandler();
});

const setReplyChangeHandler = (): void => {
  emitter.on('comment-deleted', (id: number) => {
    comment.value.children = comment.value.children?.filter(
      child => child.id !== id,
    );
  });
  emitter.on('comment-updated', (updatedComment: Comment) => {
    const idx = comment.value.children?.findIndex(
      child => child.id === updatedComment.id,
    );
    if (idx >= 0) {
      const updatedChildren = comment.value.children.slice();
      updatedChildren[idx] = updatedComment;
      comment.value.children = updatedChildren;
    }
  });
};

onUnmounted(() => {
  window.removeEventListener('click', closeMenus);
});

onUpdated(() => {
  stopClickPropagationInComment();
});

const stopClickPropagationInComment = () => {
  // コメント内リンクをクリックした際のバブリングを止める処理(コメントウィジェットが開くのを防止)
  stopClickPropagationByClassName('mention-name');
  stopClickPropagationByClassName('url-link');
};

const stopClickPropagationByClassName = (className: string) => {
  const elements = Array.from(
    articleCommentRef.value?.getElementsByClassName(className) ?? [],
  );
  elements.forEach(element => {
    element.removeEventListener('click', stopPropagation);
    element.addEventListener('click', stopPropagation);
  });
};

const stopPropagation = (event: Event) => {
  event.stopPropagation();
};

const setGATracking = () => {
  const urlLinks = document.getElementsByClassName('url-link');
  for (const link of urlLinks) {
    link.addEventListener('click', () => gaTracker.trackArticleView(gTag));
  }
};

const formatUserName = (userName: string, isDeleted = false) => {
  return utilsFormatUserName(userName, isDeleted, MAX_NAME_LENGTH_FOR_COMMENT);
};

const exceedsEmojiTypeLimit = computed(() => {
  const reactionTypeCount = new Set(
    comment.value.emoji_reactions.map(r => r.reaction),
  ).size;

  return reactionTypeCount >= MAX_REACTION_TYPE_COUNT;
});

const showEmojiTypeExceedsLimit = () => {
  createSnackbar({
    type: 'error',
    message: `リアクションの上限数（${MAX_REACTION_TYPE_COUNT}種類）に達しています`,
  });
};

const closeMenus = (e: MouseEvent): void => {
  e.stopPropagation();
  const currentComment = replyRef.value?.trimmedComment ?? '';
  if (
    isOpenReplyTextarea.value &&
    currentComment.length > 0 &&
    currentComment !== defaultComment.value
  ) {
    localStorage.setItem(commentKey.value, currentComment);
  }
  isOpenReplyTextarea.value = false;
  window.removeEventListener('click', closeMenus);
};

const expandReplies = (): void => {
  expandedReplies.value = true;
};

const openReplyTextarea = async (mentionName: string): Promise<void> => {
  if (isChild.value) {
    emit('click-reply', comment.value.user_name);
    return;
  }
  window.addEventListener('click', closeMenus);
  isOpenReplyTextarea.value = true;
  expandReplies();

  // replyRefが有効になるまで待つ
  while (!replyRef.value) {
    await nextTick();
  }

  const commentAreaRef = replyRef.value.$refs.commentArea as HTMLInputElement;
  if (commentAreaRef) {
    defaultComment.value = `@${mentionName}`;
    const previousComment = localStorage.getItem(commentKey.value) ?? undefined;
    commentAreaRef.value = previousComment ?? defaultComment.value;

    const customEvent = new CustomEvent('input', {
      bubbles: true,
      cancelable: true,
    });
    commentAreaRef.dispatchEvent(customEvent);

    await nextTick();
    commentAreaRef.focus();
    commentAreaRef.value = commentAreaRef.value.concat(' ');
  }
};

const isReplyTextEmpty = (): boolean => {
  return (replyRef.value?.trimmedComment ?? '').length === 0;
};

const callPostComment = (): void => {
  replyRef.value?.postComment();
};

const joinGroup = async () => {
  if (group.value) {
    await api.joinGroup(group.value?.id);
    await store.dispatch('groups/fetchGroups', userInfo.value?.id);
  }
};

const postComment = async (): Promise<void> => {
  if (replyRef.value) {
    const createdComment = await api
      .sendComment({
        groupId: comment.value.group_id,
        adpDocument: comment.value.article,
        themeId: comment.value.theme_id,
        content: replyRef.value?.trimmedComment,
        mention: replyRef.value?.mention,
        parentCommentId: comment.value.id,
        trackingBaseData: {
          pageName: pageName?.value,
          feature: feature?.value,
          pageUrl: PAGE_URL,
        },
      })
      .catch(err => {
        createSnackbar({
          message: 'コメントを投稿できませんでした',
          type: 'error',
        });
        throw err;
      });
    createSnackbar({
      message: 'コメントを投稿しました',
      type: 'success',
    });

    emit('reply', createdComment);
    replyRef.value.resetTrimmedComment();
    localStorage.removeItem(commentKey.value);
  }
};

const parsedComment = computed(() => {
  if (!userInfo.value) return '';

  return Parser.commentParse(
    comment.value.content,
    activeTeamUsers.value,
    groups.value,
    userInfo.value,
  );
});
const sanitizedParsedComment = computed(() => sanitize(parsedComment.value));

const isMobileUser = computed(() => {
  return utilsIsMobileUser();
});

const imageUrl = computed(() => {
  const commentUser = activeTeamUsers.value.find(
    user => user.id === comment.value.user_id,
  );
  return commentUser?.image_url;
});

const group = computed(() => {
  return groups.value && groups.value.length > 0
    ? groups.value.find(g => g.id === Number(comment.value.group_id))
    : { id: -1, name: '', is_member: false };
});

const showOtherCommentLink = computed(() => {
  return (
    !isChild.value &&
    (route.name === 'anewsHome' ||
      route.name === 'groupComment' ||
      route.name === 'themeFeed' ||
      route.name === 'teamFeaturedArticles')
  );
});

const comments = computed<Comment[]>(() => props.article?.comments ?? []);

const highlightedCommentId = computed((): number | undefined => {
  return route.query.commentId ? Number(route.query.commentId) : undefined;
});

const showReplies = computed((): boolean => {
  return (
    !foldReplies.value ||
    comment.value.id === highlightedCommentId.value ||
    comment.value.children?.find(c => c.id === highlightedCommentId.value) !==
      undefined
  );
});

const commentKey = computed(() => `reply-comment-${comment.value.id}`);

let emojiIndex: import('@/utils/emoji').EmojiIndexType = shallowRef(undefined);
let Picker: import('@/utils/emoji').PickerType = undefined;
const showEmoji = async () => {
  if (emojiIndex.value === undefined) {
    const emojiModule = await import('@/utils/emoji');
    emojiIndex.value = await emojiModule.createEmojiIndex();
    Picker = emojiModule.definePickerComponent();
  }
};
</script>

<template>
  <div
    v-if="userInfo"
    ref="articleCommentRef"
    class="m-article-comment"
    :class="{ 'none-article': !article }"
    @click="closeMenus"
  >
    <div class="group" v-if="showGroup && group">
      <div class="group-content">
        <div class="group-name-content c-title c-title--s">
          <component
            :is="isDisplayOnly ? 'div' : 'router-link'"
            :to="`/groups/${group.id}`"
            class="group-name"
            :class="!isDisplayOnly ? 'link' : ''"
          >
            <DgrIcon size="xs" name="users" />
            <div class="group-name-text">
              {{ group.is_member ? group.name : `${group.name}(未参加)` }}
            </div>
          </component>
          <div v-if="groupCommentsCount" class="group-comments-count">
            ・{{ groupCommentsCount }}件
          </div>
        </div>
        <div class="group-invite">
          <button
            class="c-btn c-btn--small c-btn--auto c-btn--AnewsPrimary"
            v-if="showJoinGroupButton && !group.is_member"
            @click="joinGroup"
          >
            参加
          </button>
        </div>
      </div>
    </div>
    <div class="spacing-16" v-if="isSummaryGroup"></div>
    <div
      class="m-comment-body"
      :class="{ 'm-comment-body__outer': !showGroup }"
    >
      <component
        :is="isDisplayOnly ? 'div' : 'router-link'"
        :to="`/users/${comment.user_id}`"
      >
        <Avatar
          class="comment-avatar"
          v-if="showAvatar && !isNarrow"
          :size="avatarSize"
          :image-url="imageUrl"
        ></Avatar>
      </component>
      <div class="comment-and-buttons">
        <div
          :class="{
            'm-comment': !showAvatar,
            'm-comment-avatar': showAvatar,
            highlight: highlightedCommentId === comment.id,
          }"
        >
          <CommentBox>
            <template v-slot="{ toggleEmojiReaction }">
              <div class="m-comment-header">
                <div class="m-comment-sub-info">
                  <div
                    class="m-comment-user-name-unlink c-title c-title--s"
                    v-if="isDisplayOnly"
                  >
                    {{ formatUserName(comment.user_name, false) }}
                  </div>
                  <router-link
                    v-else
                    class="m-comment-user-name c-title"
                    :to="`/users/${comment.user_id}`"
                    :class="{
                      'c-title--m': isMobileUser,
                      'c-title--s': !isMobileUser,
                    }"
                    >{{ formatUserName(comment.user_name, false) }}</router-link
                  >
                  <div class="m-midpoint c-text c-text--s">・</div>
                  <div class="m-commented-date c-text c-text--s">
                    <RelativeTime
                      :datetime="comment.created_at"
                      :hide-tooltip="isChild"
                    ></RelativeTime>
                  </div>
                </div>
                <div
                  class="comment-menu-wrap"
                  v-if="!isDisplayOnly && showCommentMenu"
                >
                  <CommentMenu :comment="{ ...comment, article }"></CommentMenu>
                </div>
              </div>
              <div class="m-comment-content c-text c-text--m">
                <!-- eslint-disable vue/no-v-html -->
                <div
                  class="content"
                  :style="{
                    'font-size': commentFontSize,
                    'line-height': commentLineHeight,
                  }"
                  :class="{ omit: omitting }"
                  v-html="sanitizedParsedComment"
                ></div>
                <!-- eslint-enable vue/no-v-html -->
              </div>
              <CommentReactionList
                @click="
                  toggleEmojiReaction(article, $event.comment, $event.reaction)
                "
                :comment="comment"
                v-if="!isMobileUser && !isDisplayOnly"
              ></CommentReactionList>
            </template>
          </CommentBox>
        </div>
        <div class="buttons" v-if="!isDisplayOnly">
          <CommentBox>
            <template v-slot="{ toggleEmojiReaction }">
              <DgrPopover :z-index="emojiZIndex">
                <template #default="{ triggerProps }">
                  <button
                    v-if="exceedsEmojiTypeLimit"
                    @click="showEmojiTypeExceedsLimit"
                    class="reaction-add-button"
                  >
                    <DgrIcon
                      size="small"
                      name="smile-plus"
                      v-if="showReaction"
                    />
                  </button>
                  <button
                    v-else
                    v-bind="triggerProps"
                    @click="showEmoji"
                    class="reaction-add-button"
                  >
                    <DgrIcon
                      size="small"
                      name="smile-plus"
                      v-if="showReaction"
                    />
                  </button>
                </template>
                <template #content="{ close }">
                  <Picker
                    v-if="emojiIndex"
                    :data="emojiIndex"
                    set="twitter"
                    :native="true"
                    :show-preview="false"
                    @select="
                      toggleEmojiReaction(article, comment, $event.native);
                      close();
                    "
                    :i18n="{
                      search: '検索',
                      categories: {
                        search: '検索結果',
                        recent: 'よく使う絵文字',
                        smileys: 'スマイリー & 人',
                        people: '顔 & 人',
                        nature: '動物 & 自然',
                        foods: '食べ物 & 飲み物',
                        activity: 'アクティビティ',
                        places: '旅行 & 場所',
                        objects: 'オブジェクト',
                        symbols: '記号',
                        flags: '旗',
                        custom: 'カスタム',
                      },
                    }"
                  />
                </template>
              </DgrPopover>
            </template>
          </CommentBox>
          <div
            class="reply-button c-title c-title--s"
            v-if="showReplyButton"
            @click.stop="() => openReplyTextarea(comment.user_name)"
          >
            返信
          </div>
          <router-link
            class="other-comment-link c-title c-title--s"
            v-if="showOtherCommentLink && comments.length > 1"
            :to="getArticlePageUrl(article)"
            >その他のコメントを表示</router-link
          >
        </div>
        <div
          class="reply-comment-count c-title c-title--s"
          v-if="
            !showReplies &&
            showReplyComment &&
            !expandedReplies &&
            comment.children.length > 0 &&
            !isDisplayOnly
          "
          @click.stop="expandReplies"
        >
          <DgrIcon
            size="small"
            name="subdirectory-arrow-right"
            class="reply-arrow-icon primary"
          />返信{{ comment.children.length }}件を表示
        </div>
        <div
          class="reply-comment-count display-only c-title c-title--s"
          v-if="
            isDisplayOnly &&
            !showReplyComment &&
            !expandedReplies &&
            comment.children.length > 0
          "
        >
          <DgrIcon
            size="small"
            name="subdirectory-arrow-right"
            class="reply-arrow-icon"
          />返信{{ comment.children.length }}件
        </div>
        <div
          class="reply-comment-list"
          v-if="showReplyComment && (showReplies || expandedReplies)"
        >
          <div
            class="reply-comment"
            :class="{ 'is-narrow': isNarrow }"
            v-for="replyComment in comment.children"
            :key="replyComment.id"
          >
            <ArticleComment
              :article="article"
              :is-child="true"
              :comment="replyComment"
              :show-avatar="true"
              avatar-size="m"
              :page-name="pageName"
              :feature="feature"
              :is-narrow="isNarrow"
              @click-reply="openReplyTextarea"
            ></ArticleComment>
          </div>
        </div>
        <div class="reply-widget" @click.stop="" v-if="isOpenReplyTextarea">
          <router-link :to="`/users/${userInfo.id}`" v-if="!isNarrow">
            <Avatar :size="avatarSize" :image-url="userInfo.image_url"></Avatar>
          </router-link>
          <MentionAutocompleteTextarea
            class="reply-textarea"
            ref="replyRef"
            placeholder="返信コメントする..."
            :min-height="40"
            :rows="1"
            :auto-focus="false"
            :disabled="false"
            @post-comment="postComment"
            @focus="replyFocused = true"
            @blur="replyFocused = false"
          ></MentionAutocompleteTextarea>
          <div class="replay-button-area">
            <button
              class="reply-button-small c-btn c-btn--small c-btn--AnewsPrimary"
              :class="{ disabled: isReplyTextEmpty() }"
              :disabled="isReplyTextEmpty()"
              @click.stop="callPostComment"
            >
              投稿
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.m-article-comment {
  &.none-article {
    padding: 16px;
  }
  div.m-comment-body {
    display: flex;
    align-items: flex-start;
    width: 100%;
  }

  .comment-avatar {
    margin-right: 8px;
  }

  .group {
    display: inline-block;
    vertical-align: bottom;
    width: 100%;
    .group-content {
      display: flex;
      justify-content: space-between;
      align-items: center;
      .group-name-content {
        display: flex;
        margin: 8px 0 7px 0;
        .group-name {
          display: flex;
          align-items: center;
          border-bottom: solid 1px white;
          &.link:hover {
            border-color: #4a4a4a;
            cursor: pointer;
          }
        }
      }
      .group-name-text {
        margin-left: 5px;
        font-weight: bold;
      }
      .group-comments-count {
        color: $color-gray800;
      }
    }
  }

  .m-comment {
    display: block;
    position: relative;
    background: #f2f2f2;
    border-radius: 8px;
    padding: 4px 12px 8px;

    &.no-link {
      cursor: auto;
    }

    .m-comment-header {
      display: flex;
      justify-content: space-between;
    }

    .m-comment-sub-info {
      display: flex;
      flex-direction: row;
      align-items: center;

      .m-comment-user-id {
        color: #717171;
      }

      .m-commented-date {
        color: #717171;
        min-width: fit-content;
      }
    }

    .m-comment-content {
      margin-top: 4px;
      .content {
        max-width: 512px;
        white-space: pre-line;
        word-wrap: break-word;
        word-break: break-word;
        overflow-wrap: break-word;
        &.omit {
          max-height: 220px;
          overflow: hidden;
          display: -webkit-box;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 10;
        }
      }
    }
  }

  .comment-and-buttons {
    width: 100%;
  }

  .buttons {
    display: flex;
    margin-top: 4px;
    align-items: center;

    .reaction-add-button {
      width: 32px;
      height: 24px;
      padding: 4px;
      border: none;
      border-radius: 4px;
      margin-right: 4px;
      cursor: pointer;
      background-color: white;
      &:hover {
        background-color: $color-gray200;
      }
    }

    .reply-button {
      padding: 4px;
      color: #717171;
      border-radius: 4px;
      margin-right: 4px;
      cursor: pointer;
      &:hover {
        background: #f2f2f2;
      }
    }

    .other-comment-link {
      padding: 4px;
      color: #717171;
      &:hover {
        text-decoration: underline;
      }
    }
  }

  .highlight {
    border: 1px solid #1da482;
  }

  .m-comment-avatar {
    display: block;
    background: #f2f2f2;
    border-radius: 8px;
    padding: 4px 12px 8px;

    &.no-link {
      cursor: auto;
    }

    .m-comment-header {
      display: flex;
      justify-content: space-between;
    }

    .m-comment-sub-info {
      display: flex;
      flex-direction: row;
      align-items: center;

      .m-comment-user-id {
        color: #717171;
      }

      .m-commented-date {
        color: #717171;
        min-width: fit-content;
      }
    }

    .m-comment-content {
      margin-top: 4px;
      .content {
        width: 100%;
        white-space: pre-line;
        word-wrap: break-word;
        word-break: break-word;
        overflow-wrap: break-word;
        &.omit {
          max-height: 220px;
          overflow: hidden;
          display: -webkit-box;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 10;
        }
      }
    }
  }
  .comment-menu-wrap {
    margin-left: 8px;
  }
  .m-comment-user-name {
    font-weight: bold;
    margin-right: 2px;
    &:hover {
      text-decoration: underline;
    }
  }
  .m-comment-user-name-unlink {
    font-weight: bold;
    margin-right: 2px;
  }

  .reply-comment-count {
    display: inline-flex;
    color: #1da482;
    padding: 4px;
    margin-top: 2px;
    border-radius: 4px;
    cursor: pointer;

    &:hover {
      background: #f2f2f2;
    }

    .reply-arrow-icon {
      margin-right: 4px;
      :deep(svg) {
        height: 16px;
      }
    }
  }

  .display-only {
    color: $color-gray800;
    cursor: default;
    &:hover {
      background: none;
    }
  }

  .m-comment-body__outer {
    margin-top: 8px;
  }

  .reply-widget {
    display: flex;
    align-items: center;
    margin: 12px 0 8px 0;

    .replay-button-area {
      margin-left: 8px;
      .reply-button-small {
        width: 60px;
        height: 40px;
        padding: 0;
      }
    }
    .reply-textarea {
      margin-left: 12px;
    }
  }
}
// donguri-uiの button のスタイルの影響で emoji-mart-vue の表示が崩れるため
:deep(button.emoji-mart-emoji) {
  &:hover {
    border: 0;
    background: white;
  }
}

.is-narrow {
  margin-left: 16px;
}
</style>
