import { UserRole } from '@/types/user';

export type TeamUser = {
  id: number;
  email: string;
  user_name: string;
  image_url: string;
  is_deleted: boolean;
  role: UserRole;
  enable_user_edit: boolean;
  enable_theme_edit: boolean;
  enable_translate_article_title: boolean;
  mypage_access_scope: string;
  created_at: string;
  updated_at: string;
  enabled_theme_favorite_mail: boolean;
  enabled_team_activity_mail: boolean;
};

export type TeamInfo = {
  id: number;
  name: string;
  enable_foreign_lang_media: boolean;
  enable_chinese_lang_media: boolean;
  enable_technical_literature: boolean;
  enable_ms_teams_integration: boolean;
  enable_alliance_media: boolean;
  enable_ip_address_restriction: boolean;
  enable_user_document: boolean;
  industry_id: number;
  user_document_setting: {
    accept_search_summary_v2: boolean;
  };
};

export type TeamUserMarkCount = {
  id: number;
  user_name: string;
  count_foreign: number;
  count_domestic: number;
};

export type ThemeInvitation = {
  user_id: number;
  user_name: string;
  theme_id: number;
  theme_name: string;
};

export const TeamMarkSort = ['recommended', 'latest'] as const;
export type TeamMarkSortType = (typeof TeamMarkSort)[number];

export function isTeamMarkSort(sort: string): sort is TeamMarkSortType {
  return TeamMarkSort.includes(sort as TeamMarkSortType);
}

export type Industry = {
  id: number;
  name: string;
  is_default: boolean;
  topics: Topic[];
};

export type Topic = {
  id: number;
  name: string;
  article_count: number;
  query: string;
};
