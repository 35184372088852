<script lang="ts">
import {
  computed,
  defineComponent,
  onMounted,
  onUnmounted,
  Ref,
  ref,
  UnwrapRef,
} from 'vue';
import { useRoute } from 'vue-router';
import api from '@/api';
import { ContentsContext, TrackingDataProps } from '@/api/tracking';
import { DgrSelectbox, SelectboxItem } from '@stockmarkteam/donguri-ui';
import AdpDocumentCard from '@/components/common/adp-document-card.vue';
import AdpDocumentCardSkeleton from '@/components/common/adp-document/adp-document-card-skeleton.vue';
import QuestionTooltip from '@/components/common/atoms/question-tooltip.vue';
import SelectedTagList from '@/components/common/multi-select/selected-tag-list.vue';
import TagSelect from '@/components/common/multi-select/tag-select.vue';
import SwrScrollingPagination from '@/components/common/swr-scrolling-pagination.vue';
import Content from '@/components/layouts/content.vue';
import Header from '@/components/layouts/header.vue';
import { Article, TagSelectItem, TeamMarkSortType } from '@/types';
import { Pagination } from '@/utils';
import {
  STATES,
  useRecommendedTeamMarks,
  useTeamMarkedArticles,
  useTeamUserMarkCounts,
  useTeamUsers,
} from '@/utils/swr';
import { userSession } from '@/utils/userSession';
import { useEmitter, useStore } from '@/utils/vue';

const PAGE_LIMIT = 10;
const RECOMMENDED_MARKS_COUNT = 100;
const sortOptions: SelectboxItem<TeamMarkSortType>[] = [
  {
    value: 'recommended',
    label: 'おすすめ(β)',
  },
  {
    value: 'latest',
    label: '新着順',
  },
];

export default defineComponent({
  components: {
    SwrScrollingPagination,
    QuestionTooltip,
    Header,
    Content,
    AdpDocumentCard,
    AdpDocumentCardSkeleton,
    TagSelect,
    SelectedTagList,
    DgrSelectbox,
  },
  setup() {
    const store = useStore();
    const emitter = useEmitter();
    const route = useRoute();
    // NOTE: 計測ログなど実行時にroute.fullPathを使用すると、
    // route.fullPathで取得する前に画面遷移など行われて、
    // 想定と異なるパスが取得される可能性があるため定数で保持する
    const PAGE_URL = route.fullPath;
    onMounted(async () => {
      api.trackPageView({
        pageName: 'team_marks',
        pageUrl: PAGE_URL,
      });
      emitter.on('article-updated', handleArticleUpdate);
    });

    onUnmounted(() => {
      emitter.off('article-updated', handleArticleUpdate);
      pagination?.removeEvent();
    });

    const { data: users } = useTeamUsers();
    const { data: userMarkCounts } = useTeamUserMarkCounts();

    const userList = computed<TagSelectItem[]>(() => {
      return (
        userMarkCounts.value?.user_mark_counts.map(user => {
          return {
            id: user.id,
            name: user.user_name,
            count:
              feedType.value === 'foreign'
                ? user.count_foreign
                : user.count_domestic,
          };
        }) ?? []
      );
    });

    const sortedUserList = computed(() => {
      return [...userList.value].sort((a, b) => -1 * (a.count - b.count));
    });

    const selectedUserNames = ref<string[]>(
      userSession.getMarkNewsSelectedUserNames(),
    );

    const handleFilterArticles = (names: string[]) => {
      selectedUserNames.value = names;
      userSession.setMarkNewsSelectedUserNames(selectedUserNames.value);
    };

    const articleFilter = (article: Article, id: Article['id']) => {
      return article.id === id;
    };

    const updateArticle = (articles: Article[], updatedArticle: Article) => {
      const index = articles.findIndex(a => {
        return a.id === updatedArticle.id;
      });
      if (index >= 0) {
        articles[index].marks = updatedArticle.marks;
      }
      return articles;
    };

    const handleArticleUpdate = (article: Article) => {
      emitter.emit('pagination-items-update', {
        filterFunc: (i: Article) => articleFilter(i, article.id),
        updateFunc: (items: Article[]) => updateArticle(items, article),
      });
    };

    const getThemeId = (article: Article) => {
      return article.marks[0]?.theme_id ?? undefined;
    };

    const removeSelectedUser = (userName: string) => {
      selectedUserNames.value = selectedUserNames.value.filter(
        name => name !== userName,
      );
      userSession.setMarkNewsSelectedUserNames(selectedUserNames.value);
    };

    const feedType = computed(() => store.state.feedType.feedType);

    const userIds = computed(() => {
      return users.value?.users
        .filter(user => selectedUserNames.value.includes(user.user_name))
        .map(user => user.id);
    });

    const userSelectBoxText = computed(() => {
      return selectedUserNames.value.length > 0
        ? `メンバー ${selectedUserNames.value.length}`
        : 'すべてのメンバー';
    });

    // レコメンド版は一括で取得するが、その場合初期表示が遅くなるので
    // Paginationを使ってスクロールに応じて記事を表示するようにする
    let pagination: Pagination | undefined = undefined;
    const showingIndex = ref(PAGE_LIMIT);
    const resetPagination = () => {
      pagination?.removeEvent();
      pagination = new Pagination((page, _limit) => {
        showingIndex.value = page * PAGE_LIMIT;
      }, RECOMMENDED_MARKS_COUNT);
    };

    const sort = ref<TeamMarkSortType>(
      userSession.getTeamMarkSort() ?? 'recommended',
    );
    const selectedSort = computed({
      get: () => sort.value,
      set: value => {
        sort.value = value;
        userSession.setTeamMarkSort(sort.value);
        if (sort.value === 'recommended') {
          resetPagination();
        }
      },
    });
    if (sort.value === 'recommended') {
      resetPagination();
    }

    // 'latest'選択時はフェッチが動かないように0にしておく
    const recommendedMarkCount = computed(() =>
      sort.value === 'recommended' ? RECOMMENDED_MARKS_COUNT : 0,
    );

    const trackingData = computed<TrackingDataProps>(() => {
      return {
        pageName: 'team_marks',
        pageUrl: PAGE_URL,
      };
    });

    const timeWeight = computed(() =>
      route.query._time_weight ? Number(route.query._time_weight) : undefined,
    );
    const { data: recommendedMarksData, state: recommendedMarksState } =
      useRecommendedTeamMarks(
        recommendedMarkCount,
        feedType,
        trackingData,
        timeWeight,
      );

    const recommendedMarks = computed(() => {
      if (!recommendedMarksData.value) return [];
      return recommendedMarksData.value.marked_articles.slice(
        0,
        showingIndex.value,
      );
    });
    const isLoadingRecommendedMarks = computed(() => {
      const state = recommendedMarksState.value;
      return state === STATES.PENDING || state === STATES.VALIDATING;
    });

    const contentsContext = computed<ContentsContext>(() => {
      return {
        event_name: 'marks_view',
        sort: sort.value,
        users: sort.value === 'latest' ? userIds.value ?? [] : [],
      };
    });

    return {
      pageLimit: PAGE_LIMIT,
      paginationFunc: (pageRef: Ref<number>, pageLimit: number) =>
        useTeamMarkedArticles(
          pageRef,
          pageLimit,
          true,
          feedType.value == 'all' ? undefined : feedType.value,
          userIds.value,
          trackingData,
        ),
      dataAccessor: (
        d: UnwrapRef<ReturnType<typeof useTeamMarkedArticles>['data']>,
      ) => d?.marked_articles,
      getThemeId,
      sortedUserList,
      selectedUserNames,
      handleFilterArticles,
      userSelectBoxText,
      removeSelectedUser,
      feedType,
      selectedSort,
      sortOptions,
      recommendedMarks,
      isLoadingRecommendedMarks,
      contentsContext,
    };
  },
});
</script>

<template>
  <div class="mark-news">
    <Header title="新着マークニュース">
      <template #titleMenu>
        <QuestionTooltip
          class="icon"
          text="マイページ公開している全ユーザのマークした記事を表示しています。"
        ></QuestionTooltip>
      </template>
    </Header>
    <Content>
      <div>
        <div class="options">
          <span class="c-title c-title--m">ニュース</span>
          <div class="spacing-16"></div>
          <DgrSelectbox
            class="sort-selectbox"
            :options="sortOptions"
            v-model="selectedSort"
            size="small"
          >
          </DgrSelectbox>
          <div class="spacing-16"></div>
          <div class="user-select">
            <TagSelect
              :tag-list="sortedUserList"
              :target-tags="selectedUserNames"
              :placeholder="userSelectBoxText"
              :disabled="selectedSort === 'recommended'"
              :is-show-count="false"
              empty-filter-message="指定したメンバーは存在しません。"
              @filter-articles="handleFilterArticles"
            ></TagSelect>
          </div>
        </div>
        <div class="spacing-16"></div>
        <div class="description c-text c-text--m">
          <template v-if="selectedSort === 'recommended'">
            あなたの興味関心に基づき、マークされた記事からおすすめの記事を表示します。
          </template>
          <template v-else-if="selectedSort === 'latest'">
            マークされた順に記事を表示します。
          </template>
        </div>
        <div class="spacing-16"></div>
        <div v-if="selectedSort === 'latest' && selectedUserNames.length > 0">
          <SelectedTagList
            class="selected-users"
            label="選択中のメンバー"
            :selected-tags="selectedUserNames"
            :is-editing="true"
            :show-tooltip="true"
            @delete-tag="removeSelectedUser"
          ></SelectedTagList>
          <div class="spacing-16"></div>
        </div>
        <SwrScrollingPagination
          v-if="selectedSort === 'latest'"
          :key="`${[feedType, selectedUserNames]}`"
          :page-limit="pageLimit"
          :pagination-func="paginationFunc"
          :data-accessor="dataAccessor"
        >
          <template v-slot="{ items, loaded }">
            <div v-if="!loaded" class="skeletons">
              <AdpDocumentCardSkeleton v-for="i in 5" :key="i" />
            </div>
            <template v-else>
              <AdpDocumentCard
                v-for="(article, index) in items"
                :key="article.id"
                class="article-card"
                :adp-document="article"
                :show-comment-count="1"
                :theme-id="getThemeId(article)"
                page-name="team_marks"
                feature="team_marks"
                :contents-context="contentsContext"
                :rank-in-whole-feed="index + 1 /* 計測のrankは1はじまり */"
                :disable-related-articles="false"
              ></AdpDocumentCard>
              <div
                class="no-marked-articles"
                v-if="loaded && items.length === 0"
              >
                <div class="c-text c-text--m">
                  マークされた記事がないため表示できません。
                </div>
              </div>
            </template>
          </template>
        </SwrScrollingPagination>
        <div v-else-if="selectedSort === 'recommended'">
          <div v-if="isLoadingRecommendedMarks" class="skeletons">
            <AdpDocumentCardSkeleton v-for="i in 5" :key="i" />
          </div>
          <div v-else-if="recommendedMarks.length === 0">
            <div class="c-text c-text--m">
              マークされた記事がないため表示できません。
            </div>
          </div>
          <template v-else>
            <AdpDocumentCard
              v-for="(article, index) in recommendedMarks"
              :key="article.id"
              class="article-card"
              :adp-document="article"
              :show-comment-count="1"
              :theme-id="getThemeId(article)"
              page-name="team_marks"
              feature="team_marks"
              :contents-context="contentsContext"
              :rank-in-whole-feed="index + 1 /* 計測のrankは1はじまり */"
              :disable-related-articles="false"
            ></AdpDocumentCard>
          </template>
        </div>
      </div>
    </Content>
  </div>
</template>
<style lang="scss" scoped>
.mark-news {
  width: 100%;
  margin: -24px 0 0 0;
  .article-card {
    margin-bottom: 10px;
  }
  .icon {
    margin-left: 4px;
  }
  .no-marked-articles {
    width: 616px;
    flex-direction: row;
    justify-content: space-between;
    background: #ffffff;
    border: 1px solid #e6e6e6;
    box-sizing: border-box;
    border-radius: 4px;
    padding: 16px;
  }
  .options {
    display: flex;
    align-items: center;
  }
  .description {
    color: $color-gray800;
  }
  .selected-users {
    width: 920px;
  }
  .skeletons {
    display: flex;
    flex-direction: column;
    gap: 8px;
    width: 616px;
  }
}
</style>
