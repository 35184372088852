<script lang="ts">
import { computed, defineComponent, ref } from 'vue';
import {
  DgrIcon,
  DgrSelectbox,
  SelectboxItem,
} from '@stockmarkteam/donguri-ui';
import GroupSearchCard from '@/components/group/group-search-card.vue';
import Header from '@/components/layouts/header.vue';
import { Group } from '@/types';
import { formatDate } from '@/utils/formatters';
import { sortActiveGroups } from '@/utils/sorters';
import { STATES, useGroups } from '@/utils/swr';

type SortType = {
  field: keyof Pick<Group, 'name' | 'created_at' | 'member_count'>;
  order: 'asc' | 'desc';
};

type SortListValue = `${SortType['field']}_${SortType['order']}`;

type FilterType = {
  field: keyof Pick<Group, 'is_member'> | 'none';
  equals: boolean | null;
};

type FilterListValue = `${FilterType['field']}_${FilterType['equals']}`;

export default defineComponent({
  components: {
    GroupSearchCard,
    Header,
    DgrIcon,
    DgrSelectbox,
  },
  setup() {
    const { data: groups, state } = useGroups();
    const sortList: SelectboxItem<SortListValue>[] = [
      {
        value: 'created_at_desc',
        label: '新しい順',
      },
      {
        value: 'created_at_asc',
        label: '古い順',
      },
      {
        value: 'name_asc',
        label: 'グループ名（昇順）',
      },
      {
        value: 'name_desc',
        label: 'グループ名（降順）',
      },
      {
        value: 'member_count_desc',
        label: '参加人数が多い順',
      },
      {
        value: 'member_count_asc',
        label: '参加人数が少ない順',
      },
    ];

    const sortTypeMap: Record<SortListValue, SortType> = {
      created_at_desc: { field: 'created_at', order: 'desc' },
      created_at_asc: { field: 'created_at', order: 'asc' },
      name_asc: { field: 'name', order: 'asc' },
      name_desc: { field: 'name', order: 'desc' },
      member_count_desc: { field: 'member_count', order: 'desc' },
      member_count_asc: { field: 'member_count', order: 'asc' },
    };

    const filterList: SelectboxItem<FilterListValue>[] = [
      {
        value: 'none_null',
        label: 'すべてのグループ',
      },
      {
        value: 'is_member_true',
        label: '参加中のグループ',
      },
      {
        value: 'is_member_false',
        label: '未参加のグループ',
      },
    ];

    const filterTypeMap: Record<FilterListValue, FilterType> = {
      none_null: { field: 'none', equals: null },
      none_true: { field: 'none', equals: true },
      none_false: { field: 'none', equals: false },
      is_member_null: { field: 'is_member', equals: null },
      is_member_true: { field: 'is_member', equals: true },
      is_member_false: { field: 'is_member', equals: false },
    };

    const selectedSort = ref<SortListValue>(sortList[0].value);
    const selectedFilter = ref<FilterListValue>(filterList[0].value);
    const searchKeyword = ref('');

    /**
     * 検索条件を適用したグループ一覧を返却する算出プロパティ
     */
    const queriedGroups = computed(() => {
      // 「全体・つぶやき」は検索画面で不要であるため除外
      let queryResult = groups.value
        ? groups.value.groups.filter(g => g.group_type !== 'all_user_group')
        : [];
      // フィルターを適用
      const filter = filterTypeMap[selectedFilter.value];
      const selectedFilterField = filter.field;
      if (selectedFilterField !== 'none') {
        queryResult = queryResult.filter(
          group => group[selectedFilterField] === filter.equals,
        );
      }
      // キーワード検索を適用
      if (searchKeyword.value !== '') {
        queryResult = queryResult.filter(group =>
          group.name.includes(searchKeyword.value),
        );
      }
      // ソートを適用
      const sort = sortTypeMap[selectedSort.value];
      queryResult = sortActiveGroups(queryResult, sort.field, sort.order);
      return queryResult;
    });

    return {
      state,
      STATES,
      formatDate,
      sortList,
      filterList,
      selectedSort,
      selectedFilter,
      searchKeyword,
      queriedGroups,
    };
  },
});
</script>

<template>
  <div class="group-search">
    <Header title="グループ検索" :align-left="true"></Header>
    <div class="group-search__content">
      <div class="o-group-search__keyword">
        <DgrIcon
          size="small"
          name="search"
          :keep-fill="false"
          class="search-icon"
        />
        <input
          class="c-textInput"
          type="text"
          placeholder="グループ名で検索"
          v-model="searchKeyword"
        />
      </div>
      <div class="spacing-16"></div>
      <div class="o-group-search__select">
        <div class="o-group-search__counts">
          <div class="c-title c-title--m">
            グループ<span class="c-text c-text--s count"
              >・{{ queriedGroups.length }}件</span
            >
          </div>
        </div>
        <div class="spacing-16"></div>
        <DgrSelectbox
          :options="sortList"
          v-model="selectedSort"
          size="small"
        ></DgrSelectbox>
        <div class="spacing-16"></div>
        <DgrSelectbox
          :options="filterList"
          v-model="selectedFilter"
          size="small"
        ></DgrSelectbox>
      </div>
      <div class="spacing-16"></div>
      <div
        class="list-content"
        v-if="queriedGroups || state === STATES.SUCCESS"
      >
        <div class="list" v-for="group in queriedGroups" :key="group.id">
          <GroupSearchCard :group="group"></GroupSearchCard>
        </div>
        <div
          class="no-search-result c-text c-text--m"
          v-if="queriedGroups.length === 0"
        >
          該当するグループはありません
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.group-search {
  width: 100%;
  margin: -24px 0 0 0;
  .group-search__content {
    padding: 24px;
    .o-group-search__keyword {
      position: relative;
      display: flex;
      align-items: center;
      .c-textInput {
        padding-left: 36px;
        outline: none;
      }
      fill: $color-gray600;
      .search-icon {
        position: absolute;
        left: 12px;
      }
    }
    .o-group-search__select {
      display: flex;
      align-items: center;
      .o-group-search__counts {
        .count {
          color: #b3b3b3;
        }
      }
    }
    .list-content {
      .list {
        margin-bottom: 8px;
      }
      .no-search-result {
        align-items: center;
        display: flex;
        flex-direction: column;
      }
    }
  }
}
</style>
