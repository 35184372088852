<script lang="ts">
import {
  computed,
  defineComponent,
  nextTick,
  PropType,
  ref,
  toRefs,
  watch,
} from 'vue';
import { useRoute } from 'vue-router';
import api from '@/api';
import { PageName } from '@/api/tracking';
import AdpDocumentPestCategories from '@/components/adp-document/adp-document-pest-categories.vue';
import AdpDocumentAllianceMedia from '@/components/common/adp-document/adp-document-alliance-media.vue';
import AdpDocumentCardUserAction from '@/components/common/adp-document/adp-document-card-user-action.vue';
import AdpDocumentBasicInfo from '@/components/common/adp-document/basic-info.vue';
import CommentList from '@/components/common/comment/comment-list.vue';
import { useSnackbar } from '@/components/common/snackbar/use-snackbar';
import Content from '@/components/layouts/content.vue';
import { AdpDocument, Comment, DocType } from '@/types';
import { useNewsCategories } from '@/utils/composables/useNewsCategories';
import { STATES, useDocument, useThemeList, useUserInfo } from '@/utils/swr';
import { userSession } from '@/utils/userSession';
import { useEmitter, useStore } from '@/utils/vue';
import { featureFlags } from '@/featureFlags';

export default defineComponent({
  components: {
    AdpDocumentCardUserAction,
    CommentList,
    Content,
    AdpDocumentBasicInfo,
    AdpDocumentAllianceMedia,
    AdpDocumentPestCategories,
  },
  props: {
    id: { type: String, required: true },
    // eslint-disable-next-line vue/prop-name-casing
    doc_type: {
      type: String as PropType<DocType>,
      required: true,
    },
  },
  setup(props) {
    const route = useRoute();
    const store = useStore();
    const emitter = useEmitter();
    const { createSnackbar } = useSnackbar();

    const feedType = computed(() => store.state.feedType.feedType);

    const { data: userInfo } = useUserInfo();
    const teamInfo = computed(() => store.state.teamInfo.teamInfo);
    const enableAllianceMedia = computed(
      () => teamInfo.value?.enable_alliance_media ?? false,
    );

    const { id, doc_type } = toRefs(props);
    const { data: adpDocument, state, mutate } = useDocument(id, doc_type);
    const { pestCategories, newsCategoriesByPest } = useNewsCategories({
      adpDocument,
    });
    const hasPestCategories = computed(() => {
      if (!pestCategories.value) {
        return false;
      }
      return pestCategories.value.length > 0;
    });

    const { data: themes, state: themeState } = useThemeList(feedType);
    const theme = computed(() =>
      themes.value?.themes.find(t => t.id === Number(route.query.themeId)),
    );

    const userEnableTranslateTitle = computed(
      () => userInfo.value?.enable_translate_article_title,
    );

    const trackIntegration = async () => {
      if (adpDocument.value) {
        await api.trackIntegration('article', route, {
          article: { id: adpDocument.value.id, doc_type: doc_type.value },
        });
      }
    };

    watch(
      () => state.value,
      () => {
        if (state.value === STATES.SUCCESS) {
          trackIntegration();
          if (theme?.value?.id) {
            emitter.emit('select-news-menu-item', theme?.value?.id);
          }
        }
      },
    );

    const pageName: PageName = 'article';

    const setupModal = () => {
      store.commit('modal/setArticle', adpDocument.value);
      store.commit('modal/setTheme', theme.value);
      store.commit('modal/setPageName', pageName);
      store.commit('modal/setMarkSource', 'article');
    };

    const showMarkModal = () => {
      setupModal();
      store.commit('modal/showModal', 'mark');
    };

    const showCreateCommentModal = () => {
      setupModal();
      store.commit('modal/showModal', 'commentCreate');
    };

    const showGroupMarkModal = () => {
      setupModal();
      store.commit('modal/showModal', 'groupMark');
    };

    watch(
      () => [state.value, themeState.value],
      () => {
        if (
          state.value === STATES.SUCCESS &&
          themeState.value === STATES.SUCCESS
        ) {
          const modal = route.query.modal;
          switch (modal) {
            case 'mark':
              if (!hasMyMark.value) {
                showMarkModal();
              } else {
                createSnackbar({
                  message: 'この記事は既にマークされています',
                  type: 'success',
                });
              }
              break;
            case 'group_mark':
              showGroupMarkModal();
              break;
            case 'comment':
              showCreateCommentModal();
              break;
            default:
              break;
          }
        }
      },
    );

    const isMarkingArticle = ref(false);

    const hasMyMark = computed(() =>
      adpDocument.value?.marks.find(m => m.user_id === userInfo.value?.id),
    );

    const groupComments = computed(() => {
      if (!comments.value || comments.value.length == 0) return [];
      let groupIds = comments.value.map(g => g.group_id);
      groupIds = [...new Set(groupIds)];
      groupIds = groupIds?.sort((x, y) => {
        if (x < y) return -1;
        if (x > y) return 1;
        return 0;
      });

      const groupComments: { group_id: number; comments: Comment[] }[] = [];
      groupIds?.forEach(groupId => {
        const groupComment = (comments.value ?? []).filter(
          comment => comment.group_id === groupId,
        );
        groupComments.push({ group_id: groupId, comments: groupComment });
      });
      return groupComments;
    });

    const comments = computed(() => adpDocument.value?.comments ?? []);

    const removeMyMark = async (article: AdpDocument, userId: number) => {
      try {
        const myMark = article.marks.find(mark => mark.user_id === userId);
        if (myMark) {
          await api.deleteMark(myMark.id);
          article.marks = article.marks.filter(mark => mark.id !== myMark.id);
          if (adpDocument.value) {
            adpDocument.value.marks = article.marks;
          }
        }
      } catch (err) {
        createSnackbar({
          message: 'マークを取り消せませんでした',
          type: 'error',
        });
        throw err;
      }
      createSnackbar({
        message: 'マークを取り消しました',
        type: 'success',
      });
    };

    const sendMark = async (article: AdpDocument, themeId: number | null) => {
      try {
        await api.sendMark(article, themeId, 'article', {
          pageName: pageName,
          pageUrl: route.fullPath,
        });
      } catch (err) {
        createSnackbar({
          message: 'マークできませんでした',
          type: 'error',
        });
        throw err;
      }
      createSnackbar({
        type: 'link',
        message: 'マイページ',
        params: {
          link: `/users/${userInfo.value?.id}`,
          suffixText: 'にマークしました',
        },
      });
    };

    const markArticle = async () => {
      if (isMarkingArticle.value || !userInfo.value || !adpDocument.value)
        return;
      isMarkingArticle.value = true;
      if (hasMyMark.value) {
        await removeMyMark(adpDocument.value, userInfo.value.id);
      } else {
        await sendMark(adpDocument.value, theme.value?.id ?? null);
      }
      await mutate();
      nextTick(() => (isMarkingArticle.value = false));
    };

    const isAllianceMedia = computed(() => {
      if (!adpDocument.value) return false;
      return (
        'is_alliance_media' in adpDocument.value &&
        adpDocument.value.is_alliance_media
      );
    });

    watch(
      () => [state.value, teamInfo.value],
      () => {
        if (
          state.value === STATES.SUCCESS &&
          teamInfo.value &&
          adpDocument.value
        ) {
          if (isAllianceMedia.value) {
            if ('media_name' in adpDocument.value) {
              api.trackPageView({
                pageName: pageName,
                pageUrl: route.path,
                adpDocument: {
                  id: Number(id.value),
                  doc_type: doc_type.value,
                  alliance_media: adpDocument.value.media_name,
                  enable_alliance_media_body: enableAllianceMedia.value,
                },
              });

              // adp-document-card-link.vueから遷移してきた際には、 article_viewは登録しない
              if (userSession.getIsOpenedFromAdpDocumentCardLink()) {
                userSession.setIsOpenedFromAdpDocumentCardLink(false);
                return;
              }
              api.sendView({
                adpDocument: adpDocument.value,
                trackingBaseData: {
                  pageName: pageName,
                  pageUrl: route.path,
                },
                enableAllianceMedia: enableAllianceMedia.value,
              });
            }
          } else {
            api.trackPageView({
              pageName: pageName,
              pageUrl: route.path,
              adpDocument: {
                id: Number(id.value),
                doc_type: doc_type.value,
              },
            });
          }
        }
      },
    );

    return {
      adpDocument,
      comments,
      theme,
      isMarkingArticle,
      groupComments,
      markArticle,
      showMarkModal,
      showCreateCommentModal,
      showGroupMarkModal,
      userEnableTranslateTitle,
      pageName,
      isAllianceMedia,
      teamInfo,
      enableAllianceMedia,
      hasPestCategories,
      newsCategoriesByPest,
      featureFlags,
    };
  },
});
</script>

<template>
  <Content>
    <div class="document-container" v-if="adpDocument">
      <div class="document-content">
        <div class="document-card">
          <AdpDocumentBasicInfo
            v-if="!isAllianceMedia"
            class="info"
            :adp-document="adpDocument"
            :theme-id="theme ? theme.id : undefined"
            :user-enable-translate-title="userEnableTranslateTitle"
            :page-name="pageName"
            size="l"
          />
          <AdpDocumentAllianceMedia
            v-else
            :adp-document="adpDocument"
            :team-info="teamInfo"
            :enable-alliance-media="enableAllianceMedia"
          ></AdpDocumentAllianceMedia>
          <AdpDocumentCardUserAction
            class="user-actions"
            :adp-document="adpDocument"
            :comments="comments"
            :has-action-menu="true"
            :is-undisplay-button-shown="false"
            :is-site-exclusion-button-shown="false"
            :is-marking-article="isMarkingArticle"
            :disabled="!enableAllianceMedia && isAllianceMedia"
            @click-mark-icon="markArticle"
            @click-open-mark-modal="showMarkModal"
            @click-comment-icon="showCreateCommentModal"
            @click-group-mark-icon="showGroupMarkModal"
          ></AdpDocumentCardUserAction>
        </div>

        <template
          v-if="
            featureFlags.ANDEV_4635_ADD_CATEGORY_INFORMATION &&
            hasPestCategories &&
            newsCategoriesByPest
          "
        >
          <div class="pest-categories-container">
            <AdpDocumentPestCategories
              :news-categories-by-pest="newsCategoriesByPest"
            />
          </div>
        </template>

        <div class="comments" v-if="groupComments.length > 0">
          <div
            class="comment-list"
            v-for="groupComment in groupComments"
            :key="groupComment.group_id"
          >
            <CommentList
              v-if="groupComment.comments && groupComment.comments.length > 0"
              :adp-document="adpDocument"
              :comments="groupComment.comments"
              :show-group="true"
              :is-summary-group="true"
              :show-comment-widget="true"
              :group-id="groupComment.group_id"
              :show-join-group-button="true"
            ></CommentList>
          </div>
        </div>
        <div class="no-comments" v-else>
          <div class="document-text-content">
            <div class="c-text c-text--m">
              この記事に対するコメントはまだありません
            </div>
            <div class="spacing-12"></div>
            <button
              class="c-btn c-btn--auto c-btn--AnewsPrimary"
              @click="showCreateCommentModal"
            >
              コメントする
            </button>
          </div>
        </div>
      </div>
    </div>
  </Content>
</template>

<style lang="scss" scoped>
.document-container {
  width: 100%;
  margin-top: -32px;

  .document-content {
    display: flex;
    flex-direction: column;
    width: 616px;

    .document-card {
      border-radius: 4px;
      background-color: #fff;
      border: 1px solid #e6e6e6;
      margin-bottom: 24px;

      .user-actions {
        margin: 0 16px 4px 16px;
      }

      &.alliance-media {
        padding: 20px 16px 0 16px;
      }
    }

    .comment-list {
      border-radius: 4px;
      background-color: #fff;
      border: 1px solid #e6e6e6;
      padding: 12px 16px;
      margin-bottom: 24px;
    }

    .no-comments {
      padding: 0 16px;
      display: flex;
      justify-content: center;
      align-items: center;

      .document-text-content {
        display: flex;
        flex-direction: column;
        align-items: center;
      }
    }
  }

  .pest-categories-container {
    margin-bottom: 24px;
  }
}
</style>
