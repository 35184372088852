<script lang="ts">
import {
  computed,
  defineComponent,
  onBeforeUnmount,
  onMounted,
  ref,
  watch,
} from 'vue';
import { useRouter } from 'vue-router';
import api from '@/api';
import { MAX_IP_ADDRESS_RESTRICTIONS } from '@/constants';
import { DgrIcon, DgrToggleButton } from '@stockmarkteam/donguri-ui';
import AdminBaseTable, { Field } from '@/components/admin/admin-base-table.vue';
import { useSnackbar } from '@/components/common/snackbar/use-snackbar';
import AdminContent from '@/components/layouts/admin-content.vue';
import Header from '@/components/layouts/header.vue';
import { IpAddressRestriction } from '@/types';
import { useIpAddressRestrictions, useTeamInfo } from '@/utils/swr';
import { checkMoveToAdmin } from '@/utils/user';
import { useStore } from '@/utils/vue';
import IpAddressRestrictionBulkCreateModal from './ip-address-restriction-bulk-create-modal.vue';
import IpAddressRestrictionEditModal from './ip-address-restriction-edit-modal.vue';

type AdminTableItem = {
  id: number;
  ip_address: string;
  description: string;
};

export default defineComponent({
  components: {
    Header,
    AdminContent,
    AdminBaseTable,
    IpAddressRestrictionEditModal,
    IpAddressRestrictionBulkCreateModal,
    DgrIcon,
    DgrToggleButton,
  },
  props: {},
  setup() {
    const router = useRouter();
    const store = useStore();
    const { createSnackbar } = useSnackbar();

    const { data: teamInfo, mutate: teamMutate } = useTeamInfo();
    const enableIpAddressRestriction = ref(false);
    watch(teamInfo, () => {
      if (!teamInfo.value) return;
      enableIpAddressRestriction.value =
        teamInfo.value.enable_ip_address_restriction;
    });
    const actionMenuItem = ref<AdminTableItem | undefined>(undefined);
    const targetIpAddressRestriction = ref<IpAddressRestriction | undefined>(
      undefined,
    );
    const isEditModalOpen = ref(false);
    const closeActionMenu = () => (actionMenuItem.value = undefined);
    onBeforeUnmount(() =>
      document.removeEventListener('click', closeActionMenu),
    );
    onMounted(() => {
      checkMoveToAdmin(router);
      document.addEventListener('click', closeActionMenu);
    });

    const { data: ipAddressRestrictions, mutate: ipAddressRestrictionMutate } =
      useIpAddressRestrictions();
    const ipAddresses = computed(
      () => ipAddressRestrictions.value?.ip_address_restrictions ?? [],
    );
    const toggleDisabled = computed(() => !ipAddresses.value.length);
    const fields: Field<AdminTableItem>[] = [
      {
        name: 'ip_address',
        displayName: 'IPアドレス',
        isSortable: false,
        type: 'string',
        minWidth: 192,
        fieldPosition: 'left',
        cellPosition: 'left',
      },
      {
        name: 'description',
        displayName: '備考',
        isSortable: false,
        type: 'string',
        minWidth: 380,
        fieldPosition: 'left',
        cellPosition: 'left',
      },
      {
        name: 'actions',
        displayName: '',
        isSortable: false,
        type: 'string',
        fieldPosition: 'center',
        cellPosition: 'center',
      },
    ];
    const items = computed<AdminTableItem[]>(() =>
      ipAddresses.value.map(ipAddressRestriction => ({
        ip_address: ipAddressRestriction.ip_address,
        description: ipAddressRestriction.description,
        id: ipAddressRestriction.id || 0,
      })),
    );

    const openEditModal = (item: AdminTableItem) => {
      actionMenuItem.value = item;
      targetIpAddressRestriction.value = ipAddresses.value.find(
        ipAddressRestriction => ipAddressRestriction.id === item.id,
      );

      isEditModalOpen.value = true;
    };

    const updateEnableIpAddressRestriction = () => {
      if (toggleDisabled.value) return;

      const updateSetting = enableIpAddressRestriction.value
        ? {
            headerText: 'IPアドレス制限を無効にしますか?',
            bodyText:
              'IPアドレス制限を無効にすると、外部からのアクセスが全て許可されます。',
            btnText: '無効化',
            failedMessage: 'IPアドレス制限の無効化に失敗しました',
            successMessage: 'IPアドレス制限を無効にしました',
          }
        : {
            headerText: 'IPアドレス制限を有効にしますか?',
            bodyText:
              'IPアドレス制限を有効にすると、追加したIPアドレス以外からのアクセスが制限されます。',
            btnText: '有効化',
            failedMessage: 'IPアドレス制限の有効化に失敗しました',
            successMessage: 'IPアドレス制限を有効にしました',
          };
      const updateAction = () => {
        try {
          api.putEnableIpAddressRestriction(!enableIpAddressRestriction.value);
          createSnackbar({
            message: updateSetting.successMessage,
            type: 'success',
          });
          enableIpAddressRestriction.value = !enableIpAddressRestriction.value;
        } catch (error) {
          createSnackbar({
            message: updateSetting.failedMessage,
            type: 'error',
          });
          throw error;
        }
      };

      const payload = {
        headerText: updateSetting.headerText,
        bodyText: updateSetting.bodyText,
        btnText: updateSetting.btnText,
        confirmationType: 'submit',
        action: updateAction,
      };
      store.commit('confirmationModal/showConfirmationModal');
      store.commit('confirmationModal/setTextAndAction', payload);
    };

    const isIpAddressRestrictionsAtLimit = computed(
      () => items.value.length >= MAX_IP_ADDRESS_RESTRICTIONS,
    );

    const isCreateModalOpen = ref(false);
    const openCreateModal = () => {
      isCreateModalOpen.value = true;
    };

    const deleteModalBodyText = computed(() => {
      if (ipAddressRestrictions.value?.ip_address_restrictions.length === 1) {
        return `${actionMenuItem.value?.ip_address}を削除します。\nまた、削除すると自動でIPアドレス制限が無効化されます。`;
      }
      return `${actionMenuItem.value?.ip_address}を削除します。`;
    });
    const deleteIpAddressRestriction = async (item: AdminTableItem) => {
      const deleteAction = async () => {
        await api.deleteIpAddressRestriction(item.id as number).catch(err => {
          createSnackbar({
            message: 'IPアドレスを削除できませんでした',
            type: 'error',
          });
          throw err;
        });
        createSnackbar({
          message: 'IPアドレスを削除しました',
          type: 'success',
        });
        ipAddressRestrictionMutate();
        teamMutate();
      };
      const payload = {
        headerText: 'IPアドレスを削除しますか？',
        bodyText: deleteModalBodyText.value,
        action: deleteAction,
      };

      store.commit('confirmationModal/showConfirmationModal');
      store.commit('confirmationModal/setTextAndAction', payload);
    };

    const updateList = () => {
      ipAddressRestrictionMutate();
    };
    return {
      items,
      fields,
      toggleDisabled,
      actionMenuItem,
      targetIpAddressRestriction,
      enableIpAddressRestriction,
      deleteIpAddressRestriction,
      updateEnableIpAddressRestriction,
      openEditModal,
      isEditModalOpen,
      updateList,
      MAX_IP_ADDRESS_RESTRICTIONS,
      openCreateModal,
      isCreateModalOpen,
      isIpAddressRestrictionsAtLimit,
    };
  },
});
</script>
<template>
  <div class="ip-address-restriction">
    <Header title="IPアドレス制限" header-width="100%" />
    <AdminContent class="ip-address-restriction-content">
      <div>
        <div class="header">
          <div class="title">
            <span class="c-title c-title--m">IPアドレス</span
            ><span class="count c-text c-text--s"
              >・{{ items.length }}/{{ MAX_IP_ADDRESS_RESTRICTIONS }}件</span
            >
          </div>
          <button
            class="add-ip-address-button c-btn--small c-btn--auto c-btn--AnewsPrimary c-text c-text--m"
            :class="{ disabled: isIpAddressRestrictionsAtLimit }"
            @click="openCreateModal"
            :disabled="isIpAddressRestrictionsAtLimit"
          >
            IPアドレス追加
          </button>
        </div>
        <div class="spacing-16"></div>
        <div class="enable-ip-address-restriction-toggle">
          <div
            class="toggle-form"
            :class="{ 'toggle-disabled': toggleDisabled }"
          >
            <DgrToggleButton
              @click="updateEnableIpAddressRestriction"
              :model-value="enableIpAddressRestriction"
              :read-only="true"
              :disabled="toggleDisabled"
            >
            </DgrToggleButton>
            <div class="c-text c-text--s">IPアドレス制限を有効にする</div>
          </div>
          <div class="spacing-04"></div>

          <VTooltip placement="bottom" theme="white" class="disable-box-shadow">
            <DgrIcon size="small" name="question-circle-fill" />
            <template #popper>
              <span
                >IPアドレス制限を有効にすると、登録したIPアドレスのみ、Anewsにアクセス可能となります。</span
              >
            </template>
          </VTooltip>
        </div>
        <div class="spacing-16"></div>
        <AdminBaseTable
          :items="items"
          :fields="fields"
          class="ip-address-table"
        >
          <template #header-ip_address="{ item }">
            <div class="title">
              <span class="c-text c-text--m">{{ item.displayName }}</span>
              <div class="spacing-04"></div>

              <VTooltip
                placement="top"
                theme="white"
                class="disable-box-shadow"
              >
                <DgrIcon size="small" name="question-circle-fill" />
                <template #popper>
                  <span>
                    IPv4の通常の表記及びCIDR表記に対応しています。<br />通常の表記（例
                    203.0.113.0）<br />CIDR表記（例 203.0.113.0/24）
                  </span>
                </template>
              </VTooltip>
            </div>
          </template>
          <template #cell-description="{ item }">
            {{ item.description }}
          </template>
          <template #cell-actions="{ item }">
            <div class="actions">
              <div class="action-icon" @click.stop="actionMenuItem = item">
                <DgrIcon name="ellipsis-h" />
              </div>
              <div class="action-menu" v-if="actionMenuItem?.id === item.id">
                <div class="item-container">
                  <div
                    class="item c-text c-text--m"
                    @click="openEditModal(item)"
                  >
                    <DgrIcon size="small" name="pencil" class="item-icon" />
                    <span>編集</span>
                  </div>
                  <div class="separator"></div>
                  <div
                    class="item c-text c-text--m"
                    @click="deleteIpAddressRestriction(item)"
                  >
                    <DgrIcon
                      size="small"
                      name="trash"
                      class="item-icon small"
                    />
                    <span>削除</span>
                  </div>
                </div>
              </div>
            </div>
          </template>
          <template #empty-tbody>
            <div>
              登録されているIPアドレスがありません、IPアドレス追加から登録してください。
            </div>
          </template>
        </AdminBaseTable>
      </div>
    </AdminContent>
    <IpAddressRestrictionEditModal
      :is-open="isEditModalOpen"
      :ip-address-restriction="targetIpAddressRestriction"
      @on-close="isEditModalOpen = false"
      @on-submit="updateList"
    />
    <IpAddressRestrictionBulkCreateModal
      @on-close="isCreateModalOpen = false"
      @on-submit="updateList"
      :is-open="isCreateModalOpen"
      :current-ip-address-restrictions-count="items.length"
    />
  </div>
</template>
<style lang="scss" scoped>
.ip-address-restriction {
  width: 100%;
  margin: -24px 0 0 0;
  .ip-address-restriction-content {
    display: flex;
    justify-content: center;
    .header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      .add-ip-address-button {
        padding-top: 4px;
      }
    }
    .enable-ip-address-restriction-toggle {
      display: flex;
      justify-content: flex-end;
      .toggle-form {
        display: flex;
      }
      .toggle-disabled {
        color: $color-gray600;
      }
    }
    .ip-address-table {
      margin-bottom: 80px;
    }
  }
  .title {
    display: flex;
    align-items: center;
    .count {
      color: #b3b3b3;
    }
  }
  .actions {
    position: relative;
    width: 40px;
    padding-right: 0;
    display: inline-block;

    .action-icon {
      display: flex;
      justify-content: center;
      width: 32px;
      padding: 8px;
      border-radius: 4px;
      cursor: pointer;

      &:hover {
        background-color: #f2f2f2;
      }
    }

    .action-menu {
      position: absolute;
      right: 0;
      width: max-content;
      word-wrap: nowrap;
      white-space: nowrap;
      padding: 12px 0;
      background: #ffffff;
      box-shadow: 0px 1px 5px rgba(74, 74, 74, 0.25);
      border-radius: 4px;
      z-index: var(--z-action-menu);

      .item {
        display: flex;
        align-items: center;
        cursor: pointer;
        padding: 4px 12px;
        &:hover {
          background: #e6e6e6;
        }
      }
      .separator {
        margin: 8px 0;
        border-top: 1px solid #e6e6e6;
      }
      .item-icon {
        margin-right: 4px;
      }
    }
  }
}

.disable-box-shadow {
  box-shadow: none !important;
}
</style>
