<script lang="ts">
import {
  computed,
  defineComponent,
  onMounted,
  onUnmounted,
  ref,
  watch,
} from 'vue';
import AdpDocumentCard from '@/components/common/adp-document-card.vue';
import BasicEmptyFeed from '@/components/common/basic-empty-feed.vue';
import { Article, FeedType, IndustryArticle } from '@/types';
import { useIndustries, useIndustryArticles } from '@/utils/swr';
import { userSession } from '@/utils/userSession';
import { useEmitter, useStore } from '@/utils/vue';

export default defineComponent({
  components: {
    AdpDocumentCard,
    BasicEmptyFeed,
  },
  setup() {
    const store = useStore();
    const emitter = useEmitter();
    const { data } = useIndustries();

    const industries = computed(() => {
      return data.value?.industries ?? [];
    });
    const defaultIndustry = computed(() =>
      industries.value?.find(a => a.is_default),
    );
    const currentIndustryId = ref(
      userSession.getIndustryId()
        ? Number(userSession.getIndustryId())
        : defaultIndustry.value?.id,
    );
    const currentIndustry = computed(() =>
      industries.value?.find(
        industry => industry.id === currentIndustryId.value,
      ),
    );

    const { data: industryNews } = useIndustryArticles(currentIndustryId);

    const articles = computed(() => {
      const industryArticles = industryNews.value?.industry_articles ?? [];
      let articles: IndustryArticle[] = [];
      industryArticles.forEach((article, index) => {
        if (
          index === 0 ||
          industryArticles[index - 1].topic_id !==
            industryArticles[index].topic_id
        ) {
          articles.push(article);
        }
      });
      return articles.slice(0, 5);
    });

    const feedType = computed(() => store.state.feedType.feedType as FeedType);

    const handleArticleUpdate = (article: Article) => {
      if (articles.value == null) {
        return;
      }
      const index = articles.value.findIndex(a => a.id === article.id);
      if (index >= 0) {
        articles.value[index].marks = article.marks;
      }
    };

    const getIndustryTrackingData = (topicId: number) => {
      return {
        id: currentIndustryId.value ?? 0,
        topic: { id: topicId, rank: 1 },
      };
    };

    watch(
      () => industries.value,
      () => {
        if (
          !userSession.getIndustryId() &&
          !currentIndustryId.value &&
          defaultIndustry.value
        ) {
          currentIndustryId.value = defaultIndustry.value.id;
        }
      },
    );

    onMounted(async () => {
      emitter.on('article-updated', handleArticleUpdate);
    });

    onUnmounted(async () => {
      emitter.off('article-updated', handleArticleUpdate);
    });

    const noFeedMessage = computed(() =>
      currentIndustryId.value === undefined
        ? '現在、業界が未選択になっています。'
        : 'この業界のニュースはありませんでした。',
    );

    return {
      articles,
      feedType,
      PREVIEW_COUNT: 5,
      currentIndustry,
      getIndustryTrackingData,
      noFeedMessage,
    };
  },
});
</script>

<template>
  <div class="industry-news-preview">
    <div class="title">
      <div class="c-text c-text--l">業界ニュース</div>
      <div class="industry-name c-text c-text--m" v-if="currentIndustry">
        {{ `・「${currentIndustry.name}」を選択中` }}
      </div>
    </div>
    <div
      class="preview-articles"
      v-if="currentIndustry && articles && articles.length > 0"
    >
      <div class="articles" v-for="article in articles" :key="article.id">
        <div class="spacing-12"></div>
        <AdpDocumentCard
          page-name="home"
          feature="industry_news"
          :adp-document="article"
          :show-comment-count="1"
          :case-study-text="article.case_study_text"
          :trend-company-text="article.trend_company_text"
          :industry-data="getIndustryTrackingData(article.topic_id)"
          :disable-related-articles="false"
          width="100%"
        ></AdpDocumentCard>
      </div>
      <div class="spacing-16"></div>
      <div class="industry-news-page-link">
        <router-link to="/team/industry_news">
          <button
            class="c-btn c-btn--small c-btn--AnewsPrimary c-text c-text--"
          >
            もっと見る
          </button>
        </router-link>
      </div>
    </div>
    <div
      class="empty-state"
      v-if="
        currentIndustry === undefined || (articles && articles.length === 0)
      "
    >
      <div class="spacing-12"></div>
      <BasicEmptyFeed>{{ noFeedMessage }}</BasicEmptyFeed>
    </div>
  </div>
</template>
<style lang="scss" scoped>
.title {
  display: flex;
  align-items: center;
  .industry-name {
    color: #b3b3b3;
  }
}
.industry-news-preview {
  display: flex;
  flex-direction: column;

  .industry-news-page-link {
    display: flex;
    justify-content: center;
  }
}
</style>
