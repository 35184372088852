import { createApp } from 'vue';
import VueGtag, { PageView, PluginOptions } from 'vue-gtag';
import { RouteRecord } from 'vue-router';
import { createGtm } from '@gtm-support/vue-gtm';
import * as Sentry from '@sentry/vue';
import PCApp from '@/components/pc-app.vue';
import { gaTracker } from '@/utils/ga';
import { userSession } from '@/utils/userSession';
import router from './routers/pcRouter';
import store from './stores/pcStore';

const pageTrackerTemplate = (to: RouteRecord) => {
  const teamId = userSession.getTeamIdAsString();
  return {
    page_title: to.name,
    page_path: to.path,
    page_location: window.location.href,
    team_id: teamId,
  } as PageView;
};

const gTagOptions: PluginOptions = {
  config: { id: gaTracker.getTrackingId() ?? '' },
  pageTrackerTemplate:
    pageTrackerTemplate as PluginOptions['pageTrackerTemplate'],
};

const app = createApp(PCApp);

const dsn = import.meta.env.VITE_SENTRY_DSN;
Sentry.init({
  app,
  dsn,
  enabled: !!dsn && !/localhost|127\.0\.0\.1/.test(window.location.host),
  attachProps: true,
  logErrors: true,
  environment: import.meta.env.VITE_ENV,
  replaysSessionSampleRate: 0,
  replaysOnErrorSampleRate: 1.0,
  tracesSampleRate: 0.2,
  integrations: [
    Sentry.replayIntegration({
      maskAllText: true,
      blockAllMedia: true,
    }),
    Sentry.browserTracingIntegration({ router }),
  ],
  // パフォーマンス監視対象
  tracePropagationTargets: [
    'localhost',
    'development.anews-development.com',
    'staging.anews-staging.com',
    'anews.stockmark.ai',
  ],
  // https://docs.sentry.io/platforms/javascript/configuration/filtering/
  // https://gist.github.com/impressiver/5092952
  denyUrls: [
    // Wicle由来のエラーを無視する
    /cdn-edge\.karte\.io/i,
    /static\.karte\.io/i,
    // Facebook flakiness
    /graph\.facebook\.com/i,
    // Facebook blocked
    /connect\.facebook\.net\/en_US\/all\.js/i,
    // Woopra flakiness
    /eatdifferent\.com\.woopra-ns\.com/i,
    /static\.woopra\.com\/js\/woopra\.js/i,
    // Chrome extensions
    /extensions\//i,
    /^chrome:\/\//i,
    // Other plugins
    /127\.0\.0\.1:4001\/isrunning/i, // Cacaoweb
    /webappstoolbarba\.texthelp\.com\//i,
    /metrics\.itunes\.apple\.com\.edgesuite\.net\//i,
  ],
  ignoreErrors: [
    // Random plugins/extensions
    'top.GLOBALS',
    // See: http://blog.errorception.com/2012/03/tale-of-unfindable-js-error.html
    'originalCreateNotification',
    'canvas.contentDocument',
    'MyApp_RemoveAllHighlights',
    'http://tt.epicplay.com',
    "Can't find variable: ZiteReader",
    'jigsaw is not defined',
    'ComboSearch is not defined',
    'http://loading.retry.widdit.com/',
    'atomicFindClose',
    // Facebook borked
    'fb_xd_fragment',
    // ISP "optimizing" proxy - `Cache-Control: no-transform` seems to
    // reduce this. (thanks @acdha)
    // See http://stackoverflow.com/questions/4113268
    'bmi_SafeAddOnload',
    'EBCallBackMessageReceived',
    // See http://toolbar.conduit.com/Developer/HtmlAndGadget/Methods/JSInjection.aspx
    'conduitPage',
    'SecurityError: Failed to register a ServiceWorker for scope',
    /^Script.*serviceWorker\.js load failed$/,
    'AbortError: Registration failed - push service error',
  ],
});

app.use(router);
app.use(store);
app.use(VueGtag, gTagOptions, router);
app.use(
  createGtm({
    id: import.meta.env.VITE_GTM_TRACKING_ID,
    vueRouter: router,
  }),
);
app.mount('#app');

export default app;
