<script setup lang="ts">
import { computed, onMounted, ref } from 'vue';
import api from '@/api';
import { SHAREPOINT_ERROR_MESSAGES } from '@/constants';
import { isAxiosError } from 'axios';
import LoadableButton from '@/components/common/atoms/loadable-button.vue';
import { useSnackbar } from '@/components/common/snackbar/use-snackbar';

interface Props {
  isOpen: boolean;
}
defineProps<Props>();

const { createSnackbar } = useSnackbar();

const clientSecret = ref('');
const currentClientSecret = ref('');

const isCreating = ref(false);
const isCreatable = computed(() => clientSecret.value !== '');

const emit = defineEmits<{
  onClose: [void];
}>();
const hideModal = () => {
  clientSecret.value = '';
  emit('onClose');
};

const updateSharepointCredential = async () => {
  isCreating.value = true;
  try {
    await api.putSharepointCredential(clientSecret.value);
    createSnackbar({
      message: '認証情報を更新しました',
      type: 'success',
    });
    hideModal();
  } catch (error) {
    let errorMessage = '認証情報の登録に失敗しました';
    if (isAxiosError(error)) {
      const serverError: string = error.response?.data.error;
      if (serverError in SHAREPOINT_ERROR_MESSAGES) {
        errorMessage = SHAREPOINT_ERROR_MESSAGES[serverError];
      }
    }
    createSnackbar({
      message: errorMessage,
      type: 'error',
    });

    // eslint-disable-next-line no-console
    console.error(error);
  }
  isCreating.value = false;
};

onMounted(async () => {
  const response = await api.getSharepointCredential();
  currentClientSecret.value = response.client_secret;
});
</script>

<template>
  <Teleport to="body">
    <sm-dialog
      v-if="isOpen"
      @close="hideModal"
      class="screen-center no-padding auto-width"
    >
      <template #header>
        <div class="c-dialog__title modal-header">ストレージの認証情報編集</div>
      </template>
      <template #body>
        <div class="modal-body">
          <div class="modal-form">
            <label class="c-title c-title--m" for="client-secret-form"
              >クライアントシークレット</label
            >
            <div class="spacing-08"></div>
            <div class="c-text c-text--s current-client-secret">
              {{ currentClientSecret }}
            </div>
            <div class="spacing-08"></div>
            <input
              type="text"
              maxlength="250"
              v-model="clientSecret"
              id="client-secret-form"
            />
          </div>
          <div class="modal-footer">
            <button
              class="modal-cancel-button c-btn c-btn--auto c-btnOutline"
              @click="hideModal"
            >
              キャンセル
            </button>
            <div class="spacing-16"></div>
            <LoadableButton
              class="modal-create-button c-btn c-btn--auto c-btn--AnewsPrimary"
              :class="{ disabled: !isCreatable }"
              :disabled="!isCreatable"
              @click="updateSharepointCredential"
              :is-loading="isCreating"
            >
              保存
            </LoadableButton>
          </div>
        </div>
      </template>
    </sm-dialog>
  </Teleport>
</template>

<style lang="scss" scoped>
.modal-header {
  padding: 5px 0px;
}
.modal-body {
  width: 600px;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  .modal-form {
    padding: 25px 24px 0px 24px;
    .current-client-secret {
      color: $color-gray800;
    }
    input {
      width: 500px;
    }
  }
}
.modal-footer {
  padding: 9px 16px 16px 16px;
  display: flex;
  justify-content: flex-end;
}
</style>
