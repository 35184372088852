<script setup lang="ts">
import { computed, ref } from 'vue';
import { escapeRegExpSpecialCharacters } from '@/utils/regexp';
import { escapeHTML, sanitize } from '@/utils/sanitize';

interface Props {
  highlightKeywords: string[];
  text: string;
  lineClamp: number;
}

const contentRef = ref<HTMLElement | null>(null);
const props = defineProps<Props>();

const keywordPattern = computed(() =>
  props.highlightKeywords
    .map(keyword => escapeRegExpSpecialCharacters(keyword))
    .join('|'),
);

const firstHitKeywordPositionIndex = computed(() => {
  if (props.highlightKeywords.length === 0) return -1;
  return new RegExp(keywordPattern.value).exec(props.text)?.index ?? -1;
});

const partsContentHtml = computed(() => {
  const chunkText = props.text;
  if (firstHitKeywordPositionIndex.value === -1) {
    return sanitize(chunkText);
  }

  // 最大許容文字数をコンポーネントの幅から計算
  const maxDisplayContentWordSize = contentRef.value
    ? (contentRef.value.clientWidth * 3) / 15
    : 80;
  // 最初にヒットしたキーワードの位置以降のテキストを取得
  const slicedContentFromHitKeyword = chunkText.slice(
    firstHitKeywordPositionIndex.value,
  );
  // 表示文章が文末かどうかを判定
  const isEndOfSentence =
    slicedContentFromHitKeyword.length <= maxDisplayContentWordSize &&
    firstHitKeywordPositionIndex.value !== 0;
  // ハイライトのためのキーワードの位置を計算
  const startPosition = isEndOfSentence
    ? chunkText.length - maxDisplayContentWordSize
    : firstHitKeywordPositionIndex.value;
  // 表示するテキスト
  const slicedContent = isEndOfSentence
    ? chunkText.slice(-maxDisplayContentWordSize)
    : slicedContentFromHitKeyword;

  const beforeEllipsis = startPosition > 0 ? '…' : '';
  return sanitize(beforeEllipsis + replaceHighlight(slicedContent), {
    allowedTags: ['span'],
    allowedAttributes: {
      span: ['class'],
    },
  });
});

const replaceHighlight = (text: string) =>
  text.replace(
    new RegExp(keywordPattern.value, 'g'),
    match => `<span class="highlight">${escapeHTML(match)}</span>`,
  );
</script>

<template>
  <div class="quote">
    <!-- eslint-disable vue/no-v-html -->
    <div
      class="quote-content c-text c-text--m"
      ref="contentRef"
      v-html="partsContentHtml"
    ></div>
    <!-- eslint-enable vue/no-v-html -->
  </div>
</template>

<style lang="scss" scoped>
.quote {
  overflow: hidden;
  display: -webkit-box;
  text-overflow: ellipsis;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: v-bind('lineClamp');
  vertical-align: bottom;
  word-break: break-all;

  font-weight: 400;
  color: $color-gray800;
  border-left: 1px solid $color-gray400;
  .quote-content {
    padding-left: 13px;
    :deep(.highlight) {
      background-color: $color-gray400;
      color: $color-gray1000;
    }
  }
}
</style>
