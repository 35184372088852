<script lang="ts">
import {
  computed,
  defineComponent,
  nextTick,
  onMounted,
  ref,
  watch,
} from 'vue';
import { useRoute, useRouter } from 'vue-router';
import api from '@/api';
import { DgrIcon } from '@stockmarkteam/donguri-ui';
import { isAxiosError } from 'axios';
import dayjs from 'dayjs';
import BasicEmptyFeed from '@/components/common/basic-empty-feed.vue';
import HeaderDetailContent from '@/components/common/header/header-detail-content.vue';
import { useSnackbar } from '@/components/common/snackbar/use-snackbar';
import Content from '@/components/layouts/content.vue';
import Header from '@/components/layouts/header.vue';
import ThemeTitleContent from '@/components/theme/theme-title-content.vue';
import { Group, KeywordFeed, Lang, Theme, ThemeList } from '@/types';
import { getTabs, getThemeHeaderDetail } from '@/utils/theme';
import { useEmitter, useStore } from '@/utils/vue';

// TODO: Themeがundefinedになるときに型エラーにならないようにするための処置
const emptyTheme: Theme = {
  id: 0,
  name: '',
  description: '',
  is_deleted: false,
  is_favorite: false,
  is_chinese: false,
  order: 0,
  access_scope: 'public',
  created_at: '',
  updated_at: '',
  keywords: [],
  site_exclusion_ids: [],
  excluded_sites: [],
  is_foreign_media_enabled: false,
  is_research_paper_enabled: false,
  is_patent_enabled: false,
  is_entertainment_sites_filtered: false,
  is_initial_theme: false,
  is_email_notification_enabled: true,
  feed_last_updated_at: '',
  feeds_updated_at: null,
  keyword_feed_counts: {},
  related_feed_counts: { domestic: 0, foreign: 0 },
  featured_article_feed_count: 0,
  research_paper_feed_counts: { domestic: 0, foreign: 0 },
  patent_feed_counts: { domestic: 0, foreign: 0 },
  research_paper_feed_last_updated_at: null,
  excluded_site_categories: [],
  exclude_alliance_media: false,
};

export default defineComponent({
  props: {
    themeId: { type: [String, Number], default: undefined },
    isMenuVisible: {
      type: Boolean,
      default: true,
    },
  },
  components: {
    Header,
    Content,
    BasicEmptyFeed,
    ThemeTitleContent,
    HeaderDetailContent,
    DgrIcon,
  },
  setup(props) {
    const store = useStore();
    const emitter = useEmitter();
    const route = useRoute();
    const router = useRouter();
    const { createSnackbar } = useSnackbar();

    const themes = computed(() => store.state.themeList.themes);
    const hasFetchedThemes = computed(
      () => store.state.themeList.hasFetchedThemes,
    );
    const hasThemeChanged = computed(
      () =>
        route.params.themeId &&
        themeDetail.value?.id !== Number(route.params.themeId),
    );
    const userInfo = computed(() => store.state.userInfo.userInfo);
    const groups = computed(() => store.state.groups.groups);
    const teamUsers = computed(() => store.state.teamUsers.teamUsers);
    const teamInfo = computed(() => store.state.teamInfo.teamInfo);
    const feedType = computed(() => store.state.feedType.feedType);

    const loaded = ref(false);
    const themeDetail = ref<Theme | undefined>(undefined);

    onMounted(async () => {
      setUp();
    });

    watch(hasFetchedThemes, async () => setUp());

    watch(
      () => props.themeId,
      () => {
        if (hasThemeChanged.value) {
          setUp();
        }
      },
    );

    const setUp = async () => {
      if (hasFetchedThemes.value) {
        await fetchTheme();
        await trackPageView();
      }
    };

    const fetchTheme = async () => {
      if (!hasThemeChanged.value) {
        return;
      }
      loaded.value = false;
      try {
        themeDetail.value = await api.fetchTheme(Number(props.themeId));
        nextTick(() => (loaded.value = true));
      } catch (error) {
        if (isAxiosError(error) && error.response?.status === 404) {
          redirectToHome('指定されたテーマは存在しません');
          return;
        }
        throw error;
      }
      if (themeDetail.value?.is_deleted) {
        redirectToHome('指定されたテーマは存在しません');
        return;
      }
    };

    const redirectToHome = (message: string) => {
      router.push({ name: 'anewsHome' });
      createSnackbar({
        message: message,
        type: 'error',
      });
    };

    const theme = computed<Theme>(() => {
      if (!hasFetchedThemes.value) return emptyTheme;
      // 削除済みテーマの場合はthemesにテーマがないため、個別で取得したthemeDetailを返す
      if (!themeDetail.value) return emptyTheme;
      const _theme: ThemeList | undefined = themes.value.find(
        t => t.id === Number(props.themeId),
      );
      // themeがユーザーのthemeListなのでis_favoriteのデータがあって、その他の情報（例：マーク数）はthemeDetailがある
      if (!_theme) return emptyTheme;
      const margeTheme: Theme = { ..._theme, ...themeDetail.value };
      return margeTheme;
    });

    const toggleFavorite = async (is_favorite: boolean | undefined) => {
      let order: number | undefined = undefined;
      if (is_favorite) {
        try {
          await api.deleteFavoriteTheme(Number(props.themeId));
          createSnackbar({
            message: 'お気に入りを解除しました',
            type: 'success',
          });
        } catch (error) {
          createSnackbar({
            message: 'お気に入りの解除に失敗しました',
            type: 'error',
          });
          throw error;
        }
      } else {
        try {
          const data = await api.postFavoriteTheme(Number(props.themeId));
          order = data.order;
          createSnackbar({
            message: 'お気に入りに登録しました',
            type: 'success',
          });
          if (!theme.value?.is_member) {
            toggleUserTheme(true);
          }
        } catch (error) {
          createSnackbar({
            message: 'お気に入りの登録に失敗しました',
            type: 'error',
          });
          throw error;
        }
      }
      updateThemeFavorite(order);
    };

    const updateThemeFavorite = async (order?: number) => {
      if (!theme.value) return;
      theme.value.is_favorite = !theme.value.is_favorite;
      const listTheme = (
        store.getters['themeList/activeThemes'] as ThemeList[]
      ).find(theme => theme.id === Number(props.themeId));
      if (!listTheme) return;
      listTheme.is_favorite = theme.value.is_favorite;
      // orderがundefinedになるときはお気に入り解除のときなのでorderの値は何でも良い
      listTheme.order = order ?? 0;
      store.commit('themeList/updateTheme', listTheme);

      emitter.emit('theme-updated', listTheme);
    };

    const trackPageView = async () => {
      await api.trackPageView({
        pageName: 'theme',
        pageUrl: route.fullPath,
        theme: {
          id: Number(props.themeId),
        },
        feedType: feedType.value,
      });
    };

    const isAddButtonVisible = computed(() => {
      return (
        !theme.value?.is_member &&
        (theme.value?.access_scope !== 'personal' ||
          theme.value?.user_id === userInfo.value?.id)
      );
    });

    const isEditingTheme = computed(() => {
      return route.name === 'themeEdit';
    });

    const isCreatingTheme = computed(() => {
      return route.name === 'themeCreate';
    });

    const hasGroupScope = computed(() => {
      return themeDetail.value?.access_scope === 'group';
    });

    const themeGroup = computed<Group | undefined>(() => {
      return groups.value.find(g => g.id === themeDetail.value?.group_id);
    });

    const isMemberOfThemeGroup = computed(() => {
      return themeGroup.value?.is_member === true;
    });

    const updateThemeIsMember = async (isMember: boolean): Promise<void> => {
      if (!themeDetail.value) return;
      themeDetail.value.is_member = isMember;
      const listTheme = (
        store.getters['themeList/activeThemes'] as ThemeList[]
      ).find(theme => theme.id === Number(props.themeId));
      if (!listTheme) return;
      listTheme.is_member = themeDetail.value.is_member;
      store.commit('themeList/updateTheme', listTheme);
      emitter.emit('theme-updated', listTheme);
      await store.dispatch('groups/fetchGroups', userInfo.value?.id);
    };

    const isUpdatingUserTheme = ref(false);
    const toggleUserTheme = async (hideSnackbar = false): Promise<void> => {
      isUpdatingUserTheme.value = true;

      if (!theme.value?.is_member) {
        try {
          if (
            hasGroupScope.value &&
            !isMemberOfThemeGroup.value &&
            themeDetail.value?.group_id
          ) {
            await api.joinGroup(themeDetail.value.group_id);
          }
          await api.addUserTheme(Number(props.themeId));
          await updateThemeIsMember(true);

          if (hideSnackbar) {
            isUpdatingUserTheme.value = false;
            return;
          }

          await themeTrackEvent('add_theme');

          createSnackbar({
            message: 'テーマを追加しました',
            type: 'success',
          });
        } catch (error) {
          createSnackbar({
            message: 'テーマを追加できませんでした',
            type: 'error',
          });
          throw error;
        }
      } else {
        try {
          await api.deleteUserTheme(Number(props.themeId));
          await updateThemeIsMember(false);

          if (theme.value.is_favorite) {
            await api.deleteFavoriteTheme(Number(props.themeId));
            updateThemeFavorite();
          }

          if (hideSnackbar) {
            isUpdatingUserTheme.value = false;
            return;
          }

          await themeTrackEvent('remove_theme');

          createSnackbar({
            message: 'テーマを解除しました',
            type: 'success',
          });
        } catch (error) {
          createSnackbar({
            message: 'テーマを解除できませんでした',
            type: 'error',
          });
          throw error;
        }
      }
      isUpdatingUserTheme.value = false;
    };

    const themeTrackEvent = async (name: string): Promise<void> => {
      const params = {
        theme: { id: props.themeId },
      };
      await api.trackEvent(
        name,
        {
          pageName: 'theme',
          pageUrl: route.fullPath,
        },
        undefined,
        undefined,
        params,
      );
    };

    const addButtonText = computed(() => {
      if (
        themeDetail.value?.access_scope === 'group' &&
        !isMemberOfThemeGroup.value
      ) {
        return 'グループに参加して追加';
      }
      return '追加';
    });

    const isThemeContentDisabled = computed(() => {
      return (
        theme.value?.is_chinese && !teamInfo.value?.enable_chinese_lang_media
      );
    });

    const tabs = computed(() => {
      if (!theme.value) return undefined;
      if (isThemeContentDisabled.value) {
        return undefined;
      }
      return theme.value?.id
        ? getTabs(feedType.value, theme.value, teamInfo.value)
        : undefined;
    });

    const showFavorite = computed(() => {
      if (!theme.value) return false;
      return (
        theme.value?.is_favorite ||
        theme.value?.access_scope != 'personal' ||
        theme.value?.user_id === userInfo.value?.id
      );
    });

    const detail = computed(() =>
      getThemeHeaderDetail(theme.value, groups.value, teamUsers.value),
    );

    const navigateToThemeEdit = () => {
      router.push({ name: 'themeEdit', params: { themeId: theme.value.id } });
    };

    emitter.on(
      'article-removed',
      ({
        themeId,
        feedDate,
        lang,
      }: {
        themeId: number | null;
        feedDate: KeywordFeed['feed_date'];
        lang: Lang;
      }) => {
        if (themeId == theme.value.id) {
          const nowDate = dayjs().format('YYYY-MM-DD');
          if (nowDate === feedDate) {
            theme.value.keyword_feed_counts[feedDate][
              lang === 'ja' ? 'domestic' : 'foreign'
            ] = Math.max(
              0,
              theme.value.keyword_feed_counts[feedDate][
                lang === 'ja' ? 'domestic' : 'foreign'
              ] - 1,
            );
          }
        }
      },
    );

    return {
      theme,
      themeDetail,
      groups,
      teamUsers,
      getThemeHeaderDetail,
      toggleFavorite,
      toggleUserTheme,
      isAddButtonVisible,
      isEditingTheme,
      isCreatingTheme,
      addButtonText,
      tabs,
      showFavorite,
      isUpdatingUserTheme,
      loaded,
      isThemeContentDisabled,
      navigateToThemeEdit,
      detail,
    };
  },
});
</script>

<template>
  <div class="o-theme-feed">
    <Header
      :title="theme ? theme.name : undefined"
      :detail="detail.firstLine"
      icon="hashtag"
      :tabs="tabs"
      content-width="904px"
      :is-menu-visible="isMenuVisible"
    >
      <template #titleContent>
        <ThemeTitleContent v-if="theme" :title="theme.name" left-icon="hashtag">
          <template #titleIconRight>
            <DgrIcon
              :name="theme.is_favorite ? 'star-fill' : 'star'"
              class="favorite-theme"
              :keep-fill="false"
              v-if="showFavorite"
              @click="toggleFavorite(theme.is_favorite)"
              v-tooltip.top="
                theme.is_favorite ? 'お気に入り解除' : 'お気に入り'
              "
              :class="{
                favorite: theme.is_favorite,
              }"
            />
          </template>
        </ThemeTitleContent>
      </template>
      <template #button>
        <div id="buttons-wrapper" class="theme-buttons">
          <button
            class="edit-theme c-btn c-btn--small"
            v-if="!isEditingTheme && !isCreatingTheme"
            @click.prevent="() => navigateToThemeEdit()"
          >
            <DgrIcon
              size="small"
              name="pencil"
              :keep-fill="false"
              class="pencil-icon"
            />
            編集
          </button>
          <button
            class="add-theme c-btn c-btn--small c-btn--AnewsPrimary"
            v-if="loaded && isAddButtonVisible"
            :disabled="isUpdatingUserTheme"
            @click.prevent="() => toggleUserTheme()"
          >
            {{ addButtonText }}
          </button>
          <button
            class="add-theme c-btn c-btn--small c-btn"
            v-else-if="loaded && theme.is_member"
            :disabled="isUpdatingUserTheme"
            @click.prevent="() => toggleUserTheme()"
          >
            解除
          </button>
        </div>
      </template>
      <template #detail-content>
        <HeaderDetailContent
          v-if="detail.firstLine"
          :detail="detail.firstLine"
          :is-menu-visible="isMenuVisible"
          :is-responsive="true"
        />
        <HeaderDetailContent
          v-if="detail.secondLine"
          :detail="detail.secondLine"
          :is-menu-visible="isMenuVisible"
          :is-responsive="true"
        />
      </template>
    </Header>
    <Content>
      <div class="theme">
        <div
          class="o-feed-ready"
          v-if="loaded && !isThemeContentDisabled"
          :key="themeDetail?.id"
        >
          <div class="o-theme-feed-body" ref="feedBody">
            <router-view
              :theme="themeDetail"
              :theme-id="themeDetail?.id"
            ></router-view>
          </div>
        </div>
        <div v-else-if="isThemeContentDisabled">
          <BasicEmptyFeed
            >このテーマは中国語メディア専用です。ご利用になるには中国語メディア配信オプションの契約が必要です。</BasicEmptyFeed
          >
        </div>
      </div>
    </Content>
  </div>
</template>

<style lang="scss" scoped>
.o-theme-feed {
  width: 100%;
  display: flex;
  flex-direction: column;
  margin: -24px 0 0 0;

  .favorite-theme {
    margin-left: 8px;
    cursor: pointer;
    flex-shrink: 0;
    margin-left: 4px;

    &.favorite {
      fill: #ef811f; /* donguri-uiで定義されているastartegy-primaryの色 */
    }
  }

  .theme-buttons {
    flex-shrink: 0;
    display: flex;
    gap: 8px;
  }

  .add-theme {
    width: auto;
  }

  .edit-theme {
    color: $color-green600;
    border-color: $color-green600;
    width: fit-content;
    cursor: pointer;
    &:hover {
      background-color: $color-green100;
    }
  }

  .pencil-icon {
    fill: currentColor;
  }

  .o-feed-ready {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-self: center;
    min-height: 100vh;
    width: 920px;

    div.o-theme-feed-body {
      width: 100%;
    }
    div.o-team-activity {
      position: relative;
      width: 280px;
      .o-team-activity-content {
        padding-bottom: 80px;
        .featured-articles {
          width: 280px;
        }
      }
    }
    div.o-team-activity-dummy {
      width: 280px;
      height: 1px;
    }
  }
  .o-feed-removed {
    .o-body {
      width: 616px;
      background: white;
      display: flex;
      align-items: center;
      border: 1px solid #e6e6e6;
      box-sizing: border-box;
      border-radius: 4px;
      padding: 16px;
    }
  }
}
</style>
