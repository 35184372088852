<script lang="ts">
import { defineComponent, PropType, ref } from 'vue';
import { useRoute } from 'vue-router';
import api from '@/api';
import { Feature, PageName } from '@/api/tracking';
import noImage from '@/assets/noimage-1to1.jpg';
import 'lazysizes';
import ArticleActions from '@/components/common/article/article-actions.vue';
import RankBadge from '@/components/common/atoms/rank-badge.vue';
import { Article, FeedType } from '@/types';
import { gaTracker } from '@/utils/ga';
import { setRedirectUrl, unsetRedirectUrl } from '@/utils/rightClickOpenTab';
import { useUserInfo } from '@/utils/swr';
import { useGTag } from '@/utils/vue';

export default defineComponent({
  props: {
    article: { type: Object as PropType<Article>, required: true },
    rank: { type: Number },
    themeId: { type: Number },
    showRankBadge: { type: Boolean, default: false },
    pageName: { type: String as PropType<PageName>, required: true },
    feature: { type: String as PropType<Feature>, required: true },
    feedType: { type: String as PropType<FeedType> },
  },
  components: {
    ArticleActions,
    RankBadge,
  },
  setup(props) {
    const route = useRoute();
    const gtag = useGTag();
    const { data: userInfo } = useUserInfo();

    const isRead = ref(props.article.is_read ?? false);

    const articleImage = ref(props.article.image_url);
    const trackArticleView = (article: Article): void => {
      isRead.value = true;

      gaTracker.trackArticleView(gtag);
      api.sendView({
        adpDocument: article,
        themeId: props.themeId ?? undefined,
        trackingBaseData: {
          pageName: props.pageName,
          pageUrl: route.fullPath,
          feature: props.feature,
          feedType: props.feedType,
        },
      });
    };

    const isTitleTranslated = (article: Article) =>
      userInfo.value?.enable_translate_article_title &&
      article.lang &&
      article.lang !== 'ja';

    const setRightClickRedirectUrl = (event: Event, article: Article) => {
      const sendViewParams = {
        adpDocument: article,
        themeId: props.themeId ?? undefined,
        trackingBaseData: {
          pageName: props.pageName,
          pageUrl: route.fullPath,
          feature: props.feature,
          feedType: props.feedType,
        },
      };
      setRedirectUrl(event, sendViewParams);
    };

    return {
      userInfo,
      articleImage,
      noImage,
      trackArticleView,
      isTitleTranslated,
      setRightClickRedirectUrl,
      unsetRedirectUrl,
      isRead,
    };
  },
});
</script>

<template>
  <div class="article" :key="article.id">
    <div class="article-info">
      <RankBadge
        class="rank-badge-outer"
        v-if="showRankBadge && rank && rank <= 3"
        :rank="rank"
      />
      <div class="title-container">
        <div class="document-media-name c-text c-text--s">
          {{ article.media_name }}
        </div>
        <a
          class="title title-media-name c-text c-text--m"
          :href="article.url"
          :class="{ read: isRead }"
          target="_blank"
          rel="noopener noreferrer"
          @click.stop="trackArticleView(article)"
          @click.middle.stop="trackArticleView(article)"
          @contextmenu="setRightClickRedirectUrl($event, article)"
          @mouseleave="unsetRedirectUrl"
          >{{
            isTitleTranslated(article)
              ? article.translated_title
              : article.title
          }}</a
        >
        <div
          v-if="isTitleTranslated(article)"
          class="translated-title c-text c-text--s"
        >
          元タイトル：{{ article.title }}
        </div>
      </div>
      <a
        :href="article.url"
        target="_blank"
        rel="noopener noreferrer"
        @contextmenu="setRightClickRedirectUrl($event, article)"
        @mouseleave="unsetRedirectUrl"
        ><img
          class="article-image lazyload"
          loading="lazy"
          :src="articleImage"
          :data-src="articleImage"
          @error="articleImage = noImage"
          @click.middle.stop="trackArticleView(article)"
          @click.stop="trackArticleView(article)"
        />
      </a>
    </div>
    <ArticleActions
      class="actions"
      :article="article"
      :theme-id="themeId"
      :page-name="pageName"
      :feature="feature"
    />
  </div>
</template>

<style scoped lang="scss">
.article {
  .article-info {
    display: flex;
    align-items: flex-start;
    position: relative;

    .rank-badge-outer {
      position: absolute;
      top: -8px;
      left: -8px;
    }
    .title-container {
      width: 75%;
      margin-left: 8px;
      margin-right: 12px;

      .title,
      .translated-title {
        display: -webkit-box;
        word-break: break-all;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 4;
        overflow: hidden;
      }
      .title {
        &:hover {
          text-decoration: underline;
        }
        &.read {
          color: $color-gray600;
        }
      }
      .title-media-name {
        margin-top: 4px;
      }
      .translated-title {
        margin-top: 4px;
        color: $color-gray600;
      }
    }
    .article-image {
      width: 48px;
      height: 48px;
      border-left: 1px solid #e6e6e6;
      box-sizing: border-box;
      object-fit: cover;
      font-family: 'object-fit: cover';
      border-radius: 4px;
    }
  }
  .actions {
    margin-top: 4px;
  }

  .document-media-name {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    color: $color-gray800;
  }
}
</style>
