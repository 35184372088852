<script lang="ts" setup>
import { computed, ref, watch } from 'vue';
import { useRoute } from 'vue-router';
import MenuItem from '@/components/menu/common/item.vue';
import SectionWrapper from '@/components/menu/sections/section-wrapper.vue';
import { Contract } from '@/types';
import { useManagementContracts } from '@/utils/swr';
import { useStore } from '@/utils/vue';

const { data: managementContracts } = useManagementContracts();

const store = useStore();
const route = useRoute();

const teamInfo = computed(() => store.state.teamInfo.teamInfo);

const showUserDocumentAdminTab = computed(
  () => !!teamInfo.value?.enable_user_document,
);

const isUserDocumentAdminTabSelected = computed(() => {
  return (
    showUserDocumentAdminTab.value &&
    (route.name === 'userDocumentsAdmin' ||
      route.name === 'sharepointCredentialCreate')
  );
});

const isMemberListViewable = ref(true);
const contracts = computed(
  () => managementContracts.value?.management_contracts,
);
watch(
  () => contracts.value,
  (changedContracts: Contract[] | undefined) => {
    isMemberListViewable.value =
      changedContracts != undefined && changedContracts?.length > 0;
  },
);
</script>
<template>
  <SectionWrapper>
    <div class="item-list">
      <div class="title-icon c-title c-title--s">
        <span class="title">管理</span>
      </div>
      <div class="items">
        <ul class="list-style--none">
          <li>
            <MenuItem
              id="admin_contracts"
              name="契約管理"
              :route="{ name: 'contracts' }"
              :is-disabled="!isMemberListViewable"
            ></MenuItem>
          </li>
          <li>
            <MenuItem
              id="admin_members"
              name="メンバー管理"
              :route="{ name: 'members' }"
              :is-disabled="!isMemberListViewable"
            ></MenuItem>
          </li>
          <li>
            <MenuItem
              id="organization_tag_list"
              name="組織タグ管理"
              :route="{ name: 'organizationTags' }"
            ></MenuItem>
          </li>
          <li>
            <MenuItem
              id="admin_theme"
              name="テーマ管理"
              :route="{ name: 'themeAdmin' }"
            ></MenuItem>
          </li>
          <li>
            <MenuItem
              id="admin_group"
              name="グループ管理"
              :route="{ name: 'groupAdmin' }"
            ></MenuItem>
          </li>
          <li v-if="showUserDocumentAdminTab">
            <MenuItem
              id="admin_user_documents"
              name="社内情報管理"
              :route="{ name: 'userDocumentsAdmin' }"
              :is-selected="isUserDocumentAdminTabSelected"
            ></MenuItem>
          </li>
          <li>
            <MenuItem
              id="admin_dashboard"
              name="レポート"
              :route="{ name: 'dashboardHome' }"
              :is-external-link="true"
            ></MenuItem>
          </li>
        </ul>
      </div>
    </div>

    <div class="item-list">
      <div class="title-icon c-title c-title--s">
        <span class="title">設定</span>
      </div>
      <div class="items">
        <ul class="list-style--none">
          <li>
            <MenuItem
              id="environment"
              name="環境設定"
              :route="{ name: 'environmentSettings' }"
            ></MenuItem>
          </li>
          <li>
            <MenuItem
              id="ip_address"
              name="IPアドレス制限"
              :route="{ name: 'ipAddressRestrictions' }"
            ></MenuItem>
          </li>
        </ul>
      </div>
    </div>
  </SectionWrapper>
</template>
<style lang="scss" scoped>
.note {
  display: flex;
  flex-direction: column;
  padding: 8px;
  box-sizing: border-box;
  width: 240px; /** Itemに合わせる */
  color: $color-gray1000;
}
/** memo: 汎化可能  */
.list-style--none {
  list-style: none;
}
.c-title {
  padding: 4px;
}
</style>
