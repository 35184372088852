<script lang="ts">
import {
  computed,
  defineComponent,
  onMounted,
  onUnmounted,
  PropType,
  ref,
  toRefs,
} from 'vue';
import AdpDocumentCard from '@/components/common/adp-document-card.vue';
import { Article, FeedType } from '@/types';
import { useTeamMarkedArticles } from '@/utils/swr';
import { useEmitter } from '@/utils/vue';

const DISPLAY_MARK_NEWS_COUNT = 5;

export default defineComponent({
  components: {
    AdpDocumentCard,
  },
  props: {
    feedType: { type: String as PropType<FeedType>, required: true },
  },
  setup(props) {
    const emitter = useEmitter();
    const { feedType } = toRefs(props);
    const { data: markedArticles } = useTeamMarkedArticles(
      ref(1),
      DISPLAY_MARK_NEWS_COUNT,
      true,
      feedType.value === 'all' ? undefined : feedType.value,
      undefined,
    );
    const articles = computed(
      () => markedArticles.value?.marked_articles ?? [],
    );

    const handleArticleUpdate = (article: Article) => {
      const index = articles.value.findIndex(a => a.id === article.id);
      if (index >= 0) {
        articles.value[index].marks = article.marks;
      }
    };

    onMounted(async () => {
      emitter.on('article-updated', handleArticleUpdate);
    });

    onUnmounted(async () => {
      emitter.off('article-updated', handleArticleUpdate);
    });

    return {
      articles,
    };
  },
});
</script>

<template>
  <div class="mark-news" v-if="articles && articles.length > 0">
    <div class="title-position">
      <div class="title c-text c-text--l">新着マークニュース</div>
    </div>
    <div class="divider"></div>
    <div class="articles" v-for="article in articles" :key="article.id">
      <AdpDocumentCard
        page-name="home"
        feature="team_marks"
        :feed-type="feedType"
        :adp-document="article"
        :show-comment-count="1"
        :disable-related-articles="false"
        width="100%"
      ></AdpDocumentCard>
      <div class="divider"></div>
    </div>
    <router-link class="mark-news-page-link" to="/team/marks">
      <button class="c-btn c-btn--small c-btn--AnewsPrimary c-text c-text--">
        もっと見る
      </button>
    </router-link>
  </div>
</template>
<style lang="scss" scoped>
.mark-news {
  display: flex;
  flex-direction: column;
  background: white;
  border: 1px solid #e6e6e6;
  border-radius: 4px;
  padding: 20px 24px;
  .title {
    margin-bottom: 4px;
  }
  .divider {
    width: auto;
    border-bottom: 0.5px solid #e6e6e6;
    margin: 8px 0 14px 0;
  }
  .articles {
    .document-card {
      border: 0;
      padding: 0;
    }
  }
  .mark-news-page-link {
    display: flex;
    justify-content: center;
  }
  .title-position {
    position: sticky;
    top: 75px;
    background: #fff;
    z-index: 1;
  }
}
</style>
