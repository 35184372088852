<script setup lang="ts">
import {
  computed,
  h,
  onBeforeUnmount,
  onMounted,
  ref,
  RendererNode,
  toRefs,
} from 'vue';
import api from '@/api';
import noImage from '@/assets/noimage-4to3.jpg';
import { MAX_COMMENT_LENGTH, URL_REGEXP } from '@/constants';
import AdpDocumentCardSkeleton from '@/components/common/adp-document/adp-document-card-skeleton.vue';
import {
  Group,
  Mention,
  MentionItem,
  MentionTarget,
  UrlPreview,
  UserInfo,
} from '@/types';
import {
  getIsTriggeredMention,
  getMentionList,
  getMentionListPosition,
  getTextCaretAndIndex,
  mentionName,
} from '@/utils/mentionList';
import { escapeRegex, getSortedMentionTargets } from '@/utils/parsers';
import { useStore } from '@/utils/vue';
import { isMobileUser } from '../../../utils';
import MentionAutocompleteList from './mention-autocomplete-list.vue';

interface Props {
  autoFocus?: boolean;
  placeholder: string;
  disabled: boolean;
  initialComment?: string;
  minHeight?: number;
  rows?: number;
  showUrlPreview?: boolean;
}

const props = withDefaults(defineProps<Props>(), {
  autoFocus: () => false,
  placeholder: () => '',
  disabled: () => false,
  initialComment: () => '',
  minHeight: () => 82,
  rows: () => 2,
  showUrlPreview: () => false,
});

type Emits = {
  focus: [];
  blur: [];
  postComment: [];
  'update-button-status': [value: boolean];
};

const emit = defineEmits<Emits>();

const store = useStore();

const { autoFocus, initialComment, showUrlPreview, minHeight } = toRefs(props);

const initialScrollHeight = ref(0);
const activeTeamUsers = computed<UserInfo[]>(
  () => store.getters['teamUsers/activeTeamUsers'],
);
const groups = computed<Group[]>(() => store.state.groups.groups);
const userInfo = computed(() => store.state.userInfo.userInfo);
const trimmedComment = ref('');
const mention = ref<Mention>({
  all: false,
  target_user_ids: [],
  target_group_ids: [],
});
const urlPreview = ref<(UrlPreview & { url?: string }) | undefined>(undefined);
const previewing = ref(false);
const skelton = ref<RendererNode | null>();

const commentArea = ref<HTMLTextAreaElement>();
const highlightedText = ref<HTMLElement>();

onMounted(() => {
  if (commentArea.value)
    initialScrollHeight.value = commentArea.value.scrollHeight;
  adjustCommentAreaHeight();
  setEventForHighlight();
  if (initialComment?.value) {
    if (commentArea.value) commentArea.value.value = initialComment.value;
    trimmedComment.value = initialComment.value;
    var event = new Event('input', {
      bubbles: true,
      cancelable: true,
    });
    if (commentArea.value) commentArea.value.dispatchEvent(event);
  }
  if (commentArea.value && autoFocus && !isMobileUser()) {
    commentArea.value.focus();
  }
  skelton.value = h(AdpDocumentCardSkeleton, {
    props: { loading: true },
  }).el;

  document.addEventListener('click', closeEventHandler, { capture: true });
});

onBeforeUnmount(() => {
  document.removeEventListener('click', closeEventHandler, { capture: true });
});

const tooLongComment = computed(
  () => trimmedComment.value.length > MAX_COMMENT_LENGTH,
);

const urlOnly = computed(() => {
  const matchArray = trimmedComment.value.match(URL_REGEXP);

  if (
    matchArray &&
    /^https?/.test(matchArray[0]) &&
    matchArray[0] === trimmedComment.value
  ) {
    return true;
  }
  return false;
});

const errorMessages = computed(() => {
  let messages: string[] = [];
  if (tooLongComment.value) {
    messages.push('コメントは500文字以内で入力してください。');
  }
  return messages;
});

const hasError = computed(() => errorMessages.value.length > 0);

const mentionListSortedByNameLength = computed<MentionTarget[]>(() => {
  if (!userInfo.value) return [];
  const targets = [...activeTeamUsers.value, ...groups.value] as (UserInfo &
    Group)[];
  return getSortedMentionTargets(targets, userInfo.value);
});

const extractFirstUrl = () => {
  const matchArray = trimmedComment.value.match(URL_REGEXP);
  if (matchArray && /^https?/.test(matchArray[0])) {
    return matchArray[0];
  }
  return undefined;
};

const applyUrlStyle = (text: string) => {
  return text.replace(URL_REGEXP, url => {
    return `<span class="textarea-url">${url}</span>`;
  });
};

const applyUrlPreview = (text: string): string => {
  if (!urlPreview.value && previewing.value) {
    return text.concat(
      `<div id="article-preview" class="article-preview preview-skelton">
        ${skelton.value?.outerHTML}
      </div>`,
    );
  } else if (urlPreview.value) {
    return text.concat(
      `<a href="${urlPreview.value.url}"  target="_blank" rel="noopener noreferrer" class="preview-link">
            <div id="article-preview" class="article-preview">
              <div class="preview-info">
                <div class="media-name c-title c-title--s">${urlPreview.value.media_name}</div>
                <div class="title">${urlPreview.value.title}</div>
              </div>
              <img class="preview-image" src="${urlPreview.value.image_url}" onerror="this.src = '${noImage}'" />
            </div>
          </a>`,
    );
  }
  adjustCommentAreaHeight();
  return text;
};

const previewUrl = async (): Promise<void> => {
  const url = extractFirstUrl();
  if (!url) {
    urlPreview.value = undefined;
    adjustCommentAreaHeight();
    return;
  }
  if (
    showUrlPreview.value &&
    (!urlPreview.value || urlPreview.value?.url !== url) &&
    !previewing.value
  ) {
    urlPreview.value = undefined;
    previewing.value = true;
    if (highlightedText.value && commentArea.value)
      highlightedText.value.innerHTML = applyHighlights(
        commentArea.value.value,
      );
    adjustCommentAreaHeight();
    const preview = await api.fetchUrlPreview(url).catch(() => {
      previewing.value = false;
      if (highlightedText.value && commentArea.value)
        highlightedText.value.innerHTML = applyHighlights(
          commentArea.value.value,
        );
      adjustCommentAreaHeight();
    });
    if (preview) {
      urlPreview.value = { ...preview, url };
      if (highlightedText.value && commentArea.value)
        highlightedText.value.innerHTML = applyHighlights(
          commentArea.value.value,
        );
      adjustCommentAreaHeight();
    }
    previewing.value = false;
  }
};

const handleInput = async (): Promise<void> => {
  adjustCommentAreaHeight();
  updateParentTrimmedComment();
  updateButtonStatus();
  await previewUrl();
  execShowMentionList();
};

const mentionUserList = computed<MentionItem[]>(() => {
  const userList = activeTeamUsers.value.map<MentionItem>(user => {
    return {
      textInCandidateList: `${user.user_name} ${user.email}`,
      textInComment: user.user_name,
      iconName: 'user',
    };
  });
  userList.unshift({
    textInCandidateList: '全員 all',
    textInComment: '全員',
    iconName: 'users',
  });
  return userList;
});

const mentionGroupList = computed((): MentionItem[] => {
  return groups.value
    .filter(group => group.group_type !== 'all_user_group')
    .map(group => {
      return {
        textInCandidateList: `${group.name} ${group.member_count}人のメンバー`,
        textInComment: group.name,
        iconName: 'users',
      };
    });
});

const adjustCommentAreaHeight = () => {
  const commentTextArea = commentArea.value;
  if (!commentTextArea) return;
  adjustHeight(commentTextArea);
};

const adjustHeight = (ref: HTMLElement): void => {
  if (ref) {
    ref.style.height = 'auto';
    const marginTop = 24;
    const previewHeight =
      document.getElementById('article-preview')?.getBoundingClientRect()
        .height ?? 0;

    // URLが1行の場合と折り返して2行になる場合に対応するため、リンク要素の高さを取る
    let linkHeight = 0;
    const urlEls = document.getElementsByClassName('textarea-url');
    const urlEl = urlEls[0];
    if (urlEl) {
      linkHeight = urlEl.getBoundingClientRect().height;
    }

    if (
      (urlPreview.value || previewing.value) &&
      ref.scrollHeight === initialScrollHeight.value
    ) {
      // リンクの折返しの有無と一段目の改行の有無で、ズレが起きないように高さを微調整している
      ref.style.height = commentArea.value?.value.includes('\n')
        ? `${minHeight.value + 4 + linkHeight + previewHeight}px`
        : `${42 + linkHeight + previewHeight}px`;
    } else if (urlPreview.value || previewing.value) {
      ref.style.height = ref.style.height = `${Math.max(
        ref.scrollHeight + 2 + previewHeight + marginTop,
        minHeight.value + previewHeight + marginTop,
      )}px`;
    } else if (ref.scrollHeight > initialScrollHeight.value) {
      ref.style.height = `${Math.max(ref.scrollHeight + 2, minHeight.value)}px`;
    } else {
      ref.style.height = String(minHeight.value);
    }

    if (ref.parentElement) {
      ref.parentElement.style.height = 'auto';
      ref.parentElement.style.height = `${Math.max(
        ref.scrollHeight + 2,
        minHeight.value,
      )}px`;
    }
  }
};

const setEventForHighlight = () => {
  const element = commentArea.value;
  element?.addEventListener(
    'input',
    () => {
      const element = highlightedText;
      if (element.value && commentArea.value)
        element.value.innerHTML = applyHighlights(commentArea.value.value);
    },
    { passive: true },
  );
};

const setMentionInfo = (text: string) => {
  mention.value = { all: false, target_user_ids: [], target_group_ids: [] };
  text = text.replace(/\n$/g, '\n\n');
  const mentionAll = RegExp('@全員', 'g');
  if (mentionAll.test(text)) {
    mention.value.all = true;
    text = text.replace(mentionAll, '');
  }
  for (const target of mentionListSortedByNameLength.value) {
    const mentionName = RegExp(`@${escapeRegex(target.name)}`, 'g');
    if (mentionName.test(text)) {
      if (target.type === 'user') {
        mention.value.target_user_ids.push(target.target.id);
      } else if (target.type === 'group') {
        mention.value.target_group_ids.push(target.target.id);
      }
      text = text.replace(mentionName, '');
    }
  }
};

const applyHighlights = (text: string): string => {
  text = text.replace(/\n$/g, '\n\n');

  text = applyUrlStyle(text);
  text = applyUrlPreview(text);

  const mentionAll = RegExp('@全員', 'g');
  if (mentionAll.test(text)) {
    text = text.replace(mentionAll, match => {
      return `<mark class="mention-to-user">${match}</mark>`;
    });
  }

  for (const target of mentionListSortedByNameLength.value) {
    const mentionName = RegExp(`@${escapeRegex(target.name)}`, 'g');
    if (target.mentionToUser && mentionName.test(text)) {
      text = text.replace(mentionName, () => {
        return `<mark class="mention-to-user">${target.type}${target.target.id}</mark>`;
      });
    } else if (mentionName.test(text)) {
      text = text.replace(mentionName, () => {
        return `<mark>${target.type}${target.target.id}</mark>`;
      });
    }
  }
  for (const target of mentionListSortedByNameLength.value) {
    let mentionName = RegExp(
      `<mark class="mention-to-user">${target.type}${target.target.id}</mark>`,
      'g',
    );
    text = text.replace(
      mentionName,
      `<mark class="mention-to-user">@${target.name}</mark>`,
    );
    mentionName = RegExp(`<mark>${target.type}${target.target.id}</mark>`, 'g');
    text = text.replace(mentionName, `<mark>@${target.name}</mark>`);
  }
  return text;
};

// 補完機能を使ってメンションをした後textareaからfocusを外すと,
// なぜか補完で入力した文字が消えてしまうのでonbler eventで同期をとる
const updateTrimmedComment = () => {
  if (commentArea.value) {
    trimmedComment.value = commentArea.value.value;
    const element = highlightedText;
    if (element.value && commentArea.value)
      element.value.innerHTML = applyHighlights(commentArea.value.value);
  }
};

const postComment = () => {
  if (trimmedComment.value === '' || hasError.value) {
    return;
  } else {
    emitPostComment();
  }
};

const emitPostComment = () => {
  if (commentArea.value) setMentionInfo(commentArea.value.value);

  emit('postComment');
};

const updateParentTrimmedComment = () => {
  const payload = {
    trimmedComment: trimmedComment.value,
    rawComment: commentArea.value?.value,
  };
  return payload;
};

const updateButtonStatus = (): void => {
  emit('update-button-status', hasError.value || urlOnly.value);
};

const handleFocus = (): void => {
  adjustCommentAreaHeight();
  emit('focus');
};

const resetTrimmedComment = () => {
  trimmedComment.value = '';
  if (commentArea.value) commentArea.value.value = '';
  if (highlightedText.value) highlightedText.value.innerHTML = '';
  urlPreview.value = undefined;
  adjustCommentAreaHeight();
};

defineExpose({
  trimmedComment,
  mention,
  postComment,
  resetTrimmedComment,
});

// ----------------------------
// 以下、mention系の処理
// ----------------------------
const showMentionList = ref(false);
const topPosition = ref(0);
const leftPosition = ref(0);
const mentionList = ref<MentionItem[]>([]);
const mentionTriggerIdx = ref(0);
const activeIndex = ref(0);
const isKeyEvent = ref(false);
const onComposition = ref(false);
const mentionAutocompleteList =
  ref<InstanceType<typeof MentionAutocompleteList>>();

const execShowMentionList = () => {
  if (!commentArea.value) return;

  const { textBeforeCaret, triggerIdx } = getTextCaretAndIndex(
    commentArea.value,
  );

  const keystrokeTriggered = getIsTriggeredMention(textBeforeCaret, triggerIdx);

  if (!keystrokeTriggered) {
    showMentionList.value = false;
    return;
  }

  const sliceIdx = triggerIdx + 1;
  const query = textBeforeCaret.slice(sliceIdx);
  mentionList.value = getMentionList(
    mentionGroupList.value.concat(mentionUserList.value),
    query,
  );

  const { top, left } = getMentionListPosition(commentArea.value);
  activeIndex.value = 0;
  leftPosition.value = left;
  topPosition.value = top;
  mentionTriggerIdx.value = triggerIdx;
  showMentionList.value = true;
};

const onKeyDownUp = (e: KeyboardEvent) => {
  if (showMentionList.value) {
    e.preventDefault();
    if (onComposition.value) return;
    activeIndex.value = Math.max(activeIndex.value - 1, 0);

    isKeyEvent.value = true;
    mentionAutocompleteList.value?.fixScrollTop();

    // MouseEnterの処理を回避させるためsetTimeoutでフラグのオフを行う
    setTimeout(() => {
      isKeyEvent.value = false;
    }, 200);
  }
};

const onKeyDownDown = (e: KeyboardEvent) => {
  if (showMentionList.value) {
    e.preventDefault();
    if (onComposition.value) return;
    activeIndex.value = Math.min(
      activeIndex.value + 1,
      mentionList.value.length - 1,
    );
    isKeyEvent.value = true;
    mentionAutocompleteList.value?.fixScrollTop();

    // MouseEnterの処理を回避させるためsetTimeoutでフラグのオフを行う
    setTimeout(() => {
      isKeyEvent.value = false;
    }, 200);
  }
};

const onKeyDownEnter = (e: KeyboardEvent) => {
  if (showMentionList.value) {
    e.preventDefault();
    if (onComposition.value) return;
    onItemSelected(activeIndex.value);
    return;
  }
};

const onMouseEnter = (index: number) => {
  if (!isKeyEvent.value) activeIndex.value = index;
};

const onItemSelected = (active: number) => {
  if (!commentArea.value) return;
  const preMention = commentArea.value.value.substring(
    0,
    mentionTriggerIdx.value,
  );
  const option = mentionList.value[active];

  if (!option) return;

  const mention =
    mentionTriggerIdx.value === undefined
      ? ''
      : mentionName(option, commentArea.value.value[mentionTriggerIdx.value]);
  const postMention = commentArea.value.value.substring(
    commentArea.value.selectionStart ?? 0,
  );
  const newValue = `${preMention}${mention}${postMention}`;
  commentArea.value.value = newValue;
  const caretPosition = commentArea.value.value.length - postMention.length;
  commentArea.value.setSelectionRange(caretPosition, caretPosition);
  updateTrimmedComment();
  showMentionList.value = false;
  commentArea.value.focus();
};

const closeEventHandler = (event: MouseEvent) => {
  if (!showMentionList.value || !commentArea.value) {
    return;
  }
  const eventTargets = event?.composedPath() ?? [];
  if (eventTargets.includes(commentArea.value)) {
    return;
  }
  showMentionList.value = false;
};
</script>

<template>
  <div class="m-mention-autocomplete-textarea">
    <div v-show="hasError">
      <div
        class="m-error c-formBlock__text c-formBlock__text--error"
        v-for="message in errorMessages"
        :key="message"
      >
        {{ message }}
      </div>
    </div>
    <div class="m-highlight-and-textarea">
      <textarea
        class="m-comment-box-textarea c-text c-text--m"
        id="textarea"
        :rows="rows"
        :style="{ 'min-height': `${minHeight}px` }"
        :class="{
          'c-formInput--error': hasError,
          'm-normal-textarea': !hasError,
          disabled: disabled,
        }"
        :placeholder="placeholder"
        :disabled="disabled"
        v-model.trim="trimmedComment"
        ref="commentArea"
        @focus="handleFocus"
        @blur="
          $emit('blur');
          updateTrimmedComment();
        "
        @input="handleInput()"
        @keydown.exact.enter="onKeyDownEnter"
        @keydown.ctrl.enter="postComment"
        @keydown.meta.enter="postComment"
        @keydown.up="onKeyDownUp"
        @keydown.down="onKeyDownDown"
        @compositionstart="onComposition = true"
        @compositionend="onComposition = false"
      />
      <div
        class="m-highlighted-text c-text c-text--m"
        ref="highlightedText"
        :style="{ 'min-height': `${minHeight}px` }"
      ></div>
      <MentionAutocompleteList
        ref="mentionAutocompleteList"
        v-show="showMentionList"
        :top="topPosition"
        :left="leftPosition"
        :mention-list="mentionList"
        :active-index="activeIndex"
        @on-item-selected="onItemSelected"
        @on-mouse-enter="onMouseEnter"
      ></MentionAutocompleteList>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.m-mention-autocomplete-textarea {
  width: 100%;
  .m-comment-box-textarea {
    position: absolute;
    top: 0;
    outline: none;
    resize: none;
    min-height: 82px;
    overflow: hidden;
    width: 100%;
    box-sizing: border-box;
    border-radius: 4px;
    padding: 7px 11px;
    color: transparent;
    caret-color: black;
    &::placeholder {
      color: #b3b3b3;
    }
    &::selection {
      color: rgba(0, 0, 0, 0);
      background: #a0c6e8;
    }
  }
  .m-highlighted-text {
    position: absolute;
    top: 0;
    min-height: 82px;
    overflow: hidden;
    width: 100%;
    box-sizing: border-box;
    border-radius: 4px;
    padding: 8px 12px;
    pointer-events: none;
    background-attachment: fixed;
    white-space: pre-wrap;
    word-break: break-word;
  }
  .m-error {
    display: flex;
    justify-content: flex-start;
    width: 100%;
  }
  .m-normal-textarea {
    border: 1px solid $color-gray400;
  }

  textarea:disabled {
    color: #b3b3b3;
    background-color: #f2f2f2;
  }

  .m-highlight-and-textarea {
    position: relative;
  }

  .mention-list-mount-point {
    position: fixed;
  }
}
:deep(.preview-link) {
  white-space: normal;

  .article-preview {
    display: flex;
    background: #ffffff;
    border: 1px solid $color-gray400;
    border-radius: 4px;
    padding: 16px;
    margin-top: 24px;
    pointer-events: all;
    .preview-image {
      width: 106px;
      height: 60px;
      object-fit: cover;
      border: 1px solid $color-gray400;
      object-position: 50% 25%;
      margin-right: 12px;
    }
    .preview-info {
      .title {
        font-size: 16px;
        line-height: 22px;
        max-width: 440px;
      }
      .media-name {
        color: #b3b3b3;
        font-size: 10px;
        line-height: 14px;
      }
    }
  }

  .article-preview {
    display: flex;
    background: #ffffff;
    border: 1px solid $color-gray400;
    border-radius: 4px;
    padding: 16px;
    margin-top: 24px;
    pointer-events: all;
    justify-content: space-between;
    .preview-image {
      width: 80px;
      height: 80px;
      object-fit: cover;
      object-position: 50% 25%;
      border: 1px solid $color-gray400;
      aspect-ratio: 1/1;
      border-radius: 4px;
    }
    .preview-info {
      .title {
        font-size: 16px;
        line-height: 22px;
        max-width: 440px;
      }
      .media-name {
        color: #b3b3b3;
      }
    }
  }
}
:deep(.textarea-url) {
  color: #1da482;
}
:deep(.preview-skelton) {
  border: solid 1px black;
  display: flex;
  margin-top: 24px;
  border: 1px solid $color-gray400;
  border-radius: 4px;
}
</style>
