<script setup lang="ts">
import {
  computed,
  inject,
  onBeforeMount,
  onBeforeUnmount,
  onMounted,
  onUnmounted,
  Ref,
  ref,
  watch,
} from 'vue';
import { useRoute, useRouter } from 'vue-router';
import api from '@/api';
import { ContentsContext } from '@/api/tracking';
import {
  SEARCH_API_DEBOUNCE_WAIT_MS,
  SEARCH_EXPANDED_QUERY_CACHE_STORE_NAME,
  USER_DOCUMENT_SEARCH_EXPANDED_QUERY_CACHE_STORE_NAME,
} from '@/constants';
import {
  SearchCondition,
  SearchConditionKey,
} from '@/stores/pc-modules/searchResults';
import { DgrLoading, DgrSelectbox } from '@stockmarkteam/donguri-ui';
import dayjs from 'dayjs';
import AdpDocumentCard from '@/components/common/adp-document-card.vue';
import AdpDocumentCardSkeleton from '@/components/common/adp-document/adp-document-card-skeleton.vue';
import AdpReportCard from '@/components/common/adp-document/adp-report-card.vue';
import SearchHeader from '@/components/search/search-header.vue';
import SearchResultEmptyMessage from '@/components/search/search-result-empty-message.vue';
import Summary from '@/components/search/summary/summary.vue';
import UserDocumentCard from '@/components/user-document/user-document-card.vue';
import {
  AdpDocument,
  CitationSource,
  DocType,
  isUserDocument,
  UserDocument,
} from '@/types';
import { Pagination } from '@/utils';
import { debounce } from '@/utils/debounce';
import { searchExpandedQueryCache } from '@/utils/searchExpandedQueryCache';
import {
  getAllSiteCategories,
  getIncludeAllianceMediaValueForSearch,
  getSiteCategoriesValueForSearch,
} from '@/utils/siteCategories';
import { useEmitter, useStore } from '@/utils/vue';
import LegacySummary from './summary/legacy-summary.vue';

type Badge = {
  number: number;
  animationDelay: number;
};

const ES_MAX_RESULT_WINDOW = 10000; // Elastic Searchの結果ウィンドウサイズの上限値
const INIT_PAGE_NUM = 1; // 1度もページネーションを行っていない場合の初期ページ番号

const emitter = useEmitter();
const store = useStore();
const route = useRoute();
const router = useRouter();

// サーバーから取得する件数
const serverLimit = route.name === 'searchReport' ? 96 : 100;

// フロントで一度に表示する件数(100件を一度に表示すると時間がかかり、要約回答生成が遅くなるため)
const frontLimit = route.name === 'searchReport' ? 12 : 10;

const useSearchHistory = inject('useSearchHistory') as Ref<boolean>;

const resultCount = computed(() => store.state.searchResults.resultCount);
const resultAdpDocuments = computed(() => {
  return store.state.searchResults.resultArticles;
});
const resultUserDocuments = computed(
  () => store.state.searchResults.resultUserDocuments,
);
const resultDocuments = computed<AdpDocument[] | UserDocument[]>(() => {
  if (searchConditionKey.value === 'user_document') {
    return resultUserDocuments.value;
  }
  return resultAdpDocuments.value;
});
const showingIndex = ref(frontLimit);
const exceedMaxResultWindow = computed(
  () => store.state.searchResults.exceedMaxResultWindow,
);
const displayDocuments = computed(() =>
  resultDocuments.value.slice(0, showingIndex.value),
);
const searchTrackingSessionId = computed({
  get() {
    return store.state.searchResults.searchTrackingSessionId;
  },
  set(value: string | undefined) {
    store.commit('searchResults/setSearchTrackingSessionId', value);
  },
});
const expandedQueries = computed(
  () => store.state.searchResults.expandedQueries,
);
const isLastPageForDisplayDocuments = computed(
  () => displayDocuments.value.length === store.state.searchResults.resultCount,
);
const teamInfo = computed(() => store.state.teamInfo.teamInfo);

const enableTechnicalLiterature = computed(
  () => teamInfo.value?.enable_technical_literature ?? false,
);

const enableUserDocument = computed(
  () => teamInfo.value?.enable_user_document ?? false,
);

const useMarkdownSupportedModel = computed(() => {
  if (searchConditionKey.value === 'user_document') {
    return (
      teamInfo.value?.user_document_setting.accept_search_summary_v2 ?? false
    );
  }
  return true;
});

const searchQuery = ref<string | undefined>(route.query.query as string);

const resultOrders = computed(() => {
  if (route.name === 'searchReport' || route.name === 'searchUserDocument') {
    return [
      { value: 'recommended_v2', label: 'おすすめ順' },
      { value: 'latest', label: '最新順' },
    ];
  } else {
    return [
      { value: 'recommended_v2', label: 'おすすめ順' },
      { value: 'popular', label: '人気順' },
      { value: 'latest', label: '最新順' },
    ];
  }
});

let pagination = new Pagination(() => undefined, INIT_PAGE_NUM);
const hasSearchConditionsChanged = ref(false);
const isSearching = ref(true);
const isPaginationSearching = ref(false);

const openDocumentsCount = ref<undefined | number>(undefined);
const displayOpenDocumentsCount = computed(() => {
  if (openDocumentsCount.value === undefined) return undefined;
  return openDocumentsCount.value > ES_MAX_RESULT_WINDOW
    ? ES_MAX_RESULT_WINDOW
    : openDocumentsCount.value;
});

const userDocumentsCount = ref<undefined | number>(undefined);
const displayUserDocumentsCount = computed(() => {
  if (userDocumentsCount.value === undefined) return undefined;
  return userDocumentsCount.value > ES_MAX_RESULT_WINDOW
    ? ES_MAX_RESULT_WINDOW
    : userDocumentsCount.value;
});

onBeforeMount(async () => {
  // 最初の検索はonLoadedで実行する
  onLoaded();
});

const handleArticleUpdate = (updatedDocument: AdpDocument | UserDocument) => {
  // 検索の要約の①などにあるリンクをクリックしたらカセットも既読状態にする
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const resultDocument = (displayDocuments.value as any[])?.find(
    (a: AdpDocument | UserDocument) => a.id === updatedDocument.id,
  );
  if (resultDocument) {
    resultDocument.is_read = updatedDocument.is_read;
  }
  store.dispatch('searchResults/updateArticleLocally', {
    article: resultDocument,
  });
};

onMounted(() => {
  emitter.on('article-updated', handleArticleUpdate);
});

onUnmounted(() => {
  emitter.off('article-updated', handleArticleUpdate);
});

onBeforeUnmount(async () => {
  await store.dispatch('searchResults/reset');
  store.commit('searchResults/resetCondition');
  pagination.removeEvent();
});

const loading = computed(
  () => teamInfo.value?.enable_foreign_lang_media === undefined,
);

const searchData = computed(() => {
  return {
    query: searchQuery.value ?? '',
    lang: searchConditionLang.value ?? 'all',
    period: searchCondition.value.period,
    period_detail:
      searchCondition.value.period !== 'custom'
        ? undefined
        : {
            from: dayjs(searchCondition.value.from).format('YYYY-MM-DD'),
            to: dayjs(searchCondition.value.to).format('YYYY-MM-DD'),
          },
    sort: searchCondition.value.sort ?? resultOrders.value[0].value,
    result_count: String(resultCount.value),
    is_entertainment_sites_filtered:
      searchConditionEntertainmentFilter.value ?? true,
    data_sources: docTypes.value,
  };
});

const isUnpermittedRoute = computed(
  () =>
    !enableTechnicalLiterature.value &&
    ['searchPatent', 'searchResearchPapers'].includes(String(route.name)),
);

const onLoaded = async () => {
  if (!loading.value) {
    if (isUnpermittedRoute.value) {
      router.push({
        name: 'searchNews',
        query: route.query,
      });
      return;
    }
    await search(true);
  }
};
watch(loading, onLoaded);

const onRouteChange = async () => {
  window.scrollTo(0, 0);
  if (
    route.name !== 'searchAll' &&
    route.name !== 'searchNews' &&
    route.name !== 'searchResearchPapers' &&
    route.name !== 'searchPatent' &&
    route.name !== 'searchReport' &&
    route.name !== 'searchUserDocument'
  )
    return;

  if (isUnpermittedRoute.value) {
    router.push({
      name: 'searchNews',
      query: route.query,
    });
    return;
  }

  const queryChanged = searchQuery.value !== route.query.query;
  searchQuery.value = route.query.query as string;
  // ウィジェットもqueryを変えるので検索条件が必要ない時にスキップ
  if (queryChanged || hasSearchConditionsChanged.value) {
    // レポートのタブでの検索は人気順がないため、recommended_v2に変更する
    if (
      route.name === 'searchReport' &&
      searchCondition.value.sort === 'popular'
    ) {
      store.commit('searchResults/setSearchCondition', {
        key: searchConditionKey.value,
        value: {
          ...searchCondition.value,
          sort: 'recommended_v2',
        },
      });
    }

    await search(queryChanged);
  }
};

const getOpenDocumentsCount = async () => {
  if (searchQuery.value === undefined) return undefined;
  try {
    const count = await api.allOpenDocumentsCount(
      searchQuery.value,
      openDocumentsDocTypes.value,
    );
    return count;
  } catch (e) {
    // eslint-disable-next-line no-console
    console.error(e);
    return undefined;
  }
};

/**
 * 社内情報オプションのトライアルを訴求する施策で、オプションがOFFユーザーにもタブを表示するようになったため
 * オプションがOFFのユーザーは社内情報の件数をundefinedで返す
 *
 * Jiraチケット: https://anews.atlassian.net/browse/ANDEV-4409
 */
const getUserDocumentsCount = async () => {
  if (!enableUserDocument.value) return undefined;
  if (searchQuery.value === undefined) return undefined;
  try {
    const count = await api.allUserDocumentsCount(searchQuery.value);
    return count;
  } catch (e) {
    // eslint-disable-next-line no-console
    console.error(e);
    return undefined;
  }
};

const loadDocumentsCount = async () => {
  const [openCount, userCount] = await Promise.all([
    getOpenDocumentsCount(),
    getUserDocumentsCount(),
  ]);
  openDocumentsCount.value = openCount;
  userDocumentsCount.value = userCount;
};

const docTypes = computed<Array<DocType | 'user_document'>>(() => {
  if (route.name === 'searchAll') return openDocumentsDocTypes.value;
  if (route.name === 'searchNews') return ['article'];
  if (route.name === 'searchResearchPapers') return ['research_paper'];
  if (route.name === 'searchPatent') return ['patent'];
  if (route.name === 'searchReport') return ['report'];
  if (route.name === 'searchUserDocument') return ['user_document'];
  return ['article', 'research_paper', 'patent', 'report'];
});

/**
 * 公開情報の総件数取得APIで使用
 *
 * NOTE: computedで定義しているdocTypesを使用すると、社内情報の際に'user_document'になってしまうため、個別で定義する
 */
const openDocumentsDocTypes = computed<DocType[]>(() =>
  enableTechnicalLiterature.value
    ? ['article', 'research_paper', 'patent', 'report']
    : ['article', 'report'],
);

const searchConditionKey = computed<SearchConditionKey>(() => {
  if (route.name === 'searchAll') return 'all';
  if (route.name === 'searchNews') return 'news';
  if (route.name === 'searchResearchPapers') return 'research_paper';
  if (route.name === 'searchPatent') return 'patent';
  if (route.name === 'searchReport') return 'report';
  if (route.name === 'searchUserDocument') return 'user_document';
  return 'all';
});

const searchCondition = computed<SearchCondition>(() => {
  return store.getters['searchResults/searchCondition'](
    searchConditionKey.value,
  );
});
const searchConditionSort = computed({
  get: () => searchCondition.value.sort,
  set: value => {
    const changedSearchCondition = searchCondition.value;
    changedSearchCondition['sort'] = value;
    store.commit('searchResults/setSearchCondition', {
      key: searchConditionKey.value,
      value: changedSearchCondition,
    });
  },
});

const searchConditionLang = computed<string | undefined>(() => {
  const only_ja_route_names = ['searchPatent', 'searchReport'];
  return teamInfo.value?.enable_foreign_lang_media &&
    !only_ja_route_names.includes(String(route.name))
    ? searchCondition.value.lang.length === 1
      ? searchCondition.value.lang[0]
      : undefined
    : 'ja';
});

const searchConditionSiteCategory = computed<string[] | undefined>(() =>
  route.name === 'searchNews' || route.name === 'searchAll'
    ? getSiteCategoriesValueForSearch(searchCondition.value.site_categories)
    : undefined,
);

const searchConditionIncludeAllianceMedia = computed<boolean | undefined>(() =>
  teamInfo.value?.enable_alliance_media &&
  (route.name === 'searchNews' || route.name === 'searchAll')
    ? getIncludeAllianceMediaValueForSearch(
        searchCondition.value.site_categories,
      )
    : false,
);

const searchConditionEntertainmentFilter = computed<boolean | undefined>(() =>
  route.name === 'searchNews' || route.name === 'searchAll'
    ? searchCondition.value.is_entertainment_sites_filtered
    : undefined,
);

const searchConditionUserDocumentUpdaters = computed<string[] | undefined>(
  () => {
    if (route.name === 'searchUserDocument') {
      return searchCondition.value.last_updated_by;
    }
    return undefined;
  },
);

const onSearchConditionOrQueryChange = () => {
  searchTrackingSessionId.value = undefined;
  hasSearchConditionsChanged.value = true;
  onRouteChange();
};

watch(
  searchCondition,
  debounce(onSearchConditionOrQueryChange, SEARCH_API_DEBOUNCE_WAIT_MS),
  { deep: true },
);

watch(route, onSearchConditionOrQueryChange, { deep: true });

const search = async (queryChanged: boolean) => {
  isSearching.value = true;
  await store.dispatch('searchResults/reset');
  pagination.removeEvent();
  searchResultIndexCitationNumberMap.value = new Map<number, Badge>();

  const executeSearch = async (
    frontPaginationPage: number,
    frontLimit: number,
  ) => {
    const serverPaginationPage = Math.ceil(
      (frontLimit * frontPaginationPage) / serverLimit,
    );
    showingIndex.value = frontLimit * frontPaginationPage;
    isPaginationSearching.value = true;
    const isLoadDocumentsCountNeeded =
      queryChanged && frontPaginationPage === INIT_PAGE_NUM;

    if (searchQuery.value) {
      if (isLoadDocumentsCountNeeded) {
        // 検索中に前の総数が表示されるのを防ぐためにundefinedにする
        openDocumentsCount.value = undefined;
        userDocumentsCount.value = undefined;
      }
      const isFetchingDataFromServer =
        ((frontPaginationPage - 1) * frontLimit) % serverLimit === 0;
      if (isFetchingDataFromServer) {
        const localRelatedQuery = JSON.parse(
          localStorage.getItem('relatedQuery') || '{}',
        );
        const to = searchCondition.value.to;
        const from = searchCondition.value.from;
        const formattedFrom = from
          ? dayjs(from).format('YYYY-MM-DD')
          : undefined;
        const formattedTo = to ? dayjs(to).format('YYYY-MM-DD') : undefined;
        if (searchConditionKey.value === 'user_document') {
          await store.dispatch('searchResults/userDocumentSearch', {
            query: searchQuery.value,
            lang: searchConditionLang.value,
            period: searchCondition.value.period,
            from_date: formattedFrom,
            to_date: formattedTo,
            last_updated_by: searchConditionUserDocumentUpdaters.value,
            sort: searchCondition.value.sort || 'recommended_v2',
            limit: serverLimit,
            page: serverPaginationPage,
            search_tracking_session_id: searchTrackingSessionId.value,
            cached_expanded_queries: await searchExpandedQueryCache.getCache(
              searchQuery.value,
              USER_DOCUMENT_SEARCH_EXPANDED_QUERY_CACHE_STORE_NAME,
            ),
            disable_tracking: true, // キャッシュの問題で古いフロントを参照しているユーザーのためにバックエンドの計測を残しているので、新しいフロントでは計測しない
            feature:
              localRelatedQuery.feature ?? useSearchHistory.value
                ? 'search_history'
                : undefined,
            request_id: localRelatedQuery.requestId,
            search_query: localRelatedQuery.searchQuery,
            rank:
              localRelatedQuery.rank === 0 ? undefined : localRelatedQuery.rank,
          });
        } else if (searchConditionKey.value === 'report') {
          await store.dispatch('searchResults/reportSearch', {
            query: searchQuery.value,
            lang: searchConditionLang.value,
            period: searchCondition.value.period,
            from_date: formattedFrom,
            to_date: formattedTo,
            sort: searchCondition.value.sort || 'recommended_v2',
            limit: serverLimit,
            page: serverPaginationPage,
            search_tracking_session_id: searchTrackingSessionId.value,
            cached_expanded_queries: await searchExpandedQueryCache.getCache(
              searchQuery.value,
              SEARCH_EXPANDED_QUERY_CACHE_STORE_NAME,
            ),
            doc_types: docTypes.value,
            disable_tracking: true, // キャッシュの問題で古いフロントを参照しているユーザーのためにバックエンドの計測を残しているので、新しいフロントでは計測しない
            feature:
              localRelatedQuery.feature ?? useSearchHistory.value
                ? 'search_history'
                : undefined,
            request_id: localRelatedQuery.requestId,
            search_query: localRelatedQuery.searchQuery,
            rank:
              localRelatedQuery.rank === 0 ? undefined : localRelatedQuery.rank,
          });
        } else {
          await store.dispatch('searchResults/search', {
            query: searchQuery.value,
            lang: searchConditionLang.value,
            period: searchCondition.value.period,
            from_date: formattedFrom,
            to_date: formattedTo,
            sort: searchCondition.value.sort || 'recommended_v2',
            limit: serverLimit,
            page: serverPaginationPage,
            entertainment_filter: searchConditionEntertainmentFilter.value,
            doc_types: docTypes.value,
            site_categories: searchConditionSiteCategory.value,
            include_alliance_media: searchConditionIncludeAllianceMedia.value,
            search_tracking_session_id: searchTrackingSessionId.value,
            cached_expanded_queries: await searchExpandedQueryCache.getCache(
              searchQuery.value,
              SEARCH_EXPANDED_QUERY_CACHE_STORE_NAME,
            ),
            disable_tracking: true, // キャッシュの問題で古いフロントを参照しているユーザーのためにバックエンドの計測を残しているので、新しいフロントでは計測しない
            feature:
              localRelatedQuery.feature ?? useSearchHistory.value
                ? 'search_history'
                : undefined,
            request_id: localRelatedQuery.requestId,
            search_query: localRelatedQuery.searchQuery,
            rank:
              localRelatedQuery.rank === 0 ? undefined : localRelatedQuery.rank,
          });
        }
      }
      // 検索履歴を使用して検索を行ったかどうかの判定フラグは、履歴を選択した初回検索のみtrueにする(Pagingやタブ切り替えまで引き継がない)
      useSearchHistory.value = false;
      localStorage.removeItem('relatedQuery');

      if (isLoadDocumentsCountNeeded) {
        // 検索結果総数は実際の検索結果と総数が同じである必要があり、同じ拡張クエリを使用して行う必要があります。
        // そのため、検索が実行された後(拡張クエリキャッシュがされた後)に実行します。
        loadDocumentsCount();
      }

      isSearching.value = false;
      isPaginationSearching.value = false;
      if (isLastPageForDisplayDocuments.value) {
        pagination.removeEvent();
      }
    }
  };
  pagination = new Pagination(executeSearch, frontLimit);
  hasSearchConditionsChanged.value = false;
};

const createTheme = async () => {
  const keywords = searchQuery.value ? [searchQuery.value] : [];
  await api.trackThemeEvent('to_create_theme', {
    pageName: 'search',
    pageUrl: route.fullPath,
    from: 'search',
    keywords: keywords,
  });
  router.push({
    name: 'themeCreate',
    query: {
      query: keywords[0],
      expandedQueries: expandedQueries.value,
      // 検索結果「サイト種別」に何もチェックを付けずに「テーマ作成」に遷移した場合
      // 「テーマ作成」でのサイト種別は、すべての項目にチェックが付いている状態にする
      selectedSearchSiteCategories: searchCondition.value.site_categories.length
        ? searchCondition.value.site_categories
        : getAllSiteCategories(),
      lang: searchCondition.value.lang,
      isEntertainmentSitesFiltered: Number(
        !!searchCondition.value.is_entertainment_sites_filtered,
      ),
    },
    params: { selectedSiteCategories: searchConditionSiteCategory.value },
  });
};

const isSearchSummaryEnabled = computed(() => {
  return (
    (searchCondition.value.sort === undefined ||
      searchCondition.value.sort === 'recommended_v2') &&
    resultDocuments.value.length > 0
  );
});

const searchResultIndexCitationNumberMap = ref(new Map<number, Badge>());
const isBadgeAnimationEnabled = ref(false);
const updateCitationSources = (
  newCitationSources: CitationSource[],
  isAnimationEnabled: boolean,
) => {
  searchResultIndexCitationNumberMap.value = new Map<number, Badge>(
    newCitationSources.map(cs => [
      cs.index,
      {
        number: cs.citationNumber,
        animationDelay: isAnimationEnabled ? cs.animationDelay : 0,
      },
    ]),
  );
  isBadgeAnimationEnabled.value = isAnimationEnabled;
};

const contentsContext = computed<ContentsContext>(() => {
  return {
    event_name:
      searchConditionKey.value === 'user_document'
        ? 'user_document_search'
        : 'search',
    session_id: searchTrackingSessionId.value,
  };
});

const displayResultCount = computed(() => {
  if (isSearching.value || resultCount.value === null) return '-';
  return resultCount.value > ES_MAX_RESULT_WINDOW || exceedMaxResultWindow.value // 結果ウィンドウサイズの上限値を超えた際はAPIからsearch_result_count=0が返るようになるが、その場合は上限値を表示する
    ? ES_MAX_RESULT_WINDOW
    : resultCount.value;
});
</script>

<template>
  <div class="o-search-result">
    <SearchHeader
      :open-documents-count="displayOpenDocumentsCount"
      :user-documents-count="displayUserDocumentsCount"
      :is-enable-technical-literature="enableTechnicalLiterature"
      :is-button-disabled="isSearching || resultCount === 0"
      @click-theme-setting="createTheme"
    ></SearchHeader>
    <div class="search-results-container" v-if="!loading">
      <Summary
        v-if="isSearchSummaryEnabled && useMarkdownSupportedModel"
        class="search-summary-container"
        :question="searchQuery"
        :source-documents="resultDocuments.slice(0, 10)"
        :search-data="searchData"
        :contents-context="contentsContext"
        @citation-sources-updated="updateCitationSources"
      />
      <LegacySummary
        v-else-if="isSearchSummaryEnabled && !useMarkdownSupportedModel"
        class="search-summary-container"
        :question="searchQuery"
        :source-documents="resultDocuments.slice(0, 5)"
        :search-data="searchData"
        :contents-context="contentsContext"
        @citation-sources-updated="updateCitationSources"
      />
      <div class="results">
        <div class="spacing-16"></div>
        <div>
          <div class="results-count-and-select-box">
            <div class="c-title c-title--m">検索結果</div>
            <div class="results-count-text">
              <span>・</span>
              <span id="search-result-count">{{ displayResultCount }}件</span>
            </div>
            <div class="spacing-16"></div>
            <DgrSelectbox
              :options="resultOrders"
              v-model="searchConditionSort"
              size="small"
            ></DgrSelectbox>
          </div>
          <SearchResultEmptyMessage
            v-if="resultCount === 0 && !isSearching && !exceedMaxResultWindow"
            :search-query="searchQuery ?? ''"
            :search-condition-key="searchConditionKey"
          ></SearchResultEmptyMessage>
        </div>
        <div v-if="isSearching" class="searching">
          <AdpDocumentCardSkeleton
            v-for="i in Array.from(Array(5))"
            :key="i"
            class="skeleton-card"
          />
        </div>
        <div class="o-results" v-if="resultDocuments.length > 0">
          <template v-if="searchConditionKey === 'report'">
            <div class="spacing-08"></div>
            <div class="report-card-list">
              <template
                v-for="(article, i) in displayDocuments"
                :key="article.id"
              >
                <AdpReportCard
                  v-if="article.doc_type === 'report'"
                  page-name="search"
                  feature="search"
                  :adp-report="article"
                  :rank-in-whole-feed="i + 1"
                  :search-data="searchData"
                  :badge-number="
                    searchResultIndexCitationNumberMap.get(i)?.number
                  "
                  :badge-animation-delay="
                    searchResultIndexCitationNumberMap.get(i)?.animationDelay
                  "
                  :is-badge-animation-enabled="isBadgeAnimationEnabled"
                  :contents-context="contentsContext"
                >
                </AdpReportCard>
              </template>
            </div>
          </template>
          <template v-else-if="searchConditionKey === 'user_document'">
            <div class="spacing-08"></div>
            <div class="spacing-02"></div>
            <div class="user-document-card-list">
              <template
                v-for="(userDocument, i) in displayDocuments"
                :key="userDocument.id"
              >
                <UserDocumentCard
                  v-if="isUserDocument(userDocument)"
                  :user-document="userDocument"
                  :badge-number="
                    searchResultIndexCitationNumberMap.get(i)?.number
                  "
                  :badge-animation-delay="
                    searchResultIndexCitationNumberMap.get(i)?.animationDelay
                  "
                  :rank-in-whole-feed="i + 1"
                  :contents-context="contentsContext"
                />
              </template>
            </div>
          </template>
          <template v-else>
            <div
              class="o-article-card"
              v-for="(article, i) in displayDocuments"
              :key="article.id"
            >
              <AdpDocumentCard
                v-if="!isUserDocument(article)"
                width="100%"
                page-name="search"
                mark-source="search"
                feature="search"
                :adp-document="article"
                :rank-in-whole-feed="i + 1"
                :search-data="searchData"
                :disable-related-articles="false"
                :badge-number="
                  searchResultIndexCitationNumberMap.get(i)?.number
                "
                :badge-animation-delay="
                  searchResultIndexCitationNumberMap.get(i)?.animationDelay
                "
                :is-badge-animation-enabled="isBadgeAnimationEnabled"
                :contents-context="contentsContext"
              />
            </div>
          </template>
          <div v-if="isPaginationSearching" class="loading-container">
            <div class="spacing-16"></div>
            <DgrLoading class="loading" />
          </div>
          <div
            class="o-no-more-results c-text c-text--m"
            v-else-if="isLastPageForDisplayDocuments"
          >
            これ以上、表示できる情報はありません
          </div>
          <div
            class="o-no-more-results c-text c-text--m"
            v-else-if="exceedMaxResultWindow"
          >
            表示上限です。更に表示するには、<br />
            条件を絞り込むかキーワードを変更してください。
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<style lang="scss" scoped>
.o-search-result {
  width: 100%;
  margin: -24px 0 0 0;
}

.multi-select-detail {
  margin-bottom: 12px;
  span {
    color: $color-gray800;
  }
}

button.o-to-create-theme {
  flex-shrink: 0;
  &:not(:disabled) {
    border: solid 1px $color-green600;
    color: $color-green600;

    @media (any-hover: hover) {
      &:hover {
        background-color: $color-green100;
      }
    }
  }
  &:disabled {
    color: $color-gray600;
    @media (any-hover: hover) {
      &:hover {
        background-color: none;
      }
    }
    cursor: not-allowed;
  }
}

.o-filters {
  display: flex;
  gap: 12px;
  align-items: center;
  /* 新しいテーマとして保存ボタンの有無でHeaderの高さが変わらないようにする */
  padding: 3px 0;

  .dropdown {
    width: 134px;
  }
}
.o-count-and-sort {
  display: flex;
  align-items: center;
  justify-content: space-between;
  .o-result-message {
    font-size: 14px;
    line-height: 22px;
    display: flex;
    .o-entertainment-notice {
      color: #b3b3b3;
    }
  }
}

.searching {
  width: auto;
  margin: 8px auto 0 auto;
  .skeleton-card:not(:last-child) {
    margin-bottom: 8px;
  }
}
.search-results-container {
  display: flex;
  flex-direction: column;
  padding: 0 32px;
  margin: 0;
  max-width: 970px;
  .o-results {
    width: auto;
  }
  box-sizing: border-box;
}
.results {
  width: 'auto';
  .results-count-and-select-box {
    display: flex;
    justify-content: start;
    align-items: center;
    .results-count-text {
      font-size: 12px;
      font-weight: 400;
      color: $color-gray600;
    }
    #search-result-count {
      min-width: 66px;
      display: inline-block;
      text-align: end;
    }
  }
  .report-card-list {
    display: flex;
    flex-wrap: wrap;
    gap: 8px;
  }
  .user-document-card-list {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
  }
  .o-article-card {
    margin-top: 8px;
  }
  .o-no-results {
    display: flex;
    flex-direction: row;
    width: 616px;
    background: #ffffff;
    border: 1px solid #e6e6e6;
    box-sizing: border-box;
    border-radius: 4px;
    padding: 24px;
    margin-top: 12px;
    img {
      height: 140px;
      width: 140px;
    }
    ul {
      padding-left: 24px;
    }
    .help {
      .link {
        display: inline-flex;
        align-items: center;
        .primary-link {
          color: #1da482;
          &:hover {
            text-decoration: underline;
          }
        }
        .primary-icon {
          fill: #1da482;
        }
      }
    }
  }
  .o-no-more-results {
    margin: 16px 0;
    color: #b3b3b3;
    text-align: center;
  }

  .selected-information-type-row {
    display: flex;
    align-items: center;
    height: 32px;

    .selected-information-type-label {
      color: $color-gray600;
    }

    .selected-information-type-item {
      margin-left: 8px;
      height: 32px;
    }
  }

  .loading-container {
    display: flex;
    flex-direction: column;
    .loading {
      align-self: center;
    }
  }
}
.search-summary-container {
  margin: 0;
}
</style>
