export const isDev = import.meta.env.VITE_ENV === 'development';
const isTest = import.meta.env.VITE_ENV === 'test';

// flagの内容
// false: 開発中
// true: リリース
const flags = {
  ANDEV_4438_SHAREPOINT_CREDENTIAL: true,
  ANDEV_4571_ADD_GROUP_TAG_LIST: false,
  ANDEV_4635_ADD_CATEGORY_INFORMATION: false,
  ANDEV_4926_TOOLTIP_XSS: false,
  ANDEV_4747_IMAGE_CAPTION_SUMMARY: false,
  ANDEV_4901_SHAREPOINT_SITE: true,
  ANDEV_4946_SIMILAR_DOCUMENTS_AGGREGATION: false,
};

const isKey = (x: string): x is keyof typeof flags => x in flags;
Object.keys(flags).forEach(key => {
  if (!isKey(key)) return;
  flags[key] = isDev || isTest || flags[key];
});

export const featureFlags = flags;
