import { isAxiosError } from 'axios';
import { DownloadActionDataError } from '@/types';

/**
 * CSVファイルのヘッダーに挿入されるカラム名の配列
 * ただし、バックエンドから返却されるdata(Hash)のkeyに存在しないカラム名は無効となり、そのカラムのRowのセルに何も値が入らないため注意すること
 * 有効なカラム名の参照：https://github.com/stockmarkteam/anews/blob/695f4c5e7a1f71514e90b49dfe9cfc4fe737d421/app/api/v1/helpers/action_data_helper.rb#L68
 */
const actionDataColumns = [
  '名前',
  'メールアドレス',
  '組織タグ',
  'タイトル',
  '翻訳タイトル',
  'URL',
  'メディア名',
  '言語',
  'ログイン',
  '配信日',
  'アクション',
  'アクション日時',
  'グループ名',
  'テーマ名',
  'コメントタイプ',
  'コメントID',
  '親コメントID',
  '返信コメント数',
  'コメント内容',
  'タグ',
  'メモ',
] as const;

export type ActionDataResponse = ActionDataItem[];

export type ActionDataItem = {
  [key in (typeof actionDataColumns)[number]]: string | null;
};

const createWorkbook = async (data: ActionDataResponse) => {
  const ExcelJS = (await import('exceljs')).default;
  const workbook = new ExcelJS.Workbook();
  workbook.creator = 'Stockmark';
  const worksheet = workbook.addWorksheet('アクション履歴', {
    pageSetup: {
      fitToPage: true,
    },
    views: [
      {
        state: 'frozen',
        ySplit: 1,
      },
    ],
  });
  worksheet.columns = actionDataColumns.map(header => {
    return { key: header };
  });

  // ヘッダーフォーマット
  worksheet.addRow(actionDataColumns);
  for (let c = 1; c <= worksheet.columnCount; c++) {
    const cell = worksheet.getCell(1, c);
    cell.font = { bold: true };
  }
  if (data.length > 0) {
    worksheet.addRows(data);
    // ハイパーリンク作成
    for (let r = 1; r <= worksheet.rowCount; r++) {
      for (let c = 1; c <= worksheet.columnCount; c++) {
        const cell = worksheet.getCell(r, c);
        const cellValue = cell.value?.toString();
        if (cellValue?.match(/^https?:\/\//)) {
          cell.value = {
            text: cellValue,
            hyperlink: cellValue,
          };
          cell.font = {
            color: { argb: '000000FF' },
            underline: true,
          };
        }
      }
    }
  }
  return workbook;
};

export const downloadActionData = async (
  filename: string,
  data: ActionDataResponse,
  format: 'excel' | 'csv',
): Promise<void> => {
  const workbook = await createWorkbook(data);
  const dataBuffer =
    format === 'excel'
      ? await workbook.xlsx.writeBuffer()
      : await workbook.csv.writeBuffer();
  const url = window.URL.createObjectURL(new Blob([dataBuffer]));
  const link = document.createElement('a');
  link.href = url;
  link.setAttribute('download', filename);
  document.body.appendChild(link);
  link.click();
  link.remove();
};

export const handleDownloadError = (error: unknown): string => {
  if (isAxiosError<DownloadActionDataError>(error)) {
    if (
      error.response?.status === 400 &&
      error.response.data.type === 'result_too_large'
    ) {
      return 'エクスポートの上限（1万行）に達しています。お手数ですが、期間を分けてご利用ください。';
    }
  }

  return 'エラーが発生しました。しばらく時間をおいてお試しください。解消しない場合はサポートまでお問い合わせください。';
};

/**
 * グループアクティビティ画面のエクスポート機能は、
 * エラー時に画面にエラーメッセージを表示するためストアを更新せずメッセージを直接返す
 *
 * [備考] handleDownloadErrorにオプショナル引数を追加せず関数を分けた理由は、
 * エラーメッセージの表示方法が異なるため意味的に分割するため
 */
export const handleGroupActivityDownloadError = (error: unknown) => {
  if (isAxiosError<DownloadActionDataError>(error)) {
    if (
      error.response?.status === 400 &&
      error.response.data.type === 'result_too_large'
    )
      return 'ダウンロードの上限（1万行）に達しています。お手数ですが、期間を分けてご利用ください。';
  }

  return 'エラーが発生しました。しばらく時間をおいてお試しください。解消しない場合はサポートまでお問い合わせください。';
};
