<script lang="ts">
import {
  computed,
  ComputedRef,
  defineComponent,
  inject,
  PropType,
  ref,
  watch,
} from 'vue';
import { useRoute } from 'vue-router';
import {
  ContentsContext,
  Feature,
  IndustryData,
  PageName,
  SearchData,
} from '@/api/tracking';
import noImage1to1 from '@/assets/noimage-1to1.jpg';
import noImage3to1 from '@/assets/noimage-3to1.jpg';
import { MYPAGE_ROUTE_NAMES } from '@/routers/constants';
import AdpDocumentCardLink from '@/components/common/adp-document/adp-document-card-link.vue';
import {
  AdpDocumentBasicInfo,
  FeedType,
  isArticle,
  isPatent,
  isResearchPaper,
} from '@/types';
import { getPublisher } from '@/utils/adpDocument';
import { imageErrorHandler } from '@/utils/errorHandler';
import { formatDate } from '@/utils/formatters';
import { visibleAdpCardKey } from '@/utils/injectionKeys';

export default defineComponent({
  components: {
    AdpDocumentCardLink,
  },
  props: {
    adpDocument: {
      type: Object as PropType<AdpDocumentBasicInfo>,
      required: true,
    },
    isNewArticle: { type: Boolean, default: false },
    trendCompanyText: { type: String },
    size: { type: String as PropType<'xm' | 'm' | 'l'>, default: 'xm' },
    userEnableTranslateTitle: { type: Boolean },
    // 計測用
    themeId: { type: Number },
    groupId: { type: Number },
    rankInWholeFeed: { type: Number },
    pageName: { type: String as PropType<PageName>, required: true },
    feature: { type: String as PropType<Feature> },
    feedType: { type: String as PropType<FeedType> },
    searchData: { type: Object as PropType<SearchData> },
    industryData: { type: Object as PropType<IndustryData> },
    contentsContext: { type: Object as PropType<ContentsContext> },
    execution: { type: String },
    badgeNumber: { type: Number },
    badgeAnimationDelay: { type: Number },
    isBadgeAnimationEnabled: { type: Boolean, default: false },
    isRelatedArticle: { type: Boolean, default: false },
    isNarrow: { type: Boolean, default: false },
  },
  emits: ['articleClicked'],
  setup(props, context) {
    const route = useRoute();

    const documentPublisher = computed(() => getPublisher(props.adpDocument));

    const isRead = ref(props.adpDocument.is_read ?? false);
    watch(
      () => props.adpDocument.id,
      () => {
        isRead.value = props.adpDocument.is_read ?? false;
      },
    );
    const isMyPageViews = ref(route.name === MYPAGE_ROUTE_NAMES.views);

    const enableTranslateTitle = computed(() => {
      return (
        props.userEnableTranslateTitle &&
        props.adpDocument?.lang !== 'ja' &&
        !!props.adpDocument?.translated_title
      );
    });

    const adpDocumentTitle = computed(() => {
      return enableTranslateTitle.value
        ? props.adpDocument?.translated_title
        : props.adpDocument?.title;
    });

    const publishedAt = computed(() =>
      props.adpDocument?.published_at
        ? formatDate(props.adpDocument.published_at)
        : undefined,
    );

    const articleLoginRequired = computed(() => {
      if (isArticle(props.adpDocument)) {
        return props.adpDocument.login_required ?? false;
      }
      return false;
    });

    const imageUrl = computed(() => {
      if (props.adpDocument?.image_url) {
        return props.adpDocument.image_url;
      }
      return props.size === 'l' ? noImage3to1 : noImage1to1;
    });

    const isPdf = computed(() => {
      switch (props.adpDocument.doc_type) {
        case 'article':
        case 'patent':
        case 'research_paper':
          return props.adpDocument?.pdf_url;
        case 'report':
          return props.adpDocument.url.endsWith('.pdf');
        default:
          return false;
      }
    });

    const isPriorityJournal = computed(() => {
      return (
        isResearchPaper(props.adpDocument) &&
        props.adpDocument.is_priority_journal
      );
    });

    const patentIdentifier = computed(() => {
      if (isPatent(props.adpDocument)) {
        return props.adpDocument.patent_identifier;
      }
      return undefined;
    });

    const patentIssue = computed(() => {
      if (isPatent(props.adpDocument)) {
        return props.adpDocument.issue;
      }
      return undefined;
    });

    const handleArticleClicked = () => {
      isRead.value = true;
      context.emit('articleClicked');
    };

    type AdpDocumentCardLinkProps = InstanceType<
      typeof AdpDocumentCardLink
    >['$props'];
    const linkProps: ComputedRef<AdpDocumentCardLinkProps> = computed(() => {
      return {
        adpDocument: props.adpDocument,
        themeId: props.themeId,
        groupId: props.groupId,
        pageName: props.pageName,
        feature: props.feature,
        feedType: props.feedType,
        rankInWholeFeed: props.rankInWholeFeed,
        searchData: props.searchData,
        industryData: props.industryData,
        trendCompanyText: props.trendCompanyText,
        contentsContext: props.contentsContext,
        execution: props.execution,
      };
    });

    // FIXME: 共通コンポーネントに独自の処理を入れない
    const visibleAdpCard =
      route.fullPath === '/follow_lists' ? inject(visibleAdpCardKey) : false;

    const TITLE_MAX_LENGTH_FOR_VIEW = 27;
    const showTooltip = adpDocumentTitle.value
      ? adpDocumentTitle?.value?.length > TITLE_MAX_LENGTH_FOR_VIEW
      : false;

    return {
      documentPublisher,
      enableTranslateTitle,
      adpDocumentTitle,
      publishedAt,
      articleLoginRequired,
      imageUrl,
      isPdf,
      isPriorityJournal,
      patentIdentifier,
      patentIssue,
      handleArticleClicked,
      linkProps,
      isArticle,
      isResearchPaper,
      imageErrorHandler,
      isRead,
      isMyPageViews,
      visibleAdpCard,
      showTooltip,
    };
  },
});
</script>

<template>
  <div
    class="document-basic-info"
    :class="{
      'document-basic-info-m': size === 'xm' || size === 'm',
      'document-basic-info-l': size === 'l',
      'document-basic-info-related-articles': isRelatedArticle,
    }"
  >
    <div class="document-info-image">
      <div v-if="size === 'l'" class="document-img-l">
        <AdpDocumentCardLink v-bind="linkProps">
          <img
            class="lazyload"
            loading="lazy"
            :src="imageUrl"
            :data-src="imageUrl"
            @error="imageErrorHandler($event, '3to1')"
          />
        </AdpDocumentCardLink>
      </div>
      <div class="document-info-box">
        <div class="document-sub-info">
          <slot name="documentMediaName">
            <div class="document-media-name c-text c-text--s">
              <span class="text-content">
                {{ documentPublisher }}
              </span>
              <VTooltip placement="bottom" :disabled="!isPriorityJournal">
                <span
                  v-if="isPriorityJournal"
                  class="priority-journal c-text c-text--xs"
                  >SJR</span
                >
                <template #popper>
                  <div>
                    <a
                      class="help-link"
                      href="https://anews-stockmark.zendesk.com/hc/ja/articles/13717202977689-%E8%AB%96%E6%96%87%E6%A9%9F%E8%83%BD%E3%81%AB%E3%81%A4%E3%81%84%E3%81%A6"
                      target="_blank"
                      rel="noopener noreferrer"
                      >SJRスコア</a
                    >の上位に入る影響度の高い学術雑誌です
                  </div>
                </template>
              </VTooltip>
              <div class="spacing-04"></div>
              <div
                v-if="articleLoginRequired"
                class="media-type-label c-title c-title--xs"
              >
                ログインが必要
              </div>
              <div
                v-else-if="adpDocument.is_alliance_media"
                class="media-type-label c-title c-title--xs"
              >
                Anewsで読める有料記事
              </div>
              <div
                v-if="badgeNumber"
                class="number-badge"
                :class="{ 'animation-delay': isBadgeAnimationEnabled }"
                :style="{ animationDelay: `${badgeAnimationDelay}s` }"
              >
                {{ badgeNumber }}
              </div>
            </div>
          </slot>
        </div>
        <div class="title-link">
          <VTooltip
            placement="bottom"
            :disabled="!(visibleAdpCard && showTooltip)"
          >
            <div
              class="document-title"
              :class="{
                'c-text c-text--xm': size === 'xm',
                'c-title c-title--m': size === 'm',
                'c-title c-title--l': size === 'l',
                'is-narrow': isNarrow,
              }"
              ref="titleRef"
            >
              <AdpDocumentCardLink
                v-bind="linkProps"
                :class="{ read: isRead && !isMyPageViews, 'is-pdf': isPdf }"
                @article-clicked="handleArticleClicked"
              >
                {{ adpDocumentTitle }}
              </AdpDocumentCardLink>
            </div>
            <template #popper>
              {{ adpDocumentTitle }}
            </template>
          </VTooltip>
          <div
            class="document-original-title c-text c-text--s"
            :class="{ 'is-narrow': isNarrow }"
            v-if="enableTranslateTitle"
          >
            <AdpDocumentCardLink v-bind="linkProps">
              元タイトル：{{ adpDocument.title }}
            </AdpDocumentCardLink>
          </div>
        </div>
        <div
          v-if="isPdf && isResearchPaper(adpDocument)"
          class="document-original-site c-text c-text--s"
        >
          <AdpDocumentCardLink v-bind="{ ...linkProps, usePdfUrl: false }">
            提供サイトで見る
          </AdpDocumentCardLink>
        </div>
        <div class="document-sub-info">
          <div class="document-date c-text c-text--s" v-if="publishedAt">
            {{ publishedAt }}
          </div>
          <div v-if="isNewArticle" class="new-tag">新着</div>
          <div v-if="patentIdentifier" class="c-text c-text--s">
            ・{{ patentIdentifier }}
          </div>
        </div>
        <div class="spacing-08"></div>
        <div class="trend-company c-text c-text--s" v-if="trendCompanyText">
          {{ trendCompanyText }}
        </div>
      </div>
      <div v-if="size === 'xm' || size === 'm'" class="document-image-wrap">
        <AdpDocumentCardLink class="image-link" v-bind="linkProps">
          <img
            class="document-image o-article-image lazyload"
            :class="{
              'o-alliance-image': adpDocument.is_alliance_media,
              'o-small-image': isRelatedArticle,
            }"
            loading="lazy"
            :src="imageUrl"
            :data-src="imageUrl"
            @error="imageErrorHandler($event, '1to1')"
          />
        </AdpDocumentCardLink>
      </div>
    </div>
    <div v-if="patentIssue" class="patent-issue c-text c-text--s">
      {{ patentIssue }}
    </div>
  </div>
</template>

<style lang="scss" scoped>
.document-basic-info {
  width: 100%;

  &.document-basic-info-m {
    .document-info-image {
      display: flex;
      justify-content: space-between;
      align-items: center;
      gap: 16px;

      .document-info-box {
        width: 80%;
        min-width: 0;
      }
    }
    .patent-issue {
      padding: 12px 0;
    }
  }

  &.document-basic-info-l {
    .document-info-box {
      padding: 12px 16px;
    }
    .patent-issue {
      padding: 0 16px 12px 16px;
    }
  }

  &.document-basic-info-related-articles {
    // 1行のタイトルと2行のタイトルは同じ高さにするため
    min-height: 94px;
    .document-info-image .document-info-box {
      width: auto;
      // 画像は60px, gapは16px
      max-width: calc(100% - 60px - 16px);
      .document-title {
        -webkit-line-clamp: 2;

        .is-pdf::after {
          // PDFアイコンが見きれないようにする
          margin-right: 5px;
        }
      }
    }
    .document-image-wrap {
      align-self: start;
    }
  }

  div.document-info-box {
    margin-left: 2px;

    .title-link {
      &:hover {
        cursor: pointer;
        text-decoration: underline;
      }
    }
    div.document-title {
      word-wrap: break-word;
      word-break: break-word;
      overflow-wrap: break-word;

      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 4;
      overflow: hidden;

      margin: 4px 0 4px 0;

      .read {
        color: $color-gray600;
      }
      .is-pdf::after {
        display: inline-block;
        padding: 1px 2px;
        font-size: 9px;
        line-height: 10px;
        content: 'PDF';
        border-radius: 2px;
        border: solid 1px;
        transform: translate(5px, -2px);
      }
      &.is-narrow {
        -webkit-line-clamp: 3;
      }
    }
    div.document-original-title {
      color: $color-gray800;
      margin-top: 2px;
      word-wrap: break-word;
      word-break: break-word;
      overflow-wrap: break-word;

      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 4;
      overflow: hidden;

      margin: 2px 0 4px 0;
      & a {
        color: $color-gray800;
      }
      &.is-narrow {
        -webkit-line-clamp: 3;
      }
    }

    .document-original-site {
      color: $color-gray800;
      text-decoration: underline;
      margin: 4px 0;
    }

    div.document-sub-info {
      display: flex;
      flex-direction: row;
      color: $color-gray800;
      align-items: center;
      margin-top: 2px;
      div.document-date {
        margin-right: 2px;
        white-space: nowrap;
      }
      .document-media-name {
        display: inline-flex;
        max-width: 100%;

        .text-content {
          text-overflow: ellipsis;
          white-space: nowrap;
          overflow: hidden;
        }
        .priority-journal {
          align-self: center;
          height: 16px;
          margin-left: 2px;
          padding: 0 2px;
          color: #fff;
          background: #e77642;
        }
        .number-badge {
          margin-left: 4px;
        }
        .animation-delay {
          animation: text-animation 1s steps(1, end) forwards;
          opacity: 0;
        }
        @keyframes text-animation {
          to {
            opacity: 1;
          }
        }
      }
    }
  }

  .patent-issue {
    color: $color-gray800;
  }
  .document-img-l {
    img {
      width: 614px;
      height: 204px;
      object-fit: cover;
      object-position: 50% 25%;
      border-radius: 4px 4px 0 0;
    }
  }
  .document-image-wrap {
    margin-right: 2px;
  }

  .o-article-image {
    height: 80px;
    width: 80px;
    aspect-ratio: 1 / 1;
    object-fit: cover;
    object-position: 50% 25%;
    border-radius: 4px;

    &.o-alliance-image {
      object-fit: contain;
      object-position: 0;
    }
    &.o-small-image {
      height: 60px;
      width: 60px;
    }
  }

  .trend-company {
    margin-bottom: 8px;
    color: $color-gray800;
  }
  .new-tag {
    padding: 0px 8px;
    height: 16px;
    background: #fff;
    color: #c6002f;
    font-size: 10px;
    line-height: 14px;
    display: flex;
    align-items: center;
  }
  .media-type-label {
    padding: 1px 8px;
    color: $color-gray800;
    background-color: $color-gray200;
    white-space: nowrap;
    border-radius: 2px;
  }

  .m-alliance-media {
    display: flex;
    flex-direction: row;
    align-items: center;
    font-size: 12px;
    line-height: 16px;
    margin-bottom: 2px;
    color: $color-green600;
    margin-left: 10px;
    .icon-box {
      fill: $color-green600;
      padding: 0 4px 0 0 !important;
    }
  }

  .uncontracted-alliance {
    color: $color-orange1000;
  }
}
</style>
