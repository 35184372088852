<script setup lang="ts">
import { computed, onMounted, reactive, ref, watch } from 'vue';
import api from '@/api';
import { PageName } from '@/api/tracking';
import { HTTP_URL_REGEXP, MAX_ARTICLE_MEMO_LENGTH } from '@/constants';
import { DgrIcon } from '@stockmarkteam/donguri-ui';
import AdpDocumentExport from '@/components/common/adp-document/adp-document-export.vue';
import InputTag from '@/components/common/atoms/input-tag.vue';
import TextCounter from '@/components/common/molecules/text-counter.vue';
import {
  AdpDocument,
  ArticleUpdateOption,
  isArticle,
  isPatent,
  isReport,
  isResearchPaper,
  Tag,
  TeamUser,
} from '@/types';
import { sanitize } from '@/utils/sanitize';

interface Props {
  adpDocument: AdpDocument;
  creator?: TeamUser;
  tagList: Tag[];
  editable: boolean;
  isGroupView: boolean;
  pageName?: PageName;
  groupId?: number;
  hasDocumentExport?: boolean;
}

const props = withDefaults(defineProps<Props>(), {
  tagList: () => [],
  editable: () => false,
  isGroupView: () => false,
  hasDocumentExport: () => false,
});

const emit = defineEmits<{
  'article-info-updated': [
    article: AdpDocument,
    updateOptions: ArticleUpdateOption,
  ];
  'cancel-edit': [];
  'click-edit': [];
}>();

const editing = ref(false);
const editingMemo = ref(props.adpDocument?.memo ?? '');
const selectedTags = reactive({
  list: [...(props.adpDocument?.tags ?? [])],
});
const displayMemo = ref('');
const tagLabel = computed(() =>
  props.isGroupView ? 'グループタグ' : 'マイタグ',
);
const memoLabel = computed(() =>
  props.isGroupView ? 'グループメモ' : 'マイメモ',
);
let tagsBeforeUpdate = [...(props.adpDocument?.tags ?? [])];
let memoBeforeUpdate = props.adpDocument?.memo ?? '';

onMounted(() => {
  highlightMemoUrl();
});

const updateArticleInfo = () => {
  editing.value = false;
  highlightMemoUrl();

  emit(
    'article-info-updated',
    {
      ...props.adpDocument,
      memo: editingMemo.value,
      tags: selectedTags.list,
    },
    {
      shouldUpdateMemo: memoBeforeUpdate !== editingMemo.value,
      shouldUpdateTags:
        tagsBeforeUpdate.toString() !== selectedTags.list.toString(),
    },
  );
};

const cancelEdit = () => {
  editing.value = false;
  editingMemo.value = props.adpDocument?.memo ?? '';
  selectedTags.list = [...(props.adpDocument?.tags ?? [])];
  emit('cancel-edit');
};

watch(
  () => props.adpDocument,
  () => {
    editing.value = false;
    editingMemo.value = props.adpDocument?.memo ?? '';
    highlightMemoUrl();
    selectedTags.list = [...(props.adpDocument?.tags ?? [])];
  },
);

const clickEdit = () => {
  editing.value = true;
  emit('click-edit');
  tagsBeforeUpdate = [...(selectedTags.list ?? [])];
  memoBeforeUpdate = editingMemo.value;
};

const colorMemoUrl = (): void => {
  let replacedText = editingMemo.value;
  const matchArray = editingMemo.value.match(HTTP_URL_REGEXP) ?? [];
  for (const url of matchArray) {
    const urlRegex = new RegExp(url, 'ig');
    replacedText = replacedText.replace(
      urlRegex,
      `<a class="url-link" href="" target="_blank" rel="noopener noreferrer">${url}</a>`,
    );
  }
  displayMemo.value = replacedText;
};

const replaceColoredUrlToTitle = async (): Promise<void> => {
  let replacedText = displayMemo.value;
  const previewingArticleId = props.adpDocument?.id;
  const matchArray = editingMemo.value.match(HTTP_URL_REGEXP) ?? [];
  for await (const url of matchArray) {
    const preview = await api.fetchUrlPreview(url).catch(() => undefined);
    const regex = new RegExp(
      `<a class="url-link" href="" target="_blank" rel="noopener noreferrer">${url}</a>`,
      'g',
    );
    if (preview) {
      replacedText = replacedText.replace(
        regex,
        `<a class="url-link" href="${url}" target="_blank" rel="noopener noreferrer">${preview.title}</a>`,
      );
    } else {
      replacedText = replacedText.replace(
        regex,
        `<a class="url-link" href="${url}" target="_blank" rel="noopener noreferrer">${url}</a>`,
      );
    }
    if (previewingArticleId !== props.adpDocument?.id) {
      // preview取得中に記事が切り替わっていた場合は置換をやめる
      return;
    }
    displayMemo.value = replacedText;
  }
};

const highlightMemoUrl = () => {
  displayMemo.value = editingMemo.value;
  colorMemoUrl();
  replaceColoredUrlToTitle();
};

const adpDocumentPublisher = computed(() => {
  if (props.adpDocument === undefined) return '';
  if (isArticle(props.adpDocument)) {
    return props.adpDocument.media_name;
  } else if (isResearchPaper(props.adpDocument)) {
    return props.adpDocument.journal_name;
  } else if (isPatent(props.adpDocument)) {
    return props.adpDocument.applicant;
  } else if (isReport(props.adpDocument)) {
    return props.adpDocument.site_name;
  }
  return '';
});

const sanitizedDisplayMemo = computed(() => sanitize(displayMemo.value));
</script>

<template>
  <div class="article-info">
    <div v-if="adpDocument" class="basic-info">
      <template v-if="pageName && hasDocumentExport">
        <AdpDocumentExport
          :adp-documents="[adpDocument]"
          :page-name="pageName"
          :group-id="groupId"
        ></AdpDocumentExport>
        <div class="spacing-24"></div>
      </template>
      <div class="heading">
        <DgrIcon name="newspaper" />
        <div class="spacing-04"></div>
        <div class="c-title c-title--m">基本情報</div>
      </div>
      <div class="article-title">
        <div class="sub-heading c-title c-title--s">タイトル</div>
        <div class="spacing-02"></div>
        <div class="c-text c-text--m">{{ adpDocument.title }}</div>
      </div>
      <div class="spacing-12"></div>
      <div class="article-media">
        <div class="sub-heading c-title c-title--s">メディア</div>
        <div class="c-text c-text--m">{{ adpDocumentPublisher }}</div>
      </div>
      <div class="spacing-12"></div>
      <div class="article-url">
        <div class="sub-heading c-title c-title--s">URL</div>
        <div class="body">
          <a
            class="url-link"
            :href="adpDocument.url"
            target="_blank"
            rel="noopener noreferrer"
          >
            <div class="url c-text c-text--m">{{ adpDocument.url }}</div>
            <div>
              <DgrIcon size="small" name="external-link" class="primary" /></div
          ></a>
        </div>
      </div>
      <div class="creator" v-if="creator">
        <div class="spacing-12"></div>
        <div class="sub-heading c-title c-title--s">共有者</div>
        <div class="c-text c-text--m">{{ creator.user_name }}</div>
      </div>
    </div>
    <div v-if="editable" class="edit-tag-memo">
      <div class="spacing-24"></div>
      <div class="tags">
        <div class="heading">
          <DgrIcon name="tag" />
          <div class="spacing-04"></div>
          <div class="c-title c-title--m">{{ tagLabel }}</div>
        </div>
        <div
          class="tags-empty c-text c-text--m"
          v-if="selectedTags.list.length === 0 && !editing"
        >
          未設定
        </div>
        <p v-if="editing" class="tags-input-description">
          10件まで登録することができます
        </p>
        <InputTag
          :placeholder="'入力後、改行でタグを追加'"
          :tag-list="tagList"
          v-model="selectedTags.list"
          :is-editing="editing"
          :is-group="isGroupView"
          :use-label="false"
        ></InputTag>
      </div>
      <div class="spacing-24"></div>
      <div class="memo">
        <div class="heading">
          <DgrIcon name="pencil" />
          <div class="spacing-04"></div>
          <div class="c-title c-title--m">{{ memoLabel }}</div>
        </div>
        <!-- eslint-disable vue/no-v-html -->
        <div
          class="memo-content c-text c-text--m"
          v-if="!editing && displayMemo.length > 0"
          v-html="sanitizedDisplayMemo"
        ></div>
        <!-- eslint-enable vue/no-v-html -->
        <div
          class="memo-empty c-text c-text--m"
          v-if="!editing && displayMemo.length === 0"
        >
          未記入
        </div>
        <textarea
          class="memo-textarea c-textArea"
          v-if="editing"
          v-model.trim="editingMemo"
          :maxlength="MAX_ARTICLE_MEMO_LENGTH"
          @keydown.ctrl.enter="updateArticleInfo"
          @keydown.meta.enter="updateArticleInfo"
        ></textarea>
        <TextCounter
          v-if="editing"
          :text="editingMemo"
          :max-length="MAX_ARTICLE_MEMO_LENGTH"
        />
      </div>
      <div class="spacing-24"></div>
      <div
        class="edit-button c-outlineBtn c-outlineBtn--secondary c-btn--small"
        v-if="!editing"
        @click="clickEdit"
      >
        編集
      </div>
      <div v-if="editing">
        <div
          class="save-button c-btn c-btn--small c-btn--AnewsPrimary"
          @click="updateArticleInfo"
        >
          保存
        </div>
        <div class="spacing-12"></div>
        <div
          class="cancel-button c-outlineBtn c-btn--small c-outlineBtn--secondary"
          @click="cancelEdit"
        >
          キャンセル
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.article-info {
  background: #ffffff;
  padding: 24px 16px;
  border: 1px solid #e6e6e6;
  box-sizing: border-box;
  border-radius: 4px;

  .heading {
    display: flex;
    justify-content: start;
    border-bottom: solid 1px #e6e6e6;
    padding-bottom: 12px;
    margin-bottom: 12px;
  }

  .basic-info {
    .article-url {
      .url-link {
        display: flex;
        align-items: center;
        &:hover {
          text-decoration: underline;
        }
        .url {
          color: #1da482;
          word-wrap: nowrap;
          white-space: nowrap;
          overflow-x: hidden;
          text-overflow: ellipsis;
        }
      }
    }
  }

  .tags {
    .tags-content {
      display: flex;
      flex-wrap: wrap;

      .tag-text {
        display: inline-block;
        max-width: 94px;
        word-wrap: nowrap;
        white-space: nowrap;
        overflow-x: hidden;
        text-overflow: ellipsis;
        margin-right: 2px;
      }
    }
  }

  .memo {
    .memo-content {
      white-space: pre-wrap;
      word-wrap: break-word;
      word-break: break-word;
      overflow-wrap: break-word;
    }
    .memo-textarea {
      width: 256px;
      max-width: 256px;
      height: 242px;
      outline: none;
    }
  }

  .memo-empty,
  .tags-empty {
    color: #b3b3b3;
  }

  .tags-input-description {
    font-size: 12px;
    color: $color-gray600;
    margin-bottom: 4px;
  }

  .edit-button {
    width: 256px;
    border-color: #1da482;
    color: #1da482;
    &:hover {
      background: #1da482;
      color: #ffffff;
    }
  }

  .cancel-button {
    width: 256px;
    color: #b3b3b3;
  }

  .save-button {
    width: 256px;
  }
}
</style>
