export type ContentPosition = 'top' | 'middle' | 'bottom';
export type ScrollData = {
  windowScrollY: number;
  contentPosition: ContentPosition;
};

export const syncContentScrollWithViewport = function (
  content: HTMLElement,
  container: HTMLElement,
  prevWindowScrollY: number,
  contentPosition: ContentPosition,
  contentHeaderHeight: number,
  initialMarginTop = 0,
  reset = false,
): ScrollData | undefined {
  if (!content || !container) {
    return;
  }
  const marginTop = 62;
  const pageHeaderHeight = 64;
  const headerHeight = pageHeaderHeight + contentHeaderHeight + marginTop;
  const timelineHeight = content.offsetHeight;
  const windowHeight = window.innerHeight;
  const windowScrollY = window.scrollY;
  const windowScrollX = window.scrollX;

  // contentのサイズが変わるようなケースでリセットを行う
  if (reset) {
    if (container.offsetHeight <= timelineHeight) {
      content.style.position = 'relative';
      content.style.top = '';
      content.style.bottom = '';
      return;
    }
    content.style.position = 'fixed';
    if (windowScrollY > contentHeaderHeight) {
      content.style.top = `${pageHeaderHeight + marginTop}px`;
    } else {
      content.style.position = 'absolute';
      content.style.top = `${initialMarginTop}px`;
    }
    content.style.bottom = 'auto';
    return { windowScrollY, contentPosition: 'top' };
  }

  if (container.offsetHeight == timelineHeight) {
    // カラムの方が長いので何もしなくていい
    return;
  } else if (timelineHeight < windowHeight) {
    // カラムが短いので一回fixedにして終わり
    if (content.style.position != 'fixed') {
      content.style.position = 'fixed';
      content.style.top = `${headerHeight}px`;
      content.style.bottom = 'auto';
      content.style.transform = `translateX(-${windowScrollX}px)`;
    }
    // スクロール位置によって固定するポジションを変える;
    if (windowScrollY > contentHeaderHeight) {
      content.style.top = `${pageHeaderHeight + marginTop}px`;
      content.style.transform = `translateX(-${windowScrollX}px)`;
    } else {
      content.style.position = 'absolute';
      content.style.top = `${initialMarginTop}px`;
      content.style.transform = 'translateX(0px)';
    }
    return { windowScrollY, contentPosition };
  }

  const timelineTop = content.offsetTop;
  const timelineBottom = timelineTop + timelineHeight;
  const scrollingDown = windowScrollY >= prevWindowScrollY;

  if (
    (scrollingDown && contentPosition == 'bottom') ||
    (!scrollingDown && contentPosition == 'top')
  ) {
    if (!scrollingDown && contentPosition == 'top') {
      if (windowScrollY > contentHeaderHeight) {
        content.style.top = `${pageHeaderHeight + marginTop}px`;
      } else {
        content.style.position = 'absolute';
        content.style.top = `${initialMarginTop}px`;
        content.style.transform = 'translateX(0px)';
      }
    }

    if (content.style.position === 'fixed') {
      content.style.transform = `translateX(-${windowScrollX}px)`;
    }
    return { windowScrollY, contentPosition };
  }

  // カラムの一番上か一番下になっていたか
  const wasScrollingAtContentBoundary = content.style.position == 'fixed';

  // 今カラムの中にスクロールになっているか
  const scrollingInColumn =
    (!scrollingDown &&
      (wasScrollingAtContentBoundary ||
        windowScrollY >= timelineTop + contentHeaderHeight)) ||
    (scrollingDown &&
      (wasScrollingAtContentBoundary ||
        windowScrollY - headerHeight + windowHeight <= timelineBottom));

  if (scrollingInColumn) {
    contentPosition = 'middle';
    if (wasScrollingAtContentBoundary) {
      // ページスクロールの追従していたので固定にする
      content.style.position = 'absolute';
      content.style.top = `${Math.max(
        0,
        windowScrollY - headerHeight + timelineTop,
      )}px`;
      content.style.bottom = 'auto';
      content.style.transform = 'translateX(0px)';
    }
    return { windowScrollY, contentPosition };
  }

  // 今カラムの中にスクロールになっていたか
  // その場合ならページスクロールの追従するのためにfixedにする
  const wasScrollingInColumn = content.style.position == 'absolute';
  if (wasScrollingInColumn) {
    content.style.position = 'fixed';
    content.style.transform = `translateX(-${windowScrollX}px)`;
    if (scrollingDown) {
      contentPosition = 'bottom';
      content.style.top = 'auto';
      content.style.bottom = '0';
    } else {
      contentPosition = 'top';
      content.style.top = `${headerHeight - contentHeaderHeight}px`;
      content.style.bottom = 'auto';
    }
  }
  return { windowScrollY, contentPosition };
};
