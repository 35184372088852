import { computed, onMounted, onUnmounted, Ref, ref } from 'vue';
import { useRoute } from 'vue-router';
import Dexie, { Table } from 'dexie';
import { SearchHistory, SearchType } from '@/types';

export interface UseSearchHistoriesReturn {
  fetchLatestSearchHistories: () => Promise<void>;
  createSearchHistory: (query: string) => Promise<void>;
  deleteSearchHistory: (query: string) => Promise<void>;
  searchHistories: Ref<SearchHistory[]>;
}

interface QuotaExceededError extends DOMException {
  name: 'QuotaExceededError';
}

class SearchHistoryDB extends Dexie {
  searchHistories!: Table<SearchHistory, number>;

  constructor(dbName: string) {
    super(dbName);
    this.version(1).stores({
      searchHistories: '++id,createdAt,query',
    });
  }
}

/**
 * 検索履歴に関するカスタムフック
 *
 * IndexedDBを使用して検索履歴の 保存 / 取得 / 削除 を行う
 *
 * NOTE: 後々に直近の検索履歴だけでなく、入力値に合わせて部分一致する履歴をリアルタイムで表示する機能を追加する予定なので、データ量を考慮してIndexedDBを使用している
 */
export const useSearchHistories = (
  userId: number | null,
): UseSearchHistoriesReturn => {
  const route = useRoute();

  // IndexedDBの設定
  const DB_NAME = `searchHistoryDB-${userId}`;
  const db = new SearchHistoryDB(DB_NAME);

  const MAX_SEARCH_HISTORY_ITEM_COUNTS = 5;
  const searchHistories = ref<SearchHistory[]>([]);
  const USER_DOCUMENT_ROUTE_NAME = ['UserDocument', 'searchUserDocumentDemo'];
  const searchType = computed<SearchType>(() =>
    USER_DOCUMENT_ROUTE_NAME.includes(route.name as string)
      ? 'userDocument'
      : 'openInfo',
  );

  /**
   * 直近の (重複のない) 検索履歴を5件取得する
   */
  const fetchLatestSearchHistories = async (): Promise<void> => {
    try {
      // 作成時に重複するqueryは削除しているため、ここでは重複の考慮が不要
      const results: SearchHistory[] = await db.searchHistories
        .orderBy('createdAt')
        .reverse()
        .limit(MAX_SEARCH_HISTORY_ITEM_COUNTS)
        .toArray();

      searchHistories.value = results;
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error('fetchLatestSearchHistories error: ', error);
    }
  };

  /**
   * 検索実行時に検索履歴を作成する
   */
  const createSearchHistory = async (query: string): Promise<void> => {
    const addSearchHistory = async () => {
      await db.transaction('rw', db.searchHistories, async () => {
        await deleteSearchHistory(query);
        await db.searchHistories.add({
          query,
          createdAt: new Date().toISOString(),
          searchType: searchType.value,
        });
      });
    };

    try {
      await addSearchHistory();
    } catch (error) {
      if (isQuotaExceededError(error)) {
        await cleanupSearchHistories(0.5);
        try {
          await addSearchHistory();
        } catch (retryError) {
          // eslint-disable-next-line no-console
          console.error('addSearchHistory retryError: ', retryError);
        }
      } else {
        // eslint-disable-next-line no-console
        console.error('addSearchHistory error: ', error);
      }
    }
  };

  /**
   * 同じqueryを持つ全ての履歴を削除する
   */
  const deleteSearchHistory = async (query: string): Promise<void> => {
    try {
      await db.searchHistories.where('query').equals(query).delete();
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error('deleteSearchHistory error: ', error);
    }
  };

  /**
   * オリジンがクォータを使い切った場合の削除処理
   * @param deleteRatio IndexedDBの削除量を割合で指定 (0.0 ~ 1.0)
   *
   * WARN: IndexedDBのデータを指定した割合の数で消してしまうので、無闇に使わないこと
   * INFO: IndexedDBの上限については右記を参照 => https://developer.mozilla.org/ja/docs/Web/API/Storage_API/Storage_quotas_and_eviction_criteria#どれだけのデータが格納できるか
   */
  const cleanupSearchHistories = async (deleteRatio: number): Promise<void> => {
    try {
      await db.transaction('rw', db.searchHistories, async () => {
        const totalItems = await db.searchHistories.count();
        const deleteCount = Math.ceil(totalItems * deleteRatio);
        const itemsToDelete = await db.searchHistories
          .orderBy('createdAt')
          .limit(deleteCount)
          .primaryKeys();

        await db.searchHistories.bulkDelete(itemsToDelete);
      });
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error('cleanupSearchHistories error: ', error);
    }
  };

  const isQuotaExceededError = (
    error: unknown,
  ): error is QuotaExceededError => {
    if (error instanceof Dexie.DexieError) {
      return (error.inner as DOMException)?.name === 'QuotaExceededError';
    }
    return (error as QuotaExceededError).name === 'QuotaExceededError';
  };

  onMounted(async () => {
    await fetchLatestSearchHistories();
  });

  onUnmounted(() => {
    db.close();
  });

  return {
    fetchLatestSearchHistories,
    createSearchHistory,
    deleteSearchHistory,
    searchHistories,
  };
};
