<script lang="ts">
import {
  computed,
  defineComponent,
  onBeforeUnmount,
  onMounted,
  PropType,
  ref,
} from 'vue';
import FeedArticles from '@/components/common/feed-articles.vue';
import { Theme } from '@/types';
import { ContentPosition, syncContentScrollWithViewport } from '@/utils/scroll';
import { useStore } from '@/utils/vue';
import RelatedNewsPreview from './related-news-preview.vue';

export default defineComponent({
  components: {
    RelatedNewsPreview,
    FeedArticles,
  },
  props: {
    theme: {
      type: Object as PropType<Theme>,
      required: true,
    },
  },
  setup() {
    const store = useStore();

    const userInfo = computed(() => store.state.userInfo.userInfo);
    const feedType = computed(() => store.state.feedType.feedType);

    onMounted(async () => {
      window.addEventListener('scroll', handleScroll);
      window.addEventListener('resize', handleResize);
      handleScroll();
    });

    onBeforeUnmount(() => {
      window.removeEventListener('scroll', handleScroll);
      window.removeEventListener('resize', handleResize);
    });

    const activityColumn = ref<HTMLElement | undefined>();
    const activityContent = ref<HTMLElement | undefined>();
    let prevWindowScrollY = 0;
    let contentPosition: ContentPosition = 'top';
    const CONTENT_HEADER_HEIGHT = 121;

    const handleResize = (event?: Event) => handleScroll(event, true);
    const handleScroll = (_event?: Event, reset = false) => {
      if (!activityColumn.value || !activityContent.value) return;
      const data = syncContentScrollWithViewport(
        activityContent.value,
        activityColumn.value,
        prevWindowScrollY,
        contentPosition,
        CONTENT_HEADER_HEIGHT,
        0,
        reset,
      );
      if (data) {
        prevWindowScrollY = data.windowScrollY;
        contentPosition = data.contentPosition;
      }
    };

    return {
      userInfo,
      feedType,
      activityColumn,
      activityContent,
    };
  },
});
</script>

<template>
  <div class="keyword-feed">
    <div class="m-feed-ready" :key="theme.id">
      <div class="title">
        <span class="c-title c-title--xm">今日のニュース</span>
        <span class="subtitle c-text c-text--m"
          >テーマニュースは毎日1回更新されます(朝7時)</span
        >
      </div>
      <FeedArticles feed="keyword_feed" />
    </div>
    <div class="o-team-activity" ref="activityColumn">
      <div class="o-team-activity-content" ref="activityContent">
        <div v-if="!theme.is_chinese" class="related-news">
          <RelatedNewsPreview :theme="theme"></RelatedNewsPreview>
          <div class="spacing-16"></div>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
div.keyword-feed {
  display: flex;
  div.m-feed-ready {
    display: flex;
    flex-direction: column;
    width: 632px;

    .title {
      display: flex;
      flex-direction: column;
      gap: 4px;
      margin-bottom: 16px;

      .subtitle {
        color: #b3b3b3;
      }
    }

    div.m-no-related-feed {
      font-size: 12px;
    }
    .m-feed-menu {
      display: flex;
      justify-content: flex-end;
      .m-foreign-media-button {
        cursor: pointer;
        color: #1da482;
        margin-right: 40px;
        padding: 8px 16px;
        &:hover {
          background: #e6e6e6;
          border-radius: 4px;
        }
      }
    }
    div.m-feed-header {
      height: 24px;
      margin-bottom: 16px;

      span.m-feed-title {
        color: #4a4a4a;
        font-weight: bold;
      }

      span.m-feed-description {
        font-size: 12px;
        color: #b3b3b3;
      }
    }
    .m-no-related-feed {
      display: flex;
      flex-direction: row;
      width: 616px;
      height: 120px;
      background: #ffffff;
      border: 1px solid #e6e6e6;
      box-sizing: border-box;
      border-radius: 4px;
      align-items: center;
      justify-content: space-between;
      padding: 0 32px;
    }
    .m-no-related-feed {
      height: 120px;
      .m-description {
      }
      img {
        height: 100px;
        width: 100px;
      }
    }
  }
  .m-feed-removed {
    .m-body {
      width: 616px;
      height: 54px;
      background: white;
      display: flex;
      align-items: center;
      margin-top: 16px;
      .m-text {
        margin-left: 16px;
      }
    }
  }
  .loading {
    margin: 0 auto;
  }
  div.o-team-activity {
    position: relative;
    width: 280px;
    .o-team-activity-content {
      position: absolute;
      top: 0;
      padding-bottom: 80px;
      .featured-articles {
        width: 280px;
      }
    }
  }
}
</style>
