import { VueGtag } from 'vue-gtag';
import { userSession } from '@/utils/userSession';

export const gaTracker = {
  getTrackingId: function () {
    const value = import.meta.env.VITE_GA_TRACKING_ID;
    return value;
  },
  config: function (gtag: VueGtag) {
    const userId = userSession.getUserId();
    const teamId = userSession.getTeamId();

    if (userId === null || teamId === null) return;

    gtag.config({
      custom_map: { dimension1: 'team_id' },
      team_id: teamId.toString(),
    });
    gtag.set({
      user_id: userId,
    });
  },
  clearConfig: function (gtag: VueGtag) {
    gtag.config({
      custom_map: { dimension1: 'team_id' },
      team_id: undefined,
    });
    gtag.set({
      user_id: undefined,
    });
  },
  trackArticleView: function (gtag: VueGtag) {
    const teamId = userSession.getTeamIdAsString();
    gtag.event('click', {
      event_category: 'link',
      event_label: '外部URL',
      team_id: teamId,
    });
  },
  trackCreateComment: function (gtag: VueGtag) {
    const teamId = userSession.getTeamIdAsString();
    gtag.event('create', {
      event_category: 'comment',
      event_label: 'コメント投稿',
      team_id: teamId,
    });
  },
  trackEditComment: function (gtag: VueGtag) {
    const teamId = userSession.getTeamIdAsString();
    gtag.event('update', {
      event_category: 'comment',
      event_label: 'コメント編集',
      team_id: teamId,
    });
  },
  trackDeleteComment: function (gtag: VueGtag) {
    const teamId = userSession.getTeamIdAsString();
    gtag.event('delete', {
      event_category: 'comment',
      event_label: 'コメント削除',
      team_id: teamId,
    });
  },
  trackCreateTheme: function (gtag: VueGtag, _themeName: string) {
    const teamId = userSession.getTeamIdAsString();
    gtag.event('create', {
      event_category: 'theme',
      event_label: 'テーマ作成',
      team_id: teamId,
    });
  },
  trackUpdateTheme: function (gtag: VueGtag, _themeName: string) {
    const teamId = userSession.getTeamIdAsString();
    gtag.event('update', {
      event_category: 'theme',
      event_label: 'テーマ編集',
      team_id: teamId,
    });
  },
  trackDeleteTheme: function (gtag: VueGtag, _themeName: string) {
    const teamId = userSession.getTeamId();
    gtag.event('delete', {
      event_category: 'theme',
      event_label: 'テーマ削除',
      team_id: teamId,
    });
  },
  trackShowMoreFeed: function (gtag: VueGtag) {
    const teamId = userSession.getTeamId();
    gtag.event('click', {
      event_category: 'show more',
      event_label: 'もっと記事を表示',
      team_id: teamId,
    });
  },
  trackToggleFavorite: function (gtag: VueGtag) {
    const teamId = userSession.getTeamId();
    gtag.event('toggle', {
      event_category: 'favorite',
      event_label: 'お気に入り切り替え',
      team_id: teamId,
    });
  },

  // Anews Labs テンプレートの作成
  trackCreateTemplate: function (gtag: VueGtag, templateName: string) {
    const teamId = userSession.getTeamId();
    gtag.event('create', {
      event_category: 'labs.aiagent.template',
      event_label: templateName,
      team_id: teamId,
    });
  },
  // Anews Labs テンプレート作成時のキーワード
  trackCreateTemplateKeyword: function (
    gtag: VueGtag,
    templateName: string,
    keyword: string,
  ) {
    const teamId = userSession.getTeamId();
    gtag.event('create', {
      event_category: `labs.aiagent.template_${templateName}`,
      event_label: keyword,
      team_id: teamId,
    });
  },
  // Anews Labs SFUC 検索
  trackSearchSFUC: function (gtag: VueGtag, label: string) {
    const teamId = userSession.getTeamId();
    gtag.event('search', {
      event_category: 'labs.sfuc',
      event_label: label,
      team_id: teamId,
    });
  },
  // Anews Labs SFUC リンククリック
  trackClickSFUCLink: function (gtag: VueGtag, label: string) {
    const teamId = userSession.getTeamId();
    gtag.event('click_description', {
      event_category: 'labs.sfuc',
      event_label: label,
      team_id: teamId,
    });
  },
  // Anews Labs SFUC Good Reaction
  trackReactionGoodSFUC: function (gtag: VueGtag, label: string) {
    const teamId = userSession.getTeamId();
    gtag.event('reaction_good', {
      event_category: 'labs.sfuc',
      event_label: label,
      team_id: teamId,
    });
  },
  // Anews Labs SFUC Bad Reaction
  trackReactionBadSFUC: function (gtag: VueGtag, label: string) {
    const teamId = userSession.getTeamId();
    gtag.event('reaction_bad', {
      event_category: 'labs.sfuc',
      event_label: label,
      team_id: teamId,
    });
  },
  // Anews Labs SFUC Bad Reaction Comment
  trackReactionBadCommentSFUC: function (gtag: VueGtag, label: string) {
    const teamId = userSession.getTeamId();
    gtag.event('reaction_bad_comment', {
      event_category: 'labs.sfuc',
      event_label: label,
      team_id: teamId,
    });
  },
  // Anews Labs SFUC 公開情報で調べる
  trackClickSFUCOpenAsAnews: function (gtag: VueGtag, label: string) {
    const teamId = userSession.getTeamId();
    gtag.event('open_as_anews', {
      event_category: 'labs.sfuc',
      event_label: label,
      team_id: teamId,
    });
  },
  // Anews Labs SFUC 社内情報で調べる
  trackClickSFUCOpenAsAnewsUserDocumentSearch: function (
    gtag: VueGtag,
    label: string,
  ) {
    const teamId = userSession.getTeamId();
    gtag.event('open_as_anews_user_document_search', {
      event_category: 'labs.sfuc',
      event_label: label,
      team_id: teamId,
    });
  },
  // Anews Labs SFUC 市場構造を調べる
  trackClickSFUCOpenAsAstrategyRelationNet: function (
    gtag: VueGtag,
    label: string,
  ) {
    const teamId = userSession.getTeamId();
    gtag.event('open_as_astrategy_relation_net', {
      event_category: 'labs.sfuc',
      event_label: label,
      team_id: teamId,
    });
  },
  // Anews Labs SFUC 事例を見る
  trackClickSFUCOpenAsAstrategyCaseExample: function (
    gtag: VueGtag,
    label: string,
  ) {
    const teamId = userSession.getTeamId();
    gtag.event('open_as_astrategy_case_examples', {
      event_category: 'labs.sfuc',
      event_label: label,
      team_id: teamId,
    });
  },
  // Anews Labs AIに聞く
  trackAskAi: function (gtag: VueGtag, question: string) {
    const teamId = userSession.getTeamId();
    gtag.event('askai', {
      event_category: 'labs.aiagent.editoroption',
      event_label: question.slice(0, 100),
      team_id: teamId,
    });
  },
  // Anews Labs AIと探す
  trackSearchWithAi: function (gtag: VueGtag, question: string) {
    const teamId = userSession.getTeamId();
    gtag.event('search', {
      event_category: 'labs.aiagent.editoroption',
      event_label: question.slice(0, 100),
      team_id: teamId,
    });
  },
  // Anews Labs 要約する
  trackSummarize: function (gtag: VueGtag, text: string) {
    const teamId = userSession.getTeamId();
    gtag.event('summarize', {
      event_category: 'labs.aiagent.editoroption',
      event_label: text.slice(0, 100),
      team_id: teamId,
    });
  },
  // Labs API 実行
  trackRequestDemoAPI: function (gtag: VueGtag, apiName: string) {
    const teamId = userSession.getTeamId();
    gtag.event('requestapi', {
      event_category: 'labs.apidemo',
      event_label: apiName,
      team_id: teamId,
    });
  },
};
