<script setup lang="ts">
import { computed, onMounted, ref, UnwrapRef, watch } from 'vue';
import { Ref } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import api from '@/api';
import { DgrIcon, DgrLoading, DgrPopover } from '@stockmarkteam/donguri-ui';
import SwrScrollingPagination from '@/components/common/swr-scrolling-pagination.vue';
import AddGroupTagModal from '@/components/group/tags/add-group-tag-modal.vue';
import EditGroupTagModal from '@/components/group/tags/edit-group-tag-modal.vue';
import Content from '@/components/layouts/content.vue';
import Header from '@/components/layouts/header.vue';
import {
  GroupTag,
  GroupTagSortAttribute,
  GroupTagSortOrder,
  GroupTagSortTypes,
  GroupTagWithCount,
} from '@/types';
import { getTabs } from '@/utils/group';
import {
  STATES,
  useGroups,
  useGroupTagItems,
  useGroupTagTotalCount,
  useUserInfo,
} from '@/utils/swr';
import { useEmitter } from '@/utils/vue';
import { useSnackbar } from '../common/snackbar/use-snackbar';
import DeleteGroupTagModal from './tags/delete-group-tag-modal.vue';

const isOpenCreateGroupTagModal = ref(false);
const isOpenEditGroupTagModal = ref(false);
const isOpenDeleteGroupTagModal = ref(false);

const modifiableGroupTag: Ref<{ id: number; name: string } | undefined> =
  ref(undefined);

const { createSnackbar } = useSnackbar();
const emitter = useEmitter();
const router = useRouter();
const { data: groups, state: groupLoadingState } = useGroups();

const route = useRoute();
const { data: myInfo } = useUserInfo();
const groupId = computed(() => {
  if (
    route.params.groupId instanceof Array &&
    route.params.groupId[0] !== undefined
  ) {
    return Number(route.params.groupId[0]);
  }
  if (route.params.groupId !== undefined) {
    return Number(route.params.groupId);
  }
  return undefined;
});

const sortAttribute = ref<GroupTagSortAttribute | undefined>(undefined);
const sortOrder = ref<GroupTagSortOrder | undefined>(undefined);

const sortType = computed<GroupTagSortTypes | undefined>(() => {
  if (sortAttribute.value === undefined || sortOrder.value === undefined) {
    return undefined;
  }
  return `${sortAttribute.value}_${sortOrder.value}`;
});

const {
  data: groupTagTotalCount,
  state: groupTagTotalCountLoadingState,
  mutate: groupTagTotalCountMutate,
} = useGroupTagTotalCount(groupId);

const openCreateTagModal = () => {
  isOpenCreateGroupTagModal.value = true;
};
const closeCreateTagModal = () => {
  isOpenCreateGroupTagModal.value = false;
};

const openEditTagModal = ({
  groupTagId,
  tagName,
}: {
  groupTagId: number;
  tagName: string;
}) => {
  modifiableGroupTag.value = { id: groupTagId, name: tagName };
  isOpenEditGroupTagModal.value = true;
};
const closeEditTagModal = () => {
  isOpenEditGroupTagModal.value = false;
};

const openDeleteTagModal = ({
  groupTagId,
  tagName,
}: {
  groupTagId: number;
  tagName: string;
}) => {
  modifiableGroupTag.value = { id: groupTagId, name: tagName };
  isOpenDeleteGroupTagModal.value = true;
};
const closeDeleteTagModal = () => {
  isOpenDeleteGroupTagModal.value = false;
};

const submitCreateTagModal = ({ groupTag }: { groupTag: GroupTag }) => {
  emitter.emit('pagination-item-create', {
    ...groupTag,
    total_count: 0,
  });
  closeCreateTagModal();
  groupTagTotalCountMutate();
};
const submitDeleteTagModal = ({ groupTagId }: { groupTagId: number }) => {
  emitter.emit('pagination-item-delete', (items: GroupTagWithCount[]) => {
    const index = items.findIndex(c => c.id === groupTagId);
    if (index >= 0) {
      items.splice(index, 1);
    }
  });
  groupTagTotalCountMutate();
  closeDeleteTagModal();
};
const submitEditTagModal = ({
  groupTagId,
  tagName,
}: {
  groupTagId: number;
  tagName: string;
}) => {
  emitter.emit('pagination-items-update', {
    filterFunc: (_: GroupTagWithCount) => true,
    updateFunc: (groupTags: GroupTagWithCount[]) => {
      groupTags.forEach(tag => {
        if (tag.id === groupTagId) {
          tag.name = tagName;
        }
      });
    },
  });
  closeEditTagModal();
};

const group = computed(() =>
  groups.value?.groups.find(g => g.id === groupId.value),
);

const sortItems = (attribute: GroupTagSortAttribute) => {
  if (sortAttribute.value === attribute) {
    sortOrder.value = sortOrder.value === 'asc' ? 'desc' : 'asc';
  } else {
    sortAttribute.value = attribute;
    sortOrder.value = 'asc';
  }
};

watch([() => groupId.value, () => group.value], () => {
  validateGroup();
});
onMounted(() => {
  validateGroup();
  api.trackPageView({
    pageName: 'group_tags',
    pageUrl: route.path,
  });
});

const validateGroup = () => {
  // グループから他のページに移動したらバリデーションが必要ない
  if (groupId.value === undefined) return false;

  if (groupLoadingState.value === STATES.SUCCESS && group.value === undefined) {
    createSnackbar({
      message: '指定されたグループは存在しません',
      type: 'error',
    });
    router.replace({ name: 'anewsHome' });
    return false;
  }
  return true;
};

const pageLimit = 50;

const paginationFunc = (pageRef: Ref<number>, pageLimit: number) =>
  useGroupTagItems(groupId, sortType, pageRef, pageLimit);

const dataAccessor = (
  data: UnwrapRef<ReturnType<typeof useGroupTagItems>['data']>,
) => data;
</script>

<template>
  <div class="group-tag-list" v-if="group">
    <Header
      v-if="myInfo"
      :title="group.name"
      :detail="`メンバー${group.member_count}人`"
      :tabs="getTabs(group, myInfo)"
    >
      <template #button><GroupActionButtons :group="group" /></template>
    </Header>
    <Content>
      <div class="tag-list-content-header">
        <div class="title">
          <span class="c-title c-title--m">グループ</span>
          <span
            v-if="
              groupTagTotalCountLoadingState === STATES.PENDING ||
              groupTagTotalCountLoadingState === STATES.VALIDATING ||
              groupTagTotalCount?.group_tag_count === undefined
            "
            class="count c-text c-text--s"
            >・- 件</span
          >
          <span v-else class="count c-text c-text--s"
            >・{{ groupTagTotalCount.group_tag_count }}件</span
          >
        </div>
        <div>
          <button
            class="group-tag-add-button o-create-button c-btn c-btn--auto c-btn--AnewsPrimary"
            @click="openCreateTagModal"
          >
            作成
          </button>
        </div>
      </div>
      <div class="group-tag-list-content">
        <SwrScrollingPagination
          :key="`${groupId}-${sortType}`"
          :page-limit="pageLimit"
          :pagination-func="paginationFunc"
          :data-accessor="dataAccessor"
          scroll-target="self"
          class="table-container"
        >
          <template v-slot="{ items, loaded }">
            <table class="table">
              <thead>
                <tr class="table-header-row">
                  <th
                    class="table-header-cell ta-l c-text c-text--s sortable-table-header"
                    @click="sortItems('name')"
                  >
                    <span>グループタグ名</span>
                    <span v-if="sortType === 'name_asc'"> ↑ </span>
                    <span v-else-if="sortType === 'name_desc'"> ↓ </span>
                  </th>
                  <th
                    width="80"
                    class="table-header-cell ta-l c-text c-text--s sortable-table-header"
                    @click="sortItems('article_count')"
                  >
                    <span>記事数</span>
                    <span v-if="sortType === 'article_count_asc'"> ↑ </span>
                    <span v-else-if="sortType === 'article_count_desc'">
                      ↓
                    </span>
                  </th>
                  <th width="80" class="table-header-cell"></th>
                </tr>
              </thead>
              <tbody v-if="!loaded">
                <tr>
                  <td colspan="3">
                    <div class="loading-container">
                      <DgrLoading />
                    </div>
                  </td>
                </tr>
              </tbody>
              <tbody v-else-if="items.length === 0">
                <tr>
                  <td colspan="3">
                    <div class="empty-text-container">
                      <span class="c-text c-text--m"
                        >グループタグがありません</span
                      >
                    </div>
                  </td>
                </tr>
              </tbody>
              <tbody v-else>
                <tr v-for="groupTagItem in items" :key="groupTagItem.id">
                  <td>
                    <div class="cell-inner-block group-tag-name c-text--m">
                      <span>{{ groupTagItem.name }}</span>
                    </div>
                  </td>
                  <td>
                    <div
                      class="cell-inner-block group-tag-count-text c-text--m"
                    >
                      <span>{{ groupTagItem.total_count }}</span>
                    </div>
                  </td>
                  <td>
                    <div
                      class="cell-inner-block group-tag-action-button-container"
                    >
                      <DgrPopover>
                        <template #default="{ triggerProps }">
                          <button class="action-button" v-bind="triggerProps">
                            <DgrIcon name="ellipsis-h" />
                          </button>
                        </template>
                        <template #content="{ close }">
                          <div class="item-container">
                            <button
                              class="item c-text c-text--m"
                              @click="
                                close();
                                openEditTagModal({
                                  groupTagId: groupTagItem.id,
                                  tagName: groupTagItem.name,
                                });
                              "
                            >
                              <DgrIcon
                                size="small"
                                :name="'pencil'"
                                class="icon small"
                              />
                              編集
                            </button>
                            <div class="separator"></div>
                            <button
                              class="item c-text c-text--m"
                              @click="
                                close();
                                openDeleteTagModal({
                                  groupTagId: groupTagItem.id,
                                  tagName: groupTagItem.name,
                                });
                              "
                            >
                              <DgrIcon
                                size="small"
                                :name="'trash'"
                                class="icon small"
                              />削除
                            </button>
                          </div>
                        </template>
                      </DgrPopover>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </template>
        </SwrScrollingPagination>
      </div>
    </Content>
    <AddGroupTagModal
      v-if="groupId"
      :is-open="isOpenCreateGroupTagModal"
      :group-id="groupId"
      @close="closeCreateTagModal"
      @submit="submitCreateTagModal"
    />
    <EditGroupTagModal
      v-if="modifiableGroupTag"
      :is-open="isOpenEditGroupTagModal"
      :group-tag-id="modifiableGroupTag.id"
      :group-tag-name="modifiableGroupTag.name"
      @close="closeEditTagModal"
      @submit="submitEditTagModal"
    />
    <DeleteGroupTagModal
      v-if="modifiableGroupTag"
      :is-open="isOpenDeleteGroupTagModal"
      :group-tag-id="modifiableGroupTag.id"
      :group-tag-name="modifiableGroupTag.name"
      @close="closeDeleteTagModal"
      @submit="submitDeleteTagModal"
    />
  </div>
</template>

<style lang="scss" scoped>
.group-tag-list {
  width: 100%;
  margin: -24px 0 0 0;
}

.c-btn {
  height: fit-content;
  padding: 6px 16px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.actions {
  width: 40px;
  padding: 0;
  border: none;
  border-radius: 4px;
  background: none;

  &:hover {
    background: $color-gray200;
  }
}
.count {
  color: $color-gray600;
}

.tag-list-content-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 21px;
}

.admin-base-table {
  max-height: 100%;
}

.table-container {
  border-radius: 4px;
  background-color: #fff;
  border: 1px solid $color-border;
  padding: 0 16px;
  overflow-y: auto;
  max-height: calc(100vh - 280px);
}

.table {
  width: 100%;
  border-spacing: 0 0;

  tr:first-child {
    td {
      padding-top: 6px;
    }
  }
  tr:last-child {
    td {
      padding-bottom: 16px;
    }
  }
  td,
  th {
    height: 40px;
  }

  thead {
    background-color: #fff;
    position: sticky;
    top: 0;
    z-index: 1;
  }
}

.cell-inner-block {
  display: flex;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
  border-bottom: 1px solid $color-border;
}

.group-tag-count-text {
  padding-right: 12px;
  justify-content: flex-end;
}

.group-tag-action-button-container {
  justify-content: flex-end;
}

.group-tag-name {
  padding-left: 8px;
  justify-content: flex-start;
}

.action-button {
  display: flex;
  justify-content: center;
  width: 32px;
  height: 32px;
  padding: 8px;
  border-radius: 4px;
  box-sizing: border-box;
  background-color: inherit;
  border: none;

  &:hover {
    border: none;
    background-color: $color-gray200;
  }
}

.table-header-cell {
  text-indent: 8px;
  padding: 12px 0;
  border-bottom: 1px solid $color-border;
}

.sortable-table-header {
  cursor: pointer;
  &:hover {
    background-color: $color-gray200;
  }
}

.ta-l {
  text-align: left;
}
.ta-r {
  text-align: right;
}

.loading-container {
  width: 100%;
  display: flex;
  justify-content: center;
}

.item-container {
  padding: 0;

  .item {
    display: flex;
    align-items: center;
    width: 100%;
    border: none;
    background: none;

    &:hover {
      background: $color-gray400;
    }
    .icon {
      margin-right: 4px;
    }
  }
  .separator {
    border-top: 1px solid $color-gray400;
  }
}
.group-tag-add-button {
  border: none;
}
.min-cell {
  width: 80px;
}
.empty-text-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 65px;
  color: $color-gray600;
}
</style>
