<script setup lang="ts">
import {
  computed,
  onUnmounted,
  provide,
  Ref,
  ref,
  toRefs,
  UnwrapRef,
  watch,
  WatchStopHandle,
} from 'vue';
import { useRoute } from 'vue-router';
import api from '@/api';
import { ContentsContext } from '@/api/tracking';
import {
  DgrIcon,
  DgrPopover,
  DgrSelectbox,
  SelectboxItem,
} from '@stockmarkteam/donguri-ui';
import AdpDocumentCard from '@/components/common/adp-document-card.vue';
import SwrScrollingPagination from '@/components/common/swr-scrolling-pagination.vue';
import { FollowListMarkSortType } from '@/types';
import { Pagination } from '@/utils';
import { adpDocumentCardWrapperRefKey } from '@/utils/injectionKeys';
import {
  useFollowList,
  useFollowListMarkedArticles,
  useUserSettings,
} from '@/utils/swr';
import DeleteConfirmationDialog from './delete-confirmation-dialog.vue';
import EmptyMessage from './empty-message.vue';
import MypageScopeAccessMessage from './mypage-scope-access-message.vue';
import SkeletonCard from './skeleton-card.vue';

const PAGE_LIMIT = 10;
const RECOMMENDED_MARKS_COUNT = 100;

interface Props {
  followListId: number;
  isVisibleComment: boolean;
}
const props = defineProps<Props>();
const { followListId, isVisibleComment } = toRefs(props);

type Emits = {
  delete: [];
};
const emit = defineEmits<Emits>();

const sortOptions: SelectboxItem<FollowListMarkSortType>[] = [
  { label: 'おすすめ順', value: 'recommended' },
  { label: '新着順', value: 'latest' },
];

const { data: followList, mutate } = useFollowList(followListId);

const userId = computed(() =>
  followList.value?.resources[0].type === 'user'
    ? followList.value?.resources[0].id
    : undefined,
);
const { data: userSettings } = useUserSettings(userId);

const sort = ref(followList.value?.sort);
if (!sort.value) {
  let unwatch: WatchStopHandle;
  unwatch = watch(followList, value => {
    if (value?.sort) {
      sort.value = value.sort;
      unwatch();
    }
  });
}

const sortPlaceholder = '　　　'; // ロード中に「新着順」ぐらいの幅を確保する

watch(sort, async newSort => {
  if (!newSort) return;
  await api.updateFollowListSort(followListId.value, newSort);
  await mutate();
});

// MEMO: フォローリスト Phase 1では、フォローリストあたりの
// リソースは1つで、その名前をそのままリストのタイトルとする
const title = computed(() =>
  followList.value?.resources[0].type === 'team'
    ? 'すべてのマーク'
    : followList.value?.resources[0].name,
);

// MEMO: フォローリスト Phase 1では、
// リソースは1つで、そのタイプがteamのときは削除できないようにする
const followListType = computed(() => followList.value?.resources[0].type);

const route = useRoute();
// NOTE: 計測ログで使用する際に定数で保持しないとタイミング次第では変更されてしまうため、定数で保持している
const currentPath = route.path;

// 新着順
const getLatestMarksWithPagination = (
  pageRef: Ref<number>,
  pageLimit: number,
) => {
  return useFollowListMarkedArticles(
    followListId,
    ref('latest'),
    pageRef,
    ref(pageLimit),
    {
      page: {
        name: 'follow_list',
        url: currentPath,
      },
    },
  );
};
const latestMarksDataAccessor = (
  data: UnwrapRef<ReturnType<typeof useFollowListMarkedArticles>['data']>,
) => data?.marked_articles ?? [];

// レコメンド順は一括で取得するが、その場合初期表示が遅くなるので
// Paginationを使ってスクロールに応じて記事を表示するようにする

const recommendedSort = computed(() =>
  sort.value === 'recommended' ? 'recommended' : undefined,
);

const { data: recommendedMarksData, state: recommendedMarksState } =
  useFollowListMarkedArticles(
    followListId,
    recommendedSort,
    ref(1),
    ref(RECOMMENDED_MARKS_COUNT),
    {
      page: {
        name: 'follow_list',
        url: currentPath,
      },
    },
  );

let pagination: Pagination | undefined = undefined;
const scrollerRef = ref<HTMLElement>();
const showingIndex = ref(PAGE_LIMIT);
watch(
  scrollerRef,
  () => {
    pagination?.removeEvent();

    if (!scrollerRef.value) return;

    showingIndex.value = PAGE_LIMIT;
    pagination = new Pagination(
      (page, _limit) => {
        showingIndex.value = page * PAGE_LIMIT;
      },
      RECOMMENDED_MARKS_COUNT,
      {
        element: scrollerRef.value,
      },
    );
  },
  { immediate: true },
);
onUnmounted(() => {
  pagination?.removeEvent();
});

const recommendedMarks = computed(() => {
  if (!recommendedMarksData.value) return [];
  return recommendedMarksData.value.marked_articles.slice(
    0,
    showingIndex.value,
  );
});

const isRecommendedMarksLoading = computed(() => {
  const state = recommendedMarksState.value;
  return (
    (state === 'PENDING' || state === 'VALIDATING') &&
    recommendedMarks.value.length === 0
  );
});

const isConfirmationDialogShown = ref(false);
const showConfirmationDialog = () => {
  isConfirmationDialogShown.value = true;
};
const cancelDelete = () => {
  isConfirmationDialogShown.value = false;
};
const confirmDelete = async () => {
  isConfirmationDialogShown.value = false;
  emit('delete');
  if (!followList.value) return;
  await api.trackingFollowListEvent('follow_list_remove', {
    page: {
      name: 'follow_list',
      url: currentPath,
    },
    follow_list: {
      id: followList.value.id,
      type: followList.value.resources[0].type,
      ...(followList.value.resources[0].type === 'organization_tag'
        ? { organization_tag: { id: followList.value.resources[0].id } }
        : {}),
      ...(followList.value.resources[0].type === 'user'
        ? // NOTE: 現状では1名しか入らないが、後続で複数人追加の可能性があるため配列で対応している
          { users: [{ id: followList.value.resources[0].id }] }
        : {}),
      order: followList.value.order,
      sort: followList.value.sort === 'recommended' ? 'おすすめ順' : '新着順',
    },
  });
};

const contentsContext = computed<ContentsContext>(() => {
  if (!followList.value) throw new Error('followList is not defined');
  return {
    event_name: 'follow_list_view',
    follow_list: {
      id: followList.value.id,
      type: followList.value.resources[0].type,
      ...(followList.value.resources[0].type === 'organization_tag'
        ? { organization_tag: { id: followList.value.resources[0].id } }
        : {}),
      ...(followList.value.resources[0].type === 'user'
        ? // NOTE: 現状では1名しか入らないが、後続で複数人追加の可能性があるため配列で対応している
          { users: [{ id: followList.value.resources[0].id }] }
        : {}),
      order: followList.value?.order,
      sort: followList.value?.sort === 'recommended' ? 'おすすめ順' : '新着順',
    },
  };
});

const wrapper = ref<HTMLElement | null>(null);
provide(adpDocumentCardWrapperRefKey, wrapper);
</script>

<template>
  <div class="follow-list">
    <div class="header c-title c-title--m">
      <DgrIcon size="small" name="grip-vertical" class="grip drag-icon" />
      {{ title }}
    </div>
    <div class="content" ref="wrapper">
      <div class="option">
        <DgrSelectbox
          :placeholder="sortPlaceholder"
          :options="sortOptions"
          v-model="sort"
          size="small"
        />
        <DgrPopover v-if="followListType !== 'team'">
          <template #default="{ triggerProps }">
            <button
              v-bind="triggerProps"
              :aria-label="`フォローリスト${title}のメニュー`"
              class="menu-button"
              :disabled="followList === undefined"
            >
              <DgrIcon size="small" name="sliders-h" />
            </button>
          </template>
          <template #content="{ close }">
            <div class="menu-list c-text c-text--m">
              <div
                class="menu-list-item"
                @click="
                  showConfirmationDialog();
                  close();
                "
              >
                <DgrIcon size="small" name="trash" />
                このリストを削除
              </div>
            </div>
          </template>
        </DgrPopover>
      </div>

      <div v-if="sort === undefined" class="skeletons scroller">
        <SkeletonCard v-for="i in 5" :key="i" />
      </div>
      <SwrScrollingPagination
        v-if="sort === 'latest'"
        :page-limit="PAGE_LIMIT"
        :pagination-func="getLatestMarksWithPagination"
        :data-accessor="latestMarksDataAccessor"
        scroll-target="self"
        class="scroller"
      >
        <template v-slot="{ items: articles, loaded }">
          <div v-if="!loaded" class="skeletons">
            <SkeletonCard v-for="i in 5" :key="i" />
          </div>
          <MypageScopeAccessMessage
            v-else-if="userSettings?.mypage_access_scope === 'personal'"
          />
          <EmptyMessage v-else-if="articles.length === 0" />
          <template v-else>
            <AdpDocumentCard
              v-for="article in articles"
              :key="article.id"
              :adp-document="article"
              :show-comment="isVisibleComment"
              :is-narrow="true"
              page-name="follow_list"
              width="100%"
              feature="follow_list"
              :contents-context="contentsContext"
            />
          </template>
        </template>
      </SwrScrollingPagination>
      <div
        v-else-if="sort === 'recommended'"
        class="scroller"
        ref="scrollerRef"
      >
        <div v-if="isRecommendedMarksLoading" class="skeletons">
          <SkeletonCard v-for="i in 5" :key="i" />
        </div>
        <MypageScopeAccessMessage
          v-else-if="userSettings?.mypage_access_scope === 'personal'"
        />
        <EmptyMessage v-else-if="recommendedMarks.length === 0" />
        <template v-else>
          <AdpDocumentCard
            v-for="article in recommendedMarks"
            :key="article.id"
            :adp-document="article"
            :show-comment="isVisibleComment"
            :is-narrow="true"
            page-name="follow_list"
            width="100%"
            feature="follow_list"
            :contents-context="contentsContext"
          ></AdpDocumentCard>
        </template>
      </div>
    </div>
  </div>
  <Teleport to="body" v-if="isConfirmationDialogShown">
    <DeleteConfirmationDialog
      :name="title ?? ''"
      @cancel="cancelDelete"
      @confirm="confirmDelete"
    />
  </Teleport>
</template>

<style lang="scss" scoped>
.follow-list {
  width: 320px;
  height: 100%;
  display: flex;
  flex-direction: column;
  gap: 4px;

  .header {
    display: flex;
    align-items: center;
    gap: 8px;
    border-radius: 4px;
    padding: 8px;
    background: white;

    .grip {
      fill: $color-gray400;
      cursor: grab;
    }
  }

  .content {
    display: flex;
    flex-direction: column;
    min-height: 0;
    height: 100%;
    border-radius: 4px;
    background: white;

    .option {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      gap: 12px;
      padding: 8px 16px;

      .menu-button {
        position: relative;
        width: 32px;
        height: 32px;
        padding: 8px;
        border: none;
        border-radius: 4px;
        background-color: white;

        &:hover {
          background: $color-gray200;
        }
      }
    }

    .scroller {
      padding: 0 16px 8px;
      overflow: auto;
      flex-grow: 1;
    }

    .document-card {
      padding: 16px 0 0 0;
      border: 0;
      border-top: 1px solid $color-gray400;
    }
  }
}

.menu-list {
  padding: 12px 0;
}
.menu-list-item {
  display: flex;
  width: max-content;
  align-items: center;
  gap: 8px;
  padding: 8px;
  cursor: pointer;

  &:hover {
    background: $color-gray200;
  }
}

.skeletons {
  display: flex;
  flex-direction: column;
  gap: 8px;

  > * {
    border-top: 1px solid $color-gray400;
    padding-top: 16px;
  }
}
</style>
