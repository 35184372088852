import { UNEXPECTED_CONTENT_TYPE_ERROR } from '@/constants';
import axios from 'axios';
import * as admin from './admin';
import * as articleExclusion from './articleExclusion';
import * as articleShare from './articleShare';
import * as comment from './comment';
import * as document from './document';
import * as followList from './followList';
import * as group from './group';
import * as groupMark from './groupMark';
import * as keywordRecommendation from './keywordRecommendation';
import * as lab from './lab';
import * as mark from './mark';
import * as organizationTag from './organizationTag';
import * as relatedArticle from './relatedArticle';
import * as reportSearch from './reportSearch';
import * as search from './search';
import * as site from './site';
import * as summary from './summary';
import * as team from './team';
import * as teamSearch from './teamSearch';
import * as theme from './theme';
import * as tracking from './tracking';
import * as user from './user';
import * as webPush from './webPush';

axios.defaults.baseURL = '/api/v1/';
axios.defaults.timeout = 35000;
axios.interceptors.response.use(
  function (response) {
    // ユーザー環境によっては、社内プロキシなどの認証が切れたときに200で
    // エラー画面が返されることがある。Content-TypeがJSONではなくなるので
    // ここで拾って、pcApp.tsでエラー表示とロギングを行う。
    const contentType = response.headers['content-type'];
    if (
      response.status === 200 &&
      !/(text|application)\/json/.test(contentType)
    ) {
      return Promise.reject(new Error(UNEXPECTED_CONTENT_TYPE_ERROR));
    }

    return response;
  },
  function (error) {
    return Promise.reject(error);
  },
);

// 新しくモジュールを追加したときは tests/api/index.spec.tsにも追加してください
// APIの重複チェックなどをしてます
const api = {
  ...admin,
  ...articleExclusion,
  ...articleShare,
  ...comment,
  ...document,
  ...followList,
  ...group,
  ...groupMark,
  ...keywordRecommendation,
  ...mark,
  ...organizationTag,
  ...relatedArticle,
  ...reportSearch,
  ...search,
  ...site,
  ...summary,
  ...team,
  ...teamSearch,
  ...theme,
  ...tracking,
  ...user,
  ...webPush,
  ...lab,
} as const;
export default api;
