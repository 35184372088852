import {
  CitationSource,
  Part,
  SearchResultResponse,
  SummaryCitation,
  SummaryMessage,
  UserDocumentSearchResultResponse,
} from './types';

type LegacySearchSummaryCache = {
  responses: (
    | SummaryMessage
    | { type: 'citations'; citations: SummaryCitation[] }
  )[];
  citationSources: CitationSource[];
  requestId: string | undefined;
};

type SearchSummaryCache = {
  contents: Part[];
  citationSources: CitationSource[];
  requestId: string | undefined;
};

class APICache<T> {
  readonly prefix: string;
  readonly cacheHours: number;

  constructor(prefix: string, cacheHours = 1) {
    this.prefix = prefix;
    this.cacheHours = cacheHours;
  }

  getCachedValue(key: string): { data: T | null; timestamp: number | null } {
    const cacheKey = `${this.prefix}.${key}`;
    const stringData = sessionStorage.getItem(cacheKey);
    const data = stringData
      ? JSON.parse(stringData)
      : { data: null, timestamp: null };
    return data;
  }

  getCache(key: string): T | null {
    if (import.meta.env.VITE_DISABLE_SEARCH_CACHE === 'true') return null;
    const { data, timestamp } = this.getCachedValue(key);
    const now = Date.now();
    if (timestamp && now - timestamp < this.cacheHours * 60 * 60 * 1000) {
      return data as T;
    } else if (timestamp) {
      sessionStorage.removeItem(key);
    }
    return null;
  }

  setCache(key: string, data: T): void {
    if (import.meta.env.VITE_DISABLE_SEARCH_CACHE === 'true') return;
    const value = {
      timestamp: Date.now(),
      data,
    };
    const cacheKey = `${this.prefix}.${key}`;
    try {
      sessionStorage.setItem(cacheKey, JSON.stringify(value));
    } catch (e) {
      if (e instanceof DOMException && e.name === 'QuotaExceededError') {
        // NOTE: キャッシュが溢れた場合は、古いものから削除してから再度保存するリトライ処理を行う
        this.expireOldSearchCache();
        try {
          sessionStorage.setItem(cacheKey, JSON.stringify(value));
        } catch (_e) {
          // eslint-disable-next-line no-console
          console.error('Search cache exceeded quota');
        }
      } else {
        throw e;
      }
    }
  }

  updateCache(key: string, updatedData: T): void {
    if (import.meta.env.VITE_DISABLE_SEARCH_CACHE === 'true') return;
    const cacheKey = `${this.prefix}.${key}`;
    const { timestamp } = this.getCachedValue(key);
    if (!timestamp) return;

    const updatedValue = {
      timestamp,
      data: updatedData,
    };
    sessionStorage.setItem(cacheKey, JSON.stringify(updatedValue));
  }

  expireOldSearchCache(): void {
    const searchCacheKeys = this.getSearchCacheKeys().sort((a, b) => {
      const aValue =
        JSON.parse(sessionStorage.getItem(a) || '{}').timestamp || 0;
      const bValue =
        JSON.parse(sessionStorage.getItem(b) || '{}').timestamp || 0;
      return aValue - bValue;
    });

    // "SearchCache"で始まるキーのセッションストレージの削除する数は、一旦半分で様子見
    const numberToRemove = Math.ceil(searchCacheKeys.length / 2);
    for (let i = 0; i < numberToRemove; i++) {
      sessionStorage.removeItem(searchCacheKeys[i]);
    }
  }

  getSearchCacheKeys(): string[] {
    return Object.keys(sessionStorage).filter(key =>
      key.startsWith(this.prefix),
    );
  }

  cleanup(): void {
    const searchCacheKeys = this.getSearchCacheKeys();
    for (const key of searchCacheKeys) {
      sessionStorage.removeItem(key);
    }
  }
}

export const searchCache = new APICache<SearchResultResponse>('SearchCache');
export const userDocumentSearchCache =
  new APICache<UserDocumentSearchResultResponse>('UserDocumentSearchCache');
export const searchSummaryCache = new APICache<SearchSummaryCache>(
  'SearchSummaryCache',
);
export const legacySearchSummaryCache = new APICache<LegacySearchSummaryCache>(
  'LegacySearchSummaryCache',
);
