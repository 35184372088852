import axios from 'axios';
import dayjs from 'dayjs';
import Qs from 'qs';
import {
  Article,
  Comment,
  DocType,
  Group,
  GroupTag,
  GroupUser,
  Lang,
  MSTeamsSettings,
  Tag,
} from '@/types';
import { userSession } from '@/utils/userSession';

export async function fetchGroupActivityComments(
  groupId: number | undefined,
  pageRef: number,
  pageLimit = 1,
  tags: string[],
  abortController: AbortController,
  existsMemo?: boolean,
  fromDate?: Date,
  toDate?: Date,
  lang?: Lang[],
  docTypes?: DocType[],
  query?: string,
  newsCategories?: string[],
): Promise<{ comments: Comment[]; totalCount: number }> {
  if (groupId === undefined) return { comments: [], totalCount: 0 };

  const params = {
    page: pageRef,
    limit: pageLimit,
    order: 'desc',
    is_activity_feed: true,
    exists_memo: existsMemo,
    tags: tags.length > 0 ? tags : undefined,
    from_date: fromDate ? dayjs(fromDate).format('YYYY-MM-DD') : undefined,
    to_date: toDate ? dayjs(toDate).format('YYYY-MM-DD') : undefined,
    lang: lang,
    doc_types: docTypes,
    query: query,
    news_categories: newsCategories,
  };
  try {
    const { data } = await axios.get<{
      group_comments: Comment[];
      total_count: number;
    }>(
      `/groups/${groupId}/activity_comments?${Qs.stringify(params, {
        arrayFormat: 'brackets',
      })}`,
      { signal: abortController.signal },
    );
    return { comments: data.group_comments, totalCount: data.total_count };
  } catch (e) {
    if (axios.isCancel(e)) return { comments: [], totalCount: 0 };
    throw e;
  }
}

export async function fetchGroupMarks(
  groupId: number | undefined,
  pageRef: number,
  pageLimit = 1,
  tags: string[],
  abortController: AbortController,
  memoExists?: boolean,
  fromDate?: Date,
  toDate?: Date,
  lang?: Lang[],
  docTypes?: DocType[],
  query?: string,
  newsCategories?: string[],
): Promise<{ articles: Article[]; totalCount: number }> {
  if (groupId === undefined) return { articles: [], totalCount: 0 };

  const params = {
    page: pageRef,
    limit: pageLimit,
    order: 'desc',
    memo_exists: memoExists,
    include_technical_literature: true,
    tags: tags.length > 0 ? tags : undefined,
    filter_target_group_comment: true,
    from_date: fromDate ? dayjs(fromDate).format('YYYY-MM-DD') : undefined,
    to_date: toDate ? dayjs(toDate).format('YYYY-MM-DD') : undefined,
    lang: lang,
    doc_types: docTypes,
    query: query,
    news_categories: newsCategories,
  };
  try {
    const { data } = await axios.get<{
      marked_articles: Article[];
      total_count: number;
    }>(
      `/groups/${groupId}/marked_articles?${Qs.stringify(params, {
        arrayFormat: 'brackets',
      })}`,
      { signal: abortController.signal },
    );
    return {
      articles: data.marked_articles,
      totalCount: data.total_count,
    };
  } catch (e) {
    if (axios.isCancel(e)) return { articles: [], totalCount: 0 };
    throw e;
  }
}

export async function updateGroupMSTeamsSettings(
  groupId: number,
  settings: MSTeamsSettings,
): Promise<void> {
  await axios.put<void>(`/groups/${groupId}/ms_teams_settings`, settings);
}

export async function fetchGroupMSTeamsSettings(
  groupId: number,
): Promise<MSTeamsSettings> {
  const { data } = await axios.get<MSTeamsSettings>(
    `/groups/${groupId}/ms_teams_settings`,
  );
  return data;
}

export async function createGroup(
  name: string,
  description: string,
): Promise<void> {
  await axios.post<void>(`/groups`, { name, description });
}

export async function fetchGroup(groupId: number): Promise<Group> {
  const { data } = await axios.get<Group>(`/groups/${groupId}`);
  return data;
}

export async function updateGroup(
  groupId: number,
  name: string,
  description: string,
  enabledOverlookedMail: boolean,
): Promise<void> {
  await axios.put<void>(`/groups/${groupId}`, {
    name,
    description,
    enabled_overlooked_mail: enabledOverlookedMail,
  });
}

export async function deleteGroup(groupId: number): Promise<void> {
  await axios.delete<void>(`/groups/${groupId}`);
}

export async function fetchGroupMembers(groupId: number): Promise<GroupUser[]> {
  const { data } = await axios.get<{ group_users: GroupUser[] }>(
    `/groups/${groupId}/users`,
  );
  return data.group_users;
}

export async function joinGroup(groupId: number): Promise<void> {
  const myUserId = userSession.getUserId();
  await axios.post<void>(`/groups/${groupId}/users/${myUserId}`);
}

export async function leaveGroup(
  groupId: number,
  userId?: number,
): Promise<void> {
  const myUserId = userId ?? userSession.getUserId();
  await axios.delete<void>(`/groups/${groupId}/users/${myUserId}`);
}

export async function createGroupInvitation(
  groupId: number,
  userIds: number[],
): Promise<void> {
  await axios.post<void>(`/groups/${groupId}/invitations`, {
    user_ids: userIds,
  });
}

export async function updateGroupArticleMemo(
  groupId: number,
  articleId: number,
  docType: DocType,
  memo: string,
): Promise<void> {
  await axios.put<void>(`/groups/${groupId}/articles/${articleId}/memos`, {
    content: memo,
    doc_type: docType ?? 'article',
  });
}

export async function deleteGroupArticleMemo(
  groupId: number,
  articleId: number,
  docType: DocType,
): Promise<void> {
  const params = docType ? `?doc_type=${docType}` : '';
  await axios.delete<void>(
    `/groups/${groupId}/articles/${articleId}/memos${params}`,
  );
}

export async function fetchGroupTags(
  groupId: number | undefined,
  allArticleCount?: boolean | undefined,
  abortController?: AbortController,
): Promise<Tag[]> {
  if (!groupId) return [];
  const params = allArticleCount ? `?all_article_count=${allArticleCount}` : '';
  const options =
    abortController !== undefined ? { signal: abortController.signal } : {};
  try {
    const { data } = await axios.get<{ tags: Tag[] }>(
      `/groups/${groupId}/tags${params}`,
      options,
    );
    return data.tags;
  } catch (e) {
    if (axios.isCancel(e)) return [];
    throw e;
  }
}

export async function updateGroupArticleTags(
  groupId: number,
  articleId: number,
  docType: DocType,
  tags: string[],
  articleLang: Lang,
): Promise<void> {
  await axios.put<void>(`/groups/${groupId}/articles/${articleId}/tags`, {
    tags: tags,
    doc_type: docType ?? 'article',
    article_lang: articleLang,
  });
}

export async function createGroupTag(
  groupId: number,
  tagName: string,
  articleId?: number,
): Promise<GroupTag> {
  const { data } = await axios.post<GroupTag>(`/groups/${groupId}/group_tag`, {
    article_id: articleId,
    tag_name: tagName,
  });
  return data;
}
export async function editGroupTag(
  groupTagId: number,
  tagName: string,
): Promise<GroupTag> {
  const { data } = await axios.put<GroupTag>(
    `/groups/group_tag/${groupTagId}`,
    {
      tag_name: tagName,
    },
  );
  return data;
}

export async function deleteGroupTag(groupTagId: number): Promise<void> {
  await axios.delete<void>(`/groups/group_tag/${groupTagId}`);
}
