import dayjs from 'dayjs';

export const getToday = (): Date => dayjs().startOf('day').toDate();

export function getADayAgo(): Date {
  return new Date(+new Date() - 1000 * 60 * 60 * 24);
}
export function getAWeekAgo(): Date {
  return new Date(+new Date() - 1000 * 60 * 60 * 24 * 7);
}
export function getAMonthAgo(): Date {
  return new Date(getNMonthsAgo(getToday(), 1));
}
export function getThreeMonthsAgo(): Date {
  return new Date(getNMonthsAgo(getToday(), 3));
}
export function getAHalfYearAgo(): Date {
  return new Date(getNMonthsAgo(getToday(), 6));
}
export function getAYearAgo(): Date {
  return new Date(getNYearsAgo(getToday(), 1));
}
export function getTwoYearsAgo(): Date {
  return new Date(getNYearsAgo(getToday(), 2));
}
export const getNMonthsAgo = (date: Date, n: number): number => {
  const result = new Date(date);
  result.setMonth(result.getMonth() - n);

  // 期間なので、nヶ月前の次の日を基準にする
  result.setDate(result.getDate() + 1);

  return +result;
};
export const getNYearsAgo = (date: Date, n: number): number => {
  const result = new Date(date);
  result.setFullYear(result.getFullYear() - n);

  // 期間なので、nヶ年前の次の日を基準にする
  result.setDate(result.getDate() + 1);

  return +result;
};
