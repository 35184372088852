<script setup lang="ts">
import { computed, ref } from 'vue';
import api from '@/api/index';
import { DgrCheckbox } from '@stockmarkteam/donguri-ui';
import { useSnackbar } from '@/components/common/snackbar/use-snackbar';
import Modal from '@/components/modals/modal.vue';

interface Props {
  requestId: string;
}

const props = defineProps<Props>();

const { createSnackbar } = useSnackbar();

const isPosFeedbackModalOpen = ref(false);
const isNegFeedbackModalOpen = ref(false);
const submittedEvaluation = ref<'positive' | 'negative' | undefined>(undefined);

const showFeedbackSubmittedSnackbar = () => {
  createSnackbar({
    message:
      submittedEvaluation.value === 'positive'
        ? 'この回答を気に入っていただき、ありがとうございます'
        : 'フィードバックをいただきありがとうございます',
    type: 'success',
  });
};

const hideModal = () => {
  isPosFeedbackModalOpen.value = false;
  isNegFeedbackModalOpen.value = false;
  options.value.forEach(o => (o.selected = false));
  comment.value = '';
};

const startFeedback = async (evaluation: 'positive' | 'negative') => {
  if (submittedEvaluation.value === evaluation) {
    // フィードバックの取り消し
    submittedEvaluation.value = undefined;
    await api.deleteSummaryFeedback(props.requestId);
    return;
  } else if (
    submittedEvaluation.value !== undefined &&
    submittedEvaluation.value !== evaluation
  ) {
    // フィードバックの変更（例： positive -> negative）
    await api.deleteSummaryFeedback(props.requestId);
  }

  if (evaluation === 'positive') {
    isPosFeedbackModalOpen.value = true;
    submittedEvaluation.value = 'positive';
  } else {
    isNegFeedbackModalOpen.value = true;
    submittedEvaluation.value = 'negative';
  }
  await api.postSummaryFeedback(props.requestId, evaluation);
};

const options = ref([
  {
    selected: false,
    label: '情報が古い',
  },
  {
    selected: false,
    label: '情報が正しくない',
  },
  {
    selected: false,
    label: '検索ワードに対して、情報の関連度が低い',
  },
  {
    selected: false,
    label: '文章が流暢ではない',
  },
]);
const selectedOptions = computed(() =>
  options.value
    .filter(o => o.selected)
    .map(o => o.label)
    .sort(),
);

const comment = ref('');

const isSubmitEnabled = computed(
  () => selectedOptions.value.length > 0 || comment.value.length > 0,
);

const sendPositiveFeedback = async () => {
  await api.postSummaryFeedback(
    props.requestId,
    'positive',
    undefined,
    comment.value,
  );
  showFeedbackSubmittedSnackbar();
  hideModal();
};

const sendNegativeFeedback = async () => {
  await api.postSummaryFeedback(
    props.requestId,
    'negative',
    selectedOptions.value,
    comment.value,
  );
  showFeedbackSubmittedSnackbar();
  hideModal();
};
</script>

<template>
  <div class="feedback">
    <div class="spacing-04"></div>
    <div class="feedback-message-container">
      <div class="feedback-message c-text c-text--s">
        要約のご意見をお聞かせください。<br />
        フィードバックは回答生成の改善に役立たせていただきます。
      </div>
      <div class="feedback-buttons">
        <button
          class="feedback-button c-text--s"
          :class="{
            selected: submittedEvaluation === 'positive',
          }"
          @click="startFeedback('positive')"
        >
          この要約が気に入った
        </button>
        <div class="spacing-04"></div>
        <button
          class="feedback-button c-text--s"
          :class="{
            selected: submittedEvaluation === 'negative',
          }"
          @click="startFeedback('negative')"
        >
          この要約は参考にならない
        </button>
      </div>
    </div>
    <div class="spacing-04"></div>
    <Teleport to="#modal-teleport-target">
      <Modal
        class="feedback-modal"
        v-if="isPosFeedbackModalOpen"
        :show-header="false"
        @close="hideModal"
      >
        <template #body>
          <div class="content">
            <div class="header">
              <div class="c-title c-title--xm">ご意見をお聞かせください</div>
              <div class="spacing-04"></div>
              <div class="subheader c-text c-text--s">
                いただいたフィードバックは、回答生成の改善に役立てさせていただきます
              </div>
            </div>

            <div>
              <p class="c-text c-text--m">気に入った点はどこですか？</p>
              <div class="spacing-04"></div>
              <textarea
                v-model.trim="comment"
                class="c-textArea"
                placeholder="コメントを書く"
                rows="5"
              ></textarea>
            </div>
            <div class="footer c-text c-text--m">
              <button class="c-btn c-btn--auto c-btnOutline" @click="hideModal">
                キャンセル
              </button>
              <button
                class="c-btn c-btn--auto c-btn--AnewsPrimary"
                :class="{ disabled: !isSubmitEnabled }"
                :disabled="!isSubmitEnabled"
                @click="sendPositiveFeedback"
              >
                フィードバックを送信する
              </button>
            </div>
          </div>
        </template>
      </Modal>
      <Modal
        class="feedback-modal"
        v-if="isNegFeedbackModalOpen"
        :show-header="false"
        @close="hideModal"
      >
        <template #body>
          <div class="content">
            <div class="header">
              <div class="c-title c-title--m">ご意見をお聞かせください</div>
              <div class="subheader c-text c-text--s">
                いただいたフィードバックは、回答生成の改善に役立てさせていただきます
              </div>
            </div>
            <div class="c-text c-text--m checkbox-option">
              <DgrCheckbox
                v-for="option in options"
                :key="option.label"
                v-model="option.selected"
                >{{ option.label }}</DgrCheckbox
              >
            </div>
            <div class="c-formBlock">
              <p class="c-text c-text--m">どのような問題がありましたか？</p>
              <div class="spacing-04"></div>
              <textarea
                v-model.trim="comment"
                class="c-textArea"
                placeholder="コメントを書く"
                rows="5"
              ></textarea>
            </div>
            <div class="footer c-text c-text--m">
              <button class="c-btn c-btn--auto c-btnOutline" @click="hideModal">
                キャンセル
              </button>
              <button
                class="c-btn c-btn--auto c-btn--AnewsPrimary"
                :class="{ disabled: !isSubmitEnabled }"
                :disabled="!isSubmitEnabled"
                @click="sendNegativeFeedback"
              >
                フィードバックを送信する
              </button>
            </div>
          </div>
        </template>
      </Modal>
    </Teleport>
  </div>
</template>

<style lang="scss" scoped>
.feedback {
  padding: 0px 8px;
  border-radius: 4px;
  .feedback-message-container {
    display: grid;
    grid-template-columns: 1fr auto;
    gap: 16px;
    .feedback-message {
      color: $color-gray800;
      display: flex;
      align-items: center;
    }
    .feedback-buttons {
      display: flex;
      gap: 5px;
      .feedback-button {
        padding: 8px 16px;
        align-items: center;
        background-color: $color-white;
        border: 1px solid $color-gray400;
        border-radius: 4px;
        cursor: pointer;
        &:hover {
          background-color: $color-gray400;
        }
      }
      .selected {
        background-color: $color-gray400;
      }
    }
  }
}
.feedback-modal {
  .content {
    display: flex;
    flex-direction: column;
    gap: 16px;
    padding: 16px 0;

    .subheader {
      color: $color-gray600;
    }
    .checkbox-option {
      display: flex;
      flex-direction: column;
      gap: 8px;
    }

    textarea {
      width: 100%;
    }

    .footer {
      display: flex;
      justify-content: flex-end;
      .c-btn:last-child {
        margin-left: 16px;
      }
    }
  }
}
</style>
