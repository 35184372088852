<script lang="ts" setup>
import { computed, inject, Ref, ref, watch } from 'vue';
import { useRoute } from 'vue-router';
import api from '@/api';
import { TrackingAutocompleteData } from '@/api/tracking';
import { KEYWORD_COMPLETION_DEBOUNCE_TIME } from '@/constants';
import { DgrIcon } from '@stockmarkteam/donguri-ui';
import { CompletionItem } from '@/types';
import { useSearchHistories } from '@/utils/composables/useSearchHistories';
import { useKeyboardNavigation } from '@/utils/composables/useSearchSuggestionKeyboard';
import { DebounceRef } from '@/utils/debounce';
import { escapeRegExpSpecialCharacters } from '@/utils/regexp';
import { useKeywordCompletion } from '@/utils/swr';
import { userSession } from '@/utils/userSession';

interface Props {
  modelValue: string;
}

const props = withDefaults(defineProps<Props>(), {
  modelValue: '',
});

const emit = defineEmits<{
  'update:modelValue': [value: string];
  search: [];
  'delete-history': [];
}>();

const route = useRoute();

const ITEM_HEIGHT = 40;
const ITEM_ROW_MAX_NUM = 5;
const dropdownHeight = (itemsLength: number) =>
  `${Math.min(itemsLength, ITEM_ROW_MAX_NUM) * ITEM_HEIGHT}px`;

// 検索窓
const inputValue = ref(props.modelValue);
const isEditing = ref(false);
const originalValue = ref<string>();
const handledValue = computed(() => originalValue.value ?? inputValue.value);

// 検索履歴
const historyRefs = ref<HTMLElement[]>();
const useSearchHistory = inject('useSearchHistory') as Ref<boolean>;
const isHistoryEnabled = ref(false);
const isDisplayedHistories = computed(
  () => isHistoryEnabled.value && !shouldFetchNewHistories.value,
);
const hoveredIndex = ref(-1);
const shouldFetchNewHistories = ref(false);
const {
  fetchLatestSearchHistories,
  createSearchHistory,
  deleteSearchHistory,
  searchHistories,
} = useSearchHistories(userSession.getUserId());

// autocomplete
const autocompleteRefs = ref<HTMLElement[]>();
const isAutocompleteEnabled = ref(false);
const debouncedInput = DebounceRef(
  computed(() => ({ text: handledValue.value })),
  KEYWORD_COMPLETION_DEBOUNCE_TIME,
);
const { data: completionsData } = useKeywordCompletion(debouncedInput);
const completions = computed<CompletionItem[]>(() => {
  if (completionsData.value === undefined) return [];
  return completionsData.value.candidates
    .filter(item => item.text !== handledValue.value)
    .map(item => {
      const splitter = new RegExp(
        '(' + escapeRegExpSpecialCharacters(handledValue.value) + ')',
        'i',
      );
      const words = item.text.split(splitter).filter(w => w.trim() !== '');
      return {
        keyword: item.text,
        splitKeywords: [words[0], words.slice(1).join('')],
        score: item.score,
      };
    })
    .sort((a, b) => b.score - a.score);
});

watch(
  () => props.modelValue,
  async value => {
    inputValue.value = value;
    originalValue.value = value;
    // NOTE: 検索履歴の再取得は、検索が実行された後の1回のみに抑制する
    if (inputValue.value.trim() === '' && shouldFetchNewHistories.value) {
      await fetchLatestSearchHistories();
      shouldFetchNewHistories.value = false;
    }
  },
);

const onInput = (event: Event) => {
  const target = event.target as HTMLInputElement;
  if (target) {
    inputValue.value = target.value;
    originalValue.value = target.value;
    emit('update:modelValue', target.value);
    updateEditingState(target.value);
  }
};

const onFocus = () => {
  isHistoryEnabled.value = inputValue.value.trim() === '';
  isAutocompleteEnabled.value = inputValue.value.trim() !== '';
};

let isComposing = false;
const onCompositionStart = () => {
  isHistoryEnabled.value = false;
  isComposing = true;
};
const onCompositionEnd = () => {
  isComposing = false;
};

const onBlur = (event: FocusEvent) => {
  // blurですぐに閉じてしまうとdropdown上のクリックイベントが拾えないので少しずらす
  setTimeout(() => {
    const relatedTarget = event.relatedTarget as HTMLElement;
    if (!relatedTarget || !relatedTarget.classList.contains('delete-button')) {
      resetState();
    }
  }, 200);
};

const onKeyDown = async (event: KeyboardEvent) => {
  if (isComposing) return;

  if (isAutocompleteEnabled.value) {
    onAutocompleteKeyDown(event, completions.value.length, selectAutocomplete);
    if (autocompleteFocusIndex.value === -1) {
      inputValue.value = originalValue.value ?? '';
    } else {
      inputValue.value =
        completions.value[autocompleteFocusIndex.value].keyword;
      const autocomplete = autocompleteRefs.value?.find(
        b => Number(b.dataset.index) === autocompleteFocusIndex.value,
      );
      autocomplete?.scrollIntoView({
        block: 'nearest',
      });
    }
  } else if (isHistoryEnabled.value) {
    onHistoryKeyDown(event, searchHistories.value.length, selectHistory);
    if (historyFocusIndex.value === -1) {
      inputValue.value = originalValue.value ?? '';
    } else {
      inputValue.value = searchHistories.value[historyFocusIndex.value].query;
      const history = historyRefs.value?.find(
        b => Number(b.dataset.index) === historyFocusIndex.value,
      );
      history?.scrollIntoView({
        block: 'nearest',
      });
    }
  }
};

const handlers = {
  input: onInput,
  focus: onFocus,
  compositionstart: onCompositionStart,
  compositionend: onCompositionEnd,
  blur: onBlur,
  keydown: onKeyDown,
};

// キーボード操作のhooks
const {
  focusIndex: historyFocusIndex,
  onKeyDown: onHistoryKeyDown,
  resetIndex: historyResetIndex,
} = useKeyboardNavigation();
const {
  focusIndex: autocompleteFocusIndex,
  onKeyDown: onAutocompleteKeyDown,
  resetIndex: autocompleteResetIndex,
} = useKeyboardNavigation();

const selectHistory = async (index: number) => {
  const value = searchHistories.value[index]?.query || inputValue.value;
  if (value.trim() === '') return;
  useSearchHistory.value = true;
  await search(value);
};

const selectAutocomplete = async (index: number) => {
  const value = completions.value[index]?.keyword || inputValue.value;
  if (value.trim() === '') return;
  await search(value);

  // tracking
  if (index >= 0) {
    const trackData: TrackingAutocompleteData = {
      pageName: route.name ?? '',
      pageUrl: route.fullPath,
      keywords: [value],
      input_text: handledValue.value,
      themeId: route.params.themeId ? Number(route.params.themeId) : undefined,
      rank: index + 1, // ログは1はじまりのルール
    };
    await api.trackAutocompleteEvent(trackData);
  }
};

const search = async (value: string) => {
  inputValue.value = value;
  emit('update:modelValue', value);
  emit('search');
  await createSearchHistory(value);
  shouldFetchNewHistories.value = true;
  isEditing.value = false;
  isHistoryEnabled.value = false;
  isAutocompleteEnabled.value = false;
};

const deleteHistory = async (query: string) => {
  inputValue.value = '';
  resetFocus();
  await deleteSearchHistory(query);
  await fetchLatestSearchHistories();
  emit('delete-history');
};

const resetFocus = () => {
  originalValue.value = inputValue.value;
  resetIndex();
};

const resetIndex = () => {
  historyResetIndex();
  autocompleteResetIndex();
};

const updateEditingState = (value: string) => {
  isEditing.value = value.trim() !== '';
  isHistoryEnabled.value = value.trim() === '';
  isAutocompleteEnabled.value = value.trim() !== '';
  resetIndex();
};

const resetState = () => {
  isEditing.value = false;
  isHistoryEnabled.value = false;
  isAutocompleteEnabled.value = false;
  resetFocus();
};
</script>

<template>
  <div>
    <slot :value="inputValue" :handlers="handlers">
      <input
        class="c-text c-text--m c-textInput"
        :value="inputValue"
        v-on="handlers"
      />
    </slot>
    <!-- 検索履歴 -->
    <div
      v-if="isDisplayedHistories && searchHistories.length > 0"
      class="dropdown"
      :class="{ open: true }"
      :style="{ '--dropdown-height': dropdownHeight(searchHistories.length) }"
    >
      <div
        v-for="(searchHistory, index) in searchHistories"
        :key="`${index}_${searchHistory.query}`"
        ref="historyRefs"
        class="item"
        :class="{ focused: historyFocusIndex === index }"
        :data-index="index"
        @click="selectHistory(index)"
        @keydown="onKeyDown"
        @mouseover="hoveredIndex = index"
        @mouseleave="hoveredIndex = -1"
      >
        <div class="item-content">
          <DgrIcon class="clock-icon" name="clock" :size="'default'" />
          <span class="c-text c-text--m search-history">{{
            searchHistory.query
          }}</span>
        </div>
        <button
          type="button"
          class="delete-button"
          @click.stop="deleteHistory(searchHistory.query)"
          v-if="historyFocusIndex === index || hoveredIndex === index"
        >
          <DgrIcon class="delete-icon" name="times" :size="'xs'" />
        </button>
      </div>
    </div>
    <!-- キーワード補完 -->
    <div
      v-if="isAutocompleteEnabled && completions.length > 0"
      class="dropdown"
      :class="{ open: true }"
      :style="{ '--dropdown-height': dropdownHeight(completions.length) }"
    >
      <div
        v-for="(completionItem, index) in completions"
        :key="`${index}_${completionItem.keyword}`"
        ref="autocompleteRefs"
        class="item"
        :class="{ focused: autocompleteFocusIndex === index }"
        :data-index="index"
        @click="selectAutocomplete(index)"
        @keydown="onKeyDown"
      >
        <div class="item-content">
          <DgrIcon class="search-icon" name="search" />
          <span class="c-text c-text--m">{{
            completionItem.splitKeywords[0]
          }}</span>
          <span class="c-title c-title--m">{{
            completionItem.splitKeywords[1]
          }}</span>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.dropdown {
  position: absolute;
  z-index: var(--z-dropdown);
  background: white;
  width: 100%;
  max-width: 520px; // keyword-search-bar.vueの".c-textInput"に合わせる
  max-height: 0;
  box-shadow: 0px 1px 5px rgba(74, 74, 74, 0.25);
  border-radius: 4px;
  transition: max-height 0.1s ease-out;
  overflow: auto;

  &.open {
    max-height: var(--dropdown-height);
  }
}

.item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 40px;
  max-width: 520px;
  border: none;
  background: white;
  padding: 0 8px;
  margin: 0;

  &:hover {
    background: $color-gray200;
    cursor: pointer;
  }

  &.focused {
    background: $color-gray400;
  }
}

.item-content {
  display: flex;
  align-items: center;
  flex-grow: 1;
  padding: 0;
  overflow: hidden;
  white-space: nowrap;
}

.clock-icon,
.search-icon {
  flex-shrink: 0;
  margin-right: 8px;
}

.delete-button {
  background: none;
  border: none;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0;
  flex-shrink: 0;
  margin: 0 8px;
}

.delete-icon {
  flex-shrink: 0;
}

.search-history {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  flex-grow: 1;
  min-width: 0;
}
</style>
