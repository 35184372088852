import { VueGtag } from 'vue-gtag';
import {
  FeedType,
  isTeamMarkSort,
  TeamMarkSortType,
  ThemeTopic,
} from '@/types';
import { gaTracker } from '@/utils/ga';
import { LabsReportSetting } from './labs/reports/types';

const keyPrefix = 'UserSession';

export const userSession = {
  getUserId(): number | null {
    const value = localStorage.getItem(`${keyPrefix}.UserId`);
    if (value != null) {
      return Number(value);
    }
    return null;
  },
  getTeamId(): number | null {
    const value = localStorage.getItem(`${keyPrefix}.TeamId`);
    if (value != null) {
      return Number(value);
    }
    return null;
  },
  getTeamIdAsString(): string | null {
    const value = this.getTeamId();
    if (value === null) {
      return null;
    }
    return value.toString();
  },
  getFeedType(): FeedType {
    const isFeedType = (x: string): x is FeedType =>
      ['all', 'domestic', 'foreign'].includes(x);
    const gotFeedType = localStorage.getItem(`${keyPrefix}.FeedType`);
    if (gotFeedType === null || !isFeedType(gotFeedType)) {
      return 'domestic';
    }
    return gotFeedType;
  },
  setFeedType(feedType: string): void {
    localStorage.setItem(`${keyPrefix}.FeedType`, feedType);
  },
  getIndustryId(): string | null {
    return localStorage.getItem(`${keyPrefix}.IndustryId`);
  },
  setIndustryId(industryId: number): void {
    localStorage.setItem(`${keyPrefix}.IndustryId`, industryId.toString());
  },
  getIsShownTranslateNotification(): boolean {
    return Boolean(
      parseInt(
        localStorage.getItem(`${keyPrefix}.IsShownTranslateNotification`) ??
          '0',
      ),
    );
  },
  setIsShownTranslateNotification(): void {
    localStorage.setItem(`${keyPrefix}.IsShownTranslateNotification`, '1');
  },
  getMarkNewsSelectedUserNames(): string[] {
    return JSON.parse(
      localStorage.getItem(`${keyPrefix}.MarkNewsSelectedUserNames`) ?? '[]',
    );
  },
  setMarkNewsSelectedUserNames(userIds: string[]): void {
    localStorage.setItem(
      `${keyPrefix}.MarkNewsSelectedUserNames`,
      JSON.stringify(userIds),
    );
  },
  start(userId: number, teamId: number, gtag: VueGtag) {
    if (!userId || !teamId) return;
    localStorage.setItem(`${keyPrefix}.UserId`, userId.toString());
    localStorage.setItem(`${keyPrefix}.TeamId`, teamId.toString());
    gaTracker.config(gtag);
  },
  isActive() {
    // TODO 本当はSession Cookieの存在有無を見るべきだが一旦LocalStorageを見る
    const user_id = localStorage.getItem(`${keyPrefix}.UserId`);
    const team_id = localStorage.getItem(`${keyPrefix}.TeamId`);
    return user_id != null && team_id != null;
  },
  clear(gtag: VueGtag) {
    Object.keys(localStorage).forEach(key => {
      if (!key.includes('LabsReportSetting')) {
        localStorage.removeItem(key);
      }
    });
    gaTracker.clearConfig(gtag);
  },
  setThemeListState(
    accessScope: string,
    id: number | null,
    isOpen: boolean,
  ): void {
    localStorage.setItem(
      `${accessScope}${id ? `-${id}` : ''}`,
      isOpen.toString(),
    );
  },
  getThemeListState(accessScope: string, id: number | null): boolean {
    const storedValue = localStorage.getItem(
      `${accessScope}${id ? `-${id}` : ''}`,
    );
    return storedValue ? storedValue === 'true' : true;
  },
  setLeftMenuIsExpanded(isExpanded: boolean): void {
    localStorage.setItem(
      `${keyPrefix}.LeftMenuIsExpanded`,
      isExpanded.toString(),
    );
  },
  getLeftMenuIsExpanded(): boolean {
    const isExpanded = localStorage.getItem(`${keyPrefix}.LeftMenuIsExpanded`);
    return isExpanded ? isExpanded === 'true' : true;
  },
  setLeftReportMenuIsExpanded(isExpanded: boolean): void {
    localStorage.setItem(
      `${keyPrefix}.LeftReportMenuIsExpanded`,
      isExpanded.toString(),
    );
  },
  getLeftReportMenuIsExpanded(): boolean {
    const isExpanded = localStorage.getItem(
      `${keyPrefix}.LeftReportMenuIsExpanded`,
    );
    return isExpanded ? isExpanded === 'true' : true;
  },
  setLeftLabsMenuIsExpanded(isExpanded: boolean): void {
    localStorage.setItem(
      `${keyPrefix}.LeftLabsMenuIsExpanded`,
      isExpanded.toString(),
    );
  },
  getLeftLabsMenuIsExpanded(): boolean {
    const isExpanded = localStorage.getItem(
      `${keyPrefix}.LeftLabsMenuIsExpanded`,
    );
    return isExpanded ? isExpanded === 'true' : true;
  },
  setSelectedThemeTopic(selectedThemeTopic: ThemeTopic): void {
    localStorage.setItem(
      `${keyPrefix}.SelectedThemeTopic`,
      JSON.stringify(selectedThemeTopic),
    );
  },
  getSelectedThemeTopic(): ThemeTopic | undefined {
    const selectedThemeTopic = localStorage.getItem(
      `${keyPrefix}.SelectedThemeTopic`,
    );
    return selectedThemeTopic ? JSON.parse(selectedThemeTopic) : undefined;
  },
  deleteSelectedThemeTopic() {
    localStorage.removeItem(`${keyPrefix}.SelectedThemeTopic`);
  },
  setShowThemeSupportContent(showThemeSupportContent: boolean): void {
    localStorage.setItem(
      `${keyPrefix}.ShowThemeSupportContent`,
      JSON.stringify(showThemeSupportContent),
    );
  },
  getShowThemeSupportContent(): boolean {
    const showThemeSupportContent = localStorage.getItem(
      `${keyPrefix}.ShowThemeSupportContent`,
    );
    return showThemeSupportContent ? showThemeSupportContent === 'true' : true;
  },
  setTeamMarkSort(sort: TeamMarkSortType) {
    localStorage.setItem(`${keyPrefix}.TeamMarkSort`, sort);
  },
  getTeamMarkSort(): TeamMarkSortType | null {
    const sort = localStorage.getItem(`${keyPrefix}.TeamMarkSort`);
    if (sort && isTeamMarkSort(sort)) {
      return sort;
    }
    return null;
  },
  setLabsReportSetting(setting: LabsReportSetting): void {
    const userId = this.getUserId();
    if (userId === null) return;
    localStorage.setItem(
      `${keyPrefix}.LabsReportSetting-user${userId}`,
      JSON.stringify(setting),
    );
  },
  getLabsReportSetting(): LabsReportSetting | null {
    const userId = this.getUserId();
    const setting = localStorage.getItem(
      `${keyPrefix}.LabsReportSetting-user${userId}`,
    );
    return setting ? JSON.parse(setting) : null;
  },
  setIsOpenedFromAdpDocumentCardLink(
    isOpenedFromAdpDocumentCardLin: boolean,
  ): void {
    localStorage.setItem(
      `${keyPrefix}.IsOpenedFromAdpDocumentCardLink`,
      JSON.stringify(isOpenedFromAdpDocumentCardLin),
    );
  },
  getIsOpenedFromAdpDocumentCardLink(): boolean {
    const isOpenedFromAdpDocumentCardLin = localStorage.getItem(
      `${keyPrefix}.IsOpenedFromAdpDocumentCardLink`,
    );
    return isOpenedFromAdpDocumentCardLin
      ? isOpenedFromAdpDocumentCardLin === 'true'
      : false;
  },
  getLastUsedGroupId(): number | undefined {
    const lastUsedGroupId = localStorage.getItem(
      `${keyPrefix}.LastUsedGroupId`,
    );
    return lastUsedGroupId !== null ? Number(lastUsedGroupId) : undefined;
  },
  setLastUsedGroupId(groupId: number): void {
    localStorage.setItem(`${keyPrefix}.LastUsedGroupId`, String(groupId));
  },
  getFollowListCommentsVisible(): boolean {
    const followListCommentsVisible = localStorage.getItem(
      `${keyPrefix}.followListCommentsVisible`,
    );
    if (followListCommentsVisible === null) {
      return false;
    }
    // 文字列trueが入っている場合はtrue、それ以外はfalseとして扱う
    return followListCommentsVisible.toLowerCase() === 'true';
  },
  setFollowListCommentsVisible(isVisible: boolean): void {
    // localStorageにはbooleanをそのまま保存できないため、文字列に変換して保存
    localStorage.setItem(
      `${keyPrefix}.followListCommentsVisible`,
      String(isVisible),
    );
  },
  getLabsSideMenuFolderState(): Array<string> {
    const labsSideMenuFolderState = localStorage.getItem(
      `${keyPrefix}.LabsSideMenuFolderState`,
    );
    return labsSideMenuFolderState ? JSON.parse(labsSideMenuFolderState) : [];
  },
  setLabsSideMenuFolderState(labsSideMenuFolderState: Array<string>): void {
    localStorage.setItem(
      `${keyPrefix}.LabsSideMenuFolderState`,
      JSON.stringify(labsSideMenuFolderState),
    );
  },
};
