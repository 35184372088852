import Dexie, { Table } from 'dexie';
import {
  SEARCH_EXPANDED_QUERY_CACHE_EXPIRATION,
  SEARCH_EXPANDED_QUERY_CACHE_STORE_NAME,
  USER_DOCUMENT_SEARCH_EXPANDED_QUERY_CACHE_STORE_NAME,
} from '../constants';

export interface SearchExpandedQuery {
  query: string;
  expandedQueries: Array<string>;
  createdAt: Date;
}

class SearchExpandedQueryCache extends Dexie {
  searchExpandedQueries: Table<SearchExpandedQuery>;
  userDocumentSearchExpandedQueries: Table<SearchExpandedQuery>;

  constructor() {
    super('SearchExpandedQueryDB');
    this.version(2).stores({
      searchExpandedQueries: '&query',
      userDocumentSearchExpandedQueries: '&query',
    });
  }

  async getCache(
    query: string,
    store:
      | typeof SEARCH_EXPANDED_QUERY_CACHE_STORE_NAME
      | typeof USER_DOCUMENT_SEARCH_EXPANDED_QUERY_CACHE_STORE_NAME = SEARCH_EXPANDED_QUERY_CACHE_STORE_NAME,
  ) {
    const caches = await this[store].where('query').equals(query);
    const cache = await caches.first();
    if (!cache) return null;
    const now = new Date();
    const expiration = new Date(
      cache.createdAt.getTime() + SEARCH_EXPANDED_QUERY_CACHE_EXPIRATION,
    );
    if (now > expiration) {
      caches.delete();
      return null;
    } else return cache.expandedQueries;
  }

  async setCache(
    query: string,
    expandedQueries: Array<string>,
    store:
      | 'searchExpandedQueries'
      | 'userDocumentSearchExpandedQueries' = 'searchExpandedQueries',
  ) {
    return this[store].add({
      query,
      expandedQueries,
      createdAt: new Date(),
    });
  }
}

export const searchExpandedQueryCache = new SearchExpandedQueryCache();
