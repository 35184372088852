<script lang="ts">
import { computed, defineComponent, nextTick, PropType, ref, Ref } from 'vue';
import { Feature } from '@/api/tracking';
import { EMPTY_KEYWORD_FEED_MESSAGE } from '@/constants';
import { DgrLoading, DgrSelectbox } from '@stockmarkteam/donguri-ui';
import dayjs, { Dayjs } from 'dayjs';
import AdpDocumentCard from '@/components/common/adp-document-card.vue';
import BasicEmptyFeed from '@/components/common/basic-empty-feed.vue';
import ThemeEmptyFeed from '@/components/common/theme-empty-feed.vue';
import {
  Article,
  ArticleMarkSource,
  FeedType,
  Theme,
  ThemeFeedType,
} from '@/types';
import { articleHideable, themeEditable } from '@/utils/authorization';
import { useGroups, useUserInfo } from '@/utils/swr';

type FeedData = {
  articles: Article[];
  feedCount: number;
  isLoaded: boolean;
  isFetchingData: boolean;
  isViewMoreButtonVisible: boolean;
  remainingArticles: number;
  load: () => void;
  loadMore: () => void;
};

type DateArticles = {
  date: Dayjs;
  dateStr: string;
  dateLabel: string;
  domestic: Ref<FeedData>;
  foreign: Ref<FeedData>;
};

export default defineComponent({
  props: {
    articlesByDate: {
      type: Array as PropType<DateArticles[]>,
      default: () => [],
    },
    articles: {
      type: Array as PropType<Article[]>,
      default: () => [],
    },
    articleCount: { type: Number, default: 0 },
    theme: { type: Object as PropType<Theme> },
    date: { type: Dayjs, required: true },
    dateStr: { type: String, required: true },
    dateLabel: { type: String, required: true },
    feedData: { type: Object as PropType<FeedData>, required: true },
    isVisibleEmptyFeed: { type: Boolean, default: false },
    feed: {
      type: String as PropType<
        'keyword_feed' | 'related_feed' | 'personal_news'
      >,
      required: true,
    },
    displayFeedType: { type: String as PropType<FeedType>, required: true },
    themeFeedType: { type: String as PropType<ThemeFeedType>, required: true },
  },
  components: {
    AdpDocumentCard,
    BasicEmptyFeed,
    ThemeEmptyFeed,
    DgrSelectbox,
    DgrLoading,
  },
  emits: {
    'select-date': (_dateStr: string) => true,
  },
  setup(props, context) {
    const currentDate = ref(props.dateStr);
    const selectDate = (value: string | undefined) => {
      if (value === undefined) return;

      context.emit('select-date', value);

      // セレクトボックスの表示を強制的に戻すために、
      // 選択された値を代入してから元の値を入れ直して変更検知を走らせる
      currentDate.value = value;
      nextTick(() => {
        currentDate.value = props.dateStr;
      });
    };

    const dateOptions = computed(() =>
      props.articlesByDate.map(d => ({
        label: d.dateLabel,
        value: d.dateStr,
      })),
    );

    const rankInWholeFeed = (
      date: dayjs.Dayjs,
      article: Article,
    ): number | undefined => {
      if (!article.rank) {
        return undefined;
      }
      // 対象記事の前日のフィードまでの合計記事数を計算し、それに記事のrankを足す
      const lengthList = props.articlesByDate
        .filter(d => d.date.isAfter(date))
        .map(d => d[props.themeFeedType].value.articles.length);
      return lengthList.length > 0
        ? lengthList.reduce((sum, n) => sum + n) + article.rank
        : article.rank;
    };

    const markSource: ArticleMarkSource =
      props.feed === 'personal_news' ? 'personal_feed' : 'theme';
    const feature: Feature =
      props.feed === 'personal_news' ? 'personal_feed' : props.feed;

    const { data: userInfo } = useUserInfo();
    const { data: groups } = useGroups();

    const enableToEditTheme = computed(
      () =>
        userInfo.value?.role !== 'viewer' ||
        (props.theme?.access_scope === 'personal' &&
          props.theme?.user_id === userInfo.value?.id),
    );

    // 記事を非表示にすることができる
    const isArticleHideable = computed(() => {
      const groupId = props.theme?.group_id;
      const themeGroup = groups.value?.groups?.find(x => x.id === groupId);
      return articleHideable(userInfo.value, props.theme, themeGroup);
    });

    // 記事のメディアを除外することができる
    const isSiteExcludable = computed(() => {
      const groupId = props.theme?.group_id;
      const themeGroup = groups.value?.groups?.find(x => x.id === groupId);
      return themeEditable(userInfo.value, props.theme, themeGroup);
    });

    return {
      currentDate,
      selectDate,
      dateOptions,
      rankInWholeFeed,
      markSource,
      feature,
      enableToEditTheme,
      isArticleHideable,
      isSiteExcludable,
      EMPTY_KEYWORD_FEED_MESSAGE,
    };
  },
});
</script>

<template>
  <div class="feed-articles-media">
    <div class="media-title c-title c-title--xm">
      <div class="media-title-name">
        {{ themeFeedType === 'domestic' ? '国内メディア' : '海外メディア' }}
      </div>
      <div class="date-select-box">
        <DgrSelectbox
          :options="dateOptions"
          :model-value="currentDate"
          @update:model-value="selectDate"
        />
      </div>
      <div class="media-title-count c-text c-text--s">
        ・{{ articleCount }}件
      </div>
    </div>
    <div class="article-card" v-for="article in articles" :key="article.id">
      <div class="divider"></div>
      <AdpDocumentCard
        width="100%"
        :adp-document="article"
        :theme-id="theme ? theme.id : undefined"
        :theme-excluded-sites-count="
          theme ? theme.site_exclusion_ids.length : undefined
        "
        :feed-type="themeFeedType"
        :rank-in-whole-feed="rankInWholeFeed(date, article)"
        :show-comment-count="1"
        :mark-source="markSource"
        :page-name="feed"
        :feature="feature"
        :hide-undisplay-button="!isArticleHideable"
        :enable-site-exclusion-button="isSiteExcludable"
        :disable-related-articles="false"
      ></AdpDocumentCard>
    </div>
    <div style="display: flex; justify-content: center">
      <div
        v-if="feedData.isViewMoreButtonVisible"
        class="view-more-button c-text c-text--m"
        @click="() => feedData.loadMore()"
      >
        すべて表示（{{ feedData.remainingArticles }}）
      </div>
    </div>
    <div v-if="feedData.isViewMoreButtonVisible" class="spacing-16" />
    <DgrLoading
      v-if="!feedData.isLoaded || feedData.isFetchingData"
      class="loading loading-spacer"
    />
    <template v-if="isVisibleEmptyFeed">
      <div class="divider"></div>
      <ThemeEmptyFeed
        v-if="theme"
        :display-feed-type="displayFeedType"
        :theme-feed-type="themeFeedType"
        doc-type="article"
        :theme="theme"
        :enable-to-edit-theme="enableToEditTheme"
        >{{
          feed === 'keyword_feed'
            ? EMPTY_KEYWORD_FEED_MESSAGE
            : '関連ニュースはありませんでした。'
        }}
      </ThemeEmptyFeed>
      <BasicEmptyFeed v-else>{{ EMPTY_KEYWORD_FEED_MESSAGE }}</BasicEmptyFeed>
    </template>
  </div>
</template>

<style scoped lang="scss">
.feed-articles-media {
  position: relative;
  background: #fff;
  border-radius: 4px;
  margin-bottom: 20px;
}
.media-title {
  display: flex;
  align-items: center;
  position: sticky;
  top: 112px;
  background: #fff;
  z-index: 1;
  padding-left: 16px;
  border-radius: 4px;
}
.media-title-count {
  color: $color-gray600;
}
.date-select-box {
  /* 強制的にセレクトボックスのスタイルを変える */
  :deep(.c-selectBox) {
    border: none;
    font-weight: 700;
  }
}

.article-card {
  margin-bottom: 8px;
  .document-card {
    border: 0;
  }
}

.divider {
  width: auto;
  border-bottom: 0.5px solid $color-gray400;
  margin: 0px 16px 0px 16px;
}

.view-more-button {
  display: inline-flex;
  align-self: center;
  padding: 4px 12px;
  border-radius: 4px;
  border: 1px solid $color-green600;
  background: #fff;
  color: $color-green600;
  cursor: pointer;
}

.loading {
  align-self: center;
}
.loading-spacer {
  padding-bottom: 80vh;
}
</style>
