import { ref, Ref, UnwrapRef, watch } from 'vue';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const debounce = <F extends (...args: any[]) => unknown>(
  fn: F,
  wait: number,
): ((...args: Parameters<F>) => void) => {
  let timer: number | null = null;
  return (...args) => {
    if (timer) {
      window.clearTimeout(timer);
    }

    timer = window.setTimeout(() => {
      fn(...args);
    }, wait);
  };
};

const toShallowRaw = <T>(value: T): T => {
  // FIXME: [...value]がT & any[]に推論されるのでas unknown as Tで回避
  if (value instanceof Array) return [...value] as unknown as T;

  if (typeof value === 'object') return { ...value };
  return value;
};

export const DebounceRef = <T>(r: Ref<T>, wait: number): Ref<UnwrapRef<T>> => {
  const debounceRef = ref(toShallowRaw(r.value));

  watch(
    r,
    debounce(newValue => {
      debounceRef.value = toShallowRaw(newValue);
    }, wait),
    { deep: true },
  );

  return debounceRef;
};
